import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toMaxDecimals } from 'services/utils/numbers';
import { ReactComponent as CrossIcon } from 'assets/custom-icons/cross.svg';
import { ReactComponent as MagnifierIcon } from 'assets/custom-icons/magnifier.svg';
import { ITokenListProps } from './types';
/* eslint-disable */
export const TokenList: FC<ITokenListProps> = ({
	title,
	list,
	onChange,
	onClose,
	openManage,
	network,
}) => {
	const [search, setSearch] = useState('');

	const filteredList = useMemo(() => {
		const normalizedSearch = search.toLowerCase().trim();

		return list?.filter(({ asset }: any) => {
			const isSymbolMatch = asset.symbol.toLowerCase().includes(normalizedSearch);
			const isNameMatch = asset.name.toLowerCase().includes(normalizedSearch);
			return isSymbolMatch || isNameMatch;
		});
	}, [list, search]);

	return (
		<div className="popup popup--smaller popup--padding-16 swap-token-list">
			<div className="popup-header">
				<p className="popup-header__title">Select a token</p>
				<button className="popup-close" type="button" onClick={onClose}>
					<CrossIcon />
				</button>
			</div>

			<div className="popup-body">
				<div className="popup-search">
					<div className="popup-search__input">
						<input
							type="text"
							className="input-item input-item--search input-item--left-icon"
							placeholder="Search by Token"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>

						<span className="input-icon">
							<MagnifierIcon />
						</span>
					</div>
				</div>

				<div
					className={classNames('balance-list', {
						'balance-list--centered': !filteredList?.length,
					})}
				>
					{filteredList?.map((token) => (
						<div key={`${String(token?.asset.address) + String(title)}`} className="balance-item">
							<button
								type="button"
								className="token-block token-block--biger"
								onClick={() => onChange(token)}
							>
								<span className="token-block__icon">
									<img src={token.asset.logo} alt="" />
									{/* <img src={usdtImg} alt="" /> */}
								</span>

								<span className="token-block__info">
									<span className="token-block__name">{token.asset.symbol}</span>
									<span className="token-block__purpose">{network?.network_name}</span>
								</span>
							</button>

							{/* {token.balance ? (
								<div className="balance-item__value">{toMaxDecimals(token.balance, 12) || 0}</div>
							) : (
								<div className="balance-item__value">0</div>
							)} */}
						</div>
					))}

					{!filteredList?.length && <p className="swap-token-list__no-tokens">No tokens found</p>}
				</div>
			</div>
		</div>
	);
};
