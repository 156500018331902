/* eslint-disable */
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { initializeConnector } from '@web3-react/core';
import { CHAIN_TO_URL_MAP, CHAIN_INFO } from './constants';
import { Connection, ConnectionType, onConnectionError } from './connections';

const [chainid, ...optionalChains] = Object.keys(CHAIN_INFO).map(Number);

export function buildWalletConnectConnector() {
	const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnectV2>(
		(actions) =>
			new WalletConnectV2({
				actions,
				options: {
					projectId: String('a5df8e672197214fd08f40b35a65ed55'),
					chains: [4287, 97],
					rpcMap: CHAIN_TO_URL_MAP,
					optionalChains,
					showQrModal: true,
				},
				onError: onConnectionError,
			}),
	);
	const walletConnectConnection: Connection = {
		connector: web3WalletConnect,
		hooks: web3WalletConnectHooks,
		type: ConnectionType.WALLET_CONNECT,
	};
	return walletConnectConnection;
}
/*eslint-disable */
// import { initializeConnector } from '@web3-react/core'
// import { WalletConnect } from '@web3-react/walletconnect'

// import { Connection, ConnectionType, onConnectionError } from './connections'
// import { CHAIN_TO_URL_MAP } from './constants'

// export function buildWalletConnectConnector() {
//   const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnect>(
//     (actions) =>
//       new WalletConnect({
//         actions,
//         options: {
//           rpc: CHAIN_TO_URL_MAP,
//           qrcode: true,
//         },
//         onError: onConnectionError,
//       })
//   )
//   const walletConnectConnection: Connection = {
//     connector: web3WalletConnect,
//     hooks: web3WalletConnectHooks,
//     type: ConnectionType.WALLET_CONNECT,
//   }
//   return walletConnectConnection
// }
