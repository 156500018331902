/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GRAPH_HOUR_API } from './constants';
import {
	TAssetsResponsePayload,
	TAssetsPairsRequestData,
	TAssetsPairsResponsePayload,
	TCoinsStore,
	TAssetsByIdRequestData,
	TGraphPairsRequestData,
	TGraphPairsResponsePayload,
	TGraphPeriod,
	TTokenAddressRequest,
	TAssets,
	TAssetsItem,
} from './types';
// ==========================================:
export const initialState: TCoinsStore = {
	assets: null,
	assetsLoader: false,
	assetsById: [],
	assetsByIdLoader: false,
	assetsPairs: null,
	assetsPairsLoader: false,
	graphPairs: null,
	graphPairsLoader: false,
	graphPeriod: GRAPH_HOUR_API,
	assetsPairsId: 1,
	statusToken: '',
	tokenLoader: false,
};

// ==========================================:
const coins = createSlice({
	name: '@@coins',
	initialState,
	reducers: {
		getAssetsRequest: (state) => {
			const assetsRequestState = state;
			assetsRequestState.assetsLoader = true;
		},
		getAssetsSuccess: (state, action: PayloadAction<TAssets>) => {
			const { payload } = action;
			const assetsSuccessState = state;
			assetsSuccessState.assets = payload;
			assetsSuccessState.assetsLoader = false;
		},
		getAssetsByIdRequest: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const assetsPairsByIdState = state;
			assetsPairsByIdState.assetsPairsLoader = true;
		},
		getAssetsByIdSuccess: (state, action: PayloadAction<TAssetsResponsePayload>) => {
			const { payload } = action;
			const assetsPairsByIdState = state;
			assetsPairsByIdState.assetsById = payload.data;
			assetsPairsByIdState.assetsByIdLoader = false;
		},

		getAssetsPairsRequest: (state, action: PayloadAction<TAssetsPairsRequestData>) => {
			const { payload } = action;
			const assetsPairsRequestState = state;
			assetsPairsRequestState.assetsPairsLoader = true;
		},
		getAssetsPairsSuccess: (state, action: PayloadAction<TAssetsPairsResponsePayload>) => {
			const { payload } = action;
			const assetsPairsSuccessState = state;

			assetsPairsSuccessState.assetsPairs = payload;
			assetsPairsSuccessState.assetsPairsLoader = false;
		},

		getGraphPairsRequest: (state) => {
			// , action: PayloadAction<TGraphPairsRequestData>
			const graphPairsRequestState = state;
			graphPairsRequestState.graphPairsLoader = true;
		},
		getGraphPairsSuccess: (state, action: PayloadAction<TGraphPairsResponsePayload>) => {
			const { payload } = action;
			const graphPairsSuccessState = state;

			graphPairsSuccessState.graphPairs = payload;
			graphPairsSuccessState.graphPairsLoader = false;
		},
		changeGraphPeriod: (state, action: PayloadAction<TGraphPeriod>) => {
			const { payload } = action;
			const graphPeriod = state;
			graphPeriod.graphPeriod = payload;
		},
		changeAssetsPairsId: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const assetsPairsId = state;
			assetsPairsId.assetsPairsId = payload;
		},

		getAddTokenAdress: (state, action: PayloadAction<TTokenAddressRequest>) => {
			const tokenState = state;
		},
		getAddTokenSuccess: (state, action: PayloadAction<any>) => {
			const tokenState = state;
			const { payload } = action;
			tokenState.tokenLoader = false;
			if (payload) {
				tokenState.statusToken = 'success';
			}
		},
		getAddTokenFalure: (state) => {
			const tokenState = state;

			tokenState.tokenLoader = false;

			tokenState.statusToken = 'falure';
		},
		setChangeStatus: (state) => {
			const tokenState = state;
			tokenState.tokenLoader = false;
			tokenState.statusToken = '';
		},

		assetsInitState: () => initialState,
	},
});

export default coins.reducer;
export const {
	getAssetsRequest,
	getAssetsSuccess,
	getAssetsByIdRequest,
	getAssetsByIdSuccess,
	getAssetsPairsRequest,
	getAssetsPairsSuccess,
	getGraphPairsRequest,
	getGraphPairsSuccess,
	changeGraphPeriod,
	changeAssetsPairsId,
	assetsInitState,
	getAddTokenAdress,
	getAddTokenSuccess,
	getAddTokenFalure,
	setChangeStatus,
} = coins.actions;
