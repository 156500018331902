import React, { FC } from 'react';
import { format } from 'date-fns';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	Tooltip,
	LineElement,
	PointElement,
	Filler,
	ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ITransactionsGraph } from 'services/api/blockchain/types';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Filler, Tooltip);

export const options = {
	responsive: true,
	elements: {
		point: {
			borderWidth: 0,
			backgroundColor: 'rgba(0, 0, 0, 0)',
		},
		line: {
			borderColor: 'rgba(51,91,233,1)',
			backgroundColor: (context: ScriptableContext<'line'>) => {
				const { ctx } = context.chart;
				const gradient = ctx.createLinearGradient(0, 0, 0, 200);
				gradient.addColorStop(0, 'RGBA(36,50,107,1)');
				gradient.addColorStop(1, 'RGBA(36,50,107,0)');
				return gradient;
			},
			fill: true,
			borderWidth: 2,
		},
	},
	scales: {
		x: {
			ticks: {
				color: '#fff',
				fontWeight: 'bold',
				maxTicksLimit: 3,
				maxRotation: 0,
				minRotation: 0,
			},
			grid: {
				display: false,
			},
		},
		y: {
			ticks: { color: '#fff', fontWeight: 'bold' },
			grid: {
				display: false,
			},
		},
	},
};

interface IChartLineProps {
	dataChart: ITransactionsGraph[];
}

export const ChartHome: FC<IChartLineProps> = ({ dataChart }) => {
	const labels = dataChart?.map((el) => format(new Date(el.date), 'dd MMM'));

	const data = {
		labels,
		datasets: [
			{
				data: dataChart.map((el) => el.number_of_transactions),
			},
		],
	};
	return <Line options={options} data={data} />;
};
