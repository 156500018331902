/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import axios from 'axios';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { setLS, getLS } from 'components/Exchange/LiquidityForm/utils';
import { notificationContainer } from 'services/utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import {
	TAssetsResponsePayload,
	TAssetsPairsRequestPayload,
	TAssetsPairsResponsePayload,
	TGraphPairsResponsePayload,
	TGraphPeriod,
	TTokenResponse,
	TToken,
	TAssets,
	TTokenAddressRequest,
} from './types';
import {
	assetsInitState,
	getAssetsRequest,
	getAssetsSuccess,
	getAssetsPairsRequest,
	getAssetsPairsSuccess,
	getAssetsByIdSuccess,
	getAssetsByIdRequest,
	getGraphPairsSuccess,
	getGraphPairsRequest,
	changeAssetsPairsId,
	getAddTokenAdress,
	getAddTokenSuccess,
	getAddTokenFalure,
} from './reducer';
import { getAssetsPairsId, getGraphPairsPeriod } from './selectors';

function* getAssetsWorker() {
	try {
		const response: TAssets = yield call(api.assets.getAssets);

		yield put(getAssetsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(assetsInitState());
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:
function* assetsByIdRequestWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		// yield put(showLoading());
		const response: TAssetsResponsePayload = yield call(api.assets.getAssetsById, payload);

		yield put(getAssetsByIdSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(assetsInitState());
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:
function* assetsPairsRequestWorker(action: PayloadAction<TAssetsPairsRequestPayload>) {
	const { payload } = action;

	try {
		// yield put(showLoading());
		const response: TAssetsPairsResponsePayload = yield call(api.assets.getAssetsPairs, payload);

		yield put(getAssetsPairsSuccess(response));
		yield put(changeAssetsPairsId(response?.[0]?.increment ?? 0));
		yield put(getGraphPairsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(assetsInitState());
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:
function* graphPairsRequestWorker() {
	// action: PayloadAction<TGraphPairsRequestPayload>
	// const { payload } = action;

	try {
		// yield put(showLoading());
		const assetsPairsId: string = yield select(getAssetsPairsId);
		const graphPairsPeriod: TGraphPeriod = yield select(getGraphPairsPeriod);
		const payloadTmp = { idPairs: assetsPairsId, period: graphPairsPeriod };

		const response: TGraphPairsResponsePayload = yield call(api.assets.getGraph, payloadTmp);

		yield put(getGraphPairsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(assetsInitState());
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:
function* getAddTokenAdressWorker(action: PayloadAction<TTokenAddressRequest>) {
	try {
		const { payload } = action;
		const response: TTokenResponse = yield call(api.assets.postAddToken, payload);

		if (response) {
			const tokenGets: Array<TToken> = getLS('tokens');
			let newArrayTokens: Array<TToken> = [];
			// debugger;
			if (tokenGets) {
				const dublicate = tokenGets.filter((token) => token.address === response?.token?.address);
				if (dublicate.length) {
					yield put(getAddTokenSuccess(response));
					return;
				}
				newArrayTokens = [...tokenGets, response?.token];
			} else {
				newArrayTokens.push(response.token);
			}
			setLS('tokens', newArrayTokens);
			yield put(getAddTokenSuccess(response));
		}
		notificationContainer('The address add', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(getAddTokenFalure());
			if (error?.response?.data?.errors[0] === 'error_add_token') {
				notificationContainer('Error add token', 'info');
				return;
			}
			if (error?.response?.data?.errors[0] === 'error_add_token') {
				notificationContainer('Error add token', 'info');
				return;
			}
			if (
				error?.response?.data?.errors?.address &&
				error?.response?.data?.errors?.address[0] === 'the_address_has_already_been_taken'
			) {
				notificationContainer('The address has already been taken', 'info');
				return;
			}

			if (error?.response?.data?.errors) {
				yield put(assetsInitState());
				responseErrors(error);
				return;
			}
			yield put(assetsInitState());
			responseErrors(error);
		}
		yield put(assetsInitState());
	} finally {
		// yield put(hideLoading());
	}
}

export function* assetsSaga() {
	yield takeEvery(getAssetsRequest.type, getAssetsWorker);
	yield takeEvery(getAssetsByIdRequest.type, assetsByIdRequestWorker);
	yield takeEvery(getAssetsPairsRequest.type, assetsPairsRequestWorker);
	yield takeEvery(getGraphPairsRequest.type, graphPairsRequestWorker);
	yield takeEvery(getAddTokenAdress.type, getAddTokenAdressWorker);
}
