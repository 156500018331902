import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { format, formatDistance } from 'date-fns';

import { ArrowLeft, ArrowRight, CopySvg, TooltipInformation } from 'assets/custom-icons';
import TransactionsBlock from 'components/Transactions/TransactionsBlock';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import {
	getBlockRequest,
	getBlocksPrevNextRequest,
	getTransactionsRequest,
} from 'redux/reducers/blockchain/reducer';
import { getBlock, getBlocksPrevNext } from 'redux/reducers/blockchain/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { Link } from 'react-router-dom';
import { ITransaction } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';

const Block = () => {
	const dispatch = useDispatch();
	const block = useSelector(getBlock);
	const prevNext = useSelector(getBlocksPrevNext);

	const { hash } = useParams();
	const [currentTransactions, setCurrentTransactions] = useState<ITransaction[] | null>(null);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const perPage = 6;

	const moneyFormat = useMoneyFormat();

	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};

	useEffect(() => {
		if (block?.transaction) {
			setItemOffset((currentPage - 1) * perPage);
			const endOffset = itemOffset + perPage;
			setCurrentTransactions(block?.transaction.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(block.transaction.length / perPage));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [block?.transaction, currentPage]);

	useEffect(() => {
		if (hash) {
			dispatch(getBlockRequest(hash));
		}
		window.scrollTo(0, 0);
	}, [dispatch, hash]);

	useEffect(() => {
		dispatch(getTransactionsRequest({ current_page: String(currentPage) }));
	}, [currentPage, dispatch]);

	useEffect(() => {
		if (block) {
			dispatch(getBlocksPrevNextRequest(block.number));
		}
	}, [block, dispatch]);

	return (
		<section className="explore-home-section">
			<div className="container">
				<div className="explore-block explore-block--type2">
					<div className="explore-block__top">
						<h4 className="block-title block-title--mr-auto">Block Details</h4>
						<div className="block-pagination block-pagination--header">
							{prevNext?.prev ? (
								<Link to={`/blocks/${prevNext?.prev}`} className="block-pagination__btn active">
									<ArrowLeft />
								</Link>
							) : (
								<span className="block-pagination__btn">
									<ArrowLeft />
								</span>
							)}
							<p className="block-pagination__text">Block {block?.number}</p>
							{prevNext?.next ? (
								<Link to={`/blocks/${prevNext?.next}`} className="block-pagination__btn active">
									<ArrowRight />
								</Link>
							) : (
								<span className="block-pagination__btn">
									<ArrowRight />
								</span>
							)}
						</div>
					</div>

					<div className="block-details-wrap">
						<div className="block-details-list">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												The block height of a particular block is defined as the number of blocks
												preceding it in the blockchain.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Block Height
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{block?.number}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Date & time at which block was produced.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Timestamp
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>
											{block &&
												`${formatDistance(Date.parse(block?.timestamp), new Date(), {
													addSuffix: true,
												})} | ${format(Date.parse(block.timestamp), 'MMMM-dd-YYY ppp')}`}
										</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												The number of transactions in the block.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transactions
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status info-status--fw-500 info-status--fs-14">
										{block?.transactionCount} Transactions
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												A blockchain validator is someone who is responsible for verifying
												transactions on a blockchain
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Validator
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<p>{block?.miner_hash}</p>
										<CopyToClipboard
											text={String(block?.miner_hash)}
											onCopy={() => handleCopy('Validator Hash')}
										>
											<button
												type="button"
												className="block-details-value__copy-btn"
												aria-label="Copy"
											>
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">Size of the block in bytes.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Size
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{moneyFormat(block?.size, 0, 0)} bytes</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="block-details-name__icon">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">The SHA256 hash of the block.</span>
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Hash
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<p>{block?.hash}</p>
										<CopyToClipboard text={String(block?.hash)} onCopy={() => handleCopy('Hash')}>
											<button
												type="button"
												className="block-details-value__copy-btn"
												aria-label="Copy"
											>
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="block-details-name__icon">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													The hash of the block from which this block was generated.
												</span>
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Parent Hash
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<p>{block?.parent_hash}</p>
										<CopyToClipboard
											text={String(block?.parent_hash)}
											onCopy={() => handleCopy('Parent Hash')}
										>
											<button
												type="button"
												className="block-details-value__copy-btn"
												aria-label="Copy"
											>
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Block difficulty for miner, used to calibrate block generation time (Note:
												constant in POA based networks).
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Difficulty
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{moneyFormat(block?.difficulty, 0, 0)}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Total difficulty of the chain until this block.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Total Difficulty
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{moneyFormat(block?.total_difficulty, 0, 0)}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												The total gas amount used in the block and its percentage of gas filled in
												the block.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Gas Used
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>
											{moneyFormat(block?.gas_used, 0, 0)} |{' '}
											{moneyFormat(
												(Number(block?.gas_used) * 100) / Number(block?.gas_limit),
												2,
												2,
											)}
											%
										</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Total gas limit provided by all transactions in the block.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Gas Limit
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{moneyFormat(block?.gas_limit, 0, 0)}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												64-bit hash of value verifying proof-of-work (note: null for POSA chains).
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Nonce
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{block?.nonce}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Minimum fee required per unit of gas. Fee adjusts based on network
												congestion.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Base Fee per Gas
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{block?.base_fee_per_gas} Gwei</p>
									</div>
								</div>
							</div>
						</div>
						<div className="block-details-list block-details-list--type2">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Minimum fee required per unit of gas. Fee adjusts based on network
												congestion.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Miner Reward
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{block?.fee} GATO</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="block-pagination block-pagination--footer">
						{prevNext?.prev ? (
							<Link to={`/blocks/${prevNext?.prev}`} className="block-pagination__btn active">
								<ArrowLeft />
							</Link>
						) : (
							<span className="block-pagination__btn">
								<ArrowLeft />
							</span>
						)}
						<p className="block-pagination__text">Block {block?.number}</p>
						{prevNext?.next ? (
							<Link to={`/blocks/${prevNext?.next}`} className="block-pagination__btn active">
								<ArrowRight />
							</Link>
						) : (
							<span className="block-pagination__btn">
								<ArrowRight />
							</span>
						)}
					</div>
				</div>
				<div className="explore-block">
					<div className="explore-block__top">
						<h4 className="block-title block-title--mr-auto">Transactions</h4>
						<PaginationTable
							className="block-pagination--header"
							currentPage={currentPage}
							lastPage={pageCount}
							setCurrentPage={setCurrentPage}
						/>
					</div>
					{currentTransactions && <TransactionsBlock itemsList={currentTransactions} />}
				</div>
			</div>
		</section>
	);
};

export default Block;
