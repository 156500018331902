/* eslint-disable */
export const getGasData = async ({ tokenAddress }: any) => {
	try {
		const APP_EXPLORER = process.env.REACT_APP_EXPLORER;

		const gasData = await (
			await fetch(`${APP_EXPLORER}/api/transfer-fee?asset_address=${tokenAddress}`)
		).json();

		return gasData;
	} catch (err) {
		console.error(err);
	}
};
