import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransactionClaim } from 'components/Transactions/TransactionsModals/type';
import { ethers } from 'ethers';
/* eslint-disable */

export type TClaim = { firstTokenAddress: string; firstTokenAmount: string };

const { BigNumber, utils } = ethers;

export const claimApprove = async ({
	wallet,
	abi,
	to,
	firstTokenAmount,
	amount,
	provider,
	deadline,
	setOpenTransactionModal,
}: ITypeTransactionClaim) => {
	try {
		setOpenTransactionModal(true);

		const dedl =
			// String(BigNumber.from(deadline)) ||
			String(BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'));

		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, to);

		// const messageHash = web3.utils.soliditySha3(String(amount), wallet, firstTokenAmount);
		debugger;
		const claim = await contract.methods
			.getMessageHash(String(amount), wallet, firstTokenAmount)
			.call();
		const claim1 = await contract.methods.getEthSignedMessageHash(claim).call();
		const result = await web3.eth.sign(claim1, wallet);
		// const result = await web3.eth.sign(claim, wallet);

		notificationContainer('Claim done', 'success');

		setOpenTransactionModal(false);
		return result;
	} catch (error: any) {
		setOpenTransactionModal(false);
		debugger;
		notificationContainer(`Claim failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};

export const getSelector = async ({ abi, to, provider }: any) => {
	try {
		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, to);

		const result = await contract.methods.SELECTOR_B2C().call();

		return result;
	} catch (error: any) {
		notificationContainer(`Selector failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};
