import { FC } from 'react';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import gatochain2 from 'assets/images/gatochain2.svg';
import gusd from 'assets/images/tokens/gusd.jpg';
import { TReferralItemReferrals, TReferralItem } from 'redux/reducers/referral/types';
import { TItem } from './types';

/* eslint-disable */;

export const Item: FC<TItem> = ({ item, id, currentCoin }) => {
	const totalSum = (num: TReferralItemReferrals, name: string) => {
		const total = num?.reduce(
			(sum: number, i: any) => (sum = Number(sum) + Number(i[`${name}`])),
			0,
		);
		return total;
	};
	return (
		<div className="tr">
			<div className="td td--users">
				<div className="users-block">
					<div className="users">
						{item?.length
							? item.map((ref, ids: number) => {
									return (
										ids <= 4 && (
											<div key={ref.id} className="users__item">
												<img src={ref?.avatar || loginAvatar} alt="" />
											</div>
										)
									);
							  })
							: null}
							
						{item.length > 4 ? <span className="users__count">{item.length - 5}+</span> : null}
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Infinity Network Members</p>
				<p>{item.length}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Level</p>
				<p>{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total transactions</p>
				<p>
					{currentCoin === 'COIN'
						? `${totalSum(item, 'transactionsCount')}` || '0'
						: `${totalSum(item, 'transactionsGusdCount')}` || '0'}
				</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Revenue</p>
				<p>{item[0]?.feeCollected?.revenue}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total TX fees</p>
				<div className="table-td-wrapper">
					{/* <p>
						{currentCoin === 'COIN'
							? totalSum(item, 'feeCollected') || `0`
							: totalSum(item, 'feeGusdCollected') || `0`}
						{currentCoin === 'COIN' ? '' : ' GUSD'}
					</p> */}
					<p>
						{currentCoin === 'COIN'
							? item[0]?.feeCollected?.fee || `0`
							: item[0]?.feeGusdCollected?.fee || `0`}
						{currentCoin === 'COIN' ? '' : ' GUSD'}
					</p>

					<span className="gato-token gato-token--type4">
						{currentCoin === 'COIN' ? <img src={gatochain2} alt="" /> : <img src={gusd} alt="" />}
					</span>
				</div>
			</div>
		</div>
	);
};
