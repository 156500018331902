/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
	CheckCircleSvg,
	ClockSvg,
	CopySvg,
	TooltipInformation,
	TooltipNotation,
} from 'assets/custom-icons';
import { getBlocksCount, getTransaction } from 'redux/reducers/blockchain/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getBlocksCountRequest, getTransactionRequest } from 'redux/reducers/blockchain/reducer';
import { useParams } from 'react-router';
import { capitalize, shortNotation } from 'services/utils/textSlice';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { format, formatDistance } from 'date-fns';
import classNames from 'classnames';
import { getAssetsRequest } from 'redux/reducers/coins/reducer';
import { TAssetsItem } from 'redux/reducers/coins/types';
import { getAssetsList } from 'redux/reducers/coins/selectors';
import { bigNumberFormater } from 'services/utils/numbers';
import TransfersTab from './TransfersTab';
import InternalTransactionsTab from './InternalTransactionsTab';
import LogTab from './LogTab';

enum ETabTitle {
	'Token Transfers',
	'Internal Transactions',
	'Logs',
}

const Transaction = () => {
	const dispatch = useDispatch();
	const { hash } = useParams();
	const transaction = useSelector(getTransaction);
	const lastNumberBlock = useSelector(getBlocksCount);
	const assetsList = useSelector(getAssetsList);
	const moneyFormat = useMoneyFormat();
	const [activeTab, setActiveTab] = useState(ETabTitle[0]);
	const handleTabClick = (value: string) => {
		setActiveTab(value);
	};
	const handleCopy = (text: string) => {
		notificationContainer(`${text} copied successfully!`, 'info');
	};

	const gatoCoin = useMemo(() => {
		if (!assetsList) return null;
		return assetsList.find((item: TAssetsItem) => item.symbol === 'GUSD' && item.type === 'token');
	}, [assetsList]);

	const inputDecode = transaction?.input_decoded ? JSON.parse(transaction?.input_decoded) : {};
	const status = transaction?.status || '';

	useEffect(() => {
		if (hash) {
			dispatch(getTransactionRequest(hash));
		}
	}, [dispatch, hash]);

	useEffect(() => {
		dispatch(getBlocksCountRequest());
		dispatch(getAssetsRequest());
		window.scrollTo(0, 0);
	}, [dispatch]);

	return (
		<section className="explore-home-section">
			<div className="container">
				<div className="explore-block explore-block--type2">
					<div className="explore-block__top">
						<h4 className="block-title block-title--mr-auto">Transaction Details</h4>
					</div>
					<div className="block-details-wrap">
						<div className="block-details-list">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Unique character string (TxID) assigned to every verified transaction.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transaction Hash
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<p>{transaction?.hash}</p>
										<CopyToClipboard
											text={String(transaction?.hash)}
											onCopy={() => handleCopy('Transaction Hash')}
										>
											<button type="button" className="block-details-value__copy-btn">
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Current transaction state: Success, Failed (Error), or Pending (In Process)
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Result
									</p>
								</div>
								<div className="block-details__right">
									<div className={`block-details-value block-details-value--${status}`}>
										<span className="block-details__icon">
											{transaction?.status === 'failed' ? <TooltipNotation /> : <CheckCircleSvg />}
										</span>
										<p>
											{transaction?.status === 'confirmed'
												? 'Success'
												: transaction?.status && capitalize(transaction?.status)}
										</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												The status of the transaction: Confirmed or Unconfirmed.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Status
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status-wrap">
										<div
											className={`info-status info-status--fw-500 info-status--fs-14 info-status--type2 info-status--${status} `}
										>
											{transaction && capitalize(transaction?.status)}
										</div>
										<div className="info-status info-status--fw-500 info-status--fs-14">
											Confirmed by{' '}
											{moneyFormat(lastNumberBlock - Number(transaction?.block_number), 0, 0)}
										</div>
									</div>
								</div>
							</div>

							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Block number containing the transaction.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Block
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{transaction?.block_number}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="block-details-name__icon">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													Date & time of transaction inclusion, including length of time for
													confirmation.
												</span>
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Timestamp
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<span className="block-details__icon">
											<ClockSvg />
										</span>
										<p>
											{transaction &&
												`${formatDistance(Date.parse(transaction?.created_at), new Date(), {
													addSuffix: true,
												})} | ${format(
													Date.parse(transaction?.created_at),
													'MMMM-dd-YYY ppp',
												)}`}{' '}
											| Confirmed within &lt;= {transaction?.confirmationTime} seconds
										</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="block-details-name__icon">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													Address (external or contract) sending the transaction.
												</span>
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										From
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value block-details-value--copy ">
										<p>{transaction?.from_address_hash}</p>
										<CopyToClipboard
											text={String(transaction?.from_address_hash)}
											onCopy={() => handleCopy('Address From')}
										>
											<button type="button" className="block-details-value__copy-btn">
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
							{transaction?.asset && (
								<div className="block-details">
									<div className="block-details-name">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													Address (external or contract) receiving the transaction.
												</span>
											</span>
										</span>
										<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
											To
										</p>
									</div>
									<div className="block-details__right">
										{transaction?.to_address_hash ? (
											<div className="block-details-value block-details-value--border-dash">
												<p>{transaction && transaction?.to_address_hash}</p>
												<CopyToClipboard
													text={String(transaction?.to_address_hash)}
													onCopy={() => handleCopy('Address Interacted With')}
												>
													<button type="button" className="block-details-value__copy-btn">
														<CopySvg />
													</button>
												</CopyToClipboard>
											</div>
										) : (
											'---'
										)}
									</div>
								</div>
							)}

							{transaction?.token && (
								<div className="block-details">
									<div className="block-details-name">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													Address (external or contract) receiving the transaction.
												</span>
											</span>
										</span>
										<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
											Interacted With (To)
										</p>
									</div>
									<div className="block-details__right">
										{transaction?.to_address_hash ? (
											<div className="block-details-value block-details-value--border-dash">
												<p>
													{transaction && `${transaction?.token?.symbol.toUpperCase()}`}{' '}
													{transaction &&
														transaction?.to_address_hash &&
														shortNotation(transaction?.to_address_hash, 8, 6)}
												</p>
												<CopyToClipboard
													text={String(transaction?.to_address_hash)}
													onCopy={() => handleCopy('Address Interacted With')}
												>
													<button type="button" className="block-details-value__copy-btn">
														<CopySvg />
													</button>
												</CopyToClipboard>
											</div>
										) : (
											'---'
										)}
									</div>
								</div>
							)}

							{!!transaction?.token_transfer.length && (
								<div className="block-details block-details--flex-start">
									<div className="block-details-name">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<TooltipInformation />
											</span>
											<span className="tooltip tooltip--top tooltip--left">
												<span className="tooltip__text">
													List of token transferred in the transaction.
												</span>
											</span>
										</span>
										<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
											Tokens Transferred
										</p>
									</div>
									<div className="block-details__right block-details__right--collumn-gap">
										{transaction?.token_transfer.map((el) => (
											<React.Fragment key={el.id}>
												<div className="block-details-value">
													<p className="block-details-value__bold">From</p>
													<p>{el?.from_address_hash}</p>
													<CopyToClipboard
														text={String(el?.from_address_hash)}
														onCopy={() => handleCopy('Tokens Transferred From')}
													>
														<button type="button" className="block-details-value__copy-btn">
															<CopySvg />
														</button>
													</CopyToClipboard>
												</div>
												<div className="block-details-value">
													<p className="block-details-value__bold">To</p>
													<p>{el?.to_address_hash}</p>
													<CopyToClipboard
														text={String(el?.to_address_hash)}
														onCopy={() => handleCopy('Tokens Transferred To')}
													>
														<button type="button" className="block-details-value__copy-btn">
															<CopySvg />
														</button>
													</CopyToClipboard>
												</div>
												<div className="block-details-value">
													<p className="block-details-value__bold">For</p>
													<p>
														{bigNumberFormater(String(Number(el?.amount))) || 0}{' '}
														{el?.token ? el?.token.symbol.toUpperCase() : ''}
													</p>
												</div>
											</React.Fragment>
										))}
									</div>
								</div>
							)}
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Value sent in the native token (and USD) if applicable.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Value
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										{transaction?.platform_transaction ? (
											<p>
												{bigNumberFormater(String(Number(transaction?.value))) || 0} GATO ($
												{moneyFormat(Number(transaction?.valueInUsdFormatted), 2, 2)} USD)
											</p>
										) : (
											<p>
												{bigNumberFormater(String(Number(transaction?.value))) || 0} GATO ($
												{gatoCoin &&
													moneyFormat(
														Number(transaction?.value) *
															Number(
																transaction?.asset?.price_in_usd ||
																	transaction?.token?.asset?.price_in_usd ||
																	1,
															),
														2,
														2,
													)}{' '}
												USD)
											</p>
										)}
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">Total transaction fee.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transaction Fee
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value">
										{transaction?.platform_transaction &&
										transaction?.platform_transaction?.type === 'swap' &&
										transaction?.platform_transaction?.base_asset.symbol === 'GATO' ? (
											<p>
												{Number(transaction?.platform_transaction?.fee_payed) +
													Number(transaction?.fee?.fee)}{' '}
												{transaction?.platform_transaction?.base_asset.symbol} ( $
												{moneyFormat(
													(Number(transaction?.platform_transaction?.fee_payed) +
														Number(transaction?.fee?.fee)) *
														Number(transaction?.fee?.price_in_usd),
													2,
													2,
												)}{' '}
												USD)
											</p>
										) : (
											<p>
												{moneyFormat(transaction?.fee?.fee, 0, 8)} {transaction?.fee?.asset} ( $
												{gatoCoin &&
													moneyFormat(
														Number(transaction?.fee?.fee) * Number(transaction?.fee?.price_in_usd),
														2,
														2,
													)}{' '}
												USD)
											</p>
										)}
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Price per unit of gas specified by the sender. Higher gas prices can
												prioritize transaction inclusion during times of high usage.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Gas Price
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>
											{transaction?.fee?.gas_price && Number(transaction?.fee?.gas_price).toFixed()}{' '}
											Gwei
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="block-details-list block-details-list--type2">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Transaction number from the sending address. Each transaction sent from an
												address increments the nonce by 1.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Nonce
									</p>
									<div className="info-status info-status--fw-600 info-status--ml8">Position</div>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{transaction?.nonce}</p>
										<div className="info-status info-status--fw-600">{transaction?.position}</div>
									</div>
								</div>
							</div>
							<div className="block-details block-details--flex-start">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Binary data included with the transaction. See input / logs below for
												additional info.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Raw Input
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>HEX</p>
									</div>
									<div className="block-details-value ">
										<div className="block-details-textarea">
											<textarea readOnly value={transaction?.input || ''} />
										</div>
										<CopyToClipboard
											text={String(transaction?.input)}
											onCopy={() => handleCopy('Raw Input HEX')}
										>
											<button type="button" className="block-details-value__copy-btn">
												<CopySvg />
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{transaction?.input_decoded && (
					<div className="explore-block explore-block--type2">
						<div className="explore-block__top">
							<h4 className="block-title block-title--mr-auto">Input</h4>
						</div>

						<div className="input-table">
							<div className="input-table-tr">
								<div className="input-table-td">
									<p>Method Id</p>
								</div>
								<div
									style={{ wordBreak: 'break-all' }}
									className="input-table-td input-table-td--red input-table-td--fz12"
								>
									<div>{transaction.input}</div>
								</div>
							</div>
							<div className="input-table-tr">
								<div className="input-table-td">
									<p>Call</p>
								</div>
								<div className="input-table-td input-table-td--red input-table-td--fz12">
									<p>{inputDecode.params}</p>
								</div>
							</div>
							<div className="input-table-tr input-table-tr--hidden">
								<div className="input-table-td input-table-td--fw600 input-table-td--p0">
									<div className="input-table-wrapper">
										<div className="input-table-item">
											<p>Name</p>
										</div>
										<div className="input-table-item">
											<p>Type</p>
										</div>
									</div>
								</div>
								<div className="input-table-td input-table-td--fw600">
									<p>Data</p>
								</div>
							</div>
							{inputDecode.inputs.map((el: any) => (
								<div key={el.name} className="input-table-tr">
									<div className="input-table-td input-table-td--fw400 input-table-td--p0">
										<div className="input-table-wrapper">
											<div className="input-table-item">
												<p className="hide-name">Name:</p>
												<p>_{el.name}</p>
											</div>
											<div className="input-table-item">
												<p className="hide-name">Type:</p>
												<p>{el.type}</p>
											</div>
										</div>
									</div>
									<div className="input-table-td input-table-td--left ">
										<p className="hide-name">Data:</p>
										<div className="block-details-value block-details-value--copy ">
											{el.value && (
												<>
													<CopyToClipboard text={el.value} onCopy={() => handleCopy(el.name)}>
														<button type="button" className="block-details-value__copy-btn">
															<CopySvg />
														</button>
													</CopyToClipboard>
													<p>{el.value}</p>
												</>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				<div className="explore-block explore-block--p0">
					<div className="explore-block-tabs">
						{Object.keys(ETabTitle).map((el, idx) => (
							<button
								onClick={() => handleTabClick(ETabTitle[idx])}
								type="button"
								key={el}
								className={classNames('explore-block-tabs__item', {
									active: activeTab === ETabTitle[idx],
								})}
							>
								{ETabTitle[idx]}
							</button>
						))}
					</div>
					<div className="explore-block__inner">
						{activeTab === ETabTitle[0] && (
							<TransfersTab transferList={transaction?.token_transfer} />
						)}
						{activeTab === ETabTitle[1] && (
							<InternalTransactionsTab itemList={transaction?.internal_transaction} />
						)}
						{activeTab === ETabTitle[2] && <LogTab logList={transaction?.log} />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Transaction;
