import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

const persistConfig = {
	key: 'root',
	storage,
	// stateReconciler: hardSet,
	whitelist: ['auth'],
};
export { persistConfig };
