import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IEarningsApi } from './types';

export const earnings: IEarningsApi = {
	getStaking: (params) => http.get(endpoint.earnings.STAKING, { params }).then(({ data }) => data),
	addTransaction: (payload) => http.post(endpoint.earnings.STAKING_TRANSACTION, payload),
	calculateApy: (payload) =>
		http.post(endpoint.earnings.CALCULATE_APY, payload).then(({ data }) => data),
	getDividends: (params) =>
		http.get(endpoint.dividends.GET_DIVIDENDS, { params }).then(({ data }) => data),
	getProfits: (params) => http.get(endpoint.earnings.PROFITS, { params }).then(({ data }) => data),
	updateReward: (payload) =>
		http.post(endpoint.earnings.UPDATE_REWARD, payload).then(({ data }) => data),
	getClaimTransactions: (payload) =>
		http.post(endpoint.earnings.CLAIM_TRANSACTIONS, payload).then(({ data }) => data),
	addDividentsTransaction: (payload) => http.post(endpoint.dividends.STAKE, payload),
	getUnstakeTransactions: (payload) =>
		http.post(endpoint.earnings.UNSTAKE_TRANSACTIONS, payload).then(({ data }) => data),
};
