/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SwapIcon } from 'assets/custom-icons';
import b2cIcon from 'assets/custom-icons/type-icon/b2c_claim.jpg';
import dividendsIcon from 'assets/custom-icons/type-icon/dividends_claim.jpg';
import deposit from 'assets/images/deposit.jpg';
import transaction from 'assets/images/transfer.jpg';
import plus from 'assets/images/add-square.png';
import minus from 'assets/images/minus-square.png';
import stake from 'assets/images/stake.png';
import claimStake from 'assets/images/claim-stake.png';

import { utcDate } from 'services/utils/dateAndTimeHelpers';
import { TRecent } from './type';

export const RItem: FC<TRecent> = ({ item }) => {
	const { tx, status, base_asset, quote_asset, type, created_at, quote_amount, base_amount } = item;
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(!openModal);
	};
	const typeIcon = (typeO: string) => {
		if (typeO?.includes('swap')) {
			return <SwapIcon />;
		}
		if (typeO?.includes('deposit')) {
			return <img src={deposit} alt="" />;
		}
		if (typeO?.includes('add_liquidity')) {
			return <img src={plus} alt="" />;
		}
		if (typeO?.includes('delete_liquidity')) {
			return <img src={minus} alt="" />;
		}

		if (typeO?.includes('add_staking')) {
			return <img src={stake} alt="" />;
		}
		if (typeO?.includes('claim_infinity_referral')) {
			return <img src={b2cIcon} alt="" />;
		}
		if (typeO?.includes('Claim')) {
			return <img src={dividendsIcon} alt="" />;
		}
		if (typeO?.includes('claim_staking')) {
			return <img src={claimStake} alt="" />;
		}
		return <img src={transaction} alt="" />;
	};

	const typeTransaction = (typeO: string) => {
		if (typeO?.includes('swap')) {
			return (
				<p>
					{type} {base_asset?.symbol.toLocaleUpperCase()} to{' '}
					{quote_asset?.symbol.toLocaleUpperCase()}
				</p>
			);
		}
		if (typeO?.includes('add_liquidity')) {
			return (
				<p>
					Add Liquidity {base_asset?.symbol.toLocaleUpperCase()}/
					{quote_asset?.symbol.toLocaleUpperCase()}
				</p>
			);
		}
		if (typeO?.includes('delete_liquidity')) {
			return (
				<p>
					Delete Liquidity {base_asset?.symbol.toLocaleUpperCase()}/
					{quote_asset?.symbol.toLocaleUpperCase()}
				</p>
			);
		}
		if (typeO?.includes('claim_infinity_referral')) {
			return <p>Claim infinity referral {base_asset?.symbol.toLocaleUpperCase()}</p>;
		}
		if (typeO?.includes('Claim')) {
			return <p>Claim Dividends</p>;
		}

		if (typeO?.includes('allowance_hash')) {
			return <p>Approve {base_asset?.symbol.toLocaleUpperCase()}</p>;
		}

		if (typeO?.includes('add_staking')) {
			return <p> Stake</p>;
		}

		if (typeO?.includes('claim_staking')) {
			return <p> Claim Staking Reward </p>;
		}

		return (
			<p>
				{type} {base_asset?.symbol.toLocaleUpperCase()}
			</p>
		);
	};
	return (
		<div className="tr">
			<div className="td">
				<div className="token-box  token-box--gap-12">
					<div className="token-box__icon-3 ">
						<img className="token-box__icon-1" src={base_asset?.logo} alt="" />
					</div>
					<div className="token-box__name token-box__name--smaller">
						{quote_asset
							? `${base_asset?.symbol.toLocaleUpperCase()}-${quote_asset?.symbol.toLocaleUpperCase()}`
							: `${base_asset?.symbol.toLocaleUpperCase()}`}
					</div>
				</div>
				<p className="hiden-date">{utcDate(created_at)}</p>
			</div>
			<div className="td td--hide">
				<p className="td-hidden-name">Time</p>
				<p>{utcDate(created_at)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name td-hidden-name--low">Type</p>
				<div className="type-td">
					<span className="type-td__icon">{typeIcon(type)}</span>
					{typeTransaction(type)}
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Transaction</p>
				{quote_asset ? (
					<>
						<p>
							{Number(base_amount)?.toFixed(8)} {base_asset?.symbol.toLocaleUpperCase()}
						</p>
						<p>
							{Number(quote_amount)?.toFixed(8)} {quote_asset?.symbol?.toLocaleUpperCase()}
						</p>
					</>
				) : (
					`${Number(base_amount)?.toFixed(8)} ${base_asset?.symbol.toLocaleUpperCase()}`
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Txn Hash</p>
				<p>
					<NavLink to={`/transactions/${tx}`}>{tx}</NavLink>
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<p>{status}</p>
			</div>
		</div>
	);
};
