import React from 'react';
import { NavLink } from 'react-router-dom';
import { OverviewSt, StakingSt, InfinitySt, DividentSt } from 'assets/custom-icons';

const EarningNav = () => {
	return (
		<div className="switch-btns">
			<NavLink to="/earnings-overview" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<OverviewSt />
				</span>
				<span>Overview</span>
			</NavLink>
			<NavLink to="/staking" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<StakingSt />
				</span>
				<span>Staking</span>
			</NavLink>
			<NavLink to="/infinity" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<InfinitySt />
				</span>
				<span>Infinity Referral</span>
			</NavLink>
			<NavLink to="/dividends" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<DividentSt />
				</span>
				<span>Gato Dividends</span>
			</NavLink>
			<NavLink to="/token-manager" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<DividentSt />
				</span>
				<span>My token</span>
			</NavLink>
			<NavLink to="/stake-per-dividents" className="switch-btns__item">
				<span className="switch-btns__item-icon">
					<DividentSt />
				</span>
				<span>Lock per Dividends</span>
			</NavLink>
		</div>
	);
};

export default EarningNav;
