/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TRefarralStore,
	TReferallResopnse,
	TReferallWalletsResponse,
	TReferralItemRequest,
	TTranactonClaimRequest,
	TReferralRequest,
	TReferralB2BRequest,
	TReferralB2BResponse,
	TReferralB2BBalanceResponse,
	TB2CSignatureResponsce,
	Ib2cBodyRequest,
	TB2BTimeResponse,
	IB2BPayload,
} from './types';

// ==========================================:
export const initialState: TRefarralStore = {
	refferal: null,
	referralLoader: false,
	referralWallet: null,
	referralWalletLoader: false,
	referralB2B: null,
	referralB2BLoader: false,
	referralB2BBalance: null,
	referralB2CSignature: null,
	referralB2BSignature: null,
};

// ==========================================:
const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getReferralRequest: (state, action: PayloadAction<TReferralRequest | undefined>) => {
			const referralRequestState = state;
			referralRequestState.referralLoader = true;
		},
		getReferralWalletRequest: (state, action: PayloadAction<TReferralItemRequest>) => {
			const referralRequestState = state;
		},
		getReferralWalletSuccess: (state, action: PayloadAction<TReferallWalletsResponse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralWallet = payload;
		},
		setClaimLoader: (state, action: PayloadAction<boolean>) => {
			const referralRequestState = state;
			referralRequestState.referralWalletLoader = action.payload;
		},
		getReferralSuccess: (state, action: PayloadAction<TReferallResopnse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.refferal = payload;
			referralRequestState.referralLoader = false;
		},

		getReferralB2BRequest: (state, action: PayloadAction<TReferralB2BRequest | undefined>) => {
			const referralRequestState = state;
			referralRequestState.referralB2BLoader = true;
		},
		getReferralB2BSuccess: (state, action: PayloadAction<TReferralB2BResponse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralB2B = payload;
			referralRequestState.referralB2BLoader = false;
		},
		getReferralB2BBalanceRequest: (state) => {
			const referralRequestState = state;
		},
		getReferralB2BBalanceSuccess: (state, action: PayloadAction<TReferralB2BBalanceResponse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralB2BBalance = payload;
		},

		getReferralB2СSignatureRequest: (state, action: PayloadAction<Ib2cBodyRequest>) => {},
		getReferralB2СSignatureSuccess: (state, action: PayloadAction<TB2CSignatureResponsce>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralB2CSignature = payload;
		},
		getReferralB2BSignatureRequest: (state, action: PayloadAction<Ib2cBodyRequest>) => {},

		getReferralB2BSignatureSuccess: (state, action: PayloadAction<TB2CSignatureResponsce>) => {
			const { payload } = action;
			const referralRequestState = state;
			// referralRequestState.referralB2BSignature = payload;
		},

		getTrancationClaimRequest: (state, action: PayloadAction<TTranactonClaimRequest>) => {},
		getB2BDateRequest: (state, action: PayloadAction<IB2BPayload>) => {},
		getB2BDateSuccess: (state, action: PayloadAction<TB2BTimeResponse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralB2BSignature = payload;
		},
		getB2CDateRequest: (state, action: PayloadAction<IB2BPayload>) => {},
		getB2CDateSuccess: (state, action: PayloadAction<TB2BTimeResponse>) => {
			const { payload } = action;
			const referralRequestState = state;
			referralRequestState.referralB2BSignature = payload;
		},
		referralInitState: () => initialState,
	},
});

export default referral.reducer;
export const {
	getReferralRequest,
	referralInitState,
	getReferralWalletRequest,
	getReferralSuccess,
	getReferralWalletSuccess,
	getTrancationClaimRequest,
	setClaimLoader,
	getReferralB2BRequest,
	getReferralB2BSuccess,
	getReferralB2BBalanceRequest,
	getReferralB2BBalanceSuccess,
	getReferralB2СSignatureRequest,
	getReferralB2СSignatureSuccess,
	getReferralB2BSignatureRequest,
	getReferralB2BSignatureSuccess,
	getB2BDateRequest,
	getB2BDateSuccess,
	getB2CDateRequest,
	getB2CDateSuccess,
} = referral.actions;
