import { FC, useEffect } from 'react';
import { getNationalityRequest } from 'redux/reducers/settings/reducer';
import { PageProfile } from 'layouts/page/Profile';
import { CryptoItem } from 'components/Wallets/CryptoItem';
import { useDispatch } from 'react-redux';

export const CryptoPage: FC = () => {
	return (
		<PageProfile title="Gato" text="Gato" link="/profile">
			<CryptoItem />
		</PageProfile>
	);
};
