import Block from 'components/Block';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const BlockPage: FC = () => {
	return (
		<Page title="Block">
			<Block />
		</Page>
	);
};
