/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiDataResponseToken,
	IApiTokensResponse,
	IPayloadOptions,
	IPayloadToken,
	IPayloadTokenHolders,
	IPayloadTokenTransfers,
	ITokensStore,
} from './types';
// ==========================================:
export const initialState: ITokensStore = {
	tokens: null,
	tokensLoading: false,
	token: null,
	tokenLoading: false,
	transfers: null,
	transfersLoading: false,
	holders: null,
	holdersLoading: false,
};
// ==========================================:
const tokens = createSlice({
	name: '@@tokens',
	initialState,
	reducers: {
		getTokensRequest: (state, payload: PayloadAction<IPayloadOptions>) => {
			const stateItem = state;
			stateItem.tokensLoading = true;
		},
		getTokensSuccess: (state, action: PayloadAction<IApiTokensResponse>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.tokens = payload;
			stateItem.tokensLoading = false;
		},
		getTokenRequest: (state, payload: PayloadAction<IPayloadToken>) => {
			const stateItem = state;
			stateItem.tokenLoading = true;
		},
		getTokenSuccess: (state, action: PayloadAction<IApiDataResponseToken>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.token = payload;
			stateItem.tokenLoading = false;
		},
		getTransfersRequest: (state, payload: PayloadAction<IPayloadTokenTransfers>) => {
			const stateItem = state;
			stateItem.transfersLoading = true;
		},
		getTransfersSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.transfers = payload;
			stateItem.transfersLoading = false;
		},
		getHoldersRequest: (state, payload: PayloadAction<IPayloadTokenHolders>) => {
			const stateItem = state;
			stateItem.holdersLoading = true;
		},
		getHoldersSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.holders = payload;
			stateItem.holdersLoading = false;
		},
		tokensInitState: () => initialState,
		getMintRequest: (state) => {
			const stateItem = state;
		},
	},
});

export default tokens.reducer;
export const {
	getTokensRequest,
	getTokensSuccess,
	getTokenRequest,
	getTokenSuccess,
	getTransfersRequest,
	getTransfersSuccess,
	getHoldersRequest,
	getHoldersSuccess,
	tokensInitState,
	getMintRequest,
} = tokens.actions;
