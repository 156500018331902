/* eslint-disable */
import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toMaxDecimals, bigNumberFormater } from 'services/utils/numbers';
import { ReactComponent as CrossIcon } from 'assets/custom-icons/cross.svg';
import { toDecimalsAfterPoint } from 'services/utils/numbers';
import { ReactComponent as MagnifierIcon } from 'assets/custom-icons/magnifier.svg';
import { ITokenListProps } from './types';

export const TokenList: FC<ITokenListProps> = ({
	title,
	list,
	onChange,
	onClose,
	openManage,
	coin,
}) => {
	const [search, setSearch] = useState('');

	const filteredList = useMemo(() => {
		const normalizedSearch = search.toLowerCase().trim();

		return list?.filter(({ symbol, name }: any) => {
			const isSymbolMatch = symbol.toLowerCase().includes(normalizedSearch);
			const isNameMatch = name.toLowerCase().includes(normalizedSearch);
			return isSymbolMatch || isNameMatch;
		});
	}, [list, search]);

	const replaceTokenVsCoin = (item: any) => {
		if (item?.symbol === 'GATO') {
			return coin;
		}
		return item;
	};

	return (
		<div className="popup popup--smaller popup--padding-16 swap-token-list">
			<div className="popup-header">
				<p className="popup-header__title">Select a token</p>

				<button className="popup-close" type="button" onClick={onClose}>
					<CrossIcon />
				</button>
			</div>

			<div className="popup-body">
				<div className="popup-search">
					<div className="popup-search__input">
						<input
							type="text"
							className="input-item input-item--search input-item--left-icon"
							placeholder="Search by Token"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>

						<span className="input-icon">
							<MagnifierIcon />
						</span>
					</div>
				</div>

				<div
					className={classNames('balance-list', {
						'balance-list--centered': !filteredList?.length,
					})}
				>
					{filteredList?.map((tokenData) => {
						let token = tokenData;
						if(coin !== undefined || null) token = replaceTokenVsCoin(tokenData);

						if(token?.symbol === 'GATO' && token?.type === 'token') return;

						return !!token && (
						<div key={`${String(token?.address) + String(title)}`} className="balance-item">
							<button
								type="button"
								className="token-block token-block--biger"
								onClick={() => onChange(tokenData)}
							>
								<span className="token-block__icon">
									<img src={token?.logo} alt="" />
									{/* <img src={usdtImg} alt="" /> */}
								</span>

								<span className="token-block__info">
									<span className="token-block__name">{token?.symbol}</span>
									<span className="token-block__purpose">{token?.name}</span>
								</span>
							</button>

							{token.balance ? (
								<div className="balance-item__value">
									{bigNumberFormater(toDecimalsAfterPoint(String(toMaxDecimals(token?.balance, 12)),6)) || 0}
								</div>
							) : (
								<div className="balance-item__value">0</div>
							)}
						</div>
					)})}

					{!filteredList?.length && <p className="swap-token-list__no-tokens">No tokens found</p>}
				</div>

				<div className="link-block link-block--center link-block--mt18">
					<button
						onClick={openManage}
						type="button"
						className="link-block__item link-block__item--fz14"
					>
						Manage Tokens
					</button>
				</div>
			</div>
		</div>
	);
};
