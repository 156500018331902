import { FC } from 'react';

const styles = {
	height: '80vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: '#7378f8',
};

export const Blog: FC = () => {
	return <div style={styles}>Blog</div>;
};
