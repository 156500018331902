import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getTokensRequest } from 'redux/reducers/token/reducer';
import { getTokensData, getTokensLoading } from 'redux/reducers/token/selectors';
import Loader from 'ui/Loader';
import PaginationTable from 'ui/PaginationTable';
import { useNavigate } from 'react-router-dom';
import Item from './Item/Item';
import ItemSearch from './ItemSearch/ItemSearch';
import { TTokensItem } from '../../redux/reducers/token/types';

export const Tokens: FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	function parseURLParams(url: any) {
		// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
		const queryStart = url.indexOf('?') + 1;
		// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
		const queryEnd = url.indexOf('#') + 1 || url.length + 1;
		const query = url.slice(queryStart, queryEnd - 1);
		const pairs = query.replace(/\+/g, ' ').split('&');
		const parms = {};
		let i;
		let n;
		let v;
		let nv;

		if (query === url || query === '') return;

		// eslint-disable-next-line no-plusplus
		for (i = 0; i < pairs.length; i++) {
			nv = pairs[i].split('=', 2);
			n = decodeURIComponent(nv[0]);
			v = decodeURIComponent(nv[1]);

			// eslint-disable-next-line no-prototype-builtins
			if (!parms.hasOwnProperty(n)) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				parms[n] = [];
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			parms[n].push(nv.length === 2 ? v : null);
		}
		// eslint-disable-next-line consistent-return
		return parms;
	}
	const urlParams = parseURLParams(window.location.href);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const data = useSelector(getTokensData);
	const loading = useSelector(getTokensLoading);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const [inputSearch, setInputSearch] = useState(urlParams.search[0]);
	const allSearchParamsUrl = (text: string) => {
		setInputSearch(text);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		navigate(`/tokens/?page=${urlParams.page[0]}&search=${text}`);
	};
	const perPage = 8;
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		navigate(`/tokens/?page=${urlParams.page[0]}&search=${urlParams.search[0]}`);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const search = urlParams.search[0];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const page = urlParams.page[0];
		dispatch(getTokensRequest({ per_page: perPage, current_page: currentPage, search }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, currentPage, dispatch, inputSearch]);
	return (
		<section className="explore-home-section">
			<div className="container">
				<div className="explore-block explore-block--tokens">
					<div className="explore-block__top">
						<h4 className="block-title block-title--mr-auto">Tokens</h4>
						<ItemSearch
							inputSearch={inputSearch}
							setInputSearch={allSearchParamsUrl}
							setCurrentPage={setCurrentPage}
						/>
						<div className="block-pagination block-pagination--header">
							{data && (
								<PaginationTable
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									lastPage={data?.last_page}
								/>
							)}
						</div>
					</div>
					<div className="explore-blocks explore-blocks--tokens token">
						{data
							? data?.data?.map((e: TTokensItem) => {
									return <Item item={e} key={e.id} />;
							  })
							: null}
						{loading ? (
							<div style={{ position: 'relative' }} className="table-loader-wrapper">
								<Loader />
							</div>
						) : null}
					</div>
					<div className="block-pagination block-pagination--footer">
						{data && (
							<PaginationTable
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								lastPage={data?.last_page}
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};
