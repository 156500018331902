import { FC } from 'react';

import { PageProfile } from 'layouts/page/Profile';
import { AccountLanguage } from 'components/Profile/AccountLanguage';

export const AccountLanguagePage: FC = () => {
	return (
		<PageProfile title="Account Settings" text="Account Settings" link="/profile">
			<AccountLanguage />
		</PageProfile>
	);
};
