import React, { FC, Fragment, useMemo, useRef, useState } from 'react';

import { ArrowSelect, SearchIcon } from 'assets/custom-icons';
import { useDebounce } from 'hooks/useDebounce';
import { IAddressTokenBalance } from 'services/api/blockchain/types';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { Link } from 'react-router-dom';

interface ITokensDropdownProps {
	tokens: IAddressTokenBalance[];
}

const TokenDropdown: FC<ITokensDropdownProps> = ({ tokens }) => {
	const [searchValue, setSearchValue] = useState('');
	const [drop, setDrop] = useState(false);
	const handleClickDrop = () => {
		setDrop(!drop);
	};

	const ref = useRef<HTMLDivElement | null>(null);

	useOutsideClick(ref, () => setDrop(false));

	const debouncedSearch = useDebounce(searchValue, 500);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const tokensList = useMemo(() => {
		const filteredTokens = tokens.filter(
			(el) => el.token.symbol.includes(debouncedSearch) || el.token.name.includes(debouncedSearch),
		);
		const erc721 = filteredTokens.filter((el) => el.token.type === 'erc-721');
		const erc20 = filteredTokens.filter((el) => el.token.type === 'erc-20');
		const grc20 = filteredTokens.filter((el) => el.token.type === 'grc-20');
		const erc1155 = filteredTokens.filter((el) => el.token.type === 'erc-1155');
		return { 'ERC-20': erc20, 'GRC-20': grc20, 'ERC-721': erc721, 'ERC-1155': erc1155 };
	}, [debouncedSearch, tokens]);

	return (
		<div className={classNames('select select--wallet-tokens', { active: drop })} ref={ref}>
			<div onClick={handleClickDrop} className="select__current">
				{tokens.length} tokens
				<span className="select__arrow icon-arrow2">
					<ArrowSelect />
				</span>
			</div>
			<div className="select__drop select__drop--w290">
				<div className="select__drop-search">
					<div className="input-wrapper">
						<input
							type="text"
							className="input-item input-item--search input-item--left-icon"
							onChange={handleChange}
							placeholder="Search by Token"
						/>
						<span className="input-icon">
							<SearchIcon />
						</span>
					</div>
				</div>
				<div className="select__drop-scroll">
					<div>
						{Object.entries(tokensList).map((el) => (
							<Fragment key={el[0]}>
								{!!el[1]?.length && (
									<Fragment key={el[0]}>
										<p className="dropdown-header">
											{el[0]} ({el[1].length})
										</p>
										{el[1].map((item) => (
											<Link
												key={item.id}
												to={`/token/details/${String(item.token_contract_address_hash)}/transfers`}
												className="dropdown-item"
											>
												<span className="token-wallet-block">
													<span className="token-wallet-icon">
														<img src={item.token.logo} alt={item.token.name} />
													</span>
													<span className="token-wallet-info">
														<span className="token-wallet-info__title">
															{item.token.name.toUpperCase()}
														</span>
														<span className="token-wallet-info__value">
															{item.value} {item.token.symbol.toUpperCase()}
														</span>
													</span>
												</span>
											</Link>
										))}
									</Fragment>
								)}
							</Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TokenDropdown;
