import { FC } from 'react';
import PhoneCode from 'components/PhoneCode';
import { Auth } from 'layouts/Auth';

// ==========================================:
const PhoneConfirm: FC = () => {
	return (
		<Auth title="Phone confirm">
			<PhoneCode />
		</Auth>
	);
};

export default PhoneConfirm;
