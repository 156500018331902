import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const PrivacyPolicyPage: FC = () => {
	return (
		<Page title="Trade">
			<PrivacyPolicy />
		</Page>
	);
};
