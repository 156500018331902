import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { CubeButton, EchangeButton, WalletButton, BridgeButton } from 'assets/custom-icons';

export const TabsButton: FC = () => {
	return (
		<div className="tabs">
			<NavLink to="/" type="button" className="tabs__item active">
				<span className="tabs__item-icon">
					<CubeButton />
				</span>
				<span className="tabs__item-text">Explorer</span>
			</NavLink>
			<NavLink to="/exchange" className="tabs__item">
				<span className="tabs__item-icon">
					<EchangeButton />
				</span>
				<span className="tabs__item-text">Exchange</span>
			</NavLink>
			<NavLink to="/overviews" className="tabs__item">
				<span className="tabs__item-icon">
					<WalletButton />
				</span>
				<span className="tabs__item-text">GATO Wallet</span>
			</NavLink>
			<NavLink to="/bridge-transfer" className="tabs__item">
				<span className="tabs__item-icon">
					<BridgeButton />
				</span>
				<span className="tabs__item-text">Bridge</span>
			</NavLink>

			<NavLink to="/referral" className="tabs__item">
				<span className="tabs__item-icon">
					<svg
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9 9.5C11.0711 9.5 12.75 7.82107 12.75 5.75C12.75 3.67893 11.0711 2 9 2C6.92893 2 5.25 3.67893 5.25 5.75C5.25 7.82107 6.92893 9.5 9 9.5Z"
							stroke="#8592D6"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M2.55859 17C2.55859 14.0975 5.44609 11.75 9.00109 11.75C9.72109 11.75 10.4186 11.8475 11.0711 12.0275"
							stroke="#8592D6"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M16.5 14C16.5 14.5625 16.3425 15.095 16.065 15.545C15.9075 15.815 15.705 16.055 15.4725 16.25C14.9475 16.7225 14.2575 17 13.5 17C12.405 17 11.4525 16.415 10.935 15.545C10.6575 15.095 10.5 14.5625 10.5 14C10.5 13.055 10.935 12.2075 11.625 11.66C12.1425 11.2475 12.795 11 13.5 11C15.1575 11 16.5 12.3425 16.5 14Z"
							stroke="#8592D6"
							strokeWidth="1.2"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M12.3281 13.9996L13.0706 14.7421L14.6681 13.2646"
							stroke="#8592D6"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span className="tabs__item-text">Refer a friend</span>
			</NavLink>
			<NavLink to="/earnings-overview" className="tabs__item">
				<span className="tabs__item-icon">
					<svg
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.5234 14.7345H5.47344C5.15844 14.7345 4.80594 14.487 4.70094 14.187L1.59594 5.50203C1.15344 4.25703 1.67094 3.87453 2.73594 4.63953L5.66094 6.73203C6.14844 7.06953 6.70344 6.89703 6.91344 6.34953L8.23344 2.83203C8.65344 1.70703 9.35094 1.70703 9.77094 2.83203L11.0909 6.34953C11.3009 6.89703 11.8559 7.06953 12.3359 6.73203L15.0809 4.77453C16.2509 3.93453 16.8134 4.36203 16.3334 5.71953L13.3034 14.202C13.1909 14.487 12.8384 14.7345 12.5234 14.7345Z"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M4.875 17H13.125"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7.125 11H10.875"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span className="tabs__item-text">Earnings</span>
			</NavLink>
			<a href="https://gatochain.com" className="tabs__item tabs__item--extra">
				<span className="tabs__item-icon">
					<svg
						width="19"
						height="18"
						viewBox="0 0 19 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.125 16.5H11.625C15.375 16.5 16.875 15 16.875 11.25V6.75C16.875 3 15.375 1.5 11.625 1.5H7.125C3.375 1.5 1.875 3 1.875 6.75V11.25C1.875 15 3.375 16.5 7.125 16.5Z"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M12.1875 6.75H6.5625"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M12.1875 11.25H6.5625"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span className="tabs__item-text">GATO Project</span>
			</a>
			<a href="https://sales.gatochain.com/login" className="tabs__item tabs__item--extra">
				<span className="tabs__item-icon">
					<svg
						width="19"
						height="18"
						viewBox="0 0 19 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.07031 11.9098L12.0328 2.94727"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.44922 13.7096L9.34922 12.8096"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M10.4688 11.6919L12.2613 9.89941"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M2.82595 7.67913L7.80595 2.69913C9.39595 1.10913 10.191 1.10163 11.766 2.67663L15.4485 6.35913C17.0235 7.93413 17.016 8.72913 15.426 10.3191L10.446 15.2991C8.85595 16.8891 8.06095 16.8966 6.48595 15.3216L2.80345 11.6391C1.22845 10.0641 1.22845 9.27663 2.82595 7.67913Z"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M1.625 16.499H16.625"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span className="tabs__item-text">Buy GATO</span>
			</a>
		</div>
	);
};
