/* eslint-disable no-debugger */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { api, responseErrors } from 'services';
import { TWallets } from 'redux/reducers/connectWallet/types';
import {
	getTokenBalance,
	IWeb3GetTokenBalanceParams,
	getPairBalance,
} from 'services/utils/getTokenBalance';
import {
	IApiGetSwapAssetsParams,
	IApiGetSwapAssetsResponse,
	IApiGetSwapPairsParamsUnsafe,
	IApiGetSwapPairsResponse,
	IApiCalculateRateParams,
	IApiCalculateRateResponse,
	IApiGetSwapTopPoolsParams,
	IApiGetSwapTopPoolsResponse,
	IApiGetSwapTopTokensParams,
	IApiGraphResponse,
	IApiGetSwapTokensResponse,
	IApiGraphRequestPayload,
} from 'services/api/swap/types';
import { getAddress } from '../connectWallet/selectors';
import {
	getSwapAssetsError,
	getSwapAssetsRequest,
	getSwapAssetsSuccess,
	getTokenBalanceError,
	getTokenBalances,
	getTokenBalanceSuccess,
	calculateRateRequest,
	calculateRateSuccess,
	calculateRateError,
	getSwapPairsSuccess,
	getSwapPairsError,
	getSwapPairsRequest,
	sendTrancationRequest,
	getPairBalanceRequest,
	getPairBalanceSuccess,
	getTopPoolsResponse,
	getTokensResponse,
	getTopPoolsRequest,
	getTokensRequest,
	getPoolsRequest,
	getPoolsSuccess,
	getPairLiqudityInfoRequest,
	getPairLiqudityInfoSuccess,
	getTLVSuccess,
	getTLVRequest,
	getGraphDataRequest,
	getTokenBalancesLiquidity,
	sendTrancationTokneHashRequest,
	getTryTokensRequset,
	getTokensValueResponse,
	getTokensValueRequest,
	getPriceGraphDataResponse,
	getSwapGraphDataResponse,
	calculateRatePerOneRequest,
	calculateRatePerOneSuccess,
	sendAutoSwapFeeRequset,
} from './reducer';
import { getSwapTokenList, getAddTokenList } from './selectors';
import {
	ISwapTokenListUnsafe,
	TransactionsRequest,
	TPairParams,
	TPoolsRequest,
	TPoolPairDataRequest,
	TPoolPairDataSuccess,
	TPoolsSuccess,
	IApiTLVResponse,
	TTransactionResponse,
	TTokenHashDataRequest,
	TTryTokenData,
	TTryTokenResponse,
	TAutoSwapFeeRequest,
} from './types';

// 📌 Assets

function* getSwapAssetsWorker({ payload }: PayloadAction<IApiGetSwapAssetsParams | undefined>) {
	try {
		yield put(showLoading());

		const data: IApiGetSwapAssetsResponse = yield call(api.swap.getAssets, payload);

		yield put(getSwapAssetsSuccess(data));
	} catch (error) {
		yield put(getSwapAssetsError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// 📌 Pairs

function* getSwapPairsWorker({ payload }: PayloadAction<IApiGetSwapPairsParamsUnsafe>) {
	try {
		yield put(showLoading());

		const data: IApiGetSwapPairsResponse = yield call(api.swap.getPairs, payload);

		yield put(getSwapPairsSuccess(data));
	} catch (error) {
		yield put(getSwapPairsError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// 📌 Token balances

function* getTokenBalanceWorker(params: IWeb3GetTokenBalanceParams) {
	const { tokenAddress } = params;
	try {
		const balance: string = yield call(getTokenBalance, params);

		yield put(getTokenBalanceSuccess({ tokenAddress, balance }));
	} catch (error) {
		yield put(getTokenBalanceError({ tokenAddress }));
	}
}

function* getTokenBalancesWorker({ payload }: PayloadAction<string>) {
	// const tokenAddList: ISwapTokenListUnsafe = yield select(getAddTokenList);
	const tokenList: ISwapTokenListUnsafe = yield select(getSwapTokenList);
	const walletAddress: TWallets = yield select(getAddress);

	// let newTokenList: ISwapTokenListUnsafe = tokenList;
	// if (payload === 'liquidity') {
	// 	newTokenList = tokenAddList;
	// } else {
	// 	newTokenList = tokenList;
	// }
	// if (!newTokenList || !walletAddress.wallet) {
	// 	return;
	// }
	if (payload === 'liquidity') {
		return;
	}
	if (!tokenList || !walletAddress.wallet) {
		return;
	}

	// const updateTokenList = tokenList?.filter(({ balance }) => !balance);
	const updateTokenList = tokenList;
	const taskList = updateTokenList?.map(({ address, decimals, type }) =>
		call(getTokenBalanceWorker, {
			tokenAddress: address,
			walletAddress: String(walletAddress.wallet),
			tokenDecimals: decimals,
			type: String(type),
		}),
	);
	// if (taskList)
	yield all(taskList);
}
function* getTokenBalancesLiqudityWorker({ payload }: PayloadAction<string>) {
	const tokenAddList: ISwapTokenListUnsafe = yield select(getAddTokenList);
	// const tokenList: ISwapTokenListUnsafe = yield select(getSwapTokenList);
	const walletAddress: TWallets = yield select(getAddress);
	// let newTokenList: ISwapTokenListUnsafe = tokenList;
	// if (payload === 'liquidity') {
	// 	newTokenList = tokenAddList;
	// } else {
	// 	newTokenList = tokenList;
	// }
	if (payload !== 'liquidity') {
		return;
	}
	if (!tokenAddList || !walletAddress.wallet) {
		return;
	}

	const updateTokenList = tokenAddList?.filter(({ balance }) => !balance);
	const taskListLiquidity = updateTokenList?.map(({ address, decimals, type }) =>
		call(getTokenBalanceWorker, {
			tokenAddress: address,
			walletAddress: String(walletAddress.wallet),
			tokenDecimals: decimals,
			type: String(type),
		}),
	);

	yield all(taskListLiquidity);
}

// 📌 Calculate rate

function* calculateRateWorker({ payload }: PayloadAction<IApiCalculateRateParams>) {
	const { firstAddress, secondAddress } = payload;

	try {
		// const data: IApiCalculateRateResponse[] = yield all([
		// 	call(api.swap.calculateRate, payload),
		// 	call(api.swap.calculateRate, reversedParams),
		// ]);

		const { price }: IApiCalculateRateResponse = yield call(api.swap.calculateRate, payload);

		const successPayload = {
			firstAddress,
			secondAddress,
			secondPerFirst: price,
		};

		yield put(calculateRateSuccess(successPayload));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// Calculate per one rate

function* calculateRatePerOneWorker({ payload }: PayloadAction<IApiCalculateRateParams>) {
	const { firstAddress, secondAddress } = payload;

	try {
		const { price }: IApiCalculateRateResponse = yield call(api.swap.calculateRate, payload);

		const successPayload = {
			firstAddress,
			secondAddress,
			secondPerFirst: price,
		};

		yield put(calculateRatePerOneSuccess(successPayload));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* sendTrancationWorker({ payload }: PayloadAction<TransactionsRequest>) {
	try {
		const response: TTransactionResponse = yield call(api.swap.sendTransaction, payload);

		if (payload?.type === 'delete_liquidity' && response && payload.navigate) {
			payload.navigate('/pool');
			return;
		}
		if (payload?.type === 'swap' && response.status === 'success') {
			yield put(getTokenBalances(''));
		}
	} catch (error) {
		if (payload?.type === 'delete_liquidity' && payload.navigate) {
			payload.navigate('/pool');
			return;
		}
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getdPairBlanceWorker({ payload }: PayloadAction<TPairParams>) {
	try {
		const balance: string = yield call(getPairBalance, payload);

		yield put(getPairBalanceSuccess(balance));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTopPoolsWorker({ payload }: PayloadAction<IApiGetSwapTopPoolsParams>) {
	try {
		const response: IApiGetSwapTopPoolsResponse = yield call(api.swap.getTopPools, payload);
		yield put(getTopPoolsResponse(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTokensWorker({ payload }: PayloadAction<IApiGetSwapTopTokensParams>) {
	try {
		const response: IApiGetSwapTokensResponse = yield call(api.swap.getTokensList, payload);
		yield put(getTokensResponse(response));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTokensValueWorker({ payload }: PayloadAction<IApiGetSwapTopTokensParams>) {
	try {
		const response: IApiGetSwapTokensResponse = yield call(api.swap.getTokensList, payload);
		yield put(getTokensValueResponse(response));
	} catch (error) {
		yield put(calculateRateError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// pools

function* getPoolsWorker({ payload }: PayloadAction<TPoolsRequest>) {
	try {
		const pools: TPoolsSuccess = yield call(api.swap.getPools, payload.address);

		yield put(getPoolsSuccess(pools));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getPairLiquidityWorker({ payload }: PayloadAction<TPoolPairDataRequest>) {
	try {
		const pools: TPoolPairDataSuccess = yield call(api.swap.getPairLiquidity, payload);

		yield put(getPairLiqudityInfoSuccess(pools));
	} catch (error) {
		yield put(calculateRateError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTVLWorker({ payload }: PayloadAction<number>) {
	try {
		const response: IApiTLVResponse[] = yield call(api.swap.getTLV, payload);
		yield put(getTLVSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getGraphDataWorker({ payload }: PayloadAction<IApiGraphRequestPayload>) {
	const { type, apiParams } = payload;
	try {
		const response: IApiGraphResponse = yield call(api.swap.getGraphToken, apiParams);
		if (type === 'price') {
			yield put(getPriceGraphDataResponse(response));
		} else {
			yield put(getSwapGraphDataResponse(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* sendTrancationTokneHashRequestWorker({ payload }: PayloadAction<TTokenHashDataRequest>) {
	try {
		yield call(api.swap.getGraphTokenHash, payload);
		// yield put(getGraphDataResponse(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTryTokenRequestRequestWorker({ payload }: PayloadAction<TTryTokenData>) {
	const { checkStatus, params } = payload;
	try {
		const response: TTryTokenResponse = yield call(api.swap.getTryTokens, params);

		if (response) {
			checkStatus(Boolean(response?.status));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* postAutoSwapFeeWorker({ payload }: PayloadAction<TAutoSwapFeeRequest>) {
	const { params, setHash } = payload;
	try {
		const response: string = yield call(api.swap.postAutoSwapFee, params);
		setHash(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* swapSaga() {
	yield takeEvery(getSwapAssetsRequest, getSwapAssetsWorker);
	yield takeLatest(getSwapPairsRequest, getSwapPairsWorker);
	yield takeLatest(getTokenBalances, getTokenBalancesWorker);
	yield takeLatest(getTokenBalancesLiquidity, getTokenBalancesLiqudityWorker);
	yield takeLatest(calculateRateRequest, calculateRateWorker);
	yield takeLatest(calculateRatePerOneRequest, calculateRatePerOneWorker);
	yield takeLatest(sendTrancationRequest, sendTrancationWorker);
	yield takeLatest(getPairBalanceRequest, getdPairBlanceWorker);
	yield takeEvery(getTopPoolsRequest, getTopPoolsWorker);
	yield takeLatest(getTokensRequest, getTokensWorker);
	yield takeLatest(getTokensValueRequest, getTokensValueWorker);
	yield takeLatest(getPoolsRequest, getPoolsWorker);
	yield takeLatest(getPairLiqudityInfoRequest, getPairLiquidityWorker);
	yield takeEvery(getTLVRequest, getTVLWorker);
	yield takeEvery(getGraphDataRequest, getGraphDataWorker);
	yield takeEvery(sendTrancationTokneHashRequest, sendTrancationTokneHashRequestWorker);
	yield takeEvery(getTryTokensRequset, getTryTokenRequestRequestWorker);
	yield takeEvery(sendAutoSwapFeeRequset, postAutoSwapFeeWorker);
}
