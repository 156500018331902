import { FC } from 'react';
import { CloseButtonIcon } from 'assets/custom-icons';
import { TModal } from './type';

export const FelureModal: FC<TModal> = ({ setOpenModal }) => {
	const closeModal = () => setOpenModal(false);

	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<button
				onClick={closeModal}
				type="button"
				className="popup-close popup-close--type2"
				aria-label="Close"
			>
				<CloseButtonIcon />
			</button>

			<div className="popup-body">
				<div className="popup-message popup-message--error">
					<div className="popup-message__icon">
						<svg
							width="16"
							height="17"
							viewBox="0 0 16 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
								stroke="#EB5757"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8 5.83301V9.16634"
								stroke="#EB5757"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7.99609 11.167H8.00208"
								stroke="#EB5757"
								strokeWidth="1.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<p className="popup-message__title">Transaction failed.</p>
				</div>
			</div>
			<div className="popup-footer">
				<button onClick={closeModal} className="button button--full-width" type="button">
					Dismiss
				</button>
			</div>
		</div>
	);
};
