import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ArrowRight } from 'assets/custom-icons';
import { ITokenTransfer } from 'services/api/blockchain/types';

interface ITransferRowProps {
	items: ITokenTransfer;
	page: string;
}

const TransferRow: FC<ITransferRowProps> = ({ items, page = '' }) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { transaction_hash, from_address_hash, to_address_hash, amount, token } = items;
	return (
		<div className="block-item block-item--transaction">
			<div className="block-item__left">
				<div
					className={`block-number block-number--${
						page === 'address' ? 'transfer' : 'token-transfer'
					}`}
				>
					<p className="block-number__hex">Token Transfer</p>
					{page === 'address' && <p className="block-number__type">Success</p>}
				</div>
				<div className="transactions-info">
					<div className="transactions-info__top">
						<div className="transactions-info__wallet transactions-info__wallet--black">
							{transaction_hash}
						</div>
					</div>
					<div className="transactions-info__center">
						<div className="transactions-info__transfer">
							<p className="transactions-info__transfer-name">Sender</p>
							<Link to={`/address/${from_address_hash}`} className="transactions-info__wallet">
								{from_address_hash}
							</Link>
						</div>
						<div className="transactions-info__arrow">
							<ArrowRight />
						</div>
						<div className="transactions-info__transfer">
							<p className="transactions-info__transfer-name">Receiver</p>
							<Link to={`/address/${to_address_hash}`} className="transactions-info__wallet">
								{to_address_hash}
							</Link>
						</div>
					</div>
					<div className="transactions-info__bottom">
						<div className="transactions-swaptransactions-swap transactions-swap--type2">
							<div className="transactions-swap__count">
								<p className="transactions-swap__count-text">{amount}</p>
								<span className="transactions-swap__count-token">{token.symbol.toUpperCase()}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransferRow;
