import { FC } from 'react';
import { Auth } from 'layouts/Auth';
import { ConfirmatedNewPassword } from 'components/Forms/ConfirmatedNewPassword';

export const ConfirmNewPass: FC = () => {
	return (
		<Auth title="Confirm Password">
			<section className="authorization-section">
				<ConfirmatedNewPassword />
			</section>
		</Auth>
	);
};
