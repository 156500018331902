import { TokenManager } from 'components/TokenManager';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const TokenManagerPage: FC = () => {
	return (
		<Page title="My Token" text="My Token" link="/">
			<TokenManager />
		</Page>
	);
};
