import { FC } from 'react';
import { Link } from 'react-router-dom';

export const ConfirmedPhone: FC = () => {
	return (
		<section className="authorization-section authorization-section--confirm">
			<div className="container">
				<div className="authorization-box">
					<div className="authorization-form">
						<div className="authorization-form__header">
							<div className="success-icon">
								<svg
									width="54"
									height="55"
									viewBox="0 0 54 55"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 50C39.375 50 49.5 39.875 49.5 27.5C49.5 15.125 39.375 5 27 5C14.625 5 4.5 15.125 4.5 27.5C4.5 39.875 14.625 50 27 50Z"
										stroke="#3FDBB1"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M17.4375 27.5003L23.805 33.8678L36.5625 21.1328"
										stroke="#3FDBB1"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<p className="form-title form-title--center">Phone has been confirmed</p>
						</div>
						<div className="authorization-form__body">
							<div className="confirm-request">
								<p className="confirm-request__text">Now you can Log In to your account.</p>
							</div>
						</div>
						<div className="form-footer">
							<Link to="/login" className="button button--big-height button--full-width">
								Go to Log In
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
