import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IWallesStore,
	TWalletBalances,
	TUserWalletData,
	TUserTransactionResponse,
} from 'redux/reducers/wallets/types';

// ==========================================:
const getWalletsState = (state: TStoreState): IWallesStore => state.wallets;

export const getWalletsUser = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): TWalletBalances => wallets.walletBalances,
);

// ====================================================:

export const getWalletLoader = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): boolean => wallets.walletLoader,
);

// ====================================================:
export const getUserBalanse = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): TUserWalletData | null => wallets.userBalance,
);

// ====================================================:
export const getUserTransaction = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): TUserTransactionResponse => wallets.transactions,
);

export const getRecentLoader = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): boolean => wallets.recentLoader,
);

export const getSlipping = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): string => wallets.slipping,
);

export const getNetworkId = createSelector(
	[getWalletsState],
	(wallets: IWallesStore): string => wallets.network,
);
