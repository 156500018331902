import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory, useLocation } from 'react-router';
// import queryString from 'query-string';
// import { Link } from 'react-router-dom';

// import {
// 	emailResetConfirmTokenRequest,
// 	emailConfirmStatusCallback,
// } from 'redux/reducers/auth/reducer';
import { getEmailConfirm, getEmailResetConfirmTokenStatus } from 'redux/reducers/auth/selectors';

// ==========================================:
const EmailConfirmError: FC = () => {
	// const location = useLocation();
	// const history = useHistory();
	// const dispatch = useDispatch();
	const emailConfirm = useSelector(getEmailConfirm);
	// const emailConfirmStatus = useSelector(getEmailResetConfirmTokenStatus);

	// const queryPar = queryString.parse(location.search);

	// const handleResetEmailConfirm = () => {
	// 	dispatch(emailResetConfirmTokenRequest({ token: queryPar.token, history }));
	// };

	// useEffect(() => {
	// 	dispatch(emailConfirmStatusCallback());
	// }, [dispatch]);

	return (
		<div className="additional">
			{emailConfirm ? <div className="additional__content">Error Email</div> : null}
		</div>
	);
};

export default EmailConfirmError;
