import { FC } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import { trimLink } from 'services/utils/textSlice';
import { TModal } from './type';
import { FelureModal } from './falure';

export const SuccessModal: FC<TModal> = ({ openModal, setOpenModal, hash }) => {
	const closeModal = () => setOpenModal(false);
	const handleAddressCopy = () => {
		notificationContainer('Transaction copied successfully!', 'info');
	};

	return (
		<ModalWarapper openModal={openModal} setOpenModal={setOpenModal}>
			{hash ? (
				<div className="popup popup--padding-type2 popup--smaller">
					<div className="popup-body">
						<div className="popup-message popup-message--mb18">
							<div className="popup-message__icon">
								<svg
									width="55"
									height="54"
									viewBox="0 0 55 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27.5 49.5C39.875 49.5 50 39.375 50 27C50 14.625 39.875 4.5 27.5 4.5C15.125 4.5 5 14.625 5 27C5 39.375 15.125 49.5 27.5 49.5Z"
										stroke="#3FDBB1"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M17.9375 27.0001L24.305 33.3676L37.0625 20.6326"
										stroke="#3FDBB1"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<p className="popup-message__title">Transaction success</p>
						</div>
						<div className="form-text form-text--center">
							<p>Transaction hash</p>
						</div>
						<div className="form-group form-group--mt8">
							<div className="input input--no-mb">
								<div className="input-wrapper">
									<div className="code-field code-field--grey">
										{hash ? <p className="code-field__value">{trimLink(String(hash))}</p> : ''}
										<CopyToClipboard text={String(hash)} onCopy={handleAddressCopy}>
											<button
												type="button"
												className="right-info right-info--copy"
												aria-label="Copy"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M10.6673 8.59992V11.3999C10.6673 13.7333 9.73398 14.6666 7.40065 14.6666H4.60065C2.26732 14.6666 1.33398 13.7333 1.33398 11.3999V8.59992C1.33398 6.26659 2.26732 5.33325 4.60065 5.33325H7.40065C9.73398 5.33325 10.6673 6.26659 10.6673 8.59992Z"
														stroke="#C0C0CF"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M14.6673 4.59992V7.39992C14.6673 9.73325 13.734 10.6666 11.4007 10.6666H10.6673V8.59992C10.6673 6.26659 9.73398 5.33325 7.40065 5.33325H5.33398V4.59992C5.33398 2.26659 6.26732 1.33325 8.60065 1.33325H11.4007C13.734 1.33325 14.6673 2.26659 14.6673 4.59992Z"
														stroke="#C0C0CF"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</CopyToClipboard>
									</div>
								</div>
							</div>
						</div>
						<div className="form-text form-text--center form-text--mt-8">
							<p>
								View your transaction in <Link to={`/transactions/${hash}`}>Explorer</Link>
							</p>
						</div>
					</div>
					<div className="popup-footer">
						<button onClick={closeModal} className="button button--full-width" type="button">
							Ok
						</button>
					</div>
				</div>
			) : (
				<FelureModal openModal={openModal} setOpenModal={setOpenModal} />
			)}
		</ModalWarapper>
	);
};
