import { Staking } from 'components/Staking';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const StakingPage: FC = () => {
	return (
		<Content title="Gato Staking Program" text="Gato Staking Program">
			<Staking />
		</Content>
	);
};
