import { assets } from './assets/index';
import { auth } from './auth/index';
import { blockchain } from './blockchain';
import { settings } from './settings';
import { swap } from './swap';
import { IApi } from './types';
import { wallet } from './wallet';
import { tokens } from './tokens';
import { search } from './search';
import { referral } from './referral';
import { earnings } from './earnings';
import { bridge } from './bridge';

const api: IApi = {
	assets,
	settings,
	auth,
	swap,
	wallet,
	tokens,
	blockchain,
	search,
	referral,
	earnings,
	bridge,
};
export { api };
