import { spawn } from 'redux-saga/effects';
import { assetsSaga } from './reducers/coins/saga';
import { authSaga } from './reducers/auth/saga';
import { settingsSaga } from './reducers/settings/saga';
import { swapSaga } from './reducers/swap/saga';
import { connectSaga } from './reducers/connectWallet/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { tokensSaga } from './reducers/token/saga';
import { blockchainSaga } from './reducers/blockchain/saga';
import { searchSaga } from './reducers/search/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { referralSaga } from './reducers/referral/saga';
import { earningsSaga } from './reducers/earnings/saga';
import { bridgeSaga } from './reducers/bridge/saga';

export function* rootSaga() {
	yield spawn(assetsSaga);
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(swapSaga);
	yield spawn(connectSaga);
	yield spawn(walletsSaga);
	yield spawn(tokensSaga);
	yield spawn(blockchainSaga);
	yield spawn(searchSaga);
	yield spawn(socketsSaga);
	yield spawn(referralSaga);
	yield spawn(earningsSaga);
	yield spawn(bridgeSaga);
}
