import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getIs2FAEnabled } from 'redux/reducers/settings/selectors';

export const Security: FC = () => {
	const is2FAEnabled = useSelector(getIs2FAEnabled);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSelect = (): void => {
		dispatch(logoutRequest({ navigate }));

		// navigate(link);
	};
	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="general-block">
						<Link to="/information" className="account-change account-change--security">
							<div className="account-change__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M18.3281 5.67L6.58813 17.41C6.14813 17.85 5.40813 17.79 5.04813 17.27C3.80813 15.46 3.07812 13.32 3.07812 11.12V6.73C3.07812 5.91 3.69813 4.98 4.45813 4.67L10.0281 2.39C11.2881 1.87 12.6882 1.87 13.9482 2.39L17.9881 4.03999C18.6581 4.30999 18.8281 5.17 18.3281 5.67Z"
										fill="#335BE9"
									/>
									<path
										d="M19.27 7.04012C19.92 6.49012 20.91 6.96011 20.91 7.81011V11.1201C20.91 16.0101 17.36 20.5901 12.51 21.9301C12.18 22.0201 11.82 22.0201 11.48 21.9301C10.06 21.5301 8.74 20.8601 7.61 19.9801C7.13 19.6101 7.08001 18.9101 7.50001 18.4801C9.68001 16.2501 16.06 9.75012 19.27 7.04012Z"
										fill="#335BE9"
									/>
								</svg>
							</div>
							<div className="account-change__info">
								<p className="account-change__item">Login Information</p>
								<p className="account-change__subtitle">
									Change your password or see account details.
								</p>
							</div>
							<div className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</Link>

						<Link to="/two-factor" className="account-change account-change--security">
							<div className="account-change__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M19.7887 4.22007C16.8287 1.27007 12.0287 1.27007 9.08868 4.22007C7.01868 6.27007 6.39868 9.22007 7.19868 11.8201L2.49868 16.5201C2.16868 16.8601 1.93868 17.5301 2.00868 18.0101L2.30868 20.1901C2.41868 20.9101 3.08868 21.5901 3.80868 21.6901L5.98868 21.9901C6.46868 22.0601 7.13868 21.8401 7.47868 21.4901L8.29868 20.6701C8.49868 20.4801 8.49868 20.1601 8.29868 19.9601L6.35868 18.0201C6.06868 17.7301 6.06868 17.2501 6.35868 16.9601C6.64868 16.6701 7.12868 16.6701 7.41868 16.9601L9.36868 18.9101C9.55868 19.1001 9.87868 19.1001 10.0687 18.9101L12.1887 16.8001C14.7787 17.6101 17.7287 16.9801 19.7887 14.9301C22.7387 11.9801 22.7387 7.17007 19.7887 4.22007ZM14.4987 12.0001C13.1187 12.0001 11.9987 10.8801 11.9987 9.50007C11.9987 8.12007 13.1187 7.00007 14.4987 7.00007C15.8787 7.00007 16.9987 8.12007 16.9987 9.50007C16.9987 10.8801 15.8787 12.0001 14.4987 12.0001Z"
										fill="#335BE9"
									/>
									<path
										d="M14.5 12C15.8807 12 17 10.8807 17 9.5C17 8.11929 15.8807 7 14.5 7C13.1193 7 12 8.11929 12 9.5C12 10.8807 13.1193 12 14.5 12Z"
										fill="#335BE9"
									/>
								</svg>
							</div>
							<div className="account-change__info">
								<p className="account-change__item">Two-Factor Authentication</p>
								<p className="account-change__subtitle">
									Used for withdrawals and changes to security settings.
								</p>

								{is2FAEnabled && (
									<p className="account-change__enabled">
										<span className="account-change__enabled-success">
											<svg
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													opacity="0.4"
													d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
													fill="#3FDBB1"
												/>
												<path
													d="M7.93312 11.685C7.78312 11.685 7.64063 11.625 7.53563 11.52L5.41312 9.39745C5.19563 9.17995 5.19563 8.81995 5.41312 8.60245C5.63062 8.38495 5.99062 8.38495 6.20812 8.60245L7.93312 10.3275L11.7881 6.47245C12.0056 6.25495 12.3656 6.25495 12.5831 6.47245C12.8006 6.68995 12.8006 7.04995 12.5831 7.26745L8.33062 11.52C8.22562 11.625 8.08312 11.685 7.93312 11.685Z"
													fill="#3FDBB1"
												/>
											</svg>
										</span>
										Enabled
									</p>
								)}
							</div>
							<div className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</Link>
					</div>
					<button
						onClick={() => handleSelect()}
						type="button"
						className="button button--big-height button--full-width button--mt button--transparent"
					>
						Log Out
					</button>
				</div>
			</div>
		</section>
	);
};
