/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiBlocksResponse,
	IApiTransactionsResponse,
	IBlock,
	ITransaction,
	IApiBlocksRequest,
	IApiTransactionsRequest,
	IApiTransactionsCountResponse,
	ITransactionsGraph,
	IApiStatisticResponse,
	IApiBlocksCountResponse,
	IApiPrevNextResponse,
	IApiAddressResponse,
} from '../../../services/api/blockchain/types';

import { IBlockchainStore } from './types';

export const initialState: IBlockchainStore = {
	blocks: null,
	blocksLast: null,
	blocksLoading: false,
	transactions: null,
	transactionsLast: null,
	transactionsLoading: false,
	transactionsCount: 0,
	transactionsGraph: null,
	statistic: null,
	block: null,
	transaction: null,
	lastNumber: 0,
	blockPrevNext: null,
	address: null,
	addressLoading: false,
};

const blockchain = createSlice({
	name: '@@blockchain',
	initialState,
	reducers: {
		getBlocksRequest: (state, action: PayloadAction<IApiBlocksRequest>) => {
			state.blocksLoading = true;
		},
		getBlocksSuccess: (state, { payload }: PayloadAction<IApiBlocksResponse>) => {
			state.blocksLoading = false;
			state.blocks = payload;
		},
		getBlocksLastRequest: (state) => {
			state.blocksLoading = true;
		},
		getBlocksLastSuccess: (state, { payload }: PayloadAction<IBlock[]>) => {
			state.blocksLoading = false;
			state.blocksLast = payload;
		},
		getTransactionsRequest: (state, action: PayloadAction<IApiTransactionsRequest>) => {
			state.transactionsLoading = true;
		},
		getTransactionsSuccess: (state, { payload }: PayloadAction<IApiTransactionsResponse>) => {
			state.transactionsLoading = false;
			state.transactions = payload;
		},
		getTransactionsLastRequest: (state) => {
			state.transactionsLoading = true;
		},
		getTransactionsLastSuccess: (state, { payload }: PayloadAction<ITransaction[]>) => {
			state.transactionsLoading = false;
			state.transactionsLast = payload;
		},
		getTransactionsCountRequest: (state) => {},
		getTransactionsCountSuccess: (
			state,
			{ payload }: PayloadAction<IApiTransactionsCountResponse>,
		) => {
			state.transactionsCount = payload.transactions_count;
		},
		getTransactionsGraphRequest: (state) => {},
		getTransactionsGraphSuccess: (state, { payload }: PayloadAction<ITransactionsGraph[]>) => {
			state.transactionsGraph = payload;
		},
		getStatisticRequest: (state) => {},
		getStatisticSuccess: (state, { payload }: PayloadAction<IApiStatisticResponse>) => {
			state.statistic = payload;
		},
		getBlockRequest: (state, action: PayloadAction<string>) => {
			state.blocksLoading = true;
		},
		getBlockSuccess: (state, { payload }: PayloadAction<IBlock>) => {
			state.blocksLoading = false;
			state.block = payload;
		},
		getTransactionRequest: (state, action: PayloadAction<string>) => {
			state.transactionsLoading = true;
		},
		getTransactionSuccess: (state, { payload }: PayloadAction<ITransaction>) => {
			state.transactionsLoading = false;
			state.transaction = payload;
		},
		getBlocksCountRequest: (state) => {},
		getBlocksCountSuccess: (state, { payload }: PayloadAction<IApiBlocksCountResponse>) => {
			state.lastNumber = payload.last_block;
		},
		getBlocksPrevNextRequest: (state, action: PayloadAction<string>) => {},
		getBlocksPrevNextSuccess: (state, { payload }: PayloadAction<IApiPrevNextResponse>) => {
			state.blockPrevNext = payload;
		},
		getAddressRequest: (state, action: PayloadAction<string>) => {
			state.addressLoading = true;
		},
		getAddressSuccess: (state, { payload }: PayloadAction<IApiAddressResponse>) => {
			state.addressLoading = false;
			state.address = payload;
		},

		exchengeTradeSuccess: (state, { payload }: PayloadAction<IBlock>) => {
			if (state.blocksLast) state.blocksLast = [payload, ...state.blocksLast];
			if (state.transactionsLast)
				state.transactionsLast = [...payload.transaction, ...state.transactionsLast];
		},
	},
});

export default blockchain.reducer;
export const {
	getBlocksRequest,
	getBlocksSuccess,
	getBlocksLastRequest,
	getBlocksLastSuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
	getTransactionsLastRequest,
	getTransactionsLastSuccess,
	getTransactionsCountRequest,
	getTransactionsCountSuccess,
	getTransactionsGraphRequest,
	getTransactionsGraphSuccess,
	getStatisticRequest,
	getStatisticSuccess,
	getBlockRequest,
	getBlockSuccess,
	getTransactionRequest,
	getTransactionSuccess,
	getBlocksCountRequest,
	getBlocksCountSuccess,
	getBlocksPrevNextRequest,
	getBlocksPrevNextSuccess,
	getAddressRequest,
	getAddressSuccess,
	exchengeTradeSuccess,
} = blockchain.actions;
