/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useState, FC } from 'react';
import Slider from 'rc-slider';
import { getPairLiquidity } from 'redux/reducers/swap/selectors';
import { useSelector } from 'react-redux';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import { numberInDecimal } from '../utils';
/* eslint-disable no-debugger */
import { TSliderParams } from './type';

export const SliderRange: FC<TSliderParams> = ({
	tokenValue,
	setTokenValue,
	lpToken,
	setTokenLp,
}) => {
	// const [field, meta, helpers] = useField('name');
	// const { values }: FormikContextType<Values> = useFormikContext();
	const pairLiquidity = useSelector(getPairLiquidity);
	const [sliderValue, setSliderValue] = useState(100);
	const [maxValue, setMaxValue] = useState(100);
	const [currentPercent, setCurrentPercent] = useState(1);
	const [fromValue, setFromValue] = useState('1');

	const onChangeSlider = (value: any) => {
		setSliderValue(value);
		const newVal =
			(Number(
				numberInDecimal(
					Number(pairLiquidity?.userBalanceLpToken),
					Number(pairLiquidity?.decimals),
				).toString(),
			) *
				value) /
			100;
		setTokenLp(String(newVal));
	};

	const countFrom = (value: number) => {
		setTokenLp(String(value));
	};
	const percentButtonCountValue = (percentValue: number): number => {
		if (!pairLiquidity?.userBalanceLpToken || !Number(percentValue)) {
			return 0;
		}
		setSliderValue(percentValue * 100);
		return (
			Number(
				numberInDecimal(
					Number(pairLiquidity?.userBalanceLpToken),
					Number(pairLiquidity?.decimals),
				).toString(),
			) * percentValue
		);
	};

	return (
		<div className="amount-block">
			<p className="amount-block-name">Amount</p>
			<div className="amount-block-box">
				<p className="amount-block-percent">{sliderValue}%</p>
				<div className="range-slider amount-block-line">
					<Slider
						className="range-slider"
						onChange={(value) => {
							onChangeSlider(value);
						}}
						value={sliderValue}
						min={0}
						max={maxValue}
						defaultValue={0}
						step={1}
						trackStyle={{ backgroundColor: '#335BE9' }}
						handleStyle={{
							width: '25px',
							height: '25px',
							backgroundColor: '#335BE9',
							backgroundSize: '17.42px',
							backgroundPosition: '55% 50%',
							backgroundRepeat: 'no-repeat',
							top: '50%',
							transform: 'translate(-50%,-50%)',
							marginTop: 0,
						}}
					/>
				</div>
				<ConvertPercentButtons
					countFrom={countFrom}
					percentButtonCountValue={percentButtonCountValue}
					amount={fromValue}
					currentPercent={currentPercent}
					setCurrentPercent={setCurrentPercent}
				/>
			</div>
		</div>
	);
};
