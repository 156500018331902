import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMoneyFormat } from 'hooks/useMoneyFormat';
import {
	getStatisticRequest,
	getTransactionsGraphRequest,
} from 'redux/reducers/blockchain/reducer';
import { getStatistic, getTransactionsGraph } from 'redux/reducers/blockchain/selectors';
import { TooltipNotation } from 'assets/custom-icons';
import { ChartHome } from 'ui/Charts/ChartHome';

const StatisticMain = () => {
	const dispatch = useDispatch();
	const statistic = useSelector(getStatistic);
	const graphData = useSelector(getTransactionsGraph);

	const moneyFormat = useMoneyFormat();

	useEffect(() => {
		dispatch(getStatisticRequest());
		dispatch(getTransactionsGraphRequest());
	}, [dispatch]);
	return (
		<div className="statistics-info">
			<div className="statistics-diagram">
				<h4 className="statistics-diagram__title">Daily transactions (30Days)</h4>
				<div className="statistics-diagram__wrapper">
					{graphData && <ChartHome dataChart={graphData} />}
				</div>
				<div className="stat-blocks">
					<div className="stat-blocks__item stat-blocks__item--blue">
						<p className="stat-blocks__name">Day price</p>
						<p className="stat-blocks__count">${moneyFormat(statistic?.gato_coin_price, 2, 2)}</p>
					</div>
					<div className="stat-blocks__item stat-blocks__item--grey">
						<p className="stat-blocks__name">Gas tracker</p>
						<p className="stat-blocks__count">
							{moneyFormat(statistic?.gas_price, 1, 1)} Gwei
							<span className="stat-blocks__icon tooltip-item">
								<span className="tooltip-item__icon">
									<TooltipNotation />
								</span>
								<span className="tooltip tooltip--top tooltip--center">
									<span className="tooltip__text">
										<span className="tooltip__text-title">
											What Is Gas Price? A general reference for approximate transaction fees on the
											Gato blockchain
										</span>
									</span>
								</span>
							</span>
						</p>
					</div>
					<div className="stat-blocks__item stat-blocks__item--green">
						<p className="stat-blocks__name">Total Value Locked</p>
						<p className="stat-blocks__count">
							${statistic?.tlv['1'] && moneyFormat(statistic?.tlv['1'], 2, 2, true)}
							<span className="stat-blocks__icon tooltip-item">
								<span className="tooltip-item__icon">
									<TooltipNotation />
								</span>
								<span className="tooltip tooltip--top tooltip--center">
									<span className="tooltip__text">
										<span className="tooltip__text-title">
											Total value locked (TVL), in the context of blockchain, represents the sum of
											all assets deposited (as liquidity)
										</span>
									</span>
								</span>
							</span>
						</p>
					</div>
					<div className="stat-blocks__item stat-blocks__item--yellow">
						<p className="stat-blocks__name">Daily Transactions</p>
						<p className="stat-blocks__count">{moneyFormat(statistic?.daily_transactions, 0, 0)}</p>
					</div>
				</div>
			</div>
			<div className="statistics-counters">
				<div className="statistics-counters__blocks">
					<div className="statistics-counters__item">
						<div className="statistics-counters__name">Average block time</div>
						<div className="statistics-counters__text">
							{statistic?.average_block_time.split(':')[1]},
							{statistic?.average_block_time.split(':')[2][0]} seconds
						</div>
					</div>
					<div className="statistics-counters__item">
						<div className="statistics-counters__name">Total Transactions</div>
						<div className="statistics-counters__text statistics-counters__text--wbr">
							{moneyFormat(statistic?.total_transactions, 0, 0)}
						</div>
					</div>
					<div className="statistics-counters__item">
						<div className="statistics-counters__name">Total blocks</div>
						<div className="statistics-counters__text statistics-counters__text--wbr">
							{moneyFormat(statistic?.total_blocks, 0, 0)}
						</div>
					</div>
					<div className="statistics-counters__item">
						<div className="statistics-counters__name">Wallet addresses</div>
						<div className="statistics-counters__text statistics-counters__text--wbr">
							{moneyFormat(statistic?.wallet_addresses, 0, 0)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatisticMain;
