/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { TBalancesPayload, TWallets } from './types';
import { setAddress, AddWalletAddressSuccess, connectInitState, setLoading } from './reducer';

/* eslint-disable no-debugger */

// =============================================================:
function* setCcountAdressWorker({ payload }: PayloadAction<TWallets>) {
	const { account, type, loading } = payload;

	try {
		yield put(showLoading());
		yield put(setLoading(Boolean(loading)));
		const response: TBalancesPayload = yield call(api.wallet?.addWalletAddress, {
			address: String(account?.toLocaleLowerCase()),
			type: String(type),
		});

		yield put(AddWalletAddressSuccess({ ...payload, ...response }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.data?.errors &&
				error?.response?.data?.errors[0] === 'wallet_already_taken'
			) {
				notificationContainer('Wallet already taken', 'error');
				return;
			}
			responseErrors(error);
			connectInitState();
		}
	} finally {
		yield put(hideLoading());
	}
}
export function* connectSaga() {
	yield takeEvery(setAddress.type, setCcountAdressWorker);
}
