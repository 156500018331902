import { FC, useEffect, MouseEvent, useState } from 'react';
import { IConvertPercentButtonsProps } from './types';

export const PercentButtons: FC<IConvertPercentButtonsProps> = ({
	countFrom,
	percentButtonCountValue,
	amount,
	precents,
}) => {
	const [currentPercent, setCurrentPercent] = useState(0.1);

	const handleClickPercentButtons = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		countFrom(percentButtonCountValue(Number(name)));
		setCurrentPercent(Number(name));
	};

	useEffect(() => {
		if (!amount || Number(amount) === 0.1) {
			setCurrentPercent(0.1);
		}
	}, [amount]);

	return (
		<div className="buttons-tab buttons-tab--slippage">
			{precents.map((percent) => (
				<button
					key={percent}
					type="button"
					name={String(percent)}
					className={`buttons-tab__item ${percent === currentPercent ? 'active' : ''}`}
					onClick={handleClickPercentButtons}
				>
					{percent}%
				</button>
			))}
		</div>
	);
};
