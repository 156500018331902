import { FC } from 'react';
import { ActiveLink } from 'ui/ActiveLink';
import { TPanel } from './type';

export const NavPanel: FC<TPanel> = ({ linkName }) => {
	return (
		<div className="swap swap--mb">
			<ActiveLink to="/pool-token" classLink="swap__item">
				Tokens
			</ActiveLink>
			<ActiveLink to="/exchange" classLink="swap__item">
				Exchange
			</ActiveLink>
			<ActiveLink
				to={`${linkName === 'liquidity' ? '/liquidity' : '/pool'}`}
				classLink="swap__item"
			>
				Pool
			</ActiveLink>
		</div>
	);
};
