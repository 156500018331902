import TokenDetailed from 'components/TokenDetailed/TokenDetailed';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const TokenDetailsPage: FC = () => {
	return (
		<Content title="Blocks">
			<TokenDetailed />
		</Content>
	);
};
