import { TReferallResopnse } from 'redux/reducers/referral/types';

export const filterReferall = (start: any, end: any, data: TReferallResopnse) => {
	if (data) {
		const newRefferalArray: any = [];
		const newData = data.filter((item) => {
			const newItem = item.filter((itrem) => {
				return (
					// (Date.parse(String(new Date(start))) < Date.parse(String(new Date(itrem.created_at))) &&
					Date.parse(String(new Date(itrem.created_at))) < Date.parse(String(new Date(end))) ||
					new Date(itrem.created_at).toLocaleDateString() === new Date(end).toLocaleDateString()
					// (Date.parse(String(new Date(start))) < Date.parse(String(new Date(itrem.created_at))) &&
					// Date.parse(String(new Date(itrem.created_at))) < Date.parse(String(new Date(end)))
					// 	||
					// new Date(itrem.created_at).toLocaleDateString() === new Date(end).toLocaleDateString()
				);
			});
			if (newItem?.length) {
				newRefferalArray.push(newItem);
				return newItem;
			}
			return false;
		});
		return newRefferalArray;
	}
	return data;
};
