import { getYear } from 'date-fns';

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const range = (start: number, end: number) => {
	return new Array(end - start).fill(undefined).map((d, i) => i + start);
};

export const years = range(1950, getYear(new Date()) + 1);
