import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IBridgeApi } from './types';

export const bridge: IBridgeApi = {
	getNetworks: (params) => http.get(endpoint.bridge.GET_NETWORKS).then(({ data }) => data),
	getNetworksPair: (params) => http.get(endpoint.bridge.GET_NETWORKS_PAIR).then(({ data }) => data),
	getBridgeHistory: (params) =>
		http.get(endpoint.bridge.GET_BRIDGE_HISTORY, { params }).then(({ data }) => data),
};
