import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginRequest } from 'redux/reducers/auth/reducer';
import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import { LoginForm } from 'components/Forms/LoginForm';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Loader from 'ui/Loader';
import { ILoginCredsProps } from './types';

export const LoginCreds: FC<ILoginCredsProps> = ({ on2FA, onEmailAlreadySent }) => {
	const [checkbox, setCheckbox] = useState(false);
	const dispatch = useDispatch();

	const loginIsLoad = useSelector(getLoginIsLoad);

	const handleLoginSubmit = (values: ILoginSubmitValue): void => {
		if (!values) return;
		const data = {
			...values,
			remember: checkbox,
			[`${String(values.type) === 'phone' ? 'phone' : 'email'}`]: values.phone
				? `+${values?.phone}`
				: String(values?.email).toLowerCase(),
		};

		const params = { data, on2FA, onEmailAlreadySent };
		dispatch(loginRequest(params));
	};

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="authorization-form">
						<LoginForm
							loginSubmit={handleLoginSubmit}
							setCheckbox={setCheckbox}
							checkbox={checkbox}
						/>
						{loginIsLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};
