import {
	TReferallResopnse,
	TRefarralStore,
	TReferallWalletsData,
	TReferralB2B,
	TReferralB2BBalanceResponse,
	TB2CSignatureResponsce,
	TB2BTimeResponse,
} from 'redux/reducers/referral/types';
import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';

// ==========================================:
const getReferralState = (state: TStoreState): TRefarralStore => state.referral;

export const getReferral = createSelector(
	[getReferralState],
	(referral: TRefarralStore) => referral,
);

// ====================================================:

export const getReferralList = createSelector(
	[getReferral],
	(referral: TRefarralStore): null | TReferallResopnse => referral.refferal,
);

export const getReferralIsLoad = createSelector(
	[getReferral],
	(referral: TRefarralStore): boolean => referral.referralLoader,
);

export const getReferralWallet = createSelector(
	[getReferral],
	(referral: TRefarralStore): null | TReferallWalletsData => referral.referralWallet,
);

export const getReferralWalletLoader = createSelector(
	[getReferral],
	(referral: TRefarralStore): boolean => referral.referralWalletLoader,
);

export const getReferralB2B = createSelector(
	[getReferral],
	(referral: TRefarralStore): TReferralB2B | null => referral.referralB2B,
);

export const getReferralB2BBalance = createSelector(
	[getReferral],
	(referral: TRefarralStore): TReferralB2BBalanceResponse | null => referral.referralB2BBalance,
);
export const getReferralB2BSignature = createSelector(
	[getReferral],
	(referral: TRefarralStore): TB2BTimeResponse | null => referral.referralB2BSignature,
);
export const getReferralB2CSignature = createSelector(
	[getReferral],
	(referral: TRefarralStore): TB2CSignatureResponsce | null => referral.referralB2CSignature,
);
