/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useState } from 'react';
import logo4 from 'assets/images/logo-4.svg';
import { INotifications } from './types';

export const NotificationInfo: FC<INotifications> = ({ title, openModal }) => {
	const [open, setOpen] = useState<boolean | undefined>(false);

	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div className={`notification-wrapper notification-wrapper--center ${open ? 'active' : ''}`}>
			<div
				className={`notification notification--info notification--big notification--error ${
					open ? 'active' : ''
				}`}
			>
				<div className="notification__close">
					<button onClick={handleClose} type="button">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7 7L17 17"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7 17L17 7"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div>

				<div className="notification__icon">
					<svg
						width="55"
						height="54"
						viewBox="0 0 55 54"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M27.4987 48.1724H13.8637C6.05625 48.1724 2.79375 42.5924 6.57375 35.7749L13.5937 23.1299L20.2087 11.2499C24.2137 4.02738 30.7837 4.02738 34.7887 11.2499L41.4037 23.1524L48.4237 35.7974C52.2037 42.6149 48.9187 48.1949 41.1337 48.1949H27.4987V48.1724Z"
							stroke="#F2994A"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.5 20.25V31.5"
							stroke="#F2994A"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M27.4883 38.25H27.5085"
							stroke="#F2994A"
							strokeWidth="4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="notification__info">
					<div className="notification__title notification__title--blue">
						<p>{title || 'Platform Preview'}</p>
					</div>
					<div className="notification__logo">
						<span>by</span>
						<span className="notification__logo-icon">
							<img src={logo4} alt="" />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
