import Address from 'components/Address';
import { Page } from 'layouts/page';
import React from 'react';

const AddressPage = () => {
	return (
		<Page title="Address">
			<Address />
		</Page>
	);
};

export default AddressPage;
