import { Tokens } from 'components/Tokens';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const TokenPage: FC = () => {
	return (
		<Content title="Blocks">
			<Tokens />
		</Content>
	);
};
