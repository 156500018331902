import { Earnings } from 'components/Earnings';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const ErrorPage: FC = () => {
	return (
		<Content>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					flex: 1,
				}}
			>
				The page is under development
			</div>
		</Content>
	);
};
