/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TWalletBalancesDataRequest,
	IWallesStore,
	TWalletBalancesDataResponse,
	TWalletUserBalanceRequest,
	TWalletUserBalanceResponse,
	TUserTransactionRequest,
	TUserTransactionResponse,
	TWalletTransferRequest,
} from './types';

const chainId = String(process.env.REACT_APP_CHAIN_ID);
// ==========================================:
export const initialState: IWallesStore = {
	walletBalances: null,
	userBalance: null,
	transactions: null,
	walletLoader: false,
	recentLoader: false,
	network: '4287',
	slipping: '0.5',
};

// ==========================================:
const wallets = createSlice({
	name: '@@wallets',
	initialState,
	reducers: {
		getWalletBalancesRequest: (state, action: PayloadAction<TWalletBalancesDataRequest>) => {
			const dataState = state;
			dataState.walletLoader = true;
		},
		getWalletBalancesSuccess: (state, action: PayloadAction<TWalletBalancesDataResponse>) => {
			const dataState = state;
			const { payload } = action;

			dataState.walletBalances = payload;
			dataState.walletLoader = false;
		},
		getWalletBalancesFelure: (state) => {
			const dataState = state;
			dataState.walletLoader = false;
		},
		getUserBalanceRequest: (state, action: PayloadAction<TWalletUserBalanceRequest>) => {
			const dataState = state;
			dataState.walletLoader = true;
			dataState.userBalance = null;
		},

		getUserBalanceSuccess: (state, action: PayloadAction<TWalletUserBalanceResponse>) => {
			const dataState = state;
			const { payload } = action;

			dataState.userBalance = payload;
			dataState.walletLoader = false;
		},

		getUserBalanceFailure: (state) => {
			const dataState = state;
			dataState.walletLoader = false;
			dataState.userBalance = null;
		},

		getHistoryTranasactionRequest: (state, action: PayloadAction<TUserTransactionRequest>) => {
			const dataState = state;
			dataState.recentLoader = true;
		},
		getHistoryTranasactionSuccess: (state, action: PayloadAction<TUserTransactionResponse>) => {
			const dataState = state;
			const { payload } = action;

			dataState.transactions = payload;
			dataState.recentLoader = false;
		},
		getHistoryTranasactionFelure: (state) => {
			const dataState = state;
			dataState.recentLoader = false;
		},
		setSlipping: (state, action: PayloadAction<string>) => {
			const dataState = state;
			const { payload } = action;

			dataState.slipping = payload;
		},
		setNetwork: (state, action: PayloadAction<string>) => {
			const dataState = state;
			const { payload } = action;
			dataState.network = payload;
		},
		addTransferRequest: (state, action: PayloadAction<TWalletTransferRequest>) => {
			const dataState = state;
		},
		addTransferSuccess: (state, action: PayloadAction<string>) => {
			const dataState = state;
		},
		InitState: () => initialState,
	},
});

export default wallets.reducer;
export const {
	InitState,
	getWalletBalancesRequest,
	getWalletBalancesSuccess,
	getWalletBalancesFelure,
	getUserBalanceRequest,
	getUserBalanceSuccess,
	getUserBalanceFailure,
	getHistoryTranasactionRequest,
	getHistoryTranasactionSuccess,
	getHistoryTranasactionFelure,
	setSlipping,
	setNetwork,
	addTransferRequest,
	addTransferSuccess,
} = wallets.actions;
