/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IApiCalculateRateParams,
	IApiGetSwapAssetsParams,
	IApiGetSwapAssetsResponse,
	IApiGetSwapPairsParamsUnsafe,
	IApiGetSwapPairsResponse,
	IApiGetSwapTopPoolsParams,
	IApiGetSwapTopPoolsResponse,
	IApiGetSwapTopTokensParams,
	IApiGraphResponse,
	IApiGetSwapTokensResponse,
	IApiGraphRequestPayload,
} from 'services/api/swap/types';
import {
	ISwapStore,
	ISwapBalance,
	IGetTokenBalanceErrorPayload,
	ICalculatedRate,
	TransactionsRequest,
	TPairParams,
	TPoolsRequest,
	TPoolsSuccess,
	IApiTLVResponse,
	TPoolPairDataRequest,
	TPoolPairDataSuccess,
	TTokenHashDataRequest,
	TTryTokenData,
	TAutoSwapFeeRequest,
} from './types';

export const initialState: ISwapStore = {
	assets: null,
	assetsLoading: false,

	pairs: null,
	pairsLoading: false,

	balances: [],

	pairBalance: null,

	calculatedRate: null,
	calculateLoading: false,
	tokensSelectFrom: '',
	tokensSelectTo: '',
	calculatedRatePerOne: null,
	calculatePerOneLoading: false,

	topPools: null,
	tokens: null,
	tokensValue: null,
	topPoolsLoading: false,
	topTokensLoading: false,
	pools: null,
	currentLiquidity: null,
	poolsLoader: false,

	tlv: [],
	tlvLoading: false,
	graphToken: null,
	graphLoading: false,
};

const swap = createSlice({
	name: '@@swap',
	initialState,
	reducers: {
		getSwapAssetsRequest: (state, action: PayloadAction<IApiGetSwapAssetsParams | undefined>) => {
			state.assetsLoading = true;
		},
		getSwapAssetsSuccess: (state, { payload }: PayloadAction<IApiGetSwapAssetsResponse>) => {
			state.assets = payload;
			state.assetsLoading = false;
		},
		getSwapAssetsError: (state) => {
			state.assets = null;
			state.assetsLoading = false;
		},

		getSwapPairsRequest: (state, action: PayloadAction<IApiGetSwapPairsParamsUnsafe>) => {
			state.pairsLoading = true;
		},
		getSwapPairsSuccess: (state, { payload }: PayloadAction<IApiGetSwapPairsResponse>) => {
			state.pairs = payload;
			state.pairsLoading = false;
		},
		getSwapPairsError: (state) => {
			state.pairs = null;
			state.pairsLoading = false;
		},

		getTokenBalanceSuccess: (state, { payload }: PayloadAction<ISwapBalance>) => {
			const newBalances = state.balances.filter(({ tokenAddress }) => {
				return tokenAddress !== payload.tokenAddress;
			});
			newBalances.push(payload);
			state.balances = newBalances;
		},
		getTokenBalanceError: (state, { payload }: PayloadAction<IGetTokenBalanceErrorPayload>) => {
			const newBalances = state.balances.filter(({ tokenAddress }) => {
				return tokenAddress !== payload.tokenAddress;
			});
			state.balances = newBalances;
		},

		getTokenBalances: (state, action: PayloadAction<string>) => state,
		getTokenBalancesLiquidity: (state, action: PayloadAction<string>) => state,

		clearTokenBalances: (state) => {
			state.balances = [];
		},

		calculateRateRequest: (state, action: PayloadAction<IApiCalculateRateParams>) => {
			state.calculateLoading = true;
		},
		calculateRateSuccess: (state, { payload }: PayloadAction<ICalculatedRate>) => {
			state.calculatedRate = payload;
			state.calculateLoading = false;
		},
		calculateRateError: (state) => {
			state.calculatedRate = null;
			state.calculateLoading = false;
		},
		calculateRatePerOneRequest: (state, action: PayloadAction<IApiCalculateRateParams>) => {
			state.calculatePerOneLoading = true;
		},
		calculateRatePerOneSuccess: (state, { payload }: PayloadAction<ICalculatedRate>) => {
			state.calculatedRatePerOne = payload;
			state.calculatePerOneLoading = false;
		},
		sendTrancationRequest: (state, action: PayloadAction<TransactionsRequest>) => {
			const newState = state;
		},
		getPairBalanceRequest: (state, action: PayloadAction<TPairParams>) => {
			const newState = state;
		},
		getPairBalanceSuccess: (state, action: PayloadAction<string>) => {
			const newState = state;
			const { payload } = action;

			newState.pairBalance = payload;
		},
		getTopPoolsRequest: (state, action: PayloadAction<IApiGetSwapTopPoolsParams>) => {
			state.topPoolsLoading = true;
		},
		getTopPoolsResponse: (state, { payload }: PayloadAction<IApiGetSwapTopPoolsResponse>) => {
			state.topPoolsLoading = false;
			state.topPools = payload;
		},
		getTokensRequest: (state, action: PayloadAction<IApiGetSwapTopTokensParams>) => {
			state.topTokensLoading = true;
		},
		getTokensResponse: (state, { payload }: PayloadAction<IApiGetSwapTokensResponse>) => {
			state.topTokensLoading = false;
			state.tokens = payload;
		},

		getTokensValueRequest: (state, action: PayloadAction<IApiGetSwapTopTokensParams>) => {},
		getTokensValueResponse: (state, { payload }: PayloadAction<IApiGetSwapTokensResponse>) => {
			state.tokensValue = payload;
		},

		getPoolsRequest: (state, action: PayloadAction<TPoolsRequest>) => {
			state.poolsLoader = true;
		},
		getPoolsSuccess: (state, action: PayloadAction<TPoolsSuccess>) => {
			const newState = state;

			newState.pools = action.payload;
			newState.poolsLoader = false;
		},
		getTLVRequest: (state, { payload }: PayloadAction<number>) => {
			state.tlvLoading = true;
		},
		getTLVSuccess: (state, { payload }: PayloadAction<IApiTLVResponse[]>) => {
			state.tlvLoading = false;
			state.tlv = payload;
		},
		getGraphDataRequest: (state, { payload }: PayloadAction<IApiGraphRequestPayload>) => {
			state.graphLoading = true;
		},
		getPriceGraphDataResponse: (state, { payload }: PayloadAction<IApiGraphResponse>) => {
			state.graphLoading = false;
			// debugger;
			if (state.graphToken) {
				state.graphToken = {
					...state.graphToken,
					price_graph: payload.price_graph,
					last_price: payload.last_price,
					percent: payload.percent,
				};
			} else {
				state.graphToken = payload;
			}
		},
		getSwapGraphDataResponse: (state, { payload }: PayloadAction<IApiGraphResponse>) => {
			state.graphLoading = false;
			// debugger;
			if (state.graphToken) {
				state.graphToken.swap_volumes_graph = payload.swap_volumes_graph;
			} else {
				state.graphToken = payload;
			}
		},
		getPairLiqudityInfoRequest: (state, action: PayloadAction<TPoolPairDataRequest>) => {
			const newState = state;
		},
		getPairLiqudityInfoSuccess: (state, action: PayloadAction<TPoolPairDataSuccess>) => {
			const { payload } = action;
			const newState = state;

			newState.currentLiquidity = payload;
		},
		sendTrancationTokneHashRequest: (state, action: PayloadAction<TTokenHashDataRequest>) => {
			const newState = state;
		},
		getTryTokensRequset: (state, action: PayloadAction<TTryTokenData>) => {
			const newState = state;
		},

		sendAutoSwapFeeRequset: (state, action: PayloadAction<TAutoSwapFeeRequest>) => {
			const newState = state;
		},
	},
});

// function unsetActions2FALoading(state: ISettingsStore) {
// 	state.actions2FALoading = false;
// }

export default swap.reducer;
export const {
	getSwapAssetsRequest,
	getSwapAssetsSuccess,
	getSwapAssetsError,
	getSwapPairsRequest,
	getSwapPairsSuccess,
	getSwapPairsError,
	getTokenBalanceSuccess,
	getTokenBalanceError,
	getTokenBalances,
	clearTokenBalances,
	calculateRateRequest,
	calculateRatePerOneRequest,
	calculateRatePerOneSuccess,
	calculateRateSuccess,
	calculateRateError,
	sendTrancationRequest,
	getPairBalanceRequest,
	getTokenBalancesLiquidity,
	getPairBalanceSuccess,
	getTopPoolsRequest,
	getTopPoolsResponse,
	getTokensRequest,
	getTokensResponse,
	getPoolsRequest,
	getPoolsSuccess,
	getPairLiqudityInfoRequest,
	getPairLiqudityInfoSuccess,
	getTLVRequest,
	getTLVSuccess,
	getGraphDataRequest,
	getPriceGraphDataResponse,
	getSwapGraphDataResponse,
	sendTrancationTokneHashRequest,
	getTryTokensRequset,
	getTokensValueRequest,
	getTokensValueResponse,
	sendAutoSwapFeeRequset,
} = swap.actions;
