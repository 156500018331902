import { Profile } from 'components/Profile';
import { PageProfile } from 'layouts/page/Profile';
import { FC, useEffect } from 'react';

export const ProfilePage: FC = () => {
	return (
		<PageProfile title="Profile" text="My Profile" link="/profile">
			<Profile />
		</PageProfile>
	);
};
