import { FC } from 'react';

import { PageProfile } from 'layouts/page/Profile';
import { HowItWorks } from 'components/HowItWorks';

export const HowItWorksPage: FC = () => {
	return (
		<PageProfile title="How It Works" text="How It Works" link="/profile">
			<HowItWorks />
		</PageProfile>
	);
};
