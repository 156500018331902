import React, { FC } from 'react';
import { format } from 'date-fns';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	Tooltip,
	LineElement,
	PointElement,
	Filler,
	ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ISwapVolumesGraphData } from 'services/api/swap/types';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Filler, Tooltip);

export const options = {
	responsive: true,
	elements: {
		point: {
			borderWidth: 0,
			backgroundColor: 'rgba(0, 0, 0, 0)',
		},
		line: {
			borderColor: 'rgba(51,91,233,1)',
			backgroundColor: (context: ScriptableContext<'line'>) => {
				const { ctx } = context.chart;
				const gradient = ctx.createLinearGradient(0, 0, 0, 200);
				gradient.addColorStop(0, 'rgba(178, 193, 247,1)');
				gradient.addColorStop(1, 'rgba(252, 252, 255,1)');
				return gradient;
			},
			fill: true,
			tension: 0.5,
		},
	},
	scales: {
		x: {
			ticks: {
				// display: false,
				color: '#A5A5BA',
				maxTicksLimit: 7,
				maxRotation: 0,
				minRotation: 0,
			},
			grid: {
				display: false,
			},
		},
		y: {
			grid: {
				display: false,
			},
		},
	},
};

interface IChartLineProps {
	dataChart: ISwapVolumesGraphData[];
	period: string;
}

export const ChartLine: FC<IChartLineProps> = ({ dataChart, period }) => {
	const labels = dataChart?.map(
		(el) =>
			period === 'day'
				? format(Number(el.created_at) * 1000, 'hh:mm')
				: format(Number(el.created_at) * 1000, 'MMM dd'),
		// : format(new Date(el.created_at.split(' ')[0]), 'MMM dd'),
	);

	const data = {
		labels,
		datasets: [
			{
				data: dataChart.map((el) => el.amount_in_usd),
			},
		],
	};

	return dataChart && <Line options={options} data={data} />;
};
