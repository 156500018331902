import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ArrowRight } from 'assets/custom-icons';
import { IInternalTransaction } from 'services/api/blockchain/types';
import { formatDistance } from 'date-fns';

const InternalTransactionRow: FC<IInternalTransaction> = ({
	transaction_hash,
	from_address_hash,
	to_address_hash,
	value,
	block_number,
	created_at,
	block_hash,
}) => {
	return (
		<div className="block-item block-item--transaction">
			<div className="block-item__left">
				<div className="block-number block-number--internal-transactions">
					<p className="block-number__hex">Internal Transactions</p>
					<p className="block-number__type">Call</p>
				</div>
				<div className="transactions-info">
					<div className="transactions-info__top">
						<div className="transactions-info__wallet transactions-info__wallet--black">
							{transaction_hash}
						</div>
					</div>
					<div className="transactions-info__center">
						<div className="transactions-info__transfer">
							<p className="transactions-info__transfer-name">Sender</p>
							<Link to={`/address/${from_address_hash}`} className="transactions-info__wallet">
								{from_address_hash}
							</Link>
						</div>
						<div className="transactions-info__arrow">
							<ArrowRight />
						</div>
						<div className="transactions-info__transfer">
							<p className="transactions-info__transfer-name">Receiver</p>
							<Link to={`/address/${to_address_hash}`} className="transactions-info__wallet">
								{to_address_hash}
							</Link>
						</div>
					</div>
					<div className="transactions-info__bottom">
						<div className="transactions-swaptransactions-swap transactions-swap--type2">
							<div className="transactions-swap__count">
								<p className="transactions-swap__count-text">{value}</p>
								<span className="transactions-swap__count-token">USDT</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="block-item__right">
				<div className="block-period block-period--flex-end">
					<Link to={`/blocks/${block_hash}`} className="block-period__number">
						Block #{block_number}
					</Link>
					<div className="block-period__time">
						{formatDistance(Date.parse(created_at), new Date(), { addSuffix: true })}
					</div>
				</div>
			</div>
		</div>
	);
};

export default InternalTransactionRow;
