import { TermsService } from 'components/TermsService';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const TermsServicePage: FC = () => {
	return (
		<Page title="Terms Service">
			<TermsService />
		</Page>
	);
};
