import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransactionClaim } from 'components/Transactions/TransactionsModals/type';
import { ethers } from 'ethers';

export type TClaim = { firstTokenAddress: string; firstTokenAmount: string };

const { BigNumber } = ethers;
/* eslint-disable */
const DIVIDENDS_FACTORY = '0x0000000000000000000000000000000000001008';

export const claimDividentsApprove = async ({
	wallet,
	abi,
	to,
	amount,
	firstTokenAmount,
	firstTokenAddress,
	type,
	connector,
	signature,
	feeAmount,
	coin,
	provider,
	deadline = String(
		BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
	),
	setOpenTransactionModal,
}: ITypeTransactionClaim) => {
	try {
		setOpenTransactionModal(true);

		const dedl =
			// String(BigNumber.from(deadline)) ||
			String(BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'));

		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, to);

		// const contreth = new ethers.Contract(to, abi, provider);

		let claim = '';

		// debugger;
		// let result;
		// const tra = await contreth.getMessageHash(String(amount), wallet, firstTokenAmount);
		// let signature = await provider.connect(wallet).signMessage(ethers.utils.arrayify(tra));

		let result;

		// debugger;
		// const messageHash = web3.utils.soliditySha3(
		// 	wallet,
		// 	firstTokenAddress,
		// 	String(amount),
		// 	signature,
		// );
		debugger;
		claim = await contract.methods
			.getMessageHash(wallet, firstTokenAddress, String(amount), signature)
			.call();

		// const hash = web3.utils.sha3(claim); // Хеш повідомлення

		// if (type === 'claim') {
		// 	claim = await contract.methods.claim(firstTokenAddress, firstTokenAmount).encodeABI();
		// } else {
		// 	claim = await contract.methods.claim(firstTokenAmount).encodeABI();

		// }

		const claim1 = await contract.methods.getEthSignedMessageHash(claim).call();
		// const result = await web3.eth.sign(claim1, wallet);
		if (claim1)
			result = await web3.eth.sign(String(claim1), wallet, (error, signature) => {
				if (error) {
					console.error('Error signing message:', error);
				} else {
					result = signature;
					// Proceed with the rest of your logic
				}
			});

		// if (hash) {
		// 	result = await web3.eth.sign(hash, wallet);
		// }
		notificationContainer('Claim done', 'success');

		setOpenTransactionModal(false);
		return result;
	} catch (error: any) {
		setOpenTransactionModal(false);
		notificationContainer(
			`${type === 'claim' ? 'Claim' : 'Unstake'} failed! ${String(error?.message.split(':')[0])}`,
			'error',
		);
	}
};
