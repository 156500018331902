import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
// import L from 'i18n-react';
/* eslint-disable no-debugger */
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import { IApiGenerateGoogle2FAKeyResponse } from 'services/api/settings/types';
import {
	changeUserPassRequest,
	changeUserPassSuccess,
	check2faEnablesRequest,
	check2faEnablesSuccess,
	disable2faRequest,
	disable2faSuccess,
	enable2faRequest,
	enable2faSuccess,
	generate2faKeyRequest,
	generate2faKeySuccess,
	setPhoneRequest,
	checkNotificationSuccess,
	settingsInitState,
	userSettingsRequest,
	userSettingsSuccess,
	updateNotificationRequest,
	checkNotificationRequest,
	getUserReferralsInfoRequest,
	getUserReferralsInfoSuccess,
	sendEmailCodeSuccess,
	sendEmailCodeError,
	sendEmailCodeRequest,
	enableEmail2FASuccess,
	enableEmail2FAError,
	disableEmail2FASuccess,
	disableEmail2FAError,
	generateGoogle2FAKeySuccess,
	generateGoogle2FAKeyError,
	enableGoogle2FASuccess,
	enableGoogle2FAError,
	disableGoogle2FASuccess,
	disableGoogle2FAError,
	enableEmail2FARequest,
	disableEmail2FARequest,
	generateGoogle2FAKeyRequest,
	enableGoogle2FARequest,
	disableGoogle2FARequest,
	editProfileRequest,
	editProfileSuccess,
	getNationalityRequest,
	userSettingsFailure,
	getNationalitySuccess,
} from './reducer';
import {
	TGetNationalityResponse,
	TChangePasswordPayload,
	ICheck2faEnables,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	ISetUserPhoneRequestPayload,
	IUserSettings,
	INotifications,
	TReferralsData,
	ISendEmailCodeRequestPayload,
	IEmail2FARequestPayload,
	IGenerateGoogle2FAKeyRequestPayload,
	IGoogle2FARequestPayload,
} from './types';

// =============================================================:
function* userSettingsWorker() {
	try {
		yield put(showLoading());
		const response: IUserSettings = yield call(api.settings.userSettings);
		yield put(userSettingsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* changeUserPassWorker(action: PayloadAction<TChangePasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.changePassword, payload.data);
		yield put(changeUserPassSuccess());
		// yield put(authInitState());

		notificationContainer('Password successfully changed', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.data?.errors?.old_password[0] ===
				'the_old_password_and_password_must_be_different'
			) {
				notificationContainer('New Password cannot be same as previously used password', 'info');
				yield put(settingsInitState());
				return;
			}

			yield put(settingsInitState());
			callErrorFunc(error?.response?.data?.errors);
			return;
			// responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editProfileUserWorker(action: PayloadAction<TChangePasswordPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.settings.editProfile, payload);
		yield put(editProfileSuccess());
		// yield put(authInitState());

		notificationContainer('Profile successfully changed', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			yield put(settingsInitState());
			callErrorFunc(error?.response?.data?.errors);
			return;
			// responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// 2FA New

function* sendEmailCodeWorker({
	payload: { apiParams, onSuccess, onAlreadySent },
}: PayloadAction<ISendEmailCodeRequestPayload>) {
	try {
		yield put(showLoading());

		yield call(api.settings.sendEmailCode, apiParams);

		yield put(sendEmailCodeSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(sendEmailCodeError());

		if (axios.isAxiosError(error)) {
			if (error.response?.data?.errors?.[0] === 'already_sent_code') {
				onAlreadySent?.();
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* enableEmail2FAWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IEmail2FARequestPayload>) {
	try {
		yield put(showLoading());

		yield call(api.settings.enableEmail2FA, apiParams);

		yield put(enableEmail2FASuccess());
		onSuccess?.();
	} catch (error) {
		yield put(enableEmail2FAError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disableEmail2FAWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IEmail2FARequestPayload>) {
	try {
		yield put(showLoading());

		yield call(api.settings.disableEmail2FA, apiParams);

		yield put(disableEmail2FASuccess());
		onSuccess?.();
	} catch (error) {
		yield put(disableEmail2FAError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* generateGoogle2FAKeyWorker({
	payload: { onSuccess },
}: PayloadAction<IGenerateGoogle2FAKeyRequestPayload>) {
	try {
		yield put(showLoading());

		const data: IApiGenerateGoogle2FAKeyResponse = yield call(api.settings.generateGoogle2FAKey);

		yield put(generateGoogle2FAKeySuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(generateGoogle2FAKeyError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* enableGoogle2FAWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IGoogle2FARequestPayload>) {
	try {
		yield put(showLoading());

		yield call(api.settings.enableGoogle2FA, apiParams);

		yield put(enableGoogle2FASuccess());
		onSuccess?.();
	} catch (error) {
		yield put(enableGoogle2FAError());

		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				notificationContainer('Invalid 2FA code', 'error');
				yield put(settingsInitState());
				return;
			}
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disableGoogle2FAWorker({
	payload: { apiParams, onSuccess },
}: PayloadAction<IGoogle2FARequestPayload>) {
	try {
		yield put(showLoading());

		yield call(api.settings.disableGoogle2FA, apiParams);

		yield put(disableGoogle2FASuccess());
		onSuccess?.();
	} catch (error) {
		yield put(disableGoogle2FAError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// 2FA Old (to delete)

function* generate2faKeyWorker() {
	try {
		yield put(showLoading());
		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
		yield put(generate2faKeySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* check2faEnablesWorker() {
	try {
		yield put(showLoading());
		const response: ICheck2faEnables = yield call(api.settings.check2faEnables);
		yield put(check2faEnablesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.enable2fa, payload);
		yield put(enable2faSuccess());
		yield put(check2faEnablesRequest());
		// notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.disable2fa, payload);
		yield put(disable2faSuccess());
		yield put(check2faEnablesRequest());
		// notificationContainer(String(L.translate(`Errors.google_2fa_turned_off`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* setUserPhoneWorker(action: PayloadAction<ISetUserPhoneRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.settings.setPhone, payload);

		yield call(userSettingsWorker);

		// notificationContainer('Phone was successfully changed', 'success');
		payload.closeModal();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* checkNotificationWorker() {
	try {
		yield put(showLoading());
		const response: INotifications = yield call(api.settings.checkNotification);

		yield put(checkNotificationSuccess(response));
		// notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

function* updateNotificationWorker(action: PayloadAction<INotifications>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: INotifications = yield call(api.settings.updateNotification, payload);
		yield put(checkNotificationSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ======================================Gato=======================:

function* getRefarralsWorker() {
	try {
		yield put(showLoading());
		const response: TReferralsData = yield call(api.settings.getReferrals);
		yield put(getUserReferralsInfoSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getNationalityProfileWorker() {
	try {
		yield put(showLoading());
		const response: TGetNationalityResponse = yield call(api.settings.getNationality);

		yield put(getNationalitySuccess(response));
		// notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* settingsSaga() {
	yield takeEvery(userSettingsRequest.type, userSettingsWorker);
	yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	yield takeEvery(editProfileRequest.type, editProfileUserWorker);

	// 2FA New
	yield takeEvery(sendEmailCodeRequest, sendEmailCodeWorker);
	yield takeEvery(enableEmail2FARequest, enableEmail2FAWorker);
	yield takeEvery(disableEmail2FARequest, disableEmail2FAWorker);
	yield takeEvery(generateGoogle2FAKeyRequest, generateGoogle2FAKeyWorker);
	yield takeEvery(enableGoogle2FARequest, enableGoogle2FAWorker);
	yield takeEvery(disableGoogle2FARequest, disableGoogle2FAWorker);
	// 2FA Old (to delete)
	yield takeEvery(check2faEnablesRequest.type, check2faEnablesWorker);
	yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	yield takeEvery(enable2faRequest.type, enable2faWorker);
	yield takeEvery(disable2faRequest.type, disable2faWorker);

	yield takeEvery(setPhoneRequest.type, setUserPhoneWorker);
	yield takeEvery(checkNotificationRequest.type, checkNotificationWorker);
	yield takeEvery(updateNotificationRequest.type, updateNotificationWorker);
	yield takeEvery(getUserReferralsInfoRequest.type, getRefarralsWorker);
	yield takeEvery(getNationalityRequest.type, getNationalityProfileWorker);
}
