import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { IToken } from 'components/Exchange/ExchangeForm/type';

declare let window: any;

const { BigNumber, constants, utils } = ethers;
const { MaxUint256 } = constants;
const contractAdd = '0x54e621abd4bF9dF2DB1CE038A3aF25B71530C028';
// import { checkTransaction } from './helpers';
/* eslint-disable */
// export interface IToken {
// 	wallet: string;
// 	amount: string;
// 	abi: any | string;
// 	tokenName: string;
// 	tokenAddress: string;
// 	contractAddess: string;
// 	connector: any | string;
// 	setLoader: (val: boolean) => boolean;
// }

const _getEtheriumProvider = (prov: any) => {
	return new ethers.providers.Web3Provider(prov);
};

const _getSigner = (provider: any, account: any) => {
	return provider.getSigner(account);
};

export const ApproveTokens = async ({
	wallet,
	amount,
	abi,
	tokenName,
	tokenAddress,
	contractAddess,
	connector,
	provider,
	setLoader,
	chainid,
}: IToken) => {

	debugger;
	try {
		setLoader(true);

		let result;

		if (String(chainid) !== '4287') {
			const provide = _getEtheriumProvider(provider);

			const signer = _getSigner(provide, wallet);

			const Contract = new ethers.Contract(tokenAddress, abi, signer);

			const approve = await Contract.approve(contractAddess, amount);
			// debugger;
			result = approve.wait();
			notificationContainer(`Token ${String(tokenName)} was approved`, 'success');
		} else {
			const web3 = new Web3(provider);

			const contract = new web3.eth.Contract(abi, wallet); // адресс не важен

			const approve = await contract.methods.approve(contractAddess, amount).encodeABI();

			if (connector) {
				result = await web3.eth.sendTransaction({
					// Send transaction
					from: wallet,
					to: tokenAddress,
					data: approve,
				});
			}
			notificationContainer(`Token ${String(tokenName)} was approved`, 'success');
		}

		setLoader(false);

		return result;
	} catch (error: any) {
		setLoader(false);
		notificationContainer(`Approve failed: ${String(error?.message)}`, 'error');
	}
};

// export const ApproveBridgeTokens = async ({
// 	wallet,
// 	amount,
// 	abi,
// 	tokenName,
// 	tokenAddress,
// 	contractAddess,
// 	connector,
// 	provider,
// 	setLoader,
// }: IToken) => {
// 	try {
// 		setLoader(true);

// 		const provider = _getEtheriumProvider();

// 		const signer = _getSigner(provider, wallet);

// 		const Contract = new ethers.Contract(tokenAddress, abi, signer);

// 		const approve = await Contract.approve(contractAddess, amount);

// 		notificationContainer(`Token ${String(tokenName)} was approved`, 'success');

// 		setLoader(false);

// 		return approve;
// 	} catch (error: any) {
// 		setLoader(false);
// 		notificationContainer(`Approve failed: ${String(error?.message)}`, 'error');
// 	}
// };
