import { FC, useState } from 'react';

import { SearchIcon } from 'assets/custom-icons';
import { useDebounce } from 'hooks/useDebounce';
import ChartBlockGato from 'components/ChartBlockGato';
import TopTokensTable from '../TopTokensTable';
import TopPoolsTable from '../TopPoolsTable';
import TransactionsTable from '../TransactionsTable';
import ExchangeVolumeTable from '../ExchangeVolumeTable';

export const TopTokenPoolOverview: FC = () => {
	const [searchValue, setSearchValue] = useState('');

	const debouncedSearch = useDebounce(searchValue, 500);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	return (
		<div className="tokens">
			<div className="tokens-header">
				<h4 className="block-title block-title--smaller">GATOCHAIN Tokens list</h4>
				<div className="search-input search-input--tokens">
					<div className="input-wrapper">
						<input
							type="text"
							className="input-item input-item input-item--left-icon"
							placeholder="Search by token name or symbol"
							value={searchValue}
							onChange={handleChange}
						/>
						<span className="input-icon">
							<SearchIcon />
						</span>
					</div>
				</div>
			</div>
			<TopTokensTable searchValue={debouncedSearch} />
			<ChartBlockGato type="price" />
			<div className="tokens-header">
				<h4 className="block-title block-title--smaller">GATOCHAIN Overview</h4>
			</div>
			<ExchangeVolumeTable searchValue={debouncedSearch} />
			<TopPoolsTable searchValue={debouncedSearch} title="Pools volume 24h" />
			<ChartBlockGato type="swap" />

			<TransactionsTable searchValue={debouncedSearch} />
		</div>
	);
};
