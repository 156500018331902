import classNames from 'classnames';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGraphDataRequest, getTLVRequest } from 'redux/reducers/swap/reducer';
import { getGraphData, getToken } from 'redux/reducers/swap/selectors';
import { EPeriod } from 'services/api/swap/types';
import { ChartVertical } from 'ui/Charts/ChartVertical';
import { ChartLine } from 'ui/Charts/ChartLine';
import { ArrowLeft, ArrowRight, ArrowUp } from 'assets/custom-icons';

const ChartBlockWallet: FC<{ asset_address: string | undefined; type: 'price' | 'swap' }> = ({
	asset_address,
	type,
}) => {
	const dispatch = useDispatch();
	const moneyFormat = useMoneyFormat();

	const token = useSelector(getToken);
	const graphToken = useSelector(getGraphData);
	const graphPeriod = Object.keys(EPeriod);

	const [period, setPeriod] = useState<string>(EPeriod.month);
	const [selectMonth, setSelectMonth] = useState<Date | null>(null);
	const hadleClickPeriod = (value: string) => {
		setPeriod(value);
		setSelectMonth(null);
	};

	const handleChangeMonth = (num: number) => {
		if (selectMonth && selectMonth.getMonth() === new Date().getMonth()) {
			setSelectMonth(null);
			return;
		}
		if (!selectMonth && num < 0) {
			setSelectMonth(startOfMonth(new Date().setMonth(new Date().getMonth() + num)));
		} else if (selectMonth) {
			setSelectMonth(startOfMonth(selectMonth.setMonth(selectMonth.getMonth() + num)));
		}
		setPeriod(EPeriod.month);
	};

	const lastAmount = graphToken?.swap_volumes_graph.slice(-1);

	// useEffect(() => {
	// 	dispatch(getTLVRequest(id));
	// }, [dispatch, id]);

	useEffect(() => {
		let points: string;
		switch (period) {
			case 'week':
				points = '72';
				break;
			case 'month':
				points = '120';
				break;
			default:
				points = '24';
				break;
		}
		if (asset_address) {
			dispatch(
				getGraphDataRequest({
					apiParams: {
						asset_address: token.address,
						graphPoints: selectMonth ? '30' : points,
						period,
						from: selectMonth ? format(selectMonth, 'yyyy-MM-dd') : undefined,
						to: selectMonth ? format(endOfMonth(selectMonth), 'yyyy-MM-dd') : undefined,
					},
					type,
				}),
			);
		}
	}, [asset_address, dispatch, period, selectMonth, token, type]);

	return (
		<div className="chart-boxes chart-boxes--block">
			<div className="stat-and-chart">
				<div className="stat-and-chart__top">
					{type === 'price' ? (
						<div className="stat-and-chart__info">
							<p className="stat-and-chart__prices">${moneyFormat(graphToken?.last_price, 0, 6)}</p>
							<p className="stat-and-chart__text">{format(new Date(), 'MMM dd, YYY')}</p>
						</div>
					) : (
						<div className="stat-and-chart__info">
							<p className="stat-and-chart__text">TVL</p>
							<p className="stat-and-chart__prices">
								${moneyFormat(token?.reserve_usd_in_pools_24h, 2, 2, true)}
							</p>
							<p
								className={classNames('stat-and-chart__procent', {
									red: Number(token?.percent_tlv) < 0,
								})}
							>
								<span className="stat-and-chart__procent-icon">
									<ArrowUp />
								</span>
								{token?.percent_tlv}%
							</p>
						</div>
					)}

					<div className="stat-and-chart__calendar">
						{graphPeriod.map((item) => (
							<button
								key={item}
								type="button"
								className={classNames('stat-and-chart__date-btn', { active: item === period })}
								onClick={() => hadleClickPeriod(item)}
							>
								{item.slice(0, 1).toUpperCase()}
							</button>
						))}
					</div>
				</div>
				<div className="stat-and-chart__graph">
					{graphToken &&
						(type === 'price' ? (
							<ChartVertical dataChart={graphToken?.price_graph} period={period} />
						) : (
							<ChartLine dataChart={graphToken?.swap_volumes_graph} period={period} />
						))}
				</div>
				<div className="stat-and-chart__footer">
					<div className="block-pagination block-pagination--center ">
						<button
							type="button"
							className="block-pagination__btn active"
							onClick={() => handleChangeMonth(-1)}
							aria-label="Pagination"
						>
							<ArrowLeft />
						</button>
						<p className="block-pagination__text">{format(selectMonth || new Date(), 'MMMM')}</p>
						<button
							type="button"
							className={classNames('block-pagination__btn', {
								active: selectMonth,
							})}
							onClick={() => handleChangeMonth(1)}
							aria-label="Pagination"
						>
							<ArrowRight />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChartBlockWallet;
