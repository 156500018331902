/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiSearchResponse, IPayloadOptions, ISearchStore } from './types';
// ==========================================:
export const initialState: ISearchStore = {
	search: null,
	searchLoading: false,
};
// ==========================================:
const search = createSlice({
	name: '@@search',
	initialState,
	reducers: {
		getSearchRequest: (state, payload: PayloadAction<IPayloadOptions>) => {
			const stateItem = state;
			stateItem.searchLoading = true;
		},
		getSearchSuccess: (state, action: PayloadAction<IApiSearchResponse>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.search = payload;
			stateItem.searchLoading = false;
		},
		searchInitState: () => initialState,
	},
});

export default search.reducer;
export const { getSearchRequest, getSearchSuccess, searchInitState } = search.actions;
