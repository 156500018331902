import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OneTokenOverview } from './OneTokenOverview';
import { TopTokenPoolOverview } from './TopTokenPoolOverview';

export const TokenOverview: FC = () => {
	const { tokenId } = useParams();

	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block inner-block--bigger">
					<div className="swap swap--mb">
						<Link to="/pool-token" className="swap__item active">
							Tokens
						</Link>
						<Link to="/exchange" className="swap__item">
							Exchange
						</Link>
						<Link to="/pool" className="swap__item">
							Pool
						</Link>
					</div>
				</div>
				{tokenId ? <OneTokenOverview /> : <TopTokenPoolOverview />}
			</div>
		</section>
	);
};
