import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { EType } from 'components/TokenOverview/types';
import { formatDistance } from 'date-fns';
import { TUserTransactionItem } from 'redux/reducers/wallets/types';

const TransactionRow: FC<TUserTransactionItem> = ({
	base_asset,
	quote_asset,
	type,
	tx,
	created_at,
}) => {
	const typeTransaction = (typeO: string) => {
		switch (typeO) {
			case EType.swaps:
				return `Swap ${base_asset.symbol.toUpperCase()} for ${quote_asset.symbol.toUpperCase()}`;

			case EType.adds:
				return `Add liquidity ${base_asset.symbol.toUpperCase()}/${quote_asset?.symbol.toLocaleUpperCase()}`;
			case EType.removes:
				return `Delete liquidity ${base_asset.symbol.toUpperCase()}/${quote_asset?.symbol.toLocaleUpperCase()}`;

			default:
				return '';
		}
	};
	return (
		<div className="td-group">
			<div className="td">
				<Link to={`/transactions/${tx}`}>{typeTransaction(type)}</Link>
			</div>
			<div className="td">
				<p>{formatDistance(Date.parse(created_at), new Date(), { addSuffix: true })}</p>
			</div>
		</div>
	);
};

export default TransactionRow;
