/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable */
import { FC, useEffect, useMemo, useState, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ethers, constants } from 'ethers';
import { ReactComponent as GatoChain2Icon } from 'assets/images/gatochain2.svg';
import { ArrowBack, TooltipNotation } from 'assets/custom-icons';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getAuthIsAuthenticated, getAuthTokenRefresh } from 'redux/reducers/auth/selectors';
import { getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { TWalletData } from 'redux/reducers/wallets/types';
import { noExponent } from 'services/utils/noExponent';
import { stakingApprove, getSelector } from 'services/utils/staking';
import { toMaxDecimals } from 'services/utils/numbers';
import classNames from 'classnames';
import { getApy } from 'redux/reducers/earnings/selectors';
import { calculatedApyRequest, sendStakeTransactionRequest } from 'redux/reducers/earnings/reducer';
import AbiFee from 'config/abi/FeeController.json';
import { useDebounce } from 'hooks/useDebounce';
import Loader from 'ui/Loader';
import AbiStaking from 'config/abi/StakingPools.json';
import { feeChecker } from 'services/utils/feeChecker';
import { useWeb3React } from '@web3-react/core';
import { ITypeTransaction } from './types';
import ConfirmStakePopUp from './ConfirmStakePopUp';
import { authInitState } from 'redux/reducers/auth/reducer';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import useRefreshToken from 'hooks/useRefreshToken';

const STAKE_CONTRACT_ADDRESS = String(process.env.REACT_APP_STAKE_CONTRACT_ADDRESS);

const TAKE_SELECTOR = '0xc7b9d8b9';

declare let window: any;
const PERCENTS = [0.25, 0.5, 0.75, 1];
const DURATION = ['10', '30', '60', '90'];

export const StakeIt: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tokenRefresh = useRefreshToken();
	const { address: addressWeb3, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [loader, setLoader] = useState(false);
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const address = useSelector(getAddress);
	const walletsUser = useSelector(getWalletsUser);
	const walletLoader = useSelector(getWalletLoader);
	const apy = useSelector(getApy);
	const [value, setValue] = useState('');
	const [days, setDays] = useState('10');
	const { provider, account } = useWeb3React();

	const debouncedAmount = useDebounce(value, 500);
	const debouncedDays = useDebounce(days, 500);

	const handleDuration = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setDays(name);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const gatoCoin = walletsUser?.balances?.data?.find(
		(item: TWalletData) => item?.asset?.symbol === 'GATO' && item?.asset?.type === 'coin',
	);

	const [currentPercent, setCurrentPercent] = useState(0);
	const handleClickPercentButtons = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentPercent(Number(name));
		setValue(String(Number(name) * Number(gatoCoin?.balance)));
	};

	const handleMax = () => {
		setValue(toMaxDecimals(String(gatoCoin?.balance), 12));
	};

	const minValue = 50;

	const handleStake = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		if (Number(value) < minValue) {
			notificationContainer('Minimum amount for staking 50 GATO', 'error');
			return;
		}
		if (address.wallet) {
			try {
				let currentAddress = connector === 'INJECTED' ? account : addressWeb3;
				let accounts;
				if (connector) {
					// accounts = await library?.provider.request({
					// 	method: 'eth_requestAccounts',
					// });
				}

				const paramsSelector: any = {
					to: String(STAKE_CONTRACT_ADDRESS),
					abi: AbiStaking.abi,
					provider: currentProvider,
				};

				const selector = await getSelector(paramsSelector);

				const paramsFee: any = {
					wallet: currentAddress,
					selector: selector,
					abi: AbiFee.abi,
					contractAddess: String(STAKE_CONTRACT_ADDRESS), // Address contract
					tokenAddress: String(constants.AddressZero),
					provider: currentProvider,
					setLoader,
					connector,
				};

				const amountFee = await feeChecker(paramsFee);

				if (!amountFee) {
					return;
				}

				const sendParams: ITypeTransaction = {
					wallet: String(currentAddress),
					connector,
					abi: AbiStaking.abi,
					amount: Number(value),
					lockPeriod: days,
					contractAddress: String(STAKE_CONTRACT_ADDRESS),
					provider: currentProvider,
					amountFee,
					setLoader,
				};

				const result = await stakingApprove(sendParams);
				if (result) {
					dispatch(
						sendStakeTransactionRequest({
							request: {
								tx: String(result?.transactionHash),
								period: days,
								type: 'add_staking',
								base_amount: Number(value),
								base_id: Number(gatoCoin?.asset.id),
							},
							wallet: String(currentAddress)?.toLowerCase(),
						}),
					);
					navigate('/staking');
				}
			} catch (error: any) {
				notificationContainer(`Error: ${String(error?.message)}`, 'error');
			}
		}
	};

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
					current_page: '1',
					per_page: 1000,
				}),
			);
		}
		window.scrollTo(0, 0);
	}, [dispatch, isAuthenticated, address]);

	useEffect(() => {
		if (address.wallet && gatoCoin) {
			dispatch(
				calculatedApyRequest({
					wallet_address: address.wallet.toLowerCase(),
					period: debouncedDays,
					amount: debouncedAmount,
					asset_address: gatoCoin?.asset.address,
				}),
			);
		}
	}, [dispatch, isAuthenticated, address, gatoCoin, debouncedDays, debouncedAmount]);

	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="back-to-page">
						<Link to="/staking" className="icon-btn">
							<ArrowBack />
						</Link>
						<h3 className="block-title block-title--smaller ">Stake GATO</h3>
					</div>

					<div className="general-block general-block--center">
						<div className="table-position">
							{walletLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)}
							<div className="token-box-wrapper">
								<div className="token-box">
									<div className="token-box__icon-4">
										{/* <img className="token-box__icon-1" src="images/gatochain2.svg" alt="" /> */}
										<GatoChain2Icon className="token-box__icon-1" />
									</div>
									<div className="token-box__info">
										<div className="token-box__name">GATO</div>
									</div>
								</div>
								<div className="token-box">
									<div className="token-box__info">
										<p className="token-box__text token-box__text--right">Amount available</p>
										<div className="token-box__name token-box__name--fw700 token-box__text--right">
											{toMaxDecimals(String(gatoCoin?.balance || 0), 6)} GATO
										</div>
									</div>
								</div>
							</div>

							<div className="block-content">
								<div className="token-box-wrapper">
									<div className="token-box">
										<div className="token-box__info">
											<p className="token-box__text token-box__text--mb8">Est. APY</p>
											<p className="token-box__percent">{toMaxDecimals(apy, 6)}%</p>
										</div>
									</div>
									<div className="token-box">
										<div className="token-box__info">
											<p className="token-box__text token-box__text--tooltip">
												Duration (days)
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<TooltipNotation />
													</span>
													<span className="tooltip tooltip--top tooltip--left">
														<span className="tooltip__text">
															Staking requires a lockup period, when your crypto can't be
															transferred for a certain period of time
														</span>
													</span>
												</span>
											</p>

											<div className="percent-buttons">
												{DURATION.map((day) => (
													<button
														key={day}
														type="button"
														className={classNames('percent-buttons__item', {
															active: day === days,
														})}
														name={day}
														onClick={handleDuration}
													>
														{day}
													</button>
												))}
											</div>
										</div>
									</div>
								</div>

								<p className="block-text">Amount</p>

								<div className="exchange-item exchange-item--small-height">
									<p className="staking-name">GATO</p>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info input-item--small-height"
													type="number"
													placeholder={`Min. ${minValue}`}
													// min="50"
													value={noExponent(toMaxDecimals(String(value), 6))}
													onChange={handleValueChange}
												/>
												<button type="button" onClick={handleMax} className="exchange-max-btn">
													MAX
												</button>
											</div>
										</label>
									</div>
								</div>
								<div className="percent-btns">
									{PERCENTS.map((percent) => (
										<button
											key={percent}
											type="button"
											name={String(percent)}
											className={`percent-btns__item ${percent === currentPercent ? 'active' : ''}`}
											onClick={handleClickPercentButtons}
										>
											{percent * 100}%
										</button>
									))}
								</div>
							</div>

							<div className="block-footer">
								<button
									type="button"
									disabled={!address.wallet}
									onClick={handleStake}
									className="button button--full-width"
								>
									{address.wallet ? 'Stake it' : 'Please connect wallet'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConfirmStakePopUp
				openModal={loader}
				setOpenModal={setLoader}
				title="Stake"
				text={`${value} GATO for ${days} days`}
			/>
		</section>
	);
};
