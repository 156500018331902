import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	TPoolPairDataRequest,
	TTryTokenRequest,
	TTransactionResponse,
} from 'redux/reducers/swap/types';
import { ISwapApi } from './types';

export const swap: ISwapApi = {
	getAssets: (params) => {
		return http.get(endpoint.swap.GET_ASSETS, { params }).then(({ data }) => data);
	},
	getPairs: (params) => {
		return http.get(endpoint.swap.GET_PAIRS, { params }).then(({ data }) => data);
	},

	calculateRate: (params) => {
		return http.get(endpoint.swap.CALCULATE_RATE(params)).then(({ data }) => data);
	},

	sendTransaction: (payload) => {
		return http
			.post<TTransactionResponse>(endpoint.swap.TRANSACTION_SWAP, payload)
			.then(({ data }) => data);
	},

	getTopPools: (params) => http.get(endpoint.swap.TOP_POOLS, { params }).then(({ data }) => data),

	getTopTokens: (params) => http.get(endpoint.swap.TOP_TOKENS, { params }).then(({ data }) => data),

	getPools: async (params) => {
		return http.get<any>(endpoint.swap.GET_POOLS(params)).then(({ data }) => data);
	},
	getTLV: (payload) => http.get(endpoint.swap.GET_TLV(payload)).then(({ data }) => data),

	getGraphToken: (params) =>
		http.get(endpoint.swap.GET_GRAPH_PRICE, { params }).then(({ data }) => data),

	getPairLiquidity: async (payload) => {
		const { wallet, pairId } = payload;
		return http
			.get<TPoolPairDataRequest>(endpoint.swap.GET_LIQUIDITY_PAIR(wallet, pairId))
			.then(({ data }) => data);
	},
	getGraphTokenHash: (payload) => {
		return http.post<any>(endpoint.swap.TRANSACTION_TOKEN_HASH, payload).then(({ data }) => data);
	},
	getTryTokens: async (params) => {
		return http.get<any>(endpoint.swap.TRY_ASSET_TOKEN_CHECK, { params }).then(({ data }) => data);
	},
	getTokensList: (params) =>
		http.get(endpoint.swap.GET_TOKENS_LIST, { params }).then(({ data }) => data),
	postAutoSwapFee: (payload) => {
		return http.post<any>(endpoint.swap.AUTO_SWAP_FEE, payload).then(({ data }) => data);
	},
};
