import {
	ISwapPairListUnsafe,
	ISwapTokenListUnsafe,
	ISwapTokenUnsafe,
	TCalculatedRateUnsafe,
} from 'redux/reducers/swap/types';
import { noExponent } from 'services/utils/noExponent';
/* eslint-disable */
import { toMaxDecimals } from 'services/utils/numbers';
import { IApiSwapPair } from 'services/api/swap/types';
import { TArrayPair } from './type';
import { IExchangeLocationStateWithObject } from '../types';
import { BigNumber } from 'ethers';
import { IApiSwapAsset } from 'services/api/swap/types';
import { wrap } from 'module';

const MINIMUM_LIQUIDITY = BigNumber.from(10).pow(3);
// 📌 Tokens

const wrapedGato = String(process.env.REACT_APP_WRAPED_GATO);

// const wrapedGato = '0x0000000000000000000000000000000000000000';
const GUDS = String(process.env.REACT_APP_GUDS);

export const getInitTokenSymbols = (
	locationState: IExchangeLocationStateWithObject | null,
	asset: IApiSwapAsset[] | undefined,
) => {

	const GUSD_address = { address: GUDS, symbol: 'GUSD' };
	const GATO_address = { address: wrapedGato, symbol: 'WGATO' };

	const defaultFromSymbol =
		locationState?.initToSymbol?.address !== 'GUSD' ? GUSD_address : GATO_address;
	const defaultToSymbol =
		locationState?.initFromSymbol?.address !== 'WGATO' ? GATO_address : GUSD_address;

	const initFromSymbolToken = locationState?.initFromSymbol || defaultFromSymbol;
	const initToSymbolToken = locationState?.initToSymbol || defaultToSymbol;

	const findeInitBySymbolFrom = asset?.find(
		({ address }) =>
			address?.toLocaleLowerCase() === initFromSymbolToken?.address?.toLocaleLowerCase(),
	);
	const findeInitBySymbolTo = asset?.find(
		({ address }) =>
			address?.toLocaleLowerCase() === initToSymbolToken?.address?.toLocaleLowerCase(),
	);

	return [findeInitBySymbolFrom, findeInitBySymbolTo];
};

export const initialTokenPair = (
	pair: ISwapPairListUnsafe,
	setList: ISwapTokenListUnsafe,
	firstTOken?: IApiSwapAsset,
) => {
	// debugger;
	const currentAsset = setList?.find((item) => {
		if (pair && setList) {
			return !firstTOken
				? String(item.address) === String(pair[0].asset_base.address)
				: String(item.address) === String(pair[0].asset_quote.address);
		}
	});
	return currentAsset;
};

export const getTokenBySymbol = (tokens: ISwapTokenListUnsafe, searchSymbol?: ISwapTokenUnsafe) => {
	if (!tokens || !searchSymbol) {
		return undefined;
	}
	return tokens.find(({ address }) => address === searchSymbol.address);
};

export const getTokensWithoutSymbol = (
	tokens: ISwapTokenListUnsafe,
	withoutSymbol?: ISwapTokenUnsafe,
	pair?: TArrayPair,
) => {
	if (!tokens || !withoutSymbol) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutSymbol.address);
};

// 📌 Pairs

export const getIsTokenPairInList = (
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
	pairList: ISwapPairListUnsafe,
) => {
	if (!fromToken || !toToken || !pairList) {
		return false;
	}
	return pairList.some(({ base_id, quote_id }) => {
		if (base_id === fromToken.address && quote_id === toToken.address) {
			return true;
		}
		return base_id === toToken.address && quote_id === fromToken.address;
	});
};

// 📌 Calculate rate

export const maxNoExponent = 1e21;
export const outputDecimals = 12;
export const minSignificant = 1 * 10 ** -outputDecimals;
export const testDecimalGato = 6;
export const testGatoDesimals = 8;

export const getRateForTokenPair = (
	isPairInList: boolean,
	calculatedRate: TCalculatedRateUnsafe,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
) => {
	if (!isPairInList || !calculatedRate || !fromToken || !toToken) {
		return null;
	}
	//debugger;
	const { firstAddress, secondAddress } = calculatedRate;

	// if (fromToken.address !== firstAddress || toToken.address !== secondAddress) {
	// 	return null;
	// }
	return calculatedRate;
};

export const getRateByDirection = (isFromPerTo: boolean, pairRate: TCalculatedRateUnsafe) => {
	if (!pairRate) {
		return undefined;
	}
	const { secondPerFirst } = pairRate;

	return isFromPerTo ? 1 / +secondPerFirst : +secondPerFirst;
};

export const getOppositeValue = (
	fromValue: string,
	toValue: string,
	isLastFromValue: boolean,
	pairRate: TCalculatedRateUnsafe,
) => {
	const lastValue = isLastFromValue ? fromValue : toValue;
	const valueNum = Number(lastValue);

	if (!valueNum || valueNum < minSignificant || valueNum >= maxNoExponent || !pairRate) {
		return '';
	}
	// if (!valueNum || !pairRate) {
	// 	return '';
	// }
	const secondPerFirstNum = Number(pairRate.secondPerFirst);
	// const otherValueNum = isLastFromValue
	// ? valueNum * secondPerFirstNum
	// : valueNum / secondPerFirstNum;
	const otherValueNum = secondPerFirstNum;

	if (otherValueNum < minSignificant || otherValueNum >= maxNoExponent || !otherValueNum) {
		return '';
	}
	return toMaxDecimals(otherValueNum, outputDecimals);
};

const calculateAmountOut = (
	amountIn: string,
	reserveBase0: string,
	reserveBase1: string,
	decimals: number,
) => {
	const reserve0 = noExponent(reserveBase0);
	const reserve1 = noExponent(reserveBase1);
	const amountInBN = BigNumber.from(
		String(noExponent(Math.floor(Number(amountIn) * 10 ** decimals))),
	);
	const amountInWithFee = amountInBN.mul('99');
	const numerator: any = amountInWithFee.mul(BigNumber.from(String(reserve1)));
	const denominator: any = BigNumber.from(String(reserve0)).mul(100).add(String(amountInWithFee));
	return Math.floor(Number(numerator / denominator));
};

export const getLiqudityValue = (
	fromValue: string,
	toValue: string,
	isLastFromValue: boolean,
	currentPair: IApiSwapPair | undefined,
	pairAmount?: boolean,
) => {
	const lastValue = isLastFromValue ? fromValue : toValue;
	const lastReserve0 = isLastFromValue ? currentPair?.reserve_quote : currentPair?.reserve_base;
	const lastReserve1 = isLastFromValue ? currentPair?.reserve_base : currentPair?.reserve_quote;
	const lastDecimals = isLastFromValue
		? currentPair?.asset_quote.decimals
		: currentPair?.asset_base.decimals;

	let amountOut = 0;
	if (lastReserve0 && lastReserve1) {
		amountOut = calculateAmountOut(lastValue, lastReserve0, lastReserve1, Number(lastDecimals));
	}

	return String(
		numberOutDecimal(
			amountOut,
			isLastFromValue
				? Number(currentPair?.asset_base.decimals)
				: Number(currentPair?.asset_quote.decimals),
		),
	);
};

export const getLS = (key: string): any => {
	try {
		const lsValue: string | null = localStorage.getItem(`chain_${key}`);
		if (lsValue) {
			return lsValue;
		}
		throw new Error(`I do not find chain_${key}`);
	} catch (error) {
		return null;
	}
};

export const setLS = (key: string, value: any): any => {
	try {
		localStorage.setItem(`chain_${key}`, value);
	} catch (error) {
		null;
	}
};

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};

export const getCurrentPair = (
	arrayPair: ISwapPairListUnsafe,
	from: ISwapTokenUnsafe,
	to: ISwapTokenUnsafe,
) => {
	const pair = arrayPair?.find((item) => {
		if (
			(item.base_id === from?.address && item.quote_id === to?.address) ||
			(item.base_id === to?.address && item.quote_id === from?.address)
		) {
			return item;
		}
	});
	return pair;
};

export const tryInsufficientLiquidity = (
	pair: IApiSwapPair | undefined,
	amountIn: string,
	amountOut: string,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
) => {
	if (Number(amountOut) <= 0) {
		return 'Amount from is to small';
	}

	if (Math.floor(Number(pair?.total_supply)) < Number(MINIMUM_LIQUIDITY)) {
		return 'Insufficient liquidity for this trade';
	}

	const reserv2 =
		toToken?.address === pair?.asset_quote.address ? pair?.reserve_quote : pair?.reserve_base;

	if (Math.floor(numberInDecimal(Number(amountOut), Number(toToken?.decimals))) > Number(reserv2)) {
		return 'Insufficient liquidity for this trade';
	}

	if (Number(fromToken?.balance) < Number(amountIn)) {
		return `Insufficient ${fromToken?.symbol} balance`;
	}

	return '';
};

export const getCurrentTokens = (
	arrayPair: ISwapPairListUnsafe,
	tokenList: ISwapTokenListUnsafe,
	value: ISwapTokenUnsafe,
	to: ISwapTokenUnsafe,
) => {

	const filteredPair = arrayPair?.filter((pair) => {
		return pair.base_id === to?.address || pair.quote_id === to?.address;
	});

	const arr: ISwapTokenListUnsafe = [];
	filteredPair?.forEach((it) => {
		if (it.base_id === to?.address) {
			arr.push(it.asset_quote);
		}
		if (it.quote_id === to?.address) {
			arr.push(it.asset_base);
		}
	});

	const pair = tokenList?.filter((item) => {
		const newTokens = arr.find((tok) => {
			return tok.address === item.address;
		});
		return newTokens;
	});

	console.log('pair__________', pair);
	return pair;
};
