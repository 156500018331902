import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TPoolItem } from './type';

export const LiquidityItem: FC<TPoolItem> = ({ pool, wallet }) => {
	// eslint-disable-next-line
	const { quote_amount, base_amount, percent_in_pool, userBalanceLpToken, pair} = pool;
	const [open, setOpne] = useState(false);
	const navigate = useNavigate();
	const openLiqudity = () => {
		navigate(
			`/remove-liquidity?${String(pair.asset_base.symbol)}/${String(
				pair.asset_quote.symbol,
			)}/${String(wallet)}/${String(pair.pair_id)}`,
		);
	};

	return (
		<div className="exchange-item exchange-item--your-liqud ">
			<div className={`select select--full-width select--transparent ${open ? 'active' : ''}`}>
				<div className="select__current">
					<div className="select-wrapper">
						<div className="token-block" onClick={openLiqudity}>
							<div className="token-block__icon">
								<img className="avatar--round" src={pair.asset_base.logo} alt="" />
							</div>
							<div className="token-block__icon">
								<img className="avatar--round" src={pair.asset_quote.logo} alt="" />
							</div>
							<div className="token-block__info">
								<p className="token-block__name">
									{pair.asset_base.symbol} - {pair.asset_quote.symbol}
								</p>
							</div>
						</div>
						<p className="exchange-item__text">{userBalanceLpToken}</p>
					</div>

					<span
						onClick={() => setOpne(!open)}
						className="select__current-arrow select__current-arrow--bigger"
					>
						<svg
							width="12"
							height="8"
							viewBox="0 0 12 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.41406 1.75L5.91406 6.25L10.4141 1.75"
								stroke="#1A203F"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				</div>
			</div>

			<div className="select-drop-list">
				<div className="select-drop-item">
					<div className="token-block">
						<div className="token-block__icon">
							<img className="avatar--round" src={pair.asset_base.logo} alt="" />
						</div>
						<div className="token-block__info">
							<p className="token-block__name">{pair.asset_base.name}</p>
						</div>
					</div>
					<p className="select-drop-text">{base_amount}</p>
				</div>
				<div className="select-drop-item">
					<div className="token-block">
						<div className="token-block__icon">
							<img className="avatar--round" src={pair.asset_quote.logo} alt="" />
						</div>
						<div className="token-block__info">
							<p className="token-block__name">{pair.asset_quote.name}</p>
						</div>
					</div>
					<p className="select-drop-text">{quote_amount}</p>
				</div>
				<div className="select-drop-item">
					<p className="select-drop-text">Share of Pool</p>
					<p className="select-drop-text">&lt;{percent_in_pool}%</p>
				</div>
			</div>
		</div>
	);
};
