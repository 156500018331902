import React, { FC } from 'react';
import { format } from 'date-fns';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
	PointElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IPriceGraph } from 'services/api/swap/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, Tooltip);

export const options: any = {
	responsive: true,
	borderLeftWidth: 2,
	borderRightWidth: 2,
	borderColor: 'rgba(255,255,255,0)',
	inflateAmount: 0,
	scales: {
		x: {
			ticks: {
				// display: false,
				color: '#A5A5BA',
				maxTicksLimit: 7,
				maxRotation: 0,
				minRotation: 0,
			},
			grid: {
				display: false,
			},
		},
		y: {
			grid: {
				display: false,
			},
			ticks: {
				callback: (val: number) =>
					new Intl.NumberFormat('en-IN', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 18,
					}).format(val),
			},
		},
	},
};

interface IChartVerticalProps {
	dataChart: IPriceGraph[];
	period: string;
}

export const ChartVertical: FC<IChartVerticalProps> = ({ dataChart, period }) => {
	const labels = dataChart?.map(
		(el) =>
			period === 'day'
				? format(Number(el.time) * 1000, 'hh:mm')
				: format(Number(el.time) * 1000, 'MMM dd'),
		// : format(new Date(el.time.split(' ')[0]), 'MMM dd'),
	);

	const minValue = Math.min(...dataChart.map((el) => el.price_in_usd)) - 0.01;
	const maxValue = Math.max(...dataChart.map((el) => el.price_in_usd)) + 0.01;

	const data = {
		labels,
		datasets: [
			{
				data: dataChart.map((el) => el.price_in_usd),
				backgroundColor: 'rgba(40,184,102, 1)',
			},
		],
	};
	return (
		dataChart && (
			<Bar
				options={{
					...options,
					scales: {
						...options.scales,
						y: { ...options.scales.y, min: minValue, max: maxValue },
					},
				}}
				data={data}
			/>
		)
	);
};
