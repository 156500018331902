import React, { FC, useMemo, useState } from 'react';
import { IAddressTokenBalance } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';
import TokensTable from 'components/Transactions/TokensTable';
import { EmptyTable } from 'layouts-elements/EmptyTable';

interface ITokensTabProps {
	tokensList: IAddressTokenBalance[] | undefined;
}

const TokensTab: FC<ITokensTabProps> = ({ tokensList }) => {
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const perPage = 6;

	const currentTokens = useMemo(() => {
		if (!tokensList) return [];
		setItemOffset((currentPage - 1) * perPage);
		const endOffset = itemOffset + perPage;
		setPageCount(Math.ceil(tokensList.length / perPage));
		return tokensList.slice(itemOffset, endOffset);
	}, [currentPage, itemOffset, tokensList]);

	return (
		currentTokens && (
			<>
				<div className="explore-block__top ">
					<h4 className="block-title block-title--mr-auto">Tokens</h4>

					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={pageCount}
						setCurrentPage={setCurrentPage}
					/>
				</div>
				{currentTokens.length ? (
					<TokensTable itemsList={currentTokens} />
				) : (
					<EmptyTable text="No Recent Tokens Yet" />
				)}
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={pageCount}
					setCurrentPage={setCurrentPage}
				/>
			</>
		)
	);
};

export default TokensTab;
