import { FC, useEffect } from 'react';
import { TPoolsItem } from 'redux/reducers/swap/types';
import { NavLink } from 'react-router-dom';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getPoolsRequest } from 'redux/reducers/swap/reducer';
import { getPools } from 'redux/reducers/swap/selectors';
import { LiquidityItem } from './LiquidityItem';

export const Pool: FC = () => {
	const walletAddress = useSelector(getAddress);
	const data = useSelector(getPools);
	const dispatch = useDispatch();

	useEffect(() => {
		if (walletAddress.wallet) {
			dispatch(getPoolsRequest({ address: walletAddress.wallet }));
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [dispatch, walletAddress.wallet]);

	return (
		<>
			{data?.pools?.length ? (
				''
			) : (
				<NavLink
					to="/liquidity"
					className="button button--full-width button--type4 button--radius-10"
				>
					<span className="btn-icon">
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.5259 4.0415C10.8711 4.04196 11.1505 4.32215 11.1501 4.66732L11.1348 16.334C11.1343 16.6792 10.8541 16.9586 10.5089 16.9582C10.1638 16.9577 9.88431 16.6775 9.88477 16.3324L9.90006 4.66568C9.90051 4.32051 10.1807 4.04105 10.5259 4.0415Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.04102 10.5C4.04102 10.1548 4.32084 9.875 4.66602 9.875H16.3327C16.6779 9.875 16.9577 10.1548 16.9577 10.5C16.9577 10.8452 16.6779 11.125 16.3327 11.125H4.66602C4.32084 11.125 4.04102 10.8452 4.04102 10.5Z"
								fill="white"
							/>
						</svg>
					</span>
					Add Liquidity
				</NavLink>
			)}
			<div
				className={`general-block ${
					data?.pools?.length ? 'general-block--center' : 'general-block--type2'
				}`}
			>
				{data?.pools?.length ? (
					<>
						<div className="block-header block-header--mb0">
							<h4 className="general-block-title">Your Liquidity</h4>
						</div>
						<p className="block-subtitle">Remove liquidity to receive tokens back</p>
					</>
				) : (
					''
				)}
				<div className="block-content">
					{data?.pools?.length ? (
						<>
							{data.pools.map((pool: TPoolsItem, id: number) => (
								<LiquidityItem
									key={`${pool.symbol}${String(id)}`}
									pool={pool}
									wallet={String(walletAddress?.wallet)}
								/>
							))}
							<p className="block-subtitle block-subtitle--mb-4">Don’t see a pool you joined</p>
							<div className="link-block">
								<NavLink to="/all-pools" className="link-block__item">
									Find other LP tokens
								</NavLink>
								<span className="link-block__arrow">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M3.5 12H20.33"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
						</>
					) : (
						<div className="liquidity-block">
							<div className="liquidity-icon">
								<svg
									width="50"
									height="50"
									viewBox="0 0 50 50"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18.7494 45.8332H31.2494C41.666 45.8332 45.8327 41.6665 45.8327 31.2498V18.7498C45.8327 8.33317 41.666 4.1665 31.2494 4.1665H18.7494C8.33268 4.1665 4.16602 8.33317 4.16602 18.7498V31.2498C4.16602 41.6665 8.33268 45.8332 18.7494 45.8332Z"
										stroke="#8E8EA9"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M4.16602 27.0833H11.9993C13.5827 27.0833 15.0202 27.9792 15.7285 29.3958L17.5827 33.125C18.7493 35.4167 20.8327 35.4167 21.3327 35.4167H28.6868C30.2702 35.4167 31.7077 34.5208 32.416 33.1042L34.2702 29.375C34.9785 27.9583 36.416 27.0625 37.9993 27.0625H45.791"
										stroke="#8E8EA9"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<p className="general-block-text">
								Your active V3 liquidity positions will appear here.
							</p>
						</div>
					)}
				</div>
				<div className="block-footer">
					{data?.pools?.length ? (
						<NavLink
							to="/liquidity"
							className="button button--full-width button--type4 button--radius-10"
						>
							<span className="btn-icon">
								<svg
									width="21"
									height="21"
									viewBox="0 0 21 21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.5259 4.0415C10.8711 4.04196 11.1505 4.32215 11.1501 4.66732L11.1348 16.334C11.1343 16.6792 10.8541 16.9586 10.5089 16.9582C10.1638 16.9577 9.88431 16.6775 9.88477 16.3324L9.90006 4.66568C9.90051 4.32051 10.1807 4.04105 10.5259 4.0415Z"
										fill="white"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.04102 10.5C4.04102 10.1548 4.32084 9.875 4.66602 9.875H16.3327C16.6779 9.875 16.9577 10.1548 16.9577 10.5C16.9577 10.8452 16.6779 11.125 16.3327 11.125H4.66602C4.32084 11.125 4.04102 10.8452 4.04102 10.5Z"
										fill="white"
									/>
								</svg>
							</span>
							Add Liquidity
						</NavLink>
					) : (
						<ConnectWalletButton />
					)}
				</div>
			</div>
			<div className="see-all">
				<NavLink to="/all-pools">See all available pools</NavLink>
			</div>
		</>
	);
};
