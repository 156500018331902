import { FC, useState } from 'react';
import { LanguageItem } from '../LanguageItem';
import { arrItem, TItem } from '../LanguageItem/constant';

export const AccountLanguage: FC = () => {
	const [checked, setChecked] = useState('English');
	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="authorization-form authorization-form--language">
						<div className="authorization-form__header">
							<p className="form-title form-title--mob-center">Language</p>
						</div>
						<ul className="language-list">
							{arrItem.map((langItem: TItem, id: number) => (
								<LanguageItem
									key={`${String(id) + langItem.text}`}
									langItem={langItem}
									checked={checked}
									setChecked={setChecked}
								/>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};
