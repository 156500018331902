import { ISwapTokenListUnsafe, ISwapTokenUnsafe } from 'redux/reducers/swap/types';
/* eslint-disable */
import { IApiSwapAsset } from 'services/api/swap/types';

import { IExchangeLocationStateWithObject } from '../types';

import { TArrayPair } from './type';

const wrapedGato = String(process.env.REACT_APP_WRAPED_GATO);

// 📌 Tokens

export const getInitTokenSymbols = (
	locationState: IExchangeLocationStateWithObject | null,
	asset: IApiSwapAsset[] | undefined,
) => {
	const GUSD_address = { address: '0x9399c6598e1d80ab5aad44aa5aaa2d7771351944', symbol: 'GUSD' };
	const GATO_address = { address: wrapedGato, symbol: 'GATO' };

	const defaultFromSymbol =
		locationState?.initToSymbol?.address !== 'GUSD' ? GUSD_address : GATO_address;
	const defaultToSymbol =
		locationState?.initFromSymbol?.address !== 'GATO' ? GATO_address : GUSD_address;

	const initFromSymbolToken = locationState?.initFromSymbol || defaultFromSymbol;
	const initToSymbolToken = locationState?.initToSymbol || defaultToSymbol;

	const findeInitBySymbolFrom = asset?.find(
		({ address }) => address === initFromSymbolToken?.address,
	);
	const findeInitBySymbolTo = asset?.find(({ address }) => address === initToSymbolToken?.address);

	return [findeInitBySymbolFrom, findeInitBySymbolTo];
};

export const getTokenBySymbol = (tokens: ISwapTokenListUnsafe, searchSymbol?: ISwapTokenUnsafe) => {
	if (!tokens || !searchSymbol) {
		return undefined;
	}
	return tokens.find(({ address }) => address === searchSymbol.address);
};

export const getTokensWithoutSymbol = (
	tokens: ISwapTokenListUnsafe,
	withoutSymbol?: ISwapTokenUnsafe,
	pair?: TArrayPair,
) => {
	if (!tokens || !withoutSymbol) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutSymbol.address);
};
export const getTokensWithoutAddress = (tokens: ISwapTokenListUnsafe, withoutAddress?: string) => {
	if (!tokens || !withoutAddress) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutAddress);
};

// 📌 Calculate rate

export const maxNoExponent = 1e21;
// export const outputDecimals = 8;
export const LIQUIDITY = 18;
export const outputDecimals = 12;
export const minSignificant = 1 * 10 ** -outputDecimals;
export const testDecimalGato = 6;
export const testGatoDesimals = 8;

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};
