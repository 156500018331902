import { TokenOverview } from 'components/TokenOverview';

import { Page } from 'layouts/page';
import { FC } from 'react';

export const TokenOverviewPage: FC = () => {
	return (
		<Page title="Gato Tokens" text="Gato Tokens" link="/pool">
			<TokenOverview />
		</Page>
	);
};
