import { FC, useCallback, useState, useMemo } from 'react';
import { IApiSwapAsset } from 'services/api/swap/types';
import { Modal } from 'ui/Modal';
import { ReactComponent as TriangularArrowIcon } from 'assets/custom-icons/triangular-arrow.svg';
import { TPairNetwork } from 'redux/reducers/bridge/types';
// import { getCurrentTokens } from '../ExchangeForm/utils';
import { ITokenInputProps } from './types';
import { TokenList } from './TokenList';
/* eslint-disable */
export const TokenInput: FC<ITokenInputProps> = ({
	tokenList,
	value,
	onChange,
	title,
	network,
	pairList = [],
	toValue,
	sortToken,
}) => {
	const [isTokenList, setIsTokenList] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	// const newTokenList = useMemo(() => {
	// 	if (sortToken) {
	// 		return getCurrentTokens(pairList, tokenList, value, toValue);
	// 	}

	// 	return tokenList;
	// }, [tokenList, pairList, value, toValue, sortToken]);

	const toggleIsTokenList = useCallback(() => {
		setIsTokenList((state) => !state);
	}, []);

	const handleTokenListOpen = () => {
		if (!tokenList?.length) return;
		toggleIsTokenList();
	};

	const handleChange = (newValue: TPairNetwork) => {
		onChange(newValue);
		toggleIsTokenList();
	};
	// debugger;
	return (
		<div className="select select--exchange">
			<div className="select__current" onClick={handleTokenListOpen}>
				<div className="token-block">
					<div className="token-block__icon">
						{value?.asset?.logo && <img src={value?.asset?.logo} alt="" />}
					</div>
					{/* <div className="token-block__icon">{value?.logo && <img src={usdtImg} alt="" />}</div> */}

					<div className="token-block__info">
						<p className="token-block__purpose">{title}</p>
						{value?.asset && <p className="token-block__name">{value?.asset?.symbol}</p>}
					</div>
				</div>

				<span className="select__current-arrow">
					<TriangularArrowIcon />
				</span>
			</div>
			<Modal isOpen={isTokenList} onClose={toggleIsTokenList}>
				<TokenList
					title={title}
					list={tokenList}
					onChange={handleChange}
					onClose={toggleIsTokenList}
					network={network}
					openManage={() => {
						setOpenModal(true);
					}}
				/>
			</Modal>
		</div>
	);
};
