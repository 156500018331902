/* eslint-disable */
import { FC, useState, useEffect } from 'react';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import Web3 from 'web3';
import { constants } from 'ethers';
import {
	getReferralList,
	getReferralWallet,
	getReferralWalletLoader,
	getReferralB2BBalance,
} from 'redux/reducers/referral/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { TReferallResopnse, IB2BPayload } from 'redux/reducers/referral/types';
import {
	getReferralRequest,
	setClaimLoader,
	getTrancationClaimRequest,
	getB2BDateRequest,
} from 'redux/reducers/referral/reducer';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { numberOutDecimal } from 'components/Exchange/ExchangeForm/utils';
import { authInitState } from 'redux/reducers/auth/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useWeb3React } from '@web3-react/core';
import { claimApprove, getSelector } from 'services/utils/claim';
import useRefreshToken from 'hooks/useRefreshToken';
import { format } from 'date-fns';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import level from 'assets/images/profile/level.png';
import token from 'assets/images/profile/token.png';
import gusd from 'assets/images/tokens/gusd.jpg';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { feeChecker } from 'services/utils/feeChecker';
import { ITypeTransactionClaim } from 'components/Transactions/TransactionsModals/type';
import { numberInDecimal } from 'components/Exchange/ExchangeForm/utils';
import AbiFee from 'config/abi/FeeController.json';
import { noExponent } from 'services/utils/noExponent';
import { SwitcherBox } from '../SwitcherBox/SwitcherBox';
import { filterReferall } from '../utils';
import { DateFilter } from '../DateFilter';
import { B2CProps } from './types';
import { Item } from './Item';

const CONTRACT_ADDRESS = String(process.env.REACT_APP_FEE_CONTRACT_ADDRESS);

const SELECTOR_B2С = '0xad16c18f';

declare let window: any;

export const B2CReferrals: FC<B2CProps> = ({
	currentCoin,
	handleClick,
	handleChangeCoin,
	activeB2,
	setOpenTransactionModal,
	setOpenModal,
	openModal,
	setFee,
	handleStartTimer,
}) => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const { address: addressWeb3, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const tokenRefresh = useRefreshToken();
	const referral = useSelector(getReferralList);
	const navigate = useNavigate();
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(1)));
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [typeFilter, setTypeFilter] = useState('');
	const [loader, setLoader] = useState(false);
	const [referralUpdate, setReferralUpdate] = useState<TReferallResopnse>(referral || []);
	const referralWalletLoader = useSelector(getReferralWalletLoader);
	const referralWallet = useSelector(getReferralWallet);
	const user = useSelector(getUserSettingsData);
	const address = useSelector(getAddress);
	const { provider, account } = useWeb3React();

	const tokenAdressGUSD = user?.wallets[0]?.balances.find((item) => {
		return item?.asset?.address === '0xd72b8ab5ef53fb0e20739c30cad013b0d333fa38';
	});

	const tokenAdressGATOCoin = user?.wallets[0]?.balances.find((item) => {
		return item?.asset?.address === '0x0000000000000000000000000000000000000000';
	});

	const handleData = () => {
		const payload: IB2BPayload = {
			onSuccess: (value: any) => {
				handlerConnection(value);
			},
		};
		dispatch(getB2BDateRequest(payload));
	};

	const handlerConnection = async (signature: any) => {
		// const savedTime = localTimeStorage('set','createTime');
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		if (referralWalletLoader) {
			notificationContainer(`Wait plese claim is pending`, 'info');
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		// if (!Number(referralWallet?.amount)) {
		// 	notificationContainer(`Don't have bonus`, 'info');
		// 	return;
		// }
		if (address.wallet && signature) {
			try {
				let currentAddress = connector === 'INJECTED' ? account : addressWeb3;
				let accounts;

				if (connector) {
					// accounts = await library?.provider.request({
					// 	method: 'eth_requestAccounts',
					// });
				}

				const paramsSelector: any = {
					to: String(CONTRACT_ADDRESS),
					abi: AbiFee.abi,
					provider: currentProvider,
				};

				const selector = await getSelector(paramsSelector);
				if (!selector) {
					notificationContainer(`Somthing wrong with selector`, 'error');
				}

				const paramsFee: any = {
					wallet: currentAddress,
					amount: String(noExponent(0)),
					selector: String(selector),
					abi: AbiFee.abi,
					tokenAddress: String(constants.AddressZero),
					contractAddess: String(CONTRACT_ADDRESS), // Address contract
					provider: currentProvider,
					setLoader,
					connector,
				};

				const amountFee = await feeChecker(paramsFee);
				if (amountFee) {
					setFee(String(numberOutDecimal(amountFee, Number(tokenAdressGATOCoin?.asset.decimals))));
				}

				const sendParams: ITypeTransactionClaim = {
					wallet: String(currentAddress),
					abi: AbiFee.abi,
					to: CONTRACT_ADDRESS,
					userId: String(user?.id),
					firstTokenAmount: String(signature),
					amount: String(
						noExponent(
							Math.floor(
								Number(
									numberInDecimal(
										Number(referralWallet?.amount),
										Number(tokenAdressGATOCoin?.asset.decimals),
									).toString(),
								),
							),
						),
					),

					firstTokenAddress: String(tokenAdressGUSD?.asset?.address),
					provider: currentProvider,
					deadline: signature?.deadline,
					setOpenTransactionModal,
					connector,
				};

				const result = await claimApprove(sendParams);

				if (result) {
					dispatch(
						getTrancationClaimRequest({
							date: String(signature),
							address: String(currentAddress),
							signature: String(result),
							type: true,
							amount: String(
								noExponent(
									Math.floor(
										Number(
											numberInDecimal(
												Number(referralWallet?.amount),
												Number(tokenAdressGATOCoin?.asset.decimals),
											).toString(),
										),
									),
								),
							),
						}),
					);
					setOpenTransactionModal(false);
					// dispatch(setClaimLoader(true));
					// handleStartTimer();
					// setHash(result?.transactionHash);
				}
			} catch (error: any) {
				notificationContainer(`Error: ${String(error?.message)}`, 'error');
				setOpenTransactionModal(false);
				dispatch(setClaimLoader(false));
				return;
			}
			// setOpenTransactionModal(true);
			return;
		}
		setOpenModal(!openModal);
	};

	useEffect(() => {
		if (!typeFilter) {
			dispatch(getReferralRequest());
			return;
		}

		if (startDate || endDate) {
			const params =
				typeFilter === 'range'
					? {
							filter_type: typeFilter,
							value: format(startDate, 'yy-MM-dd'),
							value_to: format(endDate, 'yy-MM-dd'),
					  }
					: {
							filter_type: typeFilter,
							value: format(startDate, 'yy-MM-dd'),
					  };

			dispatch(getReferralRequest(params));
		}
	}, [dispatch, startDate, endDate, typeFilter]);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
		if (referral) {
			const newReferral = filterReferall(startDate, endDate, referral);
			if (newReferral) {
				setReferralUpdate(newReferral);
			}
		}

		// eslint-disable-next-line no-debugger
	}, [endDate, isAuthenticated, navigate, startDate, referral]);
	return (
		<>
			<div className="earning-content">
				<div className="earning-block">
					<div className="earning-profile">
						<div className="earning-avatars">
							<div className="earning-avatars__item">
								<img className="avatar-img" src={user?.data?.avatar_path || loginAvatar} alt="" />
							</div>
							<div className="earning-avatars__item earning-avatars__item--level">
								<img src={level} alt="" />
							</div>
						</div>
						<div className="profile-info">
							<p className="profile-name">
								{user?.data?.first_name} {user?.data?.last_name}
							</p>
							<div className="profile-level">
								<p className="profile-level__text">Platinum level</p>
								<p className="profile-level__number">1</p>
							</div>
						</div>
					</div>

					<div className="earning-stats">
						<div className="earning-stats__item">
							<div className="earning-stats__name">
								<p>Total Earn</p>
							</div>
							<div className="earning-stats__number">
								{noExponent(String(referralWallet?.amount_total || 0))}
							</div>
						</div>
						<div className="earning-stats__item">
							<div className="earning-stats__name">Extra earnings based on loyalty program</div>
							<div className="earning-stats__number">0</div>
						</div>
					</div>

					<div className="total-earn">
						<div className="total-earn__text">My unclaimed reward</div>
						<div className="total-earn__wrapper">
							<p className="total-earn__number">
								{noExponent(String(referralWallet?.amount || 0))}
							</p>
							<div className="total-earn__icon">
								{currentCoin === 'COIN' ? (
									<img className="icon-earn" src={token} alt="" />
								) : (
									<img className="icon-earn" src={gusd} alt="" />
								)}
							</div>
						</div>
					</div>
					<div className="dividents-btns dividents-btns--mt dividents-btns--right">
						<button
							className="button button--full-width "
							type="button"
							onClick={(e) => handleData()}
							// disabled={referralWalletLoader}
						>
							Claim
						</button>
						<NavLink to="/stake-it" className="button button--type3 button--full-width">
							Stake it
						</NavLink>
					</div>
				</div>
			</div>
			<SwitcherBox
				activeCoin={currentCoin}
				activeB2={activeB2}
				handleClick={handleClick}
				handleChangeCoin={handleChangeCoin}
			/>
			<DateFilter
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				setTypeFilter={setTypeFilter}
			/>
			<div className="table table--referal">
				<div className="table-header">
					<div className="tr">
						<div className="td" />
						<div className="td">Infinity Network Members</div>
						<div className="td">Level</div>
						<div className="td">Total transactions</div>
						<div className="td">Revenue</div>
						<div className="td td--right">Total TX fees</div>
					</div>
				</div>
				<div className="table-body">
					{referralUpdate && referralUpdate?.length && isAuthenticated ? (
						referralUpdate?.map((item, id: number) => (
							<Item key={item[0].id} id={id + 1} item={item} currentCoin={currentCoin} />
						))
					) : (
						<EmptyTable text="Empty members" />
					)}
				</div>
			</div>
		</>
	);
};
