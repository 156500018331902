import React, { FC, memo, useMemo, useState } from 'react';

import InternalTransactionsBlock from 'components/Transactions/InternalTransactionsBlock';
import { IInternalTransaction } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import SelectToFrom from 'ui/SelectToFrom';

interface IInternalTransactionsTabProps {
	itemList: IInternalTransaction[] | undefined;
	// eslint-disable-next-line react/require-default-props
	hash?: string | undefined;
}

const InternalTransactionsTab: FC<IInternalTransactionsTabProps> = ({ itemList, hash }) => {
	// const [currentList, setCurrentList] = useState<IInternalTransaction[] | null>(null);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [searchValue, setSearchValue] = useState<string>('all');
	const perPage = 6;

	const filteredList = useMemo(() => {
		if (!itemList) return [];
		setCurrentPage(1);
		if (searchValue === 'to') {
			return itemList.filter((el) => el.to_address_hash === hash);
		}
		if (searchValue === 'from') {
			return itemList.filter((el) => el.from_address_hash === hash);
		}
		return itemList;
	}, [hash, searchValue, itemList]);

	const currentList = useMemo(() => {
		setItemOffset((currentPage - 1) * perPage);
		const endOffset = itemOffset + perPage;
		setPageCount(Math.ceil(filteredList.length / perPage));
		return filteredList.slice(itemOffset, endOffset);
	}, [currentPage, filteredList, itemOffset]);

	return (
		<>
			<div className="explore-block__top ">
				<h4 className="block-title block-title--mr-auto">Internal Transactions</h4>
				{hash && <SelectToFrom search={searchValue} setSearch={setSearchValue} />}
				{currentList && (
					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={pageCount}
						setCurrentPage={setCurrentPage}
					/>
				)}
			</div>
			{currentList?.length ? (
				<InternalTransactionsBlock itemsList={currentList} />
			) : (
				<EmptyTable text="No Recent Transactions Yet" />
			)}
			{currentList && (
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={pageCount}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</>
	);
};

export default memo(InternalTransactionsTab);
