import { FC } from 'react';
import token from 'assets/images/profile/token.png';
import { checkAfterPoint } from 'services/utils/numbers';
import { TItem } from './types';

export const B2BItem: FC<TItem> = ({ item }) => {
	return (
		<div className="referred-item">
			<div className="referred-item__top">
				<span className="referred-item__number">{item.level}</span>
				<h4 className="referred-item__title">Your Referred Token {item?.third_asset?.name}</h4>
			</div>
			<div className="earning-stats">
				<div className="earning-stats__item">
					<p className="earning-stats__name">Total TX</p>
					<div className="earning-stats__number">{Number(item?.total_tx || 0)?.toFixed(8)}</div>
				</div>
				<div className="earning-stats__item">
					<p className="earning-stats__name">Total TX fees</p>
					<div className="earning-stats__number">
						{Number(Object.values(item.total_tx_fee)[0]?.amount || 0)?.toFixed(8)}
						<span className="gato-token gato-token--bigger gato-tokem--type2">
							<img
								src={
									Object.values(item.total_tx_fee)[0]?.asset?.logo || item?.third_asset?.logo || ''
								}
								alt=""
							/>
						</span>
					</div>
				</div>
			</div>
			<div className="earning-stats">
				<div className="earning-stats__item">
					<p className="earning-stats__name">Level of Referred Token</p>
					<div className="earning-stats__number">{item?.level}</div>
				</div>
				<div className="earning-stats__item">
					<p className="earning-stats__name">Your %</p>
					<div className="earning-stats__number">{item?.percent}%</div>
				</div>
			</div>
			<div className="total-earn">
				<p className="total-earn__text">Total</p>
				<div className="total-earn__wrapper">
					<p className="total-earn__number">{Object.values(item.total)[0]?.amount || 0}</p>
					<div className="total-earn__icon">
						<img src={Object.values(item.total)[0]?.asset.logo || ''} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
