import React, { FC, useRef, useState } from 'react';

import { ArrowSelect } from 'assets/custom-icons';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { capitalize } from 'services/utils/textSlice';

export enum ESearchValue {
	all = 'All',
	to = 'To',
	from = 'From',
}

interface ISelectToFromProps {
	search: string;
	setSearch: (value: string) => void;
}

const SelectToFrom: FC<ISelectToFromProps> = ({ search, setSearch }) => {
	const [drop, setDrop] = useState(false);
	const handleClickDrop = () => {
		setDrop(!drop);
	};

	const handleChange = (value: string) => {
		setSearch(value);
		setDrop(false);
	};

	const ref = useRef<HTMLDivElement | null>(null);
	useOutsideClick(ref, () => setDrop(false));

	return (
		<div className={classNames('select select--internal', { active: drop })} ref={ref}>
			<button type="button" onClick={handleClickDrop} className="select__current">
				{capitalize(search)}
				<span className="select__arrow icon-arrow2">
					<ArrowSelect />
				</span>
			</button>
			<div className="select__drop select__drop--center">
				<div className="select__drop-scroll">
					{Object.entries(ESearchValue).map((el) => (
						<button
							type="button"
							key={el[0]}
							onClick={() => handleChange(el[0])}
							className="select__drop-item"
						>
							<span className="select__drop-link">{capitalize(el[0])}</span>
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default SelectToFrom;
