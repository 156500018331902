import { Transactions } from 'components/Transactions';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const TransactionsPage: FC = () => {
	return (
		<Page title="Blocks">
			<Transactions />
		</Page>
	);
};
