import { FC, useState, ChangeEvent, useEffect } from 'react';
import {
	phoneConfirmRequest,
	resendPhoneRequest,
	phoneConfirmSmsNewPassRequest,
	forgotPasswordRequest,
} from 'redux/reducers/auth/reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConfirmedPhone } from 'components/ConfirmedPhone';
/* eslint-disable no-debugger */

// ==========================================:
const PhoneCode: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [code, setCode] = useState('');
	const [phone, setPhone] = useState('');
	const [reset, setReset] = useState(false);
	const locationData: any = location?.state || '';
	const [confirm, setConfirm] = useState<boolean>(false);

	useEffect(() => {
		if (locationData?.token) {
			setReset(locationData?.token);
			setPhone(String(locationData?.phone));
			return;
		}
		if (locationData?.phone) {
			setPhone(String(locationData?.phone));
			setReset(false);
		}
	}, [locationData]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCode(e.currentTarget.value);
	};

	const handleSubmit = () => {
		if (reset) {
			dispatch(phoneConfirmSmsNewPassRequest({ token: code, navigate }));
			return;
		}
		dispatch(phoneConfirmRequest({ data: { sms: code, phone: String(phone) }, setConfirm }));
	};

	const handleClick = () => {
		if (reset) {
			dispatch(forgotPasswordRequest({ phone: String(phone) }));
			return;
		}
		dispatch(resendPhoneRequest({ phone: String(phone), type: 'phone' }));
	};
	return (
		<>
			{confirm ? (
				<ConfirmedPhone />
			) : (
				<section className="authorization-section authorization-section--confirm">
					<div className="container">
						<div className="authorization-box">
							<div className="authorization-form">
								<div className="authorization-form__header">
									<p className="form-title form-title--center">
										{reset ? 'Please check Phone' : 'Confirm Phone'}
									</p>
									<div className="form-text form-text--center form-text--fixed-width">
										<p>
											We’ve sent your 6-digit activation code at {phone}. Copy it to the field
											below.
										</p>
									</div>
								</div>
								<div className="authorization-form__body">
									<div className="authorization-form__group">
										<div className="input input--no-mb">
											<p className="input__name">Activation code</p>
											<div className="input-wrapper">
												<input
													className="input-item"
													type="text"
													value={code}
													onChange={handleChange}
													maxLength={6}
													placeholder="6-digits activation code"
												/>
											</div>
										</div>
									</div>
									<div className="confirm-request">
										<p className="confirm-request__text">
											Didn’t receive SMS? Please{' '}
											<button onClick={handleClick} type="button" className="confirm-request__link">
												request again
											</button>
										</p>
									</div>
								</div>
								<div className="form-footer">
									<button
										onClick={handleSubmit}
										type="button"
										className="button button--big-height button--full-width"
									>
										{reset ? 'Reset password' : 'Done'}
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default PhoneCode;
