import { getGraphPairsRequest } from 'redux/reducers/coins/reducer';
import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { TAssetsData, TCoinsStore, TAssetsItem } from './types';

// ==========================================:
const getCoinsState = (state: TStoreState): TCoinsStore => state.coins;
export const getCoins = createSelector([getCoinsState], (coins: TCoinsStore) => coins);

// ====================================================:
export const getAssetsIsLoad = createSelector(
	[getCoins],
	(coins: TCoinsStore): boolean => coins.assetsLoader,
);

// ====================================================:
export const getAssetsById = createSelector(
	[getCoins],
	(coins: TCoinsStore): TAssetsItem => coins.assetsById[0],
);

// ====================================================:
export const getAssetsByIdIsLoad = createSelector(
	[getCoins],
	(coins: TCoinsStore): boolean => coins.assetsByIdLoader,
);

// ====================================================:
export const getAssetsList = createSelector(
	[getCoins],
	(coins: TCoinsStore): TAssetsData => coins.assets,
);

// ==========================================:
export const getAssetsPairs = createSelector(
	[getCoins],
	(assetsPairs: TCoinsStore) => assetsPairs.assetsPairs,
);

// ==========================================:
export const getAssetsPairsIsLoad = createSelector(
	[getCoins],
	(assetsPairs: TCoinsStore) => assetsPairs.assetsPairsLoader,
);

// ==========================================:
export const getGraphPairs = createSelector(
	[getCoins],
	(graphPairs: TCoinsStore) => graphPairs.graphPairs,
);

// ==========================================:
export const getAssetsPairsId = createSelector(
	[getCoins],
	(assetsPairs: TCoinsStore) => assetsPairs.assetsPairsId,
);
// ==========================================:
export const getGraphPairsPeriod = createSelector(
	[getCoins],
	(assetsPairs: TCoinsStore) => assetsPairs.graphPeriod,
);
export const getTokenStatus = createSelector(
	[getCoins],
	(assetsPairs: TCoinsStore) => assetsPairs.statusToken,
);
