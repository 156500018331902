import { FC, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { TActive } from './type';

export const StaticHeader: FC = () => {
	const location = useLocation();
	const { search } = location;

	const referral = queryString.parse(search).ref || null;

	const setActive = ({ isActive }: TActive) =>
		isActive ? 'button button--authorization' : 'button button--type2 button--authorization';
	const disableButton = () => {
		return 'button button--type2 button--authorization button--event';
	};

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		if (referral) e.preventDefault();
	};

	return (
		<div className="header-right">
			<div className="authorization-btns">
				<NavLink to="/login" onClick={handleClick} className={referral ? disableButton : setActive}>
					Log In
				</NavLink>
				<NavLink
					to="/registration"
					onClick={handleClick}
					className={referral ? disableButton : setActive}
				>
					Sign Up
				</NavLink>
			</div>
		</div>
	);
};
