import React, { ChangeEvent } from 'react';

const ItemSearch = ({ inputSearch, setInputSearch, setCurrentPage }: any) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInputSearch(e.target.value);
		setCurrentPage(1);
	};
	return (
		<div className="search-input">
			<div className="input-wrapper">
				<input
					onChange={handleChange}
					value={inputSearch}
					type="text"
					className="input-item input-item--search input-item--left-icon input-item--white"
					placeholder="Search by token name or symbol"
				/>
				<span className="input-icon">
					<svg
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.625 16.25C12.56 16.25 15.75 13.06 15.75 9.125C15.75 5.18997 12.56 2 8.625 2C4.68997 2 1.5 5.18997 1.5 9.125C1.5 13.06 4.68997 16.25 8.625 16.25Z"
							stroke="#8E8EA9"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M16.5 17L15 15.5"
							stroke="#8E8EA9"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
			</div>
		</div>
	);
};
export default ItemSearch;
