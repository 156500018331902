import { Referral } from 'components/Referral';
import { PageProfile } from 'layouts/page/Profile';
import { FC } from 'react';

export const ReferralPage: FC = () => {
	return (
		<PageProfile title="Refer" text="Refer a Friend" link="/security">
			<Referral />
		</PageProfile>
	);
};
