import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IDividendsResponse, IEarningsStore } from './types';

export const getEarnings = (state: TStoreState): IEarningsStore => state.earnings;

export const getStake = (state: TStoreState) => getEarnings(state).staking;

export const getSumByAssets = (state: TStoreState) => getEarnings(state).sumByAssets;

export const getpaidRewardForAll = (state: TStoreState) => getEarnings(state).paidRewardForAllTime;

export const getApy = (state: TStoreState) => getEarnings(state).apy;

export const getDividends = createSelector(
	[getEarnings],
	(earnings: IEarningsStore): IDividendsResponse | null => earnings.dividends,
);

export const getDividendsLoading = createSelector(
	[getEarnings],
	(earnings: IEarningsStore): boolean => earnings.dividendsLoading,
);

export const getProfits = (state: TStoreState) => getEarnings(state).profits;
