import { FC } from 'react';
import { TLevel } from './types';

export const ButtonLevel: FC<TLevel> = ({ item, setLevel, level }) => {
	return (
		<button
			onClick={() => setLevel(item)}
			className={`button buttom-level ${level === item ? '' : 'button--type3'}`}
			type="button"
		>
			{item}
		</button>
	);
};
