import { FC, useEffect, useRef, useState } from 'react';
import { TAsset, IDCDropdown } from './types';

// ==================================:
const CoinDropdown: FC<IDCDropdown> = (props) => {
	const { value, options, onChange } = props;

	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleChange = (selectedValue: TAsset): void => {
		onChange(selectedValue);
		setOpen(false);
	};

	const handleSelectToggel = (): void => {
		setOpen(!open);
	};

	return (
		<div className={`select select--filter select--center ${open ? 'active' : ''}`} ref={node}>
			<button type="button" className="select__current" onClick={handleSelectToggel}>
				{value?.name && value?.name}
				<span className="select__current-arrow">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.9485 5.45337H7.79512H4.05512C3.41512 5.45337 3.09512 6.2267 3.54845 6.68004L7.00179 10.1334C7.55512 10.6867 8.45512 10.6867 9.00845 10.1334L10.3218 8.82003L12.4618 6.68004C12.9085 6.2267 12.5885 5.45337 11.9485 5.45337Z"
							fill="#8E8EA9"
						/>
					</svg>
				</span>
			</button>
			{open && options && (
				<div className="select__drop select__drop--indent-low">
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{options.length ? (
									options?.map((opt: TAsset) => {
										return (
											<li key={opt?.id}>
												<button
													type="button"
													className="select__drop-link"
													onClick={() => handleChange(opt)}
												>
													<div className="token-box  token-box--gap-12">
														{opt.name.toLocaleLowerCase() === 'all' ? (
															''
														) : (
															<div className="token-box__icon-3 ">
																<img className="token-box__icon-1" src={opt?.logo} alt="" />
															</div>
														)}

														<div className="token-box__name token-box__name--smaller">
															{opt?.symbol}
														</div>
														<div className="token-box__text">{opt?.name}</div>
													</div>
												</button>
											</li>
										);
									})
								) : (
									<li>
										<div className="token-box__text text--align">Global nothing</div>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CoinDropdown;
