/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import rubin from 'assets/images/icon-avatar-grey.svg';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { useSelector, useDispatch } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { trimLink } from 'services/utils/textSlice';
/* eslint-disable no-debugger */

export const Profile: FC = () => {
	const user = useSelector(getUserSettingsData);
	const adressAccount = useSelector(getAddress);

	// const [address, setAdress] = useState('');

	// useEffect(() => {
	// 	debugger;
	// 	if (adressAccount.wallet) setAdress(adressAccount.wallet);
	// }, [adressAccount]);

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	return (
		<section className="general-section general-section--my-profile">
			<div className="container">
				<div className="inner-block">
					<div className="general-block general-block--mb">
						<div className="my-profile">
							<p
								className={`my-profile-address ${
									adressAccount.active ? '' : 'my-profile-address--no-connected'
								}`}
							>
								{adressAccount.active ? (
									<>
										Address:{' '}
										<span className="my-profile-address__value">
											{' '}
											{trimLink(String(adressAccount.wallet))}{' '}
										</span>
										<CopyToClipboard text={String(adressAccount.wallet)} onCopy={handleAddressCopy}>
											<button type="button" className="copy-icon">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
														stroke="#335BE9"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</CopyToClipboard>
									</>
								) : (
									'Wallet is not connected'
								)}
							</p>
							<div className="autorized">
								<NavLink to="/account-details" className="avatar-box">
									<img
										className="avatar-img"
										src={user?.data?.avatar_path || loginAvatar}
										alt="avatar"
									/>
									<span className="avatar-icon avatar-icon--nob">
										<img src={rubin} alt="" />
									</span>
								</NavLink>
							</div>
						</div>
						<div className="profile-level profile-level--space-b">
							<p className="profile-level__text">Level</p>
							<p className="profile-level__number">1</p>
						</div>
						{/* <ConnectWalletButton address={address} /> */}
						<ConnectWalletButton />
					</div>
					<div className="general-block">
						<nav className="profile-nav">
							<NavLink to="/account-settings" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.4"
											d="M21.0901 22C21.0901 22.28 20.8701 22.5 20.5901 22.5H3.41016C3.13016 22.5 2.91016 22.28 2.91016 22C2.91016 17.86 6.99015 14.5 12.0002 14.5C13.0302 14.5 14.0302 14.64 14.9502 14.91C14.3602 15.61 14.0002 16.52 14.0002 17.5C14.0002 18.25 14.2101 18.96 14.5801 19.56C14.7801 19.9 15.0401 20.21 15.3401 20.47C16.0401 21.11 16.9702 21.5 18.0002 21.5C19.1202 21.5 20.1302 21.04 20.8502 20.3C21.0102 20.84 21.0901 21.41 21.0901 22Z"
											fill="#335BE9"
										/>
										<rect
											x="13.332"
											y="13.167"
											width="9.33333"
											height="9.33333"
											rx="4.66667"
											fill="#335BE9"
										/>
										<path
											d="M17.9987 18.5003C18.3669 18.5003 18.6654 18.2018 18.6654 17.8337C18.6654 17.4655 18.3669 17.167 17.9987 17.167C17.6305 17.167 17.332 17.4655 17.332 17.8337C17.332 18.2018 17.6305 18.5003 17.9987 18.5003Z"
											stroke="white"
											strokeWidth="0.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14.6641 18.1441V17.5226C14.6641 17.1553 14.9473 16.8516 15.2971 16.8516C15.9001 16.8516 16.1467 16.3996 15.8435 15.8451C15.6702 15.5273 15.7735 15.1141 16.0767 14.9305L16.6531 14.5808C16.9163 14.4149 17.2561 14.5137 17.4127 14.7927L17.4493 14.8598C17.7492 15.4143 18.2423 15.4143 18.5455 14.8598L18.5821 14.7927C18.7387 14.5137 19.0785 14.4149 19.3417 14.5808L19.9181 14.9305C20.2213 15.1141 20.3246 15.5273 20.1513 15.8451C19.8481 16.3996 20.0947 16.8516 20.6977 16.8516C21.0442 16.8516 21.3307 17.1518 21.3307 17.5226V18.1441C21.3307 18.5114 21.0475 18.8151 20.6977 18.8151C20.0947 18.8151 19.8481 19.2671 20.1513 19.8216C20.3246 20.1429 20.2213 20.5526 19.9181 20.7362L19.3417 21.0858C19.0785 21.2518 18.7387 21.1529 18.5821 20.8739L18.5455 20.8068C18.2456 20.2524 17.7525 20.2524 17.4493 20.8068L17.4127 20.8739C17.2561 21.1529 16.9163 21.2518 16.6531 21.0858L16.0767 20.7362C15.7735 20.5526 15.6702 20.1394 15.8435 19.8216C16.1467 19.2671 15.9001 18.8151 15.2971 18.8151C14.9473 18.8151 14.6641 18.5114 14.6641 18.1441Z"
											stroke="white"
											strokeWidth="0.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">Account Settings</p>
								</div>
								<div className="account-change__arrow">
									<div className="account-change__arrow">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</NavLink>
							<NavLink to="/security" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.4"
											d="M10.9608 2.61037L5.46078 4.67037C4.41078 5.07037 3.55078 6.31037 3.55078 7.44037V15.5404C3.55078 16.3504 4.08078 17.4204 4.73078 17.9004L10.2308 22.0104C11.2008 22.7404 12.7908 22.7404 13.7608 22.0104L19.2608 17.9004C19.9108 17.4104 20.4408 16.3504 20.4408 15.5404V7.44037C20.4408 6.32037 19.5808 5.07037 18.5308 4.68037L13.0308 2.62037C12.4708 2.40037 11.5308 2.40037 10.9608 2.61037Z"
											fill="#335BE9"
										/>
										<path
											d="M14.5 11C14.5 9.62 13.38 8.5 12 8.5C10.62 8.5 9.5 9.62 9.5 11C9.5 12.12 10.24 13.05 11.25 13.37V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V13.37C13.76 13.05 14.5 12.12 14.5 11Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">Security</p>
								</div>
								<div className="account-change__arrow">
									<svg
										width="12"
										height="13"
										viewBox="0 0 12 13"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
											stroke="#8E8EA9"
											strokeWidth="1.2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
							</NavLink>
							<NavLink to="/referral" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19.0712 14.7396C18.7812 15.0296 18.3212 15.0296 18.0413 14.7396C17.7513 14.4496 17.7513 13.9896 18.0413 13.7096C20.0413 11.7096 20.0413 8.45961 18.0413 6.46961C16.0413 4.47961 12.7912 4.46961 10.8012 6.46961C8.81125 8.46961 8.80125 11.7196 10.8012 13.7096C11.0912 13.9996 11.0912 14.4596 10.8012 14.7396C10.5112 15.0296 10.0512 15.0296 9.77125 14.7396C7.20125 12.1696 7.20125 7.98961 9.77125 5.42961C12.3412 2.86961 16.5212 2.85961 19.0812 5.42961C21.6412 7.99961 21.6412 12.1696 19.0712 14.7396Z"
											fill="#335BE9"
										/>
										<path
											opacity="0.4"
											d="M19.0695 5.43043C21.6395 8.00043 21.6395 12.1704 19.0695 14.7404C18.1895 15.6204 17.1095 16.2004 15.9795 16.4804C16.5095 14.3204 15.9295 11.9504 14.2395 10.2604C12.5495 8.57043 10.1795 7.99043 8.01953 8.52043C8.29953 7.39043 8.86953 6.31043 9.75953 5.43043C12.3295 2.86043 16.4995 2.86043 19.0695 5.43043Z"
											fill="#335BE9"
										/>
										<path
											d="M4.92875 10.2605C5.21875 9.97047 5.67875 9.97047 5.95875 10.2605C6.24875 10.5505 6.24875 11.0105 5.95875 11.2905C3.95875 13.2905 3.95875 16.5405 5.95875 18.5305C7.95875 20.5205 11.2088 20.5305 13.1988 18.5305C15.1888 16.5305 15.1988 13.2805 13.1988 11.2905C12.9088 11.0005 12.9088 10.5405 13.1988 10.2605C13.4888 9.97047 13.9488 9.97047 14.2288 10.2605C16.7988 12.8305 16.7988 17.0105 14.2288 19.5705C11.6588 22.1305 7.47875 22.1405 4.91875 19.5705C2.35875 17.0005 2.35875 12.8305 4.92875 10.2605Z"
											fill="#335BE9"
										/>
										<path
											opacity="0.5"
											d="M14.2414 10.2599C15.9314 11.9499 16.5114 14.3199 15.9814 16.4799C15.7014 17.6099 15.1214 18.6899 14.2414 19.5699C11.6714 22.1399 7.50141 22.1399 4.93141 19.5699C2.36141 16.9999 2.36141 12.8299 4.93141 10.2599C5.81141 9.36988 6.89141 8.79988 8.02141 8.51988C10.1814 7.98988 12.5514 8.56988 14.2414 10.2599Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">Refer a Friend</p>
								</div>
								<div className="account-change__arrow">
									<div className="account-change__arrow">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</NavLink>
							<div className="account-change-line" />
							<NavLink to="/how-it-works" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.4"
											d="M17 18.9297H13L8.54999 21.8897C7.88999 22.3297 7 21.8597 7 21.0597V18.9297C4 18.9297 2 16.9297 2 13.9297V7.92969C2 4.92969 4 2.92969 7 2.92969H17C20 2.92969 22 4.92969 22 7.92969V13.9297C22 16.9297 20 18.9297 17 18.9297Z"
											fill="#335BE9"
										/>
										<path
											d="M11.9998 12.6104C11.5898 12.6104 11.2498 12.2704 11.2498 11.8604V11.6504C11.2498 10.4904 12.0998 9.92038 12.4198 9.70038C12.7898 9.45038 12.9098 9.28039 12.9098 9.02039C12.9098 8.52039 12.4998 8.11035 11.9998 8.11035C11.4998 8.11035 11.0898 8.52039 11.0898 9.02039C11.0898 9.43039 10.7498 9.77039 10.3398 9.77039C9.92984 9.77039 9.58984 9.43039 9.58984 9.02039C9.58984 7.69039 10.6698 6.61035 11.9998 6.61035C13.3298 6.61035 14.4098 7.69039 14.4098 9.02039C14.4098 10.1604 13.5698 10.7304 13.2598 10.9404C12.8698 11.2004 12.7498 11.3704 12.7498 11.6504V11.8604C12.7498 12.2804 12.4098 12.6104 11.9998 12.6104Z"
											fill="#335BE9"
										/>
										<path
											d="M12 15.0996C11.58 15.0996 11.25 14.7596 11.25 14.3496C11.25 13.9396 11.59 13.5996 12 13.5996C12.41 13.5996 12.75 13.9396 12.75 14.3496C12.75 14.7596 12.42 15.0996 12 15.0996Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">How it Works?</p>
								</div>
								<div className="account-change__arrow">
									<div className="account-change__arrow">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</NavLink>
							<NavLink to="/" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.4"
											d="M20.5 10.69H17.61C15.24 10.69 13.31 8.76 13.31 6.39V3.5C13.31 2.95 12.86 2.5 12.31 2.5H8.07C4.99 2.5 2.5 4.5 2.5 8.07V16.93C2.5 20.5 4.99 22.5 8.07 22.5H15.93C19.01 22.5 21.5 20.5 21.5 16.93V11.69C21.5 11.14 21.05 10.69 20.5 10.69Z"
											fill="#335BE9"
										/>
										<path
											d="M15.7997 2.71048C15.3897 2.30048 14.6797 2.58048 14.6797 3.15048V6.64048C14.6797 8.10048 15.9197 9.31048 17.4297 9.31048C18.3797 9.32048 19.6997 9.32048 20.8297 9.32048C21.3997 9.32048 21.6997 8.65048 21.2997 8.25048C19.8597 6.80048 17.2797 4.19048 15.7997 2.71048Z"
											fill="#335BE9"
										/>
										<path
											d="M13.5 14.25H7.5C7.09 14.25 6.75 13.91 6.75 13.5C6.75 13.09 7.09 12.75 7.5 12.75H13.5C13.91 12.75 14.25 13.09 14.25 13.5C14.25 13.91 13.91 14.25 13.5 14.25Z"
											fill="#335BE9"
										/>
										<path
											d="M11.5 18.25H7.5C7.09 18.25 6.75 17.91 6.75 17.5C6.75 17.09 7.09 16.75 7.5 16.75H11.5C11.91 16.75 12.25 17.09 12.25 17.5C12.25 17.91 11.91 18.25 11.5 18.25Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">About Gato Chain</p>
								</div>
								<div className="account-change__arrow">
									<div className="account-change__arrow">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</NavLink>
							<NavLink to="/help-and-support" className="account-change account-change--profile">
								<div className="account-change__icon">
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											opacity="0.4"
											d="M18 19.3597H17.24C16.44 19.3597 15.68 19.6697 15.12 20.2297L13.41 21.9197C12.63 22.6897 11.36 22.6897 10.58 21.9197L8.87 20.2297C8.31 19.6697 7.54 19.3597 6.75 19.3597H6C4.34 19.3597 3 18.0298 3 16.3898V5.47974C3 3.83974 4.34 2.50977 6 2.50977H18C19.66 2.50977 21 3.83974 21 5.47974V16.3898C21 18.0198 19.66 19.3597 18 19.3597Z"
											fill="#335BE9"
										/>
										<path
											d="M10.3783 15.0099H7.69827C7.25827 15.0099 6.84827 14.7999 6.58827 14.4399C6.33827 14.0999 6.27827 13.68 6.39827 13.28C6.74827 12.21 7.60827 11.6299 8.36827 11.1099C9.16827 10.5699 9.61827 10.23 9.61827 9.64996C9.61827 9.12996 9.19827 8.70996 8.67827 8.70996C8.15827 8.70996 7.73828 9.12996 7.73828 9.64996C7.73828 10.06 7.39828 10.4 6.98828 10.4C6.57828 10.4 6.23828 10.06 6.23828 9.64996C6.23828 8.30996 7.32827 7.20996 8.67827 7.20996C10.0283 7.20996 11.1183 8.29996 11.1183 9.64996C11.1183 11.06 10.0583 11.7799 9.20828 12.3599C8.67828 12.7199 8.17827 13.0599 7.92827 13.5099H10.3683C10.7783 13.5099 11.1183 13.8499 11.1183 14.2599C11.1183 14.6699 10.7883 15.0099 10.3783 15.0099Z"
											fill="#335BE9"
										/>
										<path
											d="M16.0389 15.0099C15.6289 15.0099 15.2889 14.6699 15.2889 14.2599V13.5699H13.3289C13.3289 13.5699 13.3289 13.5699 13.3189 13.5699C12.8289 13.5699 12.3789 13.31 12.1289 12.89C11.8789 12.46 11.8789 11.9299 12.1289 11.5099C12.8089 10.3399 13.5989 9.00991 14.3189 7.84991C14.6389 7.33991 15.2489 7.10996 15.8189 7.26996C16.3889 7.43996 16.7889 7.95993 16.7789 8.55993V12.08H16.9989C17.4089 12.08 17.7489 12.42 17.7489 12.83C17.7489 13.24 17.4089 13.58 16.9989 13.58H16.7889V14.27C16.7889 14.68 16.4589 15.0099 16.0389 15.0099ZM15.2889 9.13995C14.6989 10.1 14.0889 11.1299 13.5389 12.0699H15.2889V9.13995Z"
											fill="#335BE9"
										/>
									</svg>
								</div>
								<div className="account-change__info">
									<p className="account-change__item">Help & Support</p>
								</div>
								<div className="account-change__arrow">
									<div className="account-change__arrow">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M4.47656 2.53996L7.73656 5.79996C8.12156 6.18496 8.12156 6.81496 7.73656 7.19996L4.47656 10.46"
												stroke="#8E8EA9"
												strokeWidth="1.2"
												strokeMiterlimit="10"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</div>
								</div>
							</NavLink>
						</nav>
					</div>
				</div>
			</div>
		</section>
	);
};
