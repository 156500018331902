import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRefreshTokenRequest } from 'redux/reducers/auth/reducer';
import { getTokenExpired } from '../redux/reducers/auth/selectors';

const useRefreshToken = () => {
	const dispatch = useDispatch();
	const tokenExpired = useSelector(getTokenExpired);
	const diff = tokenExpired - Date.now() / 1000;
	const getExpired = useCallback(() => {
		if (diff < 600) {
			dispatch(getRefreshTokenRequest({ token: localStorage.accessToken }));
		}
	}, [diff, dispatch]);

	return getExpired;
};

export default useRefreshToken;
