import { FC } from 'react';
import { PageProfile } from 'layouts/page/Profile';
import { TwoFactor } from 'components/TwoFactor';

export const TwoFactorPage: FC = () => {
	return (
		<PageProfile
			title="Two-Factor Authentication"
			text="Two-Factor Authentication"
			link="/two-factor"
		>
			<TwoFactor />
		</PageProfile>
	);
};
