import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { IToken } from 'components/Exchange/ExchangeForm/type';

declare let window: any;

const { BigNumber, constants, utils } = ethers;
const { MaxUint256 } = constants;

const getEtheriumProvider = (provider: any) => new ethers.providers.Web3Provider(provider);

const getSigner = (provider: any, account: any) => provider.getSigner(account);

const getPermitSignature = async (
	signer: any,
	token: any,
	spender: any,
	value: any,
	deadline: string | number,
) => {
	const [nonce, name, version, chainId] = await Promise.all([
		token.nonces(signer._address),
		token.name(),
		'1',
		signer.getChainId(),
	]);

	return ethers.utils.splitSignature(
		await signer._signTypedData(
			{
				name,
				version,
				chainId,
				verifyingContract: token.address,
			},
			{
				Permit: [
					{ name: 'owner', type: 'address' },
					{ name: 'spender', type: 'address' },
					{ name: 'value', type: 'uint256' },
					{ name: 'nonce', type: 'uint256' },
					{ name: 'deadline', type: 'uint256' },
				],
			},
			{
				owner: signer._address,
				spender,
				value,
				nonce,
				deadline,
			},
		),
	);
};

export const autoSwapTransaction = async ({
	wallet,
	amount,
	abi,
	tokenName,
	tokenAddress,
	abiAdmin,
	adminContract,
	setAmountRecive,
	provider,
	setLoader,
	deadline = '100000000000000000000',
}: any) => {
	try {
		setLoader(true);
		const etheriumProvider = getEtheriumProvider(provider);
		const signer = getSigner(etheriumProvider, wallet);
		const userContract = new ethers.Contract(tokenAddress, abi, signer);
		const adminContractInstance = new ethers.Contract(adminContract, abiAdmin, signer);

		const { v, r, s } = await getPermitSignature(
			signer,
			userContract,
			adminContract,
			amount,
			deadline,
		);

		notificationContainer('Swap done', 'success');

		return {
			owner: wallet,
			spender: String(adminContract).toLowerCase(),
			value: amount,
			deadline,
			asset: tokenAddress,
			v: String(v),
			r,
			s,
		};
	} catch (error: any) {
		setLoader(false);
		notificationContainer(`Approve failed: ${String(error?.message)}`, 'error');
		return undefined;
	}
};

export const getTransactionCostin = async ({
	wallet,
	abi,
	amount,
	tokenAddress,
	abiAdmin,
	adminContract,
	provider,
	setDisableBtn,
}: any) => {
	try {
		const etheriumProvider = getEtheriumProvider(provider);
		const signer = getSigner(etheriumProvider, wallet);
		const adminContractInstance = new ethers.Contract(adminContract, abiAdmin, signer);

		const tokenUserFee = await adminContractInstance.getTokenEquivalentOfFee(tokenAddress);
		const tokenFee = await adminContractInstance.getTransactionCost(tokenAddress, amount);

		return {
			formatTokenFee: utils.formatEther(tokenFee),
			formatSwapFee: utils.formatEther(tokenUserFee),
		};
	} catch (error: any) {
		if (error?.errorArgs?.[0] === 'amount less than fee') {
			setDisableBtn('Amount less than fee');
			notificationContainer('Amount less than fee', 'error');
			return undefined;
		}
		notificationContainer(`Fee cost failed: ${String(error?.message)}`, 'error');
		return undefined;
	}
};
