import { FC, useState, MouseEvent, useEffect } from 'react';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import level from 'assets/images/profile/level.png';
import token from 'assets/images/profile/token.png';
import { NavLink, useNavigate } from 'react-router-dom';
import AbiRouter from 'config/abi/B2CInfinityReferralContract.json';
import { WalletsModal } from 'components/Wallets/WalletsModal';
import { useDispatch, useSelector } from 'react-redux';
import { notificationContainer } from 'services/utils/notificationContainer';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import {
	setClaimLoader,
	getReferralWalletRequest,
	getTrancationClaimRequest,
	getReferralB2BBalanceRequest,
	getReferralB2СSignatureRequest,
} from 'redux/reducers/referral/reducer';
import { Ib2cBodyRequest } from 'redux/reducers/referral/types';
import gusd from 'assets/images/tokens/gusd.jpg';
import { checkTimeAndPerformAction, localTimeStorage, removeLS } from 'hooks/useStorage';
import {
	getReferralWallet,
	getReferralWalletLoader,
	getReferralB2BBalance,
} from 'redux/reducers/referral/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import EarningNav from 'components/Earnings/EarningNav';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { TransactionModal } from 'components/Transactions/TransactionsModals';
import { numberInDecimal } from 'components/Exchange/ExchangeForm/utils';
import { noExponent } from 'services/utils/noExponent';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';
import { useWeb3React } from '@web3-react/core';
import { B2CReferrals } from './B2CReferrals';
import { B2BReferrals } from './B2BReferrals';

/* eslint-disable */
const Web3 = require('web3');

const RPC_HOST = String(process.env.REACT_APP_RPC_URL);

const INFINITY_REFERRAL_CONTRACT_ADDRESS = String(process.env.INFINITY_REFERRAL_CONTRACT_ADDRESS);

const _web3 = new Web3(RPC_HOST);

declare let window: any;

export const InfinityRef: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const address = useSelector(getAddress);
	const tokenRefresh = useRefreshToken();

	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const B2BBalance = useSelector(getReferralB2BBalance);

	const [activeB2, setActiveB2] = useState('B2C');
	const [activeCoin, setActiveCoin] = useState('COIN');

	const [openTransactionModal, setOpenTransactionModal] = useState<boolean>(false);
	const [loader, setLoader] = useState(false);
	const [fee, setFee] = useState('');

	const referralWallet = useSelector(getReferralWallet);
	const user = useSelector(getUserSettingsData);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setActiveB2(name);
	};
	const handleChangeCoin = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setActiveCoin(name);
		dispatch(getReferralWalletRequest({ type: name === 'COIN' ? 'coin' : 'gusd' }));
	};

	const [openModal, setOpenModal] = useState(false);

	const [remainingTime, setRemainingTime] = useState<any>(null);
	const [timerStarted, setTimerStarted] = useState(false);
	const [intervalId, setIntervalId] = useState<number | null | any>(null);

	const savedTime = localTimeStorage('get', 'createTime');

	useEffect(() => {
		const savedTime = localTimeStorage('get', 'createTime');
		if (savedTime) {
			const timeLeft = checkTimeAndPerformAction(savedTime);
			if (timeLeft > 0) {
				setRemainingTime(timeLeft);
				setTimerStarted(true);
			} else {
				removeLS('createTime');
				dispatch(setClaimLoader(false));
			}
		}
	}, []);

	const updateRemainingTime = () => {
		const savedTime = localTimeStorage('get', 'createTime');

		if (savedTime) {
			const timeLeft = checkTimeAndPerformAction(savedTime);
			setRemainingTime(timeLeft);
		} else {
			setRemainingTime(null);
		}
	};

	useEffect(() => {
		if (timerStarted) {
			const id = setInterval(updateRemainingTime, 1000);
			setIntervalId(id);

			const savedTime = localTimeStorage('get', 'createTime');
			if (!savedTime) {
				clearInterval(id);
				setRemainingTime(null);
				return () => clearInterval(id);
			}

			updateRemainingTime();

			return () => clearInterval(id);
		}
	}, [timerStarted]);
	useEffect(() => {
		if (!B2BBalance && activeB2 === 'B2B') {
			dispatch(getReferralB2BBalanceRequest());
		}
	}, [dispatch, B2BBalance, activeB2]);

	useEffect(() => {
		dispatch(getReferralWalletRequest({ type: 'coin' }));
	}, [dispatch]);

	useEffect(() => {
		if (remainingTime === null || savedTime === null) {
			clearInterval(intervalId);
			setIntervalId(null);
		}
	}, [remainingTime, savedTime]);
	const handleStartTimer = () => {
		setTimerStarted(true);
		localTimeStorage('set', 'createTime');
		updateRemainingTime();
	};

	// debugger;

	const b2bSelecet = {
		B2C: (
			<B2CReferrals
				currentCoin={activeCoin}
				activeB2={activeB2}
				handleClick={handleClick}
				handleChangeCoin={handleChangeCoin}
				setOpenTransactionModal={setOpenTransactionModal}
				setOpenModal={setOpenModal}
				openModal={openModal}
				setFee={setFee}
				handleStartTimer={handleStartTimer}
			/>
		),
		B2B: (
			<B2BReferrals
				currentCoin={activeCoin}
				activeB2={activeB2}
				handleClick={handleClick}
				handleChangeCoin={handleChangeCoin}
				setOpenTransactionModal={setOpenTransactionModal}
				setOpenModal={setOpenModal}
				setFee={setFee}
				openModal={openModal}
				handleStartTimer={handleStartTimer}
			/>
		),
	};

	return (
		<SocketConnectWrapper>
			<SocketWithTopicConnectWrapper topicName="referral_claim">
				<section className="earning-section earning-section--type2">
					<div className="container">
						<EarningNav />
						<h3 className="earning-title">Infinity Referral System</h3>

						{b2bSelecet[activeB2 as keyof typeof b2bSelecet]}
					</div>
					<WalletsModal openModal={openModal} setOpenModal={setOpenModal} />
					<TransactionModal
						setOpenModal={setOpenTransactionModal}
						openModal={openTransactionModal}
						toValue={noExponent(
							String(
								String(activeB2) === 'B2C'
									? referralWallet && referralWallet?.amount
									: B2BBalance && B2BBalance[0]?.amount,
							),
						)}
						coinName="GATO"
						fee={fee}
					/>
				</section>
			</SocketWithTopicConnectWrapper>
		</SocketConnectWrapper>
	);
};
