import { CopySvg } from 'assets/custom-icons';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ILog } from 'services/api/blockchain/types';
import { notificationContainer } from 'services/utils/notificationContainer';

const LogTabRow: FC<ILog> = ({
	address_hash,
	data,
	first_topic,
	second_topic,
	fourth_topic,
	third_topic,
	index,
}) => {
	const handleCopy = () => {
		notificationContainer('Hash copied successfully!', 'info');
	};
	const parseData = JSON.parse(data);

	return (
		<div className="logs-block">
			<div className="logs-block">
				<div className="logs-block__line">
					<p className="logs-block__name">Address</p>
					<p className="logs-block__value">{address_hash}</p>
				</div>
				<div className="logs-block__line">
					<p className="logs-block__name">Decoded</p>
					<div className="input-table input-table--logs">
						<div className="input-table-tr">
							<div className="input-table-td">
								<p>Method Id</p>
							</div>
							<div className="input-table-td input-table-td--red input-table-td--fz12">
								<p>{parseData.log_decoded.EventId}</p>
							</div>
						</div>
						<div className="input-table-tr">
							<div className="input-table-td">
								<p>Call</p>
							</div>
							<div className="input-table-td input-table-td--red input-table-td--fz12">
								<p>{parseData.log_decoded.EventCall}</p>
							</div>
						</div>
						<div className="input-table-tr input-table-tr--hidden">
							<div className="input-table-td input-table-td--fw600 input-table-td--p0">
								<div className="input-table-wrapper">
									<div className="input-table-item">
										<p>Name</p>
									</div>
									<div className="input-table-item">
										<p>Type</p>
									</div>
									<div className="input-table-item">
										<p>Indexed?</p>
									</div>
								</div>
							</div>
							<div className="input-table-td input-table-td--fw600 input-table-td--hidden">
								<p>Data</p>
							</div>
						</div>
						{parseData.log_decoded.ABI.inputs.map((el: any) => (
							<div key={el.name} className="input-table-tr">
								<div className="input-table-td input-table-td--fw400 input-table-td--p0">
									<div className="input-table-wrapper">
										<div className="input-table-item">
											<p className="hide-name">Name:</p>
											<p>{el.name}</p>
										</div>
										<div className="input-table-item">
											<p className="hide-name">Type:</p>
											<p>{el.type}</p>
										</div>
										<div className="input-table-item">
											<p className="hide-name">Indexed?:</p>
											<p>{el.indexed ? 'true' : 'false'}</p>
										</div>
									</div>
								</div>
								<div className="input-table-td input-table-td--left">
									<p className="hide-name">Data:</p>
									<div className="block-details-value block-details-value--copy ">
										<CopyToClipboard text={parseData.log_decoded[el.name]} onCopy={handleCopy}>
											<button
												type="button"
												className="block-details-value__copy-btn"
												aria-label="Copy"
											>
												<CopySvg />
											</button>
										</CopyToClipboard>
										<p>{parseData.log_decoded[el.name]}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="logs-block__line">
					<p className="logs-block__name">Topic</p>
					<div className="logs-block__value-group">
						{first_topic && (
							<p className="logs-block__value">
								<span>[0]</span>
								{first_topic}
							</p>
						)}
						{second_topic && (
							<p className="logs-block__value">
								<span>[1]</span>
								{second_topic}
							</p>
						)}
						{third_topic && (
							<p className="logs-block__value">
								<span>[2]</span>
								{third_topic}
							</p>
						)}
						{fourth_topic && (
							<p className="logs-block__value">
								<span>[3]</span>
								{fourth_topic}
							</p>
						)}
					</div>
				</div>
				<div className="logs-block__line">
					<p className="logs-block__name">Data</p>
					<p className="logs-block__value">{parseData.log.data}</p>
				</div>
				<div className="logs-block__line">
					<p className="logs-block__name">Log index</p>
					<p className="logs-block__value">{index}</p>
				</div>
			</div>
		</div>
	);
};

export default LogTabRow;
