import { FC } from 'react';
import { Page } from 'layouts/page';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import { CreateTokenForm } from '../CreateTokenForm';

export const CreateToken: FC = () => {
	return (
		<div className="content-block content content-block--mt0 ">
			<div className="block-notification">
				{/* <div className="block-notification-item block-notification-item--warning">
					<span className="block-notification-item__icon">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.0001 21.4098H5.94005C2.47005 21.4098 1.02005 18.9298 2.70005 15.8998L5.82006 10.2798L8.76006 4.99979C10.5401 1.78979 13.4601 1.78979 15.2401 4.99979L18.1801 10.2898L21.3001 15.9098C22.9801 18.9398 21.5201 21.4198 18.0601 21.4198H12.0001V21.4098Z"
								stroke="#EB5757"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M12 9V14"
								stroke="#EB5757"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M11.9945 17H12.0035"
								stroke="#EB5757"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
					<p className="block-notification-item__text">This information cannot be changed.</p>
				</div> */}
			</div>
			<CreateTokenForm />
			<NotificationInfo />
		</div>
	);
};
