export const getLastDayOfMonth: (date: Date) => number = (date) => {
	const month = date.getMonth();
	if ([0, 2, 4, 6, 7, 9, 11].includes(month)) {
		return 31;
	}
	if (month === 1) {
		const year = date.getFullYear();
		if (year % 4 === 0) {
			return 29;
		}
		return 28;
	}
	return 30;
};
