/* eslint-disable */
import { FC, useState, MouseEvent, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import {
	getDividendsRequest,
	getTrancationClaimDividensRequest,
	getTrancationUnstakeDividensRequest,
} from 'redux/reducers/earnings/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { IB2BPayload } from 'redux/reducers/referral/types';
import { getB2BDateRequest } from 'redux/reducers/referral/reducer';
import { getAddress, getBalances } from 'redux/reducers/connectWallet/selectors';
import { WalletsModal } from 'components/Wallets/WalletsModal';
import { getDividends } from 'redux/reducers/earnings/selectors';
import { feeChecker } from 'services/utils/feeChecker';
import EarningNav from 'components/Earnings/EarningNav';
import { TWalletData } from 'redux/reducers/wallets/types';
import { numberInDecimal } from 'components/Exchange/ExchangeForm/utils';
import AbiFee from 'config/abi/FeeController.json';
import { noExponent } from 'services/utils/noExponent';
import { IconCoinGato } from 'assets/custom-icons';
import { authInitState } from 'redux/reducers/auth/reducer';
import { useWeb3React } from '@web3-react/core';
import useRefreshToken from 'hooks/useRefreshToken';
import { unstakingApprove, getSelector } from 'services/utils/stakingPerDividents';
import { claimDividentsApprove } from 'services/utils/claimPerDividents';
import AbiStakingDividents from 'config/abi/Dividends.json';
import { format } from 'date-fns';
import { notificationContainer } from 'services/utils/notificationContainer';
import { upgrateDate } from './utils';
import DividendsFilter from './DividendsFilter';
import { debug } from 'console';

const Web3 = require('web3');

const DIVIDENT_CONTRACT = String(process.env.REACT_APP_DIVIDENTS_CONTRACT_ADDRESS);

declare let window: any;

const Gato_Coin = '0x561d0f5fd6cda5D9112ccA9231208684D4F8800E';
const Gato_Ambassador = '0xDDDC21dfa46b7C7B723C09cd351F5E864b1EB8B3';
const Gato_Tank = '0x9fA3574F8D42038Faee2CFFE77b8e34A25Cb460B';

export const Dividends: FC = () => {
	const dispatch = useDispatch();
	const address = useSelector(getAddress);
	const [openModal, setOpenModal] = useState(false);
	const navigate = useNavigate();
	const tokenRefresh = useRefreshToken();
	const walletsUser = useSelector(getBalances);
	const dividents = useSelector(getDividends);
	const { provider, account } = useWeb3React();
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(1)));
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [activeCurrency, setActiveCurrency] = useState('GATO');
	const [token, setToken] = useState<TWalletData | undefined>(undefined);
	const [date, setDate] = useState('');
	const { address: addressWeb3, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [openTransactionModal, setOpenTransactionModal] = useState<boolean>(false);
	const [refresh, setRefresh] = useState(false);

	const currentContract = (tokenType: TWalletData | undefined) => {
		if (tokenType?.asset.symbol === 'GGGGG') {
			return Gato_Tank;
		} else if (tokenType?.asset.symbol === 'GLAM') {
			return Gato_Ambassador;
		}
		return Gato_Coin;
	};
	let total = 0;
	const arrStaking =
		dividents &&
		dividents?.staking?.dividend_staking_periods?.forEach((obj: any) => {
			// if (obj.paid_revenue === false && obj.transaction_id !== null) {
			// 	let grossProfit = parseFloat(obj.gross_profit);
			// 	let expenses = parseFloat(obj.expenses);
			// 	total += grossProfit - expenses;
			// }
			if (obj.paid_revenue === false) {
				let grossProfit = parseFloat(obj.gross_profit);
				let expenses = parseFloat(obj.expenses);
				total += grossProfit - expenses;
			}
		});

	const disableClaim = total ? false : true;

	const locked = dividents && dividents?.staking?.unfroze === false ? 'Unlock' : 'Lock';

	const handleData = () => {
		const payload: IB2BPayload = {
			onSuccess: (value: any) => {
				debugger;
				handlerConnection('claim', value);
			},
		};
		dispatch(getB2BDateRequest(payload));
	};
	const handlerConnection = async (typeLock: string, signature: any) => {
		if (typeLock === 'Lock') {
			navigate('/stake-per-dividents');
			return;
		}
		// const { name } = e.currentTarget;

		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		// if (!Number(referralWallet?.amount)) {
		// 	notificationContainer(`Don't have bonus`, 'info');
		// 	return;
		// }

		if (address.wallet) {
			try {
				let currentAddress = connector === 'INJECTED' ? account : addressWeb3;

				let accounts;

				if (connector) {
					// accounts = await library?.provider.request({
					// 	method: 'eth_requestAccounts',
					// });
				}

				// const paramsSelector: any = {
				// 	to: String(DIVIDENT_CONTRACT),
				// 	abi: AbiStakingDividents.abi,
				// 	provider: currentProvider,
				// 	selector: 'SELECTOR_DIVIDENTS_UNSTAKE',
				// };

				// const selector = await getSelector(paramsSelector);
				// if (!selector) {
				// 	notificationContainer(`Somthing wrong with selector`, 'error');
				// }

				// const paramsFee: any = {
				// 	wallet: currentAddress,
				// 	selector: String(selector),
				// 	abi: AbiFee.abi,
				// 	tokenAddress: String(token?.asset?.address),
				// 	contractAddess: String(DIVIDENT_CONTRACT), // Address contract
				// 	provider: currentProvider,
				// 	connector,
				// };

				// const amountFee = await feeChecker(paramsFee);
				const sendParams: any = {
					wallet: String(currentAddress),
					abi: AbiStakingDividents.abi,
					to: String(DIVIDENT_CONTRACT),
					type: typeLock,
					firstTokenAmount: String(signature),
					amount: String(
						noExponent(
							Math.floor(
								Number(
									numberInDecimal(
										Number(typeLock === 'claim' ? total : dividents?.staking?.balance),
										Number(token?.asset.decimals),
									).toString(),
									// numberInDecimal(
									// 	Number(typeLock === 'claim' ? '10' : '100'),
									// 	Number(token?.asset.decimals),
									// ).toString(),
								),
							),
						),
					),
					signature: signature,
					firstTokenAddress: token?.asset.address,
					coin: token?.asset.symbol === 'GATO' && token?.asset.type === 'coin',
					provider: currentProvider,
					setOpenTransactionModal,
					connector,
				};

				const result =
					typeLock === 'claim'
						? await claimDividentsApprove(sendParams)
						: await unstakingApprove(sendParams);
				if (result && typeLock === 'claim' && dividents) {
					dispatch(
						getTrancationClaimDividensRequest({
							signature: String(result),
							amount: String(
								noExponent(
									Math.floor(
										Number(numberInDecimal(total, Number(token?.asset.decimals)).toString()),
									),
								),
							),
							date: signature,
							receiver_address: address?.wallet?.toLocaleLowerCase(),
							asset_address: dividents?.staking?.asset_address,
							stake_id: dividents?.staking?.id,
						}),
					);
					setOpenTransactionModal(false);
					// setHash(result?.transactionHash);
				}
				if (result && typeLock === 'Unlock' && dividents) {
					// if(upgrateDate(new Date(String(dividents?.staking?.end_staking)))){
					// 	notificationContainer('Unlcok afeter end stake period','info')
					// }
					debugger;
					dispatch(
						getTrancationUnstakeDividensRequest({
							tx: String(result),
							asset_address: dividents?.staking?.asset_address,
							stake_id: dividents?.staking?.id,
						}),
					);

					if (address.wallet && token) {
						dispatch(
							getDividendsRequest({
								wallet_address: address.wallet?.toLowerCase(),
								asset_address: String(token?.asset.address),
							}),
						);
					}

					console.log('dividents________', dividents);
					setOpenTransactionModal(false);
					setRefresh((value) => !value);
				}
			} catch (error: any) {
				notificationContainer(`Error: ${String(error?.message)}`, 'error');
				setOpenTransactionModal(false);
				return;
			}
			// setOpenTransactionModal(true);
			return;
		}
		setOpenModal(!openModal);
	};
	useEffect(() => {
		if (walletsUser && !token) {
			const gatoCoin = walletsUser?.find(
				(item: TWalletData) => item?.asset?.symbol === 'GATO' && item?.asset?.type === 'coin',
			);
			setToken(gatoCoin);
		}
	}, [walletsUser]);

	useEffect(() => {
		if (address.wallet && token) {
			dispatch(
				getDividendsRequest({
					wallet_address: address.wallet?.toLowerCase(),
					asset_address: String(token?.asset.address),
				}),
			);
		}
	}, [dispatch, address, token]);

	const handleClick = (tokenCode: string) => {
		setActiveCurrency(tokenCode);
		const gatoCoin = walletsUser?.find((item: TWalletData) => {
			return (
				(item?.asset?.symbol === tokenCode && item?.asset?.type === 'coin') ||
				item?.asset?.symbol === tokenCode
			);
		});

		setToken(gatoCoin);
	};
	return (
		<section className="earning-section earning-section--type2 earning-section--dividents">
			<div className="container">
				<EarningNav />
				<h3 className="earning-title">Gato Dividends</h3>
				<div className="earning-content">
					{/* <DividendsFilter
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						setTypeFilter={setTypeFilter}
					/> */}

					<div className="earning-block">
						<h4 className="earning-block-title">My Dividends Balance</h4>

						<div className="earning-stats earning-stats--divident-balance">
							<div className="earning-stats__item">
								<div className="earning-stats__name">
									<p>
										My dividends <span className="mob-break">in Gato coin</span>
									</p>
								</div>
								<div className="earning-stats__number">{total || 0}</div>
								<div className="dividents-btns dividents-btns--center ">
									<button
										className="button button--width"
										type="button"
										name="claim"
										// disabled={disableClaim}
										// disabled
										onClick={() => handleData()}
									>
										Claim
									</button>
									{/* <NavLink
										className="button button--type3 button--full-width"
										to="/stake-per-dividents"
									>
										Stake it
									</NavLink> */}
								</div>
							</div>

							<div className="earning-stats__item">
								<div className="earning-stats__name">
									<p>Locked</p>
								</div>
								<div className="earning-stats__number">
									{(dividents && dividents?.staking?.balance) || 0}
								</div>
								<div className="dividents-btns dividents-btns--center ">
									<button
										className="button button--width"
										type="button"
										// disabled
										name={locked === 'Unlock' ? 'unstake' : 'stake'}
										onClick={() => handlerConnection(locked, '')}
									>
										{locked}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="switch-box">
						<div className="earning--date-period">
							{dividents?.staking && (
								<p>
									Stake period{' '}
									<span>
										{dividents?.staking.start_staking} -{' '}
										{upgrateDate(new Date(String(dividents?.staking?.end_staking)))}
									</span>
								</p>
							)}
						</div>

						<div className="swap swap--medium">
							<button
								type="button"
								name="GATO"
								onClick={() => handleClick('GATO')}
								className={`swap__item ${activeCurrency === 'GATO' ? 'active' : ''}`}
							>
								GATO
							</button>
							<button
								type="button"
								name="GLAM"
								onClick={() => handleClick('GLAM')}
								className={`swap__item ${activeCurrency === 'GLAM' ? 'active' : ''}`}
							>
								GLAM
							</button>
							<button
								type="button"
								name="GGGG"
								onClick={() => handleClick('GGGG')}
								className={`swap__item ${activeCurrency === 'GGGG' ? 'active' : ''}`}
							>
								GFIVE
							</button>
						</div>
					</div>
					<div className="earning-block earning-block--nomb">
						<h4 className="earning-block-title">
							<span className="title-icon">
								<IconCoinGato />
							</span>
							GATO Chain Net Income
						</h4>
						{/* <div className="earning-date">
							<p className="earning-date__text">Date to Date</p>
							<p className="earning-date__range">01.05.2022 - 31.05.2022</p>
						</div> */}

						<div className="earning-stats">
							<div className="earning-stats__item">
								<div className="earning-stats__name">Your Locked</div>
								<div className="earning-stats__number">{dividents?.staking?.balance || 0}</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Total Locked</div>
								<div className="earning-stats__number">
									{(dividents?.total_locked &&
										Number(dividents?.total_locked?.amount)?.toFixed(12)) ||
										0}{' '}
								</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Available</div>
								<div className="earning-stats__number">{dividents?.available || 0}</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Dividents</div>
								<div className="earning-stats__number">
									{Number(dividents?.dividends) / 1 || 0} %
								</div>
							</div>
						</div>

						<h4 className="earning-block-title">My total earnings:</h4>
						<div className="earning-stats earning-stats--total-earning earning-stats--no-mb">
							<div className="earning-stats__item">
								<div className="earning-stats__name">
									<p>
										My dividends <span className="mob-break">in Gato coin</span>
									</p>
								</div>
								<div className="earning-stats__number">
									{(dividents?.earned?.length && dividents?.earned[0]?.amount) || 0}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<WalletsModal openModal={openModal} setOpenModal={setOpenModal} />
		</section>
	);
};
