import { FC, useState, useEffect } from 'react';
import PaginationTable from 'ui/PaginationTable';
import Loader from 'ui/Loader';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { IApiPool } from 'services/api/swap/types';
import { getTopPoolsRequest } from 'redux/reducers/swap/reducer';
import { getTopPools, topPoolsLoader } from 'redux/reducers/swap/selectors';

import { PoolItem } from '../PoolItem';
import { TPoolsList } from './type';
// import { TPoolItem } from './type';

export const PoolsTable: FC<TPoolsList> = ({ searchValue }) => {
	const dispatch = useDispatch();
	const topPoolsList = useSelector(getTopPools);
	const loader = useSelector(topPoolsLoader);

	const perPage = 8;

	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		dispatch(
			getTopPoolsRequest({ per_page: perPage, current_page: currentPage, search: searchValue }),
		);
	}, [currentPage, dispatch, searchValue]);

	return (
		<>
			<div className="table table--pools">
				<div className="table-header">
					<div className="tr">
						<div className="td">Pool</div>
						<div className="td td--right">Volume 24H</div>
					</div>
				</div>
				<div className="table-body body--position">
					{loader && (
						<div className="table-loader-wrapper">
							<Loader small />
						</div>
					)}
					{topPoolsList && topPoolsList.data.length ? (
						topPoolsList.data.map((item: IApiPool) => <PoolItem key={item.id} data={item} />)
					) : (
						<NoDataTable />
					)}
				</div>
			</div>
			{topPoolsList && (
				<PaginationTable
					className="block-pagination--center block-pagination--table"
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					lastPage={topPoolsList?.last_page}
				/>
			)}
		</>
	);
};
