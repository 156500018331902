/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAddTransactionRequest,
	IApiDividendsResponse,
	ICalculatedApyRequest,
	ICalculatedApyResponse,
	IEarningsStore,
	IPayloadOptions,
	IProfitResponse,
	IProfitsRequest,
	IStakingRequest,
	IStakingResponse,
	IUpdateRewardRequest,
	ITransactionClaimRequest,
	IAddTransactionPayload,
	IStakingDividentsRequest,
	IDividentsRequest,
	ITransactionUnstakeRequest,
	IDividendsResponse,
} from './types';

const initialState: IEarningsStore = {
	staking: null,
	stakingLoad: false,
	sumByAssets: [],
	paidRewardForAllTime: [],
	apy: '0.00',
	dividends: null,
	dividendsLoading: false,
	profits: null,
};

const earnings = createSlice({
	name: '@@earnings',
	initialState,
	reducers: {
		getStakingRequest: (state, action: PayloadAction<IStakingRequest>) => {
			state.stakingLoad = true;
		},
		getStakingSuccess: (state, { payload }: PayloadAction<IStakingResponse>) => {
			state.stakingLoad = false;
			state.staking = payload.staking;
			state.paidRewardForAllTime = payload.paidRewardForAllTime;
			state.sumByAssets = payload.sumByAssets;
		},
		getDividendsRequest: (state, payload: PayloadAction<IPayloadOptions>) => {
			const stateItem = state;
			stateItem.dividendsLoading = true;
		},
		getDividendsSuccess: (state, action: PayloadAction<IDividendsResponse>) => {
			const { payload } = action;
			const stateItem = state;
			stateItem.dividends = payload;
			stateItem.dividendsLoading = false;
		},
		sendStakeTransactionRequest: (state, action: PayloadAction<IAddTransactionPayload>) => {},
		calculatedApyRequest: (state, action: PayloadAction<ICalculatedApyRequest>) => {},
		calculatedApySuccess: (state, { payload }: PayloadAction<ICalculatedApyResponse>) => {
			state.apy = payload.apy;
		},
		getProfitsRequest: (state, action: PayloadAction<IProfitsRequest>) => {},
		getProfitsSuccess: (state, { payload }: PayloadAction<IProfitResponse>) => {
			state.profits = payload;
		},
		updateRewardRequest: (state, action: PayloadAction<IUpdateRewardRequest>) => {},
		getTrancationClaimDividensRequest: (
			state,
			action: PayloadAction<ITransactionClaimRequest>,
		) => {},
		getStakingDividentsRequest: (state, action: PayloadAction<IStakingDividentsRequest>) => {
			// state.stakingLoad = true;
		},
		getTrancationUnstakeDividensRequest: (
			state,
			action: PayloadAction<ITransactionUnstakeRequest>,
		) => {},
	},
});

export default earnings.reducer;
export const {
	getStakingRequest,
	getStakingSuccess,
	sendStakeTransactionRequest,
	calculatedApyRequest,
	calculatedApySuccess,
	getDividendsRequest,
	getDividendsSuccess,
	getProfitsRequest,
	getProfitsSuccess,
	updateRewardRequest,
	getTrancationClaimDividensRequest,
	getStakingDividentsRequest,
	getTrancationUnstakeDividensRequest,
} = earnings.actions;
