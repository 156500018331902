import { EarningsOverview } from 'components/EarningsOverview';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const OverviewEarningPage: FC = () => {
	return (
		<Content title="Earnings & Rewards" text="Earnings & Rewards">
			<EarningsOverview />
		</Content>
	);
};
