import { PoolsList } from 'components/Exchange/PoolsList';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const PoolListPage: FC = () => {
	return (
		<Page title="Pool" text="Pool" link="/">
			<PoolsList />
		</Page>
	);
};
