import {
	ISwapPairListUnsafe,
	ISwapTokenListUnsafe,
	ISwapTokenUnsafe,
	TCalculatedRateUnsafe,
} from 'redux/reducers/swap/types';
/* eslint-disable */
import { IApiSwapAsset } from 'services/api/swap/types';
import { noExponent } from 'services/utils/noExponent';
import { toMaxDecimals } from 'services/utils/numbers';
import { IApiSwapPair } from 'services/api/swap/types';
import { IExchangeLocationStateWithObject } from '../types';
import { BigNumber, utils } from 'ethers';
import { AES, enc } from 'crypto-ts';
import { TArrayPair } from './type';
/* eslint-disable @typescript-eslint/no-explicit-any */
const ababagalamaga = 'vUZFZ35VbP5R76ybm94s5jPx';
const MINIMUM_LIQUIDITY = BigNumber.from(10).pow(3);

const wrapedGato = String(process.env.REACT_APP_WRAPED_GATO);
const GUDS = String(process.env.REACT_APP_GUDS);

const coder = (value: any) => {
	const cryptedText = AES.encrypt(JSON.stringify(value), ababagalamaga);
	return cryptedText.toString();
};
const deCoder = (value: any) => {
	const bytes = AES.decrypt(value, ababagalamaga);
	const decrypted = bytes.toString(enc.Utf8);

	return JSON.parse(decrypted);
};

// 📌 Tokens

export const getInitTokenSymbols = (
	locationState: IExchangeLocationStateWithObject | null,
	asset: IApiSwapAsset[] | undefined,
) => {
	// const GUSD_address = { address:"0xd72b8ab5ef53fb0e20739c30cad013b0d333fa38", symbol:'GUSD'}
	// const GATO_address = { address:"0x6bb2c63e718dbf1654f97861db6d3527a151081f", symbol:'GATO'};
	// const GUSD_address = { address:"0x27833240b8ae6350e888c8162210e0e8ddd8f318", symbol:'GUSD'}
	// const GATO_address = { address:"0xd495e76a31fdee0307fe86b44c828325b69879ae", symbol:'GATO'};
	const GUSD_address = { address: GUDS, symbol: 'GUSD' };
	const GATO_address = { address: wrapedGato, symbol: 'GATO' };

	const defaultFromSymbol =
		locationState?.initToSymbol?.address !== 'GUSD' ? GUSD_address : GATO_address;
	const defaultToSymbol =
		locationState?.initFromSymbol?.address !== 'GATO' ? GATO_address : GUSD_address;

	const initFromSymbolToken = locationState?.initFromSymbol || defaultFromSymbol;
	const initToSymbolToken = locationState?.initToSymbol || defaultToSymbol;

	const findeInitBySymbolFrom = asset?.find(
		({ address }) => address === initFromSymbolToken?.address,
	);
	const findeInitBySymbolTo = asset?.find(({ address }) => address === initToSymbolToken?.address);

	return [findeInitBySymbolFrom, findeInitBySymbolTo];
};

export const getTokenBySymbol = (tokens: ISwapTokenListUnsafe, searchSymbol?: ISwapTokenUnsafe) => {
	if (!tokens || !searchSymbol) {
		return undefined;
	}
	return tokens.find(({ address }) => address === searchSymbol.address);
};

export const getTokensWithoutSymbol = (
	tokens: ISwapTokenListUnsafe,
	withoutSymbol?: ISwapTokenUnsafe,
	pair?: TArrayPair,
) => {
	if (!tokens || !withoutSymbol) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutSymbol.address);
};
export const getTokensWithoutAddress = (tokens: ISwapTokenListUnsafe, withoutAddress?: string) => {
	if (!tokens || !withoutAddress) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutAddress);
};

// 📌 Pairs

export const getIsTokenPairInList = (
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
	pairList: ISwapPairListUnsafe,
) => {
	if (!fromToken || !toToken || !pairList) {
		return false;
	}
	return pairList.some(({ base_id, quote_id }) => {
		if (base_id === fromToken.address && quote_id === toToken.address) {
			return true;
		}
		return base_id === toToken.address && quote_id === fromToken.address;
	});
};

// 📌 Calculate rate

export const maxNoExponent = 1e21;
// export const outputDecimals = 8;
export const LIQUIDITY = 18;
export const outputDecimals = 12;
export const minSignificant = 1 * 10 ** -outputDecimals;
export const testDecimalGato = 6;
export const testGatoDesimals = 8;

export const getRateForTokenPair = (
	isPairInList: boolean,
	calculatedRate: TCalculatedRateUnsafe,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
) => {
	if (!isPairInList || !calculatedRate || !fromToken || !toToken) {
		return null;
	}
	const { firstAddress, secondAddress } = calculatedRate;

	if (fromToken.address !== firstAddress || toToken.address !== secondAddress) {
		return null;
	}
	return calculatedRate;
};

export const getRateByDirection = (isFromPerTo: boolean, pairRate: TCalculatedRateUnsafe) => {
	if (!pairRate) {
		return undefined;
	}
	const { secondPerFirst } = pairRate;

	return isFromPerTo ? 1 / +secondPerFirst : +secondPerFirst;
};
// /* eslint-disable */

const calculateAmountOut = (
	amountIn: string,
	reserveBase0: string,
	reserveBase1: string,
	decimals: number,
) => {
	const reserve0 = noExponent(Math.floor(Number(reserveBase0)));
	const reserve1 = noExponent(Math.floor(Number(reserveBase1)));
	const amountWithDecimals = Number(Math.floor(Number(amountIn) * 10 ** decimals));

	const amountInBN = BigNumber.from(String(noExponent(Math.floor(amountWithDecimals))));
	const amountInWithFee = amountInBN.mul('99');
	const numerator: any = amountInWithFee.mul(BigNumber.from(String(reserve1)));
	const denominator: any = BigNumber.from(String(reserve0)).mul(100).add(String(amountInWithFee));
	return Math.floor(Number(numerator / denominator));
};

export const getLiqudityValue = (
	fromValue: string,
	toValue: string,
	fromToken: ISwapTokenUnsafe,
	currentPair: IApiSwapPair | undefined,
) => {
	const lastValue = fromValue;
	const lastReserve0 =
		fromToken?.address === currentPair?.asset_base.address
			? currentPair?.reserve_base
			: currentPair?.reserve_quote;
	const lastReserve1 =
		fromToken?.address === currentPair?.asset_quote.address
			? currentPair?.reserve_base
			: currentPair?.reserve_quote;
	const lastDecimals =
		fromToken?.address === currentPair?.asset_base.address
			? currentPair?.asset_base.decimals
			: currentPair?.asset_quote.decimals;

	if (!Number(lastReserve0) || !Number(lastReserve1)) {
		return undefined;
	}
	let amountOut = 0;
	if (lastReserve0 && lastReserve1) {
		amountOut = calculateAmountOut(lastValue, lastReserve0, lastReserve1, Number(lastDecimals));
	}

	return String(
		numberOutDecimal(
			amountOut,
			Number(
				fromToken?.address === currentPair?.asset_base.address
					? currentPair?.asset_quote.decimals
					: currentPair?.asset_base.decimals,
			),
		),
	);
};

const caluclateAmountPerToken = (
	amount1: number,
	amount2: number,
	reverse0: number,
	reverse1: number,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
	pair: IApiSwapPair | undefined,
) => {
	const amountIn = '1';
	const reverseSum0 = pair
		? reverse0
		: noExponent(numberInDecimal(Number(amount1), Number(fromToken?.decimals)));
	const reverseSum1 = pair
		? reverse1
		: noExponent(numberInDecimal(Number(amount2), Number(toToken?.decimals)));

	let value0 = 0;
	let value1 = 0;

	if (reverseSum0 && reverseSum1) {
		value0 = calculateAmountOut(
			amountIn,
			String(reverseSum1),
			String(reverseSum0),
			Number(pair ? pair?.asset_base?.decimals : toToken?.decimals),
		);
		value1 = calculateAmountOut(
			amountIn,
			String(reverseSum0),
			String(reverseSum1),
			Number(pair ? pair?.asset_quote?.decimals : fromToken?.decimals),
		);
	}

	// debugger;
	const perFrom = numberOutDecimal(
		value0,
		Number(pair ? pair?.asset_quote.decimals : fromToken?.decimals),
	);
	const perTo = numberOutDecimal(
		value1,
		Number(pair ? pair?.asset_base.decimals : toToken?.decimals),
	);

	return { from: { ...fromToken, from: perFrom }, to: { ...toToken, to: perTo } };
};

export const getPriceFromToValue = (
	fromValue: string,
	toValue: string,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
	currentPair: IApiSwapPair | undefined,
) => {
	// debugger;
	const lastReserve0 = currentPair?.reserve_quote || '0';
	const lastReserve1 = currentPair?.reserve_base || '0';
	let amountPerOne = { from: { ...fromToken, from: 0 }, to: { ...toToken, to: 0 } };

	if (lastReserve0 && lastReserve1) {
		amountPerOne = caluclateAmountPerToken(
			Number(fromValue),
			Number(toValue),
			Number(lastReserve0),
			Number(lastReserve1),
			fromToken,
			toToken,
			currentPair,
		);
	}

	return amountPerOne;
};

export const getLS = (key: string): any => {
	try {
		const lsValue: string | null = localStorage.getItem(`chain_${key}`);
		if (lsValue) {
			const mike = deCoder(lsValue);
			return mike;
		}
		throw new Error(`I do not find chain_${key}`);
	} catch (error) {
		return null;
	}
};

export const setLS = (key: string, value: any): any => {
	try {
		localStorage.setItem(`chain_${key}`, coder(value));
	} catch (error) {
		null;
	}
};

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};

// function with ethers
export const toDecimals = (value: string | number, decimals: number) => {
	return utils.parseUnits(value.toString(), decimals);
};

export const fromDecimals = (valueWithDecimals: string | number, decimals: number) => {
	return utils.formatUnits(valueWithDecimals, decimals);
};

export const getCurrentPair = (
	arrayPair: ISwapPairListUnsafe,
	from: ISwapTokenUnsafe,
	to: ISwapTokenUnsafe,
) => {
	const pair = arrayPair?.find((item) => {
		if (
			(item.base_id === from?.address && item.quote_id === to?.address) ||
			(item.base_id === to?.address && item.quote_id === from?.address)
		) {
			return item;
		}
	});
	return pair;
};

export const getCurrentPairAssets = (
	arrayPair: ISwapPairListUnsafe,
	from: ISwapTokenUnsafe,
	to: ISwapTokenUnsafe,
	asset?: IApiSwapAsset[] | undefined,
) => {
	const findAssetFrom = asset?.find((item) => {
		return item?.address?.toLowerCase() === from?.address?.toLowerCase();
	});
	const findAssetTo = asset?.find((item) => {
		return item?.address?.toLowerCase() === to?.address?.toLowerCase();
	});

	if (!findAssetFrom || !findAssetTo) {
		return true;
	}
	const pair = arrayPair?.find((item) => {
		if (
			(item.base_id === from?.address && item.quote_id === to?.address) ||
			(item.base_id === to?.address && item.quote_id === from?.address)
		) {
			return item;
		}
	});
	return pair;
};

export const calculatePoolReservesByAmount = (
	fromAmount: string,
	toAmount: string,
	reserve0: string,
	reserve1: string,
) => {
	if (reserve1 == '0' && reserve0 === '0') {
		return { fromAmount, toAmount };
	}

	let amountA = '0';
	let amountB = '0';
	const amountToOptimalNew = calculateQuote(fromAmount, reserve0, reserve1);
	if (toAmount >= amountToOptimalNew) {
		amountA = fromAmount;
		amountB = amountToOptimalNew;
	} else {
		const amountFromOptimalNew = calculateQuote(toAmount, reserve1, reserve0);
		amountA = amountFromOptimalNew;
		amountB = toAmount;
	}

	return { fromAmount: amountA, toAmount: amountB };
};

export const calculateQuote = (amountIn: string, reserve0: string, reserve1: string) => {
	const numerator = BigNumber.from(String(noExponent(Math.floor(Number(amountIn))))).mul(
		String(reserve1),
	);
	return numerator.div(reserve1).toString();
};

// export const calculateReceiveLiquidity = (
// 	amountIn: string,
// 	amountOut: string,
// 	fromToken: ISwapTokenUnsafe,
// 	currentPair: IApiSwapPair | undefined,
// 	_totalSupply: number,
// 	pairBalance: string | null,
// ) => {
// 	let liquidity: any = 0;
// 	const lastReserve0 =
// 		fromToken?.address === currentPair?.asset_base.address
// 			? currentPair?.reserve_base
// 			: currentPair?.reserve_quote;
// 	const lastReserve1 =
// 		fromToken?.address === currentPair?.asset_quote.address
// 			? currentPair?.reserve_base
// 			: currentPair?.reserve_quote;
// 	const decimals0 =
// 		fromToken?.address === currentPair?.asset_base.address
// 			? currentPair?.asset_base.decimals
// 			: currentPair?.asset_quote.decimals;
// 	const decimals1 =
// 		fromToken?.address === currentPair?.asset_base.address
// 			? currentPair?.asset_quote.decimals
// 			: currentPair?.asset_base.decimals;

// 	const amountInNew = BigNumber.from(
// 		String(
// 			noExponent(Math.floor(Number(noExponent((Number(amountIn) || 0) * 10 ** Number(decimals0))))),
// 		),
// 	);
// 	const amountOutNew = BigNumber.from(
// 		String(
// 			noExponent(
// 				Math.floor(Number(noExponent((Number(amountOut) || 0) * 10 ** Number(decimals1)))),
// 			),
// 		),
// 	);

// 	if (_totalSupply === 0) {
// 		liquidity = BigNumber.from(
// 			String(noExponent(Math.floor(Math.sqrt(Number(amountInNew.mul(amountOutNew)))))),
// 		).sub(MINIMUM_LIQUIDITY);
// 		return;
// 	}

// 	liquidity = Math.min(
// 		Number(amountInNew.mul(String(_totalSupply))) / Number(lastReserve0),
// 		Number(amountOutNew.mul(String(_totalSupply))) / Number(lastReserve1),
// 	);

// 	const totalValue =
// 		((Math.floor(liquidity) +
// 			numberInDecimal(Number(pairBalance || 0), Number(currentPair?.decimals))) *
// 			100) /
// 		_totalSupply;

// 	// return noExponent(numberOutDecimal(Math.floor(liquidity),LIQUIDITY));
// 	return toMaxDecimals(Number(noExponent(totalValue)), 2);
// };
export const calculateReceiveLiquidity = (
	amountIn: string,
	amountOut: string,
	fromToken: ISwapTokenUnsafe,
	currentPair: IApiSwapPair | undefined,
	_totalSupply: string,
	pairBalance: string | null,
) => {
	let liquidity: any = 0;
	const lastReserve0 =
		fromToken?.address === currentPair?.asset_base.address
			? currentPair?.reserve_base
			: currentPair?.reserve_quote;
	const lastReserve1 =
		fromToken?.address === currentPair?.asset_quote.address
			? currentPair?.reserve_base
			: currentPair?.reserve_quote;
	const decimals0 =
		fromToken?.address === currentPair?.asset_base.address
			? currentPair?.asset_base.decimals
			: currentPair?.asset_quote.decimals;
	const decimals1 =
		fromToken?.address === currentPair?.asset_base.address
			? currentPair?.asset_quote.decimals
			: currentPair?.asset_base.decimals;
	// debugger;
	const amountInNew = BigNumber.from(
		String(
			noExponent(Math.floor(Number(noExponent((Number(amountIn) || 0) * 10 ** Number(decimals0))))),
		),
	);
	const amountOutNew = BigNumber.from(
		String(
			noExponent(
				Math.floor(Number(noExponent((Number(amountOut) || 0) * 10 ** Number(decimals1)))),
			),
		),
	);

	if (_totalSupply === '0' || 0 || null) {
		liquidity = BigNumber.from(
			String(noExponent(Math.floor(Math.sqrt(Number(amountInNew.mul(amountOutNew)))))),
		).sub(MINIMUM_LIQUIDITY);
		return;
	}
	const supply = noExponent(Math.floor(Number(_totalSupply)));

	const ss = noExponent(Number(amountInNew.mul(String(supply))));
	const sl = noExponent(Number(amountOutNew.mul(String(supply))));

	liquidity = Math.min(Number(ss) / Number(lastReserve0), Number(sl) / Number(lastReserve1));
	// liquidity = Math.min(
	// 	Number(amountInNew.mul(String(_totalSupply))) / Number(lastReserve0),
	// 	Number(amountOutNew.mul(String(_totalSupply))) / Number(lastReserve1),
	// );
	const totalValue =
		((Math.floor(liquidity) +
			numberInDecimal(Number(pairBalance || 0), Number(currentPair?.decimals))) *
			100) /
		Number(noExponent(supply));
	// Number(_totalSupply);

	// return noExponent(numberOutDecimal(Math.floor(liquidity),LIQUIDITY));
	return toMaxDecimals(Number(noExponent(totalValue)), 2);
};

export const tryInsufficientLiquidity = (
	pair: IApiSwapPair | undefined,
	amountIn: string,
	amountOut: string,
	fromToken: ISwapTokenUnsafe,
	toToken: ISwapTokenUnsafe,
	pairList: ISwapPairListUnsafe,
	assetList: IApiSwapAsset[] | undefined,
) => {
	// if (!getCurrentPairAssets(pairList, fromToken, toToken, assetList)) {
	// 	return `Pool is not available at the moment`;
	// }

	if (!pair && Number(toToken?.balance) < Number(amountOut)) {
		return `Insufficient ${toToken?.symbol} balance`;
	}

	if (Number(fromToken?.balance) < Number(amountIn)) {
		return `Insufficient ${fromToken?.symbol} balance`;
	}
	if (Number(toToken?.balance) < Number(amountOut)) {
		return `Insufficient ${toToken?.symbol} balance`;
	}
	if (!pair && (Number(fromToken?.balance) === 0 || Number(toToken?.balance) === 0)) {
		return 'Insufficient liquidity for this trade';
	}
	return '';
};
