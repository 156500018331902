import { TStoreState } from 'redux/types';
import { IBlockchainStore } from './types';

export const getBlockchain = (state: TStoreState): IBlockchainStore => state.blockchain;

export const getBlocks = (state: TStoreState) => getBlockchain(state).blocks;

export const getBlocksLast = (state: TStoreState) => getBlockchain(state).blocksLast;

export const getTransactions = (state: TStoreState) => getBlockchain(state).transactions;

export const getTransactionsLast = (state: TStoreState) => getBlockchain(state).transactionsLast;

export const blocksLoading = (state: TStoreState) => getBlockchain(state).blocksLoading;

export const transactionsLoading = (state: TStoreState) => getBlockchain(state).blocksLoading;

export const getTransactionsCount = (state: TStoreState) => getBlockchain(state).transactionsCount;

export const getTransactionsGraph = (state: TStoreState) => getBlockchain(state).transactionsGraph;

export const getStatistic = (state: TStoreState) => getBlockchain(state).statistic;

export const getBlock = (state: TStoreState) => getBlockchain(state).block;

export const getTransaction = (state: TStoreState) => getBlockchain(state).transaction;

export const getBlocksCount = (state: TStoreState) => getBlockchain(state).lastNumber;

export const getBlocksPrevNext = (state: TStoreState) => getBlockchain(state).blockPrevNext;

export const getAddress = (state: TStoreState) => getBlockchain(state).address;
