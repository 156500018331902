import { Blocks } from 'components/Blocks';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const BlocksAllPage: FC = () => {
	return (
		<Page title="Blocks">
			<Blocks />
		</Page>
	);
};
