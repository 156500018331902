import { FC, useState, ChangeEvent } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon, ArrowBack, PlusButton } from 'assets/custom-icons';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getAddTokenAdress } from 'redux/reducers/coins/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { TWalletsModal } from './type';
import { ConfirmToken } from './ConfirmToken';

export const ManageModal: FC<TWalletsModal> = ({ openModal, setOpenModal }) => {
	const walletAddress = useSelector(getAddress);
	const [openForm, setOpenForm] = useState(false);
	const [openPreAdd, setOpenPreAdd] = useState(false);
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();

	const closeModal = () => {
		setOpenModal(false);
		setOpenForm(false);
	};
	const [value, setValue] = useState('');

	const handleBack = () => {
		if (openPreAdd) {
			setOpenPreAdd(false);
			setOpenForm(true);
			return;
		}
		if (openForm) {
			setOpenForm(false);
			return;
		}
		setOpenModal(false);
	};

	const handleAdd = () => {
		setOpenForm(true);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(String(e.currentTarget.value));
	};

	const handleConfirm = () => {
		setOpenPreAdd(true);
		setOpenForm(false);
		if (!walletAddress?.wallet) {
			notificationContainer('Please connect wallet', 'info');
			return;
		}
		if (value && openPreAdd && check && walletAddress?.wallet) {
			dispatch(
				getAddTokenAdress({
					address: value?.toLocaleLowerCase(),
					wallet: walletAddress?.wallet?.toLocaleLowerCase(),
				}),
			);
		}
	};

	return (
		<ModalWarapper
			openModal={openModal}
			setOpenModal={setOpenModal}
			insideClass="popup-window__inside--pt-medium"
		>
			<div className="popup popup--smaller popup--padding-16">
				<div className="popup-header">
					<button onClick={handleBack} type="button" className="popup-arrow" aria-label="Popup">
						<ArrowBack />
					</button>
					<p className="popup-header__title">{openPreAdd ? 'Add your token' : 'Manage'}</p>
					<button
						onClick={closeModal}
						type="button"
						className="popup-close"
						aria-label="Popup Close"
					>
						<CloseButtonIcon />
					</button>
				</div>
				{openForm ? (
					<div className="popup-body">
						<p className="small-descr">Add your token address</p>
						<div className="input input--no-mb">
							{/* <p className="input__name">Authenticator Code</p> */}
							<div className="input-wrapper">
								<input
									className="input-item "
									type="text"
									placeholder="0x64265CcB640530EF96454EEF83ebE71d..."
									onChange={handleChange}
									value={value}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{openPreAdd ? <ConfirmToken address={value} check={check} setCheck={setCheck} /> : ''}
				<div className="popup-footer">
					{openForm ? (
						<button onClick={handleConfirm} type="button" className="button button--full-width">
							Confirm
						</button>
					) : (
						<>
							{openPreAdd ? (
								<button
									onClick={handleConfirm}
									disabled={!check}
									type="button"
									className="button button--full-width"
								>
									Confirm
								</button>
							) : (
								<button
									onClick={handleAdd}
									type="button"
									className="button button--type4 button--full-width"
								>
									<span className="button__icon button__icon--left button__icon--bigger">
										<PlusButton />
									</span>
									Add Your Token
								</button>
							)}
						</>
					)}
				</div>
			</div>
		</ModalWarapper>
	);
};
