import React, { FC, useEffect, useMemo, useState } from 'react';

import TransactionsBlock from 'components/Transactions/TransactionsBlock';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { ITransaction } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';
import SelectToFrom from 'ui/SelectToFrom';

interface ITransactionsTabProps {
	transferList: ITransaction[] | undefined;
	// eslint-disable-next-line react/require-default-props
	hash?: string | undefined;
}

const TransactionsTab: FC<ITransactionsTabProps> = ({ transferList, hash }) => {
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [searchValue, setSearchValue] = useState<string>('all');
	const perPage = 6;

	const filteredList = useMemo(() => {
		if (!transferList) return [];
		setCurrentPage(1);
		if (searchValue === 'to') {
			return transferList.filter((el) => el.to_address_hash === hash);
		}
		if (searchValue === 'from') {
			return transferList.filter((el) => el.from_address_hash === hash);
		}
		return transferList;
	}, [hash, searchValue, transferList]);

	const currentTransaction = useMemo(() => {
		setItemOffset((currentPage - 1) * perPage);
		const endOffset = itemOffset + perPage;
		setPageCount(Math.ceil(filteredList.length / perPage));
		return filteredList.slice(itemOffset, endOffset);
	}, [currentPage, filteredList, itemOffset]);

	return (
		<>
			<div className="explore-block__top ">
				<h4 className="block-title block-title--mr-auto">Transactions</h4>
				{hash && <SelectToFrom search={searchValue} setSearch={setSearchValue} />}
				{currentTransaction && (
					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={pageCount}
						setCurrentPage={setCurrentPage}
					/>
				)}
			</div>
			{currentTransaction?.length ? (
				<TransactionsBlock itemsList={currentTransaction} />
			) : (
				<EmptyTable text="No Recent Transfers Yet" />
			)}
			{currentTransaction && (
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={pageCount}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</>
	);
};

export default TransactionsTab;
