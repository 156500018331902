/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useState, useEffect } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon } from 'assets/custom-icons';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { PendingModal } from '../TransactionsModals/pending';

import { TConfirmModal } from './type';

export const ConfirmModal: FC<TConfirmModal> = ({
	openModal,
	setOpenModal,
	itemIn,
	itemOut,
	toValue,
	fromValue,
	slipping,
	displayedRate,
	aproveSwap,
	feeToken,
	updatePrice,
	loader,
}) => {
	const closeModal = () => setOpenModal(false);
	const [viewUpdate, setViewUpdate] = useState(false);

	useEffect(() => {
		let timeId: NodeJS.Timeout;
		if (openModal && !viewUpdate) {
			timeId = setTimeout(() => {
				setViewUpdate(true);
			}, 10000);
		}
		return () => {
			clearTimeout(timeId);
		};
	}, [openModal, viewUpdate]);

	const handaleAccept = () => {
		setViewUpdate(false);
		updatePrice();
	};

	return (
		<ModalWarapper openModal={openModal} setOpenModal={setOpenModal}>
			<>
				{loader ? (
					<PendingModal
						setOpenModal={setOpenModal}
						from={itemIn?.symbol}
						to={itemOut?.symbol}
						fromValue={fromValue}
						toValue={toValue}
					/>
				) : (
					<div className="popup ">
						<div className="popup-header">
							<p className="popup-header__title">Confirm Exchange</p>
							<button onClick={closeModal} type="button" className="popup-close">
								<CloseButtonIcon />
							</button>
						</div>
						<div className="popup-body">
							<div className="popup-small-block">
								<div className="exchange-item">
									<div className="select select--exchange">
										<div className="select__current">
											<div className="token-block">
												<div className="token-block__icon">
													<img src={itemIn?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__purpose">From</p>
													<p className="token-block__name">{itemIn?.symbol}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info item--padding"
													type="number"
													placeholder="0.0"
													value={fromValue}
													readOnly
												/>
											</div>
										</label>
									</div>
								</div>
								<div className="small-arrow-down">
									<svg
										width="16"
										height="17"
										viewBox="0 0 16 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.0465 10.1201L7.99979 14.1668L3.95312 10.1201"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8 2.8335V14.0535"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
								<div className="exchange-item">
									<div className="select select--exchange">
										<div className="select__current">
											<div className="token-block">
												<div className="token-block__icon">
													<img src={itemOut?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__purpose">To</p>
													<p className="token-block__name">{itemOut?.symbol}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info item--padding"
													type="number"
													placeholder="0.0"
													value={convertExponentialToDecimal(Number(toValue))}
													readOnly
												/>
											</div>
										</label>
									</div>
								</div>
							</div>
							{viewUpdate && (
								<div className="popup-small-block popup-small-block--p12">
									<div className="small-block">
										<p className="small-block__text">
											<span className="small-block__icon">
												<svg
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M9 6.75V10.5"
														stroke="#FFA51E"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8.99958 16.0575H4.45458C1.85208 16.0575 0.764583 14.1975 2.02458 11.925L4.36458 7.70996L6.56958 3.74996C7.90458 1.34246 10.0946 1.34246 11.4296 3.74996L13.6346 7.71746L15.9746 11.9325C17.2346 14.205 16.1396 16.065 13.5446 16.065H8.99958V16.0575Z"
														stroke="#FFA51E"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8.99609 12.75H9.00283"
														stroke="#FFA51E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
											Price Updated
										</p>
										<button onClick={handaleAccept} type="button" className="button">
											Accept
										</button>
									</div>
								</div>
							)}
							<p className="block-subtitle block-subtitle--mb-8">
								Token Fee{' '}
								<span className="green-text green-text--fw-600">
									{feeToken} {itemIn?.symbol}
								</span>{' '}
							</p>
							<p className="block-subtitle block-subtitle--mb-8">
								Input is estimated. You will sell at most{' '}
								<span className="green-text green-text--fw-600">
									{fromValue} {itemIn?.symbol}
								</span>{' '}
								or the transaction will revert.
							</p>
							<div className="operation-info">
								<p className="operation-info__text operation-info__text--bold">Price</p>
								<p className="operation-info__number operation-info__number--flex">
									{displayedRate || 0}{' '}
									<span>
										{itemIn?.symbol} per {itemOut?.symbol}
									</span>
								</p>
							</div>
						</div>
						<div className="popup-footer">
							<button onClick={aproveSwap} type="button" className="button button--full-width">
								Confirm Exchange
							</button>
						</div>
					</div>
				)}
			</>
		</ModalWarapper>
	);
};
