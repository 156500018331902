import { Bridge } from 'components/Bridge';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const BridgePage: FC = () => {
	return (
		<Content title="Bridge" text="Bridge" history="History">
			<Bridge />
		</Content>
	);
};
