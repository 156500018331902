import { Overview } from 'components/Wallets/Overview';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const OverviewPage: FC = () => {
	return (
		<Content title="Gato Wallet" text="Gato Wallet">
			<Overview />
		</Content>
	);
};
