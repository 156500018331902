/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
	ConnectionType,
	getConnection,
	tryActivateConnector,
	tryDeactivateConnector,
} from 'services/utils/connections';
import { Web3Provider } from '@ethersproject/providers';
import { setLogoutWallet, setAddress, setTypeWallet } from 'redux/reducers/connectWallet/reducer';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { setLS, getLS } from 'components/Exchange/ExchangeForm/utils';
// import WalletConnect from '@walletconnect/client';
// import QRCodeModal from '@walletconnect/qrcode-modal';

// import {
// 	NoEthereumProviderError,
// 	UserRejectedRequestError as UserRejectedRequestErrorInjected,
// 	InjectedConnector,
// } from '@web3-react/injected-connector';
// import {
// 	UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
// 	WalletConnectConnector,
// } from '@web3-react/walletconnect-connector';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3Modal, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';
// import types from 'redux/types';
// import { connectorsByName } from 'services/utils/web3React';
import { setupNetwork } from 'services/utils/wallets';
import { notificationContainer } from 'services/utils/notificationContainer';
/* eslint-disable no-debugger */

const useAuth = () => {
	const dispatch = useDispatch();
	const { isActive, account } = useWeb3React<Web3Provider>();
	const wallet = useSelector(getAddress);
	const locationLink = window.location.pathname.split('/')[1];
	const { disconnect } = useDisconnect();
	const { address, chainId, isConnected } = useWeb3ModalAccount();

	const login = useCallback(
		async (connectionType: any) => {
			// const connector = connectorsByName[connectorName];
			window.localStorage.setItem('connectorLocalStorageKey', connectionType);
			// if (connector) {
			let activateCurrentNetwork;
			// debugger;
			const chainIdStorage = getLS('chainId');
			// if (chainIdStorage !== '4287' && locationLink === 'bridge-transfer') {
			// 	activateCurrentNetwork = await setupNetwork('4287');
			// 	// if (hasSetu) {
			// 	// 	activate(connector);
			// 	// }
			// }
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			// await activate(connector, async (errors: Error) => {
			// 	if (errors instanceof UnsupportedChainIdError) {
			// 		if (connectorName === 'walletconnect') return;

			// 		const hasSetup = await setupNetwork('4287');
			// 		if (hasSetup) {
			// 			activate(connector);
			// 		}
			// 	} else {
			// 		window.localStorage.removeItem('connectorLocalStorageKey');
			// 		if (
			// 			errors instanceof NoEthereumProviderError
			// 			//  || error instanceof NoBscProviderError
			// 		) {
			// 			notificationContainer(
			// 				'To connect to MetaMask from browser use Chrome extension or mobile App',
			// 				'info',
			// 			);
			// 		} else if (
			// 			errors instanceof UserRejectedRequestErrorInjected ||
			// 			errors instanceof UserRejectedRequestErrorWalletConnect
			// 		) {
			// 			if (connector instanceof WalletConnectConnector) {
			// 				const walletConnector: any = connector;
			// 				walletConnector.walletConnectProvider = null;
			// 			}
			// 			notificationContainer('Please authorize to access your account', 'error');
			// 		} else if (errors.message === 'Already processing eth_requestAccounts. Please wait.')
			// 			notificationContainer(`Enter your metamask password`, 'info');
			// 	}
			// });
			if (connectionType === 'INJECTED') {
				try {
					const activation = await tryActivateConnector(getConnection(connectionType).connector);
					if (!activation) {
						return;
					}

					// const payload = {
					// 	...wallet,
					// 	typeConrction: activation,
					// };

					window.localStorage.setItem('connectorLocalStorageKey', connectionType);
					// dispatch(setAddress(payload));
					dispatch(setTypeWallet(activation));
					// debugger;
					// await switchNetwork(Number(4287), connectionType);
				} catch (errors: any) {
					console.log(errors);
				}
			} else {
				try {
					if (isConnected) {
						console.log('conneccted successfully');
					}
					// else if (connectionType === 'WALLET_CONNECT') {
					// 	// debugger;
					// 	// await open({ view: 'Connect' });
					// }
				} catch (error: any) {
					console.log(error);
				}
			}
			// if (active && chainId) {
			// 	const payload = {
			// 		account,
			// 		active,
			// 		error,
			// 		loading: true,
			// 		type: library?.connection?.url,
			// 	};
			// 	dispatch(setAddress(payload));
			// 	notificationContainer('Connect wallet!', 'success');
			// 	setLS('chainId', chainId);
			// }
			// } else {
			// 	notificationContainer('The connector config is wrong', 'error');
			// }
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, account, isConnected],
	);

	const logout = useCallback(
		async (connectionType: any) => {
			// dispatch(setLogoutWallet());
			// deactivate();
			// window.localStorage.removeItem('connectorLocalStorageKey');
			window.localStorage.removeItem('connectorLocalStorageKey');
			if (connectionType === 'INJECTED') {
				const deactivation = await tryDeactivateConnector(getConnection(connectionType).connector);

				// undefined means the deactivation failed
				if (deactivation === undefined) {
					return;
				}
			} else {
				disconnect();
			}

			dispatch(setLogoutWallet());
			dispatch(setTypeWallet(''));
			setLS('chainId', '');
			// onDeactivate(deactivation);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[dispatch, disconnect],
	);

	return { login, logout };
};

export default useAuth;
