import { Exchange } from 'components/Exchange';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const ExchangePage: FC = () => {
	return (
		<Page title="Gato Exchange (DeFi)" text="Gato Exchange (DeFi)" link="/">
			<Exchange />
		</Page>
	);
};
