import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { ITokenTransfer } from 'services/api/blockchain/types';
import TransferRow from './TransferRow';

interface ITransfersBlockProps {
	itemsList: ITokenTransfer[];
}

const TransfersBlock: FC<ITransfersBlockProps> = ({ itemsList }) => {
	const location = useLocation();
	const page = location.pathname.split('/')[1];
	return (
		<div className="explore-blocks">
			{itemsList.map((item) => (
				<TransferRow key={item.block_id} items={item} page={page} />
			))}
		</div>
	);
};

export default TransfersBlock;
