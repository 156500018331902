/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPairLiqudityInfoRequest, sendTrancationRequest } from 'redux/reducers/swap/reducer';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { getSwapAssetsRequest } from 'redux/reducers/swap/reducer';
import { getPairLiquidity } from 'redux/reducers/swap/selectors';
import { removeLiquidity } from 'services/utils/liquidity';
import { notificationContainer } from 'services/utils/notificationContainer';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import { feeChecker } from 'services/utils/feeChecker';
import { noExponent } from 'services/utils/noExponent';
import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';
import AbiFee from 'config/abi/FeeController.json';
import { useWeb3React } from '@web3-react/core';
import { TRemove, ITypeTransactionRemove } from './type';
import { getSwapAssetList } from 'redux/reducers/swap/selectors';
import {
	numberInDecimal,
	calculateRemoveLiquidityReceiveTokens,
	caluclateAmountPerToken,
} from './utils';
import { SliderRange } from './SliderRange';

const SELECTOR_REMOVE_LIQUIDITY = '0x30ab65d5';
/* eslint-disable no-debugger */
/* eslint-disable */
declare let window: any;
export const RemoveLiquidity: FC<TRemove> = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { search } = location;
	const pairLiquidity = useSelector(getPairLiquidity);
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const assetList = useSelector(getSwapAssetList);
	const { walletProvider } = useWeb3ModalProvider();
	const tokenRefresh = useRefreshToken();

	const { provider, account } = useWeb3React();

	const [loader, setLoader] = useState(false);
	const [tokenValue, setTokenValue] = useState({
		amount0: '',
		amount1: '',
	});
	const [tokenPrice, setTokenPrice] = useState({ amount0: '0', amount1: '0' });

	const [tokenLp, setTokenLp] = useState('');

	useEffect(() => {
		if (pairLiquidity && tokenValue) {
			const amountPerToken = caluclateAmountPerToken(tokenValue, pairLiquidity?.pair);
			setTokenPrice({ ...amountPerToken });
		}
	}, [pairLiquidity, tokenValue.amount0, tokenValue.amount1]);

	useEffect(() => {
		if (pairLiquidity) {
			const amountBDesiredWithdecimals = numberInDecimal(
				Number(pairLiquidity?.userBalanceLpToken),
				Number(pairLiquidity?.decimals),
			).toString();
			setTokenLp(amountBDesiredWithdecimals);
		}
	}, [pairLiquidity]);

	useEffect(() => {
		if (!assetList) {
			dispatch(
				getSwapAssetsRequest({
					per_page: 100,
					is_active: 1,
				}),
			);
		}

		// dispatch(getSwapPairsRequest({ per_page: 1000 }));
	}, [dispatch]); // eslint-disable-line

	useEffect(() => {
		if (pairLiquidity) {
			const res = calculateRemoveLiquidityReceiveTokens(
				String(Math.floor(Number(tokenLp))),
				pairLiquidity,
				Math.floor(Number(pairLiquidity?.total_supply)),
			);
			if (res.amount0 === Infinity || res.amount1 === Infinity) {
				return;
			}

			setTokenValue({ amount0: String(res?.amount0 || 0), amount1: String(res?.amount1 || 0) });
		}
	}, [tokenLp, pairLiquidity]);

	const assetCoin = assetList?.find((item) => item.type === 'coin');

	useEffect(() => {
		if (search) {
			const addressArr = String(search).split('/');
			dispatch(getPairLiqudityInfoRequest({ wallet: addressArr[2], pairId: addressArr[3] }));
		}
	}, [dispatch, search]);

	const handleRemoveLiquidity = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		try {
			let accounts;
			let currentAddress = connector === 'INJECTED' ? account : address;
			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}
			const amountBDesiredWithdecimals = numberInDecimal(
				Number(pairLiquidity?.userBalanceLpToken),
				Number(pairLiquidity?.decimals),
			).toString();

			const assetCoinFirstPair =
				pairLiquidity?.pair.asset_base.symbol !== 'GATO'
					? pairLiquidity?.pair.asset_base
					: pairLiquidity?.pair.asset_quote;

			const paramsFee: any = {
				wallet: String(currentAddress),
				selector: SELECTOR_REMOVE_LIQUIDITY,
				abi: AbiFee.abi,
				contractAddess: String(process.env.REACT_APP_ROUTER_ADDRESS), // Address contract
				provider: currentProvider,
				// tokenAddress: assetCoinPair
				//  	? assetCoin?.address
				//  	: String(pairLiquidity?.pair.asset_base.address
				tokenAddress: String(assetCoinFirstPair?.address),
				setLoader,
				connector,
			};

			const amountFee = await feeChecker(paramsFee);

			if (!amountFee) {
				return;
			}

			const sendParams: ITypeTransactionRemove = {
				wallet: String(currentAddress),
				abi: AbiRouter.abi,
				to: process.env.REACT_APP_ROUTER_ADDRESS,
				amountBDesired: tokenLp,
				firstTokenAddress: String(pairLiquidity?.pair.asset_base.address),
				secondTokenAddress: String(pairLiquidity?.pair.asset_quote.address),
				tokenAsset:
					pairLiquidity?.pair.asset_base.symbol === 'GATO'
						? pairLiquidity?.pair.asset_quote.address
						: pairLiquidity?.pair.asset_base.address,
				provider: currentProvider,
				amountFee: String(amountFee),
				type:
					pairLiquidity?.pair.asset_base.symbol === 'GATO' ||
					pairLiquidity?.pair.asset_quote.symbol === 'GATO'
						? 'GATO'
						: 'token',
				setLoader,
				connector,
			};

			const result = await removeLiquidity(sendParams);

			if (result) {
				dispatch(
					sendTrancationRequest({
						type: 'delete_liquidity',
						tx: String(result?.transactionHash),
						base_amount: Number(tokenValue.amount0),
						quote_amount: Number(tokenValue.amount1),
						base_id: Number(pairLiquidity?.pair.asset_base.id),
						quote_id: Number(pairLiquidity?.pair.asset_quote.id),
						navigate,
					}),
				);

				//setOpenConfirmModal(false);
				// setOpenTransactionModal(true);
				// setHash(result?.transactionHash);
			}
			return;
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');

			// setOpenTransactionModal(true);
		}
	};
	const handleCansel = () => {
		navigate(-1);
	};

	return (
		<section className="general-section general-section--pool">
			<div className="container">
				<div className="inner-block">
					<div className="swap swap--center swap--mb">
						<NavLink to="/exchange" className="swap__item">
							Exchange
						</NavLink>
						<button type="button" className="swap__item active">
							Pool
						</button>
						<NavLink to="/pool-token" className="swap__item">
							Tokens
						</NavLink>
					</div>
					<div className="general-block general-block--center">
						<div className="block-header block-header--mb0">
							<h4 className="general-block-title">
								Remove {pairLiquidity?.pair.asset_base.symbol}/
								{pairLiquidity?.pair.asset_quote.symbol} Liquidity
							</h4>
						</div>
						<p className="block-subtitle">
							To Receive {pairLiquidity?.pair.asset_base.symbol} and{' '}
							{pairLiquidity?.pair.asset_quote.symbol}
						</p>
						<div className="block-content">
							<SliderRange
								lpToken={tokenLp}
								tokenValue={tokenValue}
								setTokenValue={setTokenValue}
								setTokenLp={setTokenLp}
							/>
							<div className="transfer-icon">
								<svg
									width="16"
									height="17"
									viewBox="0 0 16 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.0465 10.1201L7.99979 14.1668L3.95312 10.1201"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8 2.8335V14.0535"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>

							<div className="operation-details-box">
								<p className="block-subtitle block-subtitle--type3 block-subtitle--no-mt block-subtitle--mb-8">
									You will receive
								</p>
								<div className="operation-details-group">
									<div className="operation-info">
										<div className="token-block">
											<div className="token-block__icon">
												<img src={pairLiquidity?.pair.asset_base.logo} alt="" />
											</div>
											<div className="token-block__info">
												<p className="token-block__name">{pairLiquidity?.pair.asset_base.symbol}</p>
											</div>
										</div>
										<p className="operation-info__number">{noExponent(tokenValue?.amount0)}</p>
									</div>
									<div className="operation-info">
										<div className="token-block">
											<div className="token-block__icon">
												<img src={pairLiquidity?.pair.asset_quote.logo} alt="" />
											</div>
											<div className="token-block__info">
												<p className="token-block__name">
													{pairLiquidity?.pair.asset_quote.symbol}
												</p>
											</div>
										</div>
										<p className="operation-info__number">{noExponent(tokenValue?.amount1)}</p>
									</div>
								</div>
							</div>
							<div className="operation-details-box">
								<p className="block-subtitle block-subtitle--type3 block-subtitle--no-mt block-subtitle--mb-8">
									Prices and pool share:
								</p>
								<div className="operation-details-group">
									<div className="operation-info">
										<p className="operation-info__text">
											1 {pairLiquidity?.pair.asset_base.symbol} =
										</p>
										<p className="operation-info__number">
											{noExponent(tokenPrice?.amount0)} {pairLiquidity?.pair.asset_quote.symbol}
										</p>
									</div>
									<div className="operation-info">
										<p className="operation-info__text">
											1 {pairLiquidity?.pair.asset_quote.symbol} =
										</p>
										<p className="operation-info__number">
											{noExponent(tokenPrice?.amount1)} {pairLiquidity?.pair.asset_base.symbol}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="block-footer">
							<div className="block-footer-button">
								<button onClick={handleCansel} type="button" className="button button--full-width">
									Cancel
								</button>
								<button
									onClick={() => handleRemoveLiquidity()}
									type="button"
									className="button button--type3 button--full-width"
								>
									Remove
								</button>
							</div>
						</div>
					</div>
					<div className="general-info-block">
						<div className="operation-details-box operation-details-box--type2">
							<p className="block-subtitle block-subtitle--type2 block-subtitle--bigger block-subtitle--no-mt block-subtitle--mb-8">
								LP tokens in your wallet
							</p>
							<div className="operation-details-group">
								<div className="operation-info">
									<div className="token-block">
										<div className="token-block__icon">
											<img src={pairLiquidity?.pair?.asset_base?.logo} alt="" />
										</div>
										<div className="token-block__icon">
											<img src={pairLiquidity?.pair?.asset_quote?.logo} alt="" />
										</div>
										<div className="token-block__info">
											<p className="token-block__name">
												{pairLiquidity?.pair?.asset_base?.symbol} -{' '}
												{pairLiquidity?.pair?.asset_quote?.symbol}
											</p>
										</div>
									</div>
									<p className="operation-info__number">{pairLiquidity?.userBalanceLpToken}</p>
								</div>
								<div className="operation-info operation-info--no-mb">
									<p className="operation-info__text">Share of Pool:</p>
									<p className="operation-info__number">{pairLiquidity?.percent_in_pool}%</p>
								</div>
								<div className="operation-info operation-info--no-mb">
									<p className="operation-info__text">
										Pooled {pairLiquidity?.pair?.asset_base?.symbol}:
									</p>
									<p className="operation-info__number">{pairLiquidity?.base_amount}</p>
								</div>
								<div className="operation-info operation-info--no-mb">
									<p className="operation-info__text">
										Pooled {pairLiquidity?.pair?.asset_quote?.symbol}:
									</p>
									<p className="operation-info__number">{pairLiquidity?.quote_amount}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
