export const iconElement = (name: string): any => {
	switch (name) {
		case 'Explorer':
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.61328 4.95996L8.49995 8.36663L14.3466 4.97996"
						stroke="#8192AA"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M8.5 14.407V8.36035"
						stroke="#8192AA"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.12042 1.65287L3.56042 3.6262C2.75375 4.07287 2.09375 5.19287 2.09375 6.11287V9.87954C2.09375 10.7995 2.75375 11.9195 3.56042 12.3662L7.12042 14.3462C7.88042 14.7662 9.12708 14.7662 9.88708 14.3462L13.4471 12.3662C14.2538 11.9195 14.9138 10.7995 14.9138 9.87954V6.11287C14.9138 5.19287 14.2538 4.07287 13.4471 3.6262L9.88708 1.6462C9.12042 1.2262 7.88042 1.2262 7.12042 1.65287Z"
						stroke="#8192AA"
						strokeWidth="1.2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		case 'Wallets':
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.16797 6H5.16797"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M15.1655 7.31376V8.68713C15.1655 9.0538 14.8722 9.35377 14.4988 9.36711H13.1922C12.4722 9.36711 11.8122 8.84044 11.7522 8.12044C11.7122 7.70044 11.8722 7.30711 12.1522 7.03377C12.3988 6.78044 12.7388 6.63379 13.1122 6.63379H14.4988C14.8722 6.64712 15.1655 6.94709 15.1655 7.31376Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M12.152 7.033C11.872 7.30633 11.712 7.69967 11.752 8.11967C11.812 8.83967 12.472 9.36633 13.192 9.36633H14.4987V10.333C14.4987 12.333 13.1654 13.6663 11.1654 13.6663H5.16536C3.16536 13.6663 1.83203 12.333 1.83203 10.333V5.66634C1.83203 3.85301 2.92537 2.58634 4.62537 2.37301C4.7987 2.34634 4.9787 2.33301 5.16536 2.33301H11.1654C11.3387 2.33301 11.5054 2.33967 11.6654 2.36633C13.3854 2.56633 14.4987 3.83967 14.4987 5.66634V6.63302H13.112C12.7387 6.63302 12.3987 6.77967 12.152 7.033Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		case 'Exchange':
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15.1667 5.66634C15.1667 8.05967 13.2267 9.99967 10.8333 9.99967C10.72 9.99967 10.6 9.99302 10.4867 9.98635C10.32 7.87302 8.62666 6.17967 6.51333 6.013C6.50666 5.89967 6.5 5.77967 6.5 5.66634C6.5 3.27301 8.44 1.33301 10.8333 1.33301C13.2267 1.33301 15.1667 3.27301 15.1667 5.66634Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10.4987 10.3333C10.4987 12.7267 8.5587 14.6667 6.16536 14.6667C3.77203 14.6667 1.83203 12.7267 1.83203 10.3333C1.83203 7.94 3.77203 6 6.16536 6C6.2787 6 6.39869 6.00666 6.51202 6.01333C8.62536 6.17999 10.3187 7.87334 10.4854 9.98667C10.492 10.1 10.4987 10.22 10.4987 10.3333Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M4.22536 1.33301H2.4987C2.13203 1.33301 1.83203 1.63301 1.83203 1.99967V3.72634C1.83203 4.31967 2.55203 4.61967 2.97203 4.19967L4.69869 2.473C5.11202 2.053 4.8187 1.33301 4.22536 1.33301Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M12.7718 14.6662H14.4985C14.8652 14.6662 15.1652 14.3662 15.1652 13.9995V12.2729C15.1652 11.6795 14.4452 11.3795 14.0252 11.7995L12.2985 13.5262C11.8852 13.9462 12.1785 14.6662 12.7718 14.6662Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		case 'Earnings':
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.5 9.99967C10.9853 9.99967 13 8.05958 13 5.66634C13 3.27311 10.9853 1.33301 8.5 1.33301C6.01472 1.33301 4 3.27311 4 5.66634C4 8.05958 6.01472 9.99967 8.5 9.99967Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M5.51447 9.01355L5.50781 13.9336C5.50781 14.5336 5.92781 14.8269 6.44781 14.5802L8.23448 13.7336C8.38114 13.6602 8.62781 13.6602 8.77447 13.7336L10.5678 14.5802C11.0811 14.8202 11.5078 14.5336 11.5078 13.9336V8.89355"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		case 'Bridge':
			return (
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31778 11.6816 1.33301 7.99967 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663Z"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M11.3337 11.0003H9.26701L10.347 9.80697C10.8803 9.21363 11.1737 8.46035 11.1737 7.68701C11.1737 6.88701 10.8403 6.12034 10.247 5.55367C9.65366 4.987 8.84699 4.66699 8.00033 4.66699C7.16033 4.66699 6.35366 4.987 5.75366 5.55367C5.16033 6.12034 4.82699 6.88701 4.82699 7.68701C4.82699 8.46701 5.12031 9.2203 5.65365 9.80697L6.73364 11.0003H4.66699"
						stroke="#8192AA"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		// case 'Chain':
		// 	return (
		// 		<svg
		// 			width="11"
		// 			height="6"
		// 			viewBox="0 0 11 6"
		// 			fill="none"
		// 			xmlns="http://www.w3.org/2000/svg"
		// 		>
		// 			<path
		// 				d="M9.45906 1.47461L6.19906 4.73461C5.81406 5.11961 5.18406 5.11961 4.79906 4.73461L1.53906 1.47461"
		// 				stroke="#8192AA"
		// 				strokeWidth="1.2"
		// 				strokeMiterlimit="10"
		// 				strokeLinecap="round"
		// 				strokeLinejoin="round"
		// 			/>
		// 		</svg>
		// 	);
		default:
			return '';
	}
};

export const ExplorerList = [
	{
		order: 1,
		name: `Blocks`,
		link: '/blocks',
	},
	{
		order: 2,
		name: `Gato Chain Transactions`,
		link: '/transactions',
	},
	{
		order: 3,
		name: `Tokens`,
		link: '/tokens/?page=1&search=',
	},
];
export const WalletList = [
	{
		order: 1,
		name: `Overview`,
		link: '/overviews',
	},
	{
		order: 2,
		name: `Wallet`,
		link: '/wallets',
	},
	{
		order: 3,
		name: `Earnings`,
		link: '/earnings-overview',
	},
	{
		order: 4,
		name: `Exchange`,
		link: '/exchange',
	},
];
export const ExchangeList = [
	{
		order: 1,
		name: `Swap`,
		link: '/exchange',
	},
	{
		order: 2,
		name: `Pool`,
		link: '/pool',
	},
	{
		order: 3,
		name: `Tokens`,
		link: '/pool-token',
	},
	{
		order: 4,
		name: `AutoSwap`,
		link: '/auto-swap',
	},
];
export const EarningsList = [
	{
		order: 1,
		name: `Overview`,
		link: '/earnings-overview',
	},
	{
		order: 2,
		name: `Staking`,
		link: '/staking',
	},
	{
		order: 3,
		name: `Infinity Referral System`,
		link: '/infinity',
	},
	{
		order: 4,
		name: `Gato Dividends`,
		link: '/dividends',
	},
];

export const ChainList = [
	{
		order: 1,
		name: `Gato Mainnet`,
		link: '/',
	},
	{
		order: 2,
		name: `Gato Testnet`,
		link: '/',
	},
];
export const BridgeList = [
	{
		order: 1,
		name: `Transfer`,
		link: '/bridge-transfer',
	},
	{
		order: 2,
		name: `Liquidity`,
		link: '/bridge-liquidity',
	},
];
