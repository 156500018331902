import { FC, useCallback, useState, useMemo } from 'react';
import { IApiSwapAsset } from 'services/api/swap/types';
import { Modal } from 'ui/Modal';
import eth from 'assets/images/tokens/eth.png';
import { ReactComponent as TriangularArrowIcon } from 'assets/custom-icons/triangular-arrow.svg';
import { TPairNetwork, TNetworkItem } from 'redux/reducers/bridge/types';
import { getCurrentTokens } from 'components/Exchange/ExchangeForm/utils';
import { ITokenInputProps } from './types';
import { TokenList } from './TokenList';
/*eslint-disable */

export const NetworkInput: FC<ITokenInputProps> = ({
	chainList,
	value,
	onChange,
	title,
	pairList = [],
	toValue,
	reverse,
	sortToken,
}) => {
	const [isTokenList, setIsTokenList] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	// const newTokenList = useMemo(() => {
	// 	if (sortToken) {
	// 		return getCurrentTokens(pairList, tokenList, value, toValue);
	// 	}

	// 	return tokenList;
	// }, [tokenList, pairList, value, toValue, sortToken]);

	const toggleIsTokenList = useCallback(() => {
		setIsTokenList((state) => !state);
	}, []);

	const handleChainListOpen = () => {
		if (!chainList) return;
		toggleIsTokenList();
	};

	const handleChange = (newValue: TNetworkItem) => {
		onChange(newValue);
		toggleIsTokenList();
	};

	let reverseKey = '';

	if (title?.toLowerCase() === 'from') {
		reverseKey = reverse ? 'from' : 'to';
	} else {
		reverseKey = reverse ? 'to' : 'from';
	}

	const curentChain = `chain_id_${reverseKey === 'from' ? 'to' : 'from'}`;

	const key = `network_${reverseKey}`;
	const keyLog = `network_logo_${reverseKey}`;

	// debugger;

	const newChainList1: any = chainList && chainList;

	const newChainList: any = chainList && chainList[value?.[curentChain]];

	return (
		<div className="exchange-transfer exchange-transfer--mb0">
			<p className="exchange-transfer__text exchange-transfer__text--mr8 exchange-transfer__text--fixed-width">
				{title}
			</p>
			<div className="select select--line">
				<div className="select__current" onClick={handleChainListOpen}>
					<div className="token-block">
						<div className="token-block__icon">
							{/* <img src={value?.[keyLog]} alt="" /> */}
							<img src={value?.network_logo} alt="" />
						</div>
						<div className="token-block__info">
							{/* <p className="token-block__name">{value?.[key]}</p> */}
							<p className="token-block__name">{value?.network_name}</p>
						</div>
					</div>
					<span className="select__current-arrow">
						<TriangularArrowIcon />
					</span>
				</div>
				{/* <div className="select__drop">
					<div className="select__drop-scroll">
						<div className="select-drop__item">
							<ul>
								<li>
									<button type="button">ETH</button>
								</li>
								<li>
									<button type="button">USDT</button>
								</li>
							</ul>
						</div>
					</div>
				</div> */}
				<Modal isOpen={isTokenList} onClose={toggleIsTokenList}>
					<TokenList
						title={title}
						list={newChainList1}
						onChange={handleChange}
						onClose={toggleIsTokenList}
						openManage={() => {
							setOpenModal(true);
						}}
					/>
				</Modal>
			</div>
		</div>
	);
};
