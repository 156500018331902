/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import { FC, useEffect, useMemo, useState, MouseEvent, useCallback, FocusEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { swapApprove } from 'services/utils/swap';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ApproveTokens } from 'services/utils/approve';
import { feeChecker } from 'services/utils/feeChecker';
import { useLocation, useNavigate } from 'react-router';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import { noExponent } from 'services/utils/noExponent';
import { ethers } from 'ethers';
/* eslint-disable */
import { usePrevious } from 'hooks/usePrevious';
import {
	getCalculatedRate,
	getSwapAssetList,
	getSwapPairList,
	getSwapTokenList,
	getCalculatedPerOneRate,
} from 'redux/reducers/swap/selectors';
import {
	calculateRateRequest,
	calculateRatePerOneRequest,
	clearTokenBalances,
	getTokenBalances,
	sendTrancationRequest,
	sendTrancationTokneHashRequest,
} from 'redux/reducers/swap/reducer';
// import { getCoinBalance } from 'services/utils/getTokenBalance';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getSlipping, getNetworkId } from 'redux/reducers/wallets/selectors';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { toMaxDecimals, checkAfterPoint, toDecimalsAfterPoint } from 'services/utils/numbers';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { RefreshIcon, GearIcon, ReverseIcon, QuestionIcon } from 'assets/custom-icons';
import AbiTokens from 'config/abi/GUSD.json';
import AbiToken from 'config/abi/Token.json';
import AbiAdmin from 'config/abi/AdminPayer.json';
import AbiPairs from 'config/abi/GatoswapPair.json';
import AbiFee from 'config/abi/FeeController.json';
import { autoSwapTransaction, getTransactionCostin } from 'services/utils/autoSwap';
import { SettingsModal } from '../Exchange/SettingsModal';
import { ConfirmModal } from './ConfirmModal';
import { IExchangeLocationStateWithObject } from '../Exchange/types';
import { SuccessModal } from './TransactionsModals/succes';
import { ITypeTransaction, IToken } from './type';
import { getCurrentPair, tryInsufficientLiquidity } from './utils';
import {
	getSwapAssetsRequest,
	sendAutoSwapFeeRequset,
	getSwapPairsRequest,
} from 'redux/reducers/swap/reducer';
import { getSwapAssets } from 'redux/reducers/swap/selectors';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import {
	numberInDecimal,
	getInitTokenSymbols,
	getTokenBySymbol,
	getTokensWithoutSymbol,
	getIsTokenPairInList,
	getRateForTokenPair,
	getRateByDirection,
	getRateByDirectionGato,
	getOppositeValue,
	numberOutDecimal,
	initialTokenPair,
} from './utils';

import { TokenInput } from '../Exchange/TokenInput';
import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';

const Web3 = require('web3');

const chainId = String(process.env.REACT_APP_CHAIN_ID);

const RPC_HOST = String(process.env.REACT_APP_RPC_URL);

const ROUTER_CONTRACT = String(process.env.REACT_APP_ROUTER_ADDRESS);
// const ROUTER_CONTRACT = '0x54e621abd4bF9dF2DB1CE038A3aF25B71530C028';
const ADMIN_CONTRACT = String(process.env.REACT_APP_ADMIN_PAYER_CONTRACT);

const SELECTOR_SWAP_EXACT = '0x4336d5e8';

const _web3 = new Web3(RPC_HOST);

const RouterContract = new _web3.eth.Contract(AbiRouter.abi, ROUTER_CONTRACT);

declare let window: any;

export const AutoSwap: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const swapAssets = useSelector(getSwapAssets);
	const location = useLocation();
	const [exchangePage, setExchangePage] = useState(`${location.pathname.split('/')[1]}`);
	const [openModalNetwork, setOpenModalNetwork] = useState(false);
	const { chainId } = useWeb3React<Web3Provider>();
	const { address: addressWeb3, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const assetList = useSelector(getSwapAssetList);
	const tokenListNoCoin = useSelector(getSwapTokenList);
	const tokenList = tokenListNoCoin?.filter(({ type }) => type === 'token');
	const coinGato = assetList?.find(({ type, symbol }) => type === 'coin' && symbol === 'GATO');
	const pairList = useSelector(getSwapPairList);
	const walletAddress = useSelector(getAddress);
	const calculatedRate = useSelector(getCalculatedRate);
	const calculatedPerOneRate = useSelector(getCalculatedPerOneRate);
	const slipping = useSelector(getSlipping);
	const network = useSelector(getNetworkId);
	const tokenRefresh = useRefreshToken();
	const { account, provider } = useWeb3React();

	const prevWalletAddress = usePrevious(walletAddress?.wallet);

	const locationState = location?.state as IExchangeLocationStateWithObject | null;

	const [initFromSymbol, initToSymbol] = getInitTokenSymbols(locationState, assetList);

	const [fromTokenSymbol, setFromTokenSymbol] = useState(initFromSymbol);
	const [toTokenSymbol, setToTokenSymbol] = useState(initToSymbol);
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const [fromDecimalsValue, setFromDecimalsValue] = useState('');
	const [gatoPoolValue, setGatoPoolValue] = useState('');
	const [isLastFromValue, setIsLastFromValue] = useState(true);
	const [isFromPerToRate, setIsFromPerToRate] = useState(true);
	const [amountRecive, setAmountRecive] = useState('');
	const [hash, setHash] = useState('');
	const [loader, setLoader] = useState(false);
	const [disableBtn, setDisableBtn] = useState('');
	const [timer, setTimer] = useState<any>(null);
	const [selectFromWallet, setSelectFromWallet] = useState<any | null>(null);
	const prevFromValue = usePrevious(fromValue);
	const prevToValue = usePrevious(toValue);
	const [feeToken, setFeeToken] = useState('');

	const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

	const fromTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, toTokenSymbol);
	}, [tokenList, toTokenSymbol, pairList]);

	const toTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, fromTokenSymbol);
	}, [tokenList, fromTokenSymbol, pairList]);

	const tryNetwork = network !== String(chainId);

	const fromToken = getTokenBySymbol(fromTokenList, fromTokenSymbol);
	const toToken = getTokenBySymbol(toTokenList, toTokenSymbol);
	const isPairInList = getIsTokenPairInList(fromToken, toToken, pairList);
	const pairRate = getRateForTokenPair(isPairInList, calculatedRate, fromToken, toToken);
	const pairRatePerOne = getRateForTokenPair(
		isPairInList,
		calculatedPerOneRate,
		fromToken,
		toToken,
	);

	const displayedRate = getRateByDirection(isFromPerToRate, pairRatePerOne);
	const gatoRate = getRateByDirectionGato(isFromPerToRate, pairRatePerOne);

	const handleFromValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// if (checkAfterPoint(e.target.value, Number(fromToken?.decimals))) return;
		if (checkAfterPoint(e.target.value, 15)) return;

		setFromValue(e.target.value);

		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		// setIsLastFromValue(true);
	};

	const handleToValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// if (checkAfterPoint(e.target.value, Number(toToken?.decimals))) return;
		if (checkAfterPoint(e.target.value, 15)) return;
		setToValue(e.target.value);
		// setIsLastFromValue(false);
	};

	const disabled = !fromValue || !disableBtn;

	const [currentPercent, setCurrentPercent] = useState(0.25);
	const [openModal, setOpenModal] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openTransactionModal, setOpenTransactionModal] = useState(false);
	const [count, setCount] = useState<number>(0);

	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};
	const handlerConfirm = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenConfirmModal(!openConfirmModal);
	};

	const currentPair = useMemo(() => {
		return getCurrentPair(pairList, fromToken, toToken);
	}, [pairList, fromToken, toToken]);

	const handleMax = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'from') {
			// setIsLastFromValue(true);
			setFromValue(
				// String(toDecimalsAfterPoint(String(fromToken?.balance), Number(fromToken?.decimals))),
				String(toDecimalsAfterPoint(String(fromToken?.balance), 15)),
			);

			return;
		}
		// setIsLastFromValue(false);
		//setToValue(String(toDecimalsAfterPoint(String(toToken?.balance), Number(toToken?.decimals))));
		// setToValue(String(toDecimalsAfterPoint(String(toToken?.balance), 15)));
	};

	useEffect(() => {
		if (!swapAssets) {
			dispatch(
				getSwapAssetsRequest({
					per_page: 100,
					is_active: 1,
				}),
			);
		}
		dispatch(getSwapPairsRequest({ per_page: 1000, lock_swap: 0 }));
	}, [dispatch]); // eslint-disable-line

	// useEffect(() => {
	// 	setDisableBtn(
	// 		String(tryInsufficientLiquidity(currentPair, fromValue, toValue, fromToken, toToken)),
	// 	);
	// }, [currentPair, fromValue, toValue]);

	useEffect(() => {
		if (prevWalletAddress !== walletAddress.wallet) {
			dispatch(clearTokenBalances());
		}
		dispatch(getTokenBalances(''));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [walletAddress.wallet, assetList, dispatch]); // eslint-disable-line

	const updatePrice = () => {
		if (fromToken?.address && toToken?.address && fromValue) {
			const payload = {
				firstAddress: fromToken.address,
				secondAddress: toToken.address,
				amount: fromValue,
			};
			dispatch(calculateRateRequest(payload));
		}
	};

	useEffect(() => {
		if (fromToken?.address && toToken?.address) {
			const payload = {
				firstAddress: isLastFromValue ? fromToken.address : toToken.address,
				secondAddress: isLastFromValue ? toToken.address : fromToken.address,
				amount: '1',
			};
			dispatch(calculateRatePerOneRequest(payload));
		}
	}, [dispatch, isFromPerToRate, fromToken?.address, toToken?.address]);

	const getFeeForToken = async (data: any, rate: any) => {
		const feeCost = await getTransactionCostin(data);
		const feeSum = (Number(fromValue) - Number(feeCost)) * Number(rate);
		feeCost?.formatTokenFee ? setDisableBtn('true') : setDisableBtn('');

		setToValue(String(feeCost?.formatTokenFee));
		setFeeToken(String(feeCost?.formatSwapFee));
	};

	useEffect(() => {
		if (!fromValue || !walletAddress?.wallet) return;
		const amountInWithDecimals = numberInDecimal(
			Number(fromValue),
			Number(fromToken?.decimals),
		).toString();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;

		const feeCostTokenParams: any = {
			wallet: walletAddress?.wallet,
			abi: AbiToken.abi,
			amount: String(noExponent(amountInWithDecimals)),
			abiAdmin: AbiAdmin.abi,
			tokenAddress: String(fromToken?.address), // Address
			adminContract: String(ADMIN_CONTRACT), // Address contract
			provider: currentProvider,
			setDisableBtn,
		};

		const newTypingTimeout: NodeJS.Timeout = setTimeout(() => {
			getFeeForToken(feeCostTokenParams, gatoRate);
		}, 700);

		setTypingTimeout(newTypingTimeout);
	}, [fromValue, gatoRate]);

	useEffect(() => {
		return (): void => {
			if (typingTimeout) {
				clearTimeout(typingTimeout);
			}
		};
	}, [typingTimeout]);

	// const getGatoPoolBalance = async () => {
	// 	const gatoBalanceInContarct = await getCoinBalance(
	// 		String('0xb1B8AEEfA74D601BEf9ADD86aaB9E524B6411055'),
	// 	);
	// 	setGatoPoolValue(String(gatoBalanceInContarct));
	// };

	useEffect(() => {
		if (fromToken && toToken) return;

		const initFromToken = getTokenBySymbol(fromTokenList, initFromSymbol);
		const initToToken = getTokenBySymbol(toTokenList, initToSymbol);

		const fromTokenSet =
			!toToken && initToToken ? getTokensWithoutSymbol(fromTokenList, initToSymbol) : fromTokenList;

		let toTokenSet = toTokenList;

		// const newFromSymbol = initFromToken ? initFromSymbol : initialTokenPair(pairList, fromTokenSet);
		const newFromSymbol = initFromToken ? initFromSymbol : fromTokenSet?.[0];

		if (!fromToken && newFromSymbol) {
			setFromTokenSymbol(newFromSymbol);
			toTokenSet = getTokensWithoutSymbol(toTokenSet, newFromSymbol);
		}

		// const newToSymbol = initToToken
		// 	? initToSymbol
		// 	: initialTokenPair(pairList, toTokenSet, newFromSymbol);
		const newToSymbol = initToToken;
		// if (walletAddress?.account) {
		// getGatoPoolBalance();
		// }

		if (!toToken && newToSymbol) {
			setToTokenSymbol(newToSymbol);
		}
	}, [
		fromTokenList,
		fromToken,
		toTokenList,
		toToken,
		initFromSymbol,
		initToSymbol,
		// walletAddress?.account,
	]);

	const countFrom = (value: number) => {
		setIsLastFromValue(true);

		if (value === 0) {
			notificationContainer(`Not enough balance`, 'error');
			return;
		}
		//setFromValue(String(toDecimalsAfterPoint(value, Number(fromToken?.decimals))));
		setFromValue(toMaxDecimals(String(value), 15));
	};
	const countTo = (value: number) => {
		setToValue(toMaxDecimals(String(value), 12));

		// setFromValue(String(value));
	};

	const percentButtonCountValue = (percentValue: number): number => {
		// if (!toMaxDecimals(String(fromToken?.balance), 6) || !Number(percentValue)) {
		// 	return 0;
		// }
		if (!toMaxDecimals(String(fromToken?.balance), 15) || !Number(percentValue)) {
			return 0;
		}

		return Number(fromToken?.balance) * percentValue;
	};

	const handleSwap = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		try {
			let currentAddress = connector === 'INJECTED' ? account : addressWeb3;
			let accounts = [] || '';

			// 4286

			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			const amountInWithDecimals = numberInDecimal(
				Number(fromValue),
				Number(fromToken?.decimals),
			).toString();

			const autoSwap: any = {
				wallet: currentAddress,
				amount: String(noExponent(amountInWithDecimals)),
				abi: AbiToken.abi,
				abiAdmin: AbiAdmin.abi,
				tokenName: String(fromToken?.name),
				tokenAddress: String(fromToken?.address), // Address
				adminContract: String(ADMIN_CONTRACT), // Address contract
				provider: currentProvider,
				setAmountRecive,
				setLoader,
			};

			const hashData = await autoSwapTransaction(autoSwap);

			if (hashData) {
				dispatch(
					sendAutoSwapFeeRequset({
						params: { ...hashData },
						setHash: (has: string) => {
							setHash(has);
							setLoader(false);
							setOpenTransactionModal(true);
							setFromValue('');
							setToValue('');
						},
					}),
				);

				return;
			}
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
			setFromValue('');
			setToValue('');
			setOpenTransactionModal(true);
		}
	};

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="general-block general-block--center">
						<div className="block-header">
							<h4 className="general-block-title">Auto Exchange</h4>
						</div>

						<div className="block-content">
							<div className="exchange-item">
								<TokenInput
									tokenList={fromTokenList}
									value={fromToken}
									toValue={toToken}
									pairList={pairList}
									onChange={(token) => setFromTokenSymbol(token)}
									title="From"
									sortToken={true}
								/>

								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												className="input-item input-item--exchange input-item--get-info"
												value={noExponent(fromValue)}
												onChange={handleFromValueChange}
												type="number"
												min="0"
												onFocus={handleInputFocus}
												placeholder="0"
											/>
											<button
												onClick={handleMax}
												name="from"
												type="button"
												className="exchange-max-btn"
											>
												MAX
											</button>
										</div>
									</label>
								</div>
							</div>

							<div className="exchange-transfer">
								<p className="exchange-transfer__text">
									Availability: {toMaxDecimals(String(fromToken?.balance || 0), 8)}{' '}
									{fromToken?.symbol}
								</p>
							</div>

							<div className="exchange-item">
								<div className="select select--exchange">
									<div className="select__current">
										<div className="token-block">
											<div className="token-block__icon">
												{coinGato?.logo && <img src={coinGato.logo} alt="" />}
											</div>
											<div className="token-block__info">
												<p className="token-block__purpose">To</p>
												{coinGato?.symbol && <p className="token-block__name">{coinGato.symbol}</p>}
											</div>
										</div>
									</div>
								</div>

								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												className="input-item input-item--exchange"
												value={noExponent(toValue)}
												disabled
												type="number"
												onFocus={handleInputFocus}
												placeholder="0"
											/>
										</div>
									</label>
									{/* <p className="available-in-pool">
										Availability In Pool: {noExponent(gatoPoolValue) || 0} {coinGato?.symbol}
									</p> */}
								</div>
							</div>

							{/* <div className="exchange-transfer">
								<p className="exchange-transfer__text">
									Availability: {toMaxDecimals(String(toToken?.balance || 0), 15)} {toToken?.symbol}
								</p>
							</div> */}

							<div className="operation-info">
								{displayedRate && (
									<>
										<p className="operation-info__text operation-info__text--bold operation-info__text--fs14">
											Price
										</p>
										<p className="operation-info__number operation-info__number--flex operation-info__number--fs14">
											{/* {isFromPerToRate?(displayedRate/(10**6)).toFixed(8):(displayedRate*(10**6)).toFixed(8)||0}{' '} */}
											{displayedRate.toFixed(12) || 0}{' '}
											<span>
												{isFromPerToRate ? fromToken?.symbol : toToken?.symbol} per{' '}
												{isFromPerToRate ? toToken?.symbol : fromToken?.symbol}
											</span>
											<button
												className="operation-info__icon"
												type="button"
												onClick={() => setIsFromPerToRate((state) => !state)}
											>
												<RefreshIcon />
											</button>
										</p>
									</>
								)}
							</div>
						</div>

						<div className="block-footer">
							{prevWalletAddress ? (
								<button
									disabled={Boolean(disabled) || tryNetwork}
									onClick={handlerConfirm}
									type="button"
									className="button button--big-height button--full-width"
								>
									{fromValue ? <span>Send transaction</span> : 'Enter an amount'}
								</button>
							) : (
								<ConnectWalletButton />
							)}
						</div>
						<SettingsModal openModal={openModal} setOpenModal={setOpenModal} slipping={slipping} />
						<ConfirmModal
							openModal={openConfirmModal}
							setOpenModal={setOpenConfirmModal}
							itemIn={fromToken}
							fromValue={fromValue}
							feeToken={feeToken}
							itemOut={coinGato}
							toValue={toValue}
							displayedRate={displayedRate ? convertExponentialToDecimal(displayedRate) : 0}
							aproveSwap={handleSwap}
							updatePrice={updatePrice}
							slipping={slipping}
							loader={loader}
						/>
						<SuccessModal
							openModal={openTransactionModal}
							setOpenModal={setOpenTransactionModal}
							loader={loader}
							hash={hash}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
