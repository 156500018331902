import { Page } from 'layouts/page';
import { FC } from 'react';
import { TransferForm } from 'components/Exchange/TransferForm';

export const TransferPage: FC = () => {
	return (
		<Page title="Gato Transfer" text="Gato Transfer">
			<TransferForm />
		</Page>
	);
};
