import { createSelector } from '@reduxjs/toolkit';
import { getLS } from 'components/Exchange/LiquidityForm/utils';
import { TStoreState } from 'redux/types';
import { TToken } from 'redux/reducers/coins/types';
import { IApiSwapAsset } from 'services/api/swap/types';

import { IBridgeStore } from './types';

export const getBridge = (state: TStoreState): IBridgeStore => state.bridge;

// 📌 Assets
/* eslint-disable no-debugger */

export const getNetworkPair = (state: TStoreState) => {
	return getBridge(state).assetsPair;
};

export const getNetworks = (state: TStoreState) => {
	return getBridge(state).networks;
};

// 📌 Token balances

export const getBridgeBalances = (state: TStoreState) => {
	return getBridge(state).balances;
};

export const getBridgeHistory = (state: TStoreState) => {
	return getBridge(state).history;
};
export const getBridgeHistoryLoading = (state: TStoreState) => {
	return getBridge(state).historyLoading;
};

// export const getNetworkTokenBalanseList = createSelector(
// 	[getNetworkPair, getBridgeBalances],
// 	(assetList, balances): any => {
// 		return assetList?.map((asset) => {
// 			let checkFromto = '';
// 			debugger;
// 			const swapBalance = balances.find(({ tokenAddress }) => {
// 				if (tokenAddress === asset.asset_from_address) {
// 					checkFromto = 'from';
// 				}
// 				if (tokenAddress === asset.asset_to_address) {
// 					checkFromto = 'to';
// 				}
// 				return tokenAddress === asset.asset_from_address || tokenAddress === asset.asset_to_address;
// 			});

// 			if (swapBalance) {
// 				const { balance } = swapBalance;
// 				return { ...asset, [`balance_${checkFromto}`]: balance };
// 			}
// 			return asset;
// 		});
// 	},
// );
