import { FC, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import rubin from 'assets/images/icon-avatar-grey.svg';
import { useSelector, useDispatch } from 'react-redux';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';

export const AuthHeader: FC = () => {
	const user = useSelector(getUserSettingsData);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(userSettingsRequest());
	}, [dispatch]);
	return (
		<div className="header-right">
			<div className="autorized">
				<NavLink to="/profile" className="avatar-box">
					<img className="avatar-img" src={user?.data?.avatar_path || loginAvatar} alt="avatar" />
					<span className="avatar-icon">
						<img src={rubin} alt="" />
					</span>
				</NavLink>
			</div>
		</div>
	);
};
