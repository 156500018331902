import React, { FC, useMemo, useState } from 'react';

import { EmptyTable } from 'layouts-elements/EmptyTable';
import { ILog } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';
import LogTabRow from './LogTabRow';

interface ILogTabProps {
	logList: ILog[] | undefined;
}

const LogTab: FC<ILogTabProps> = ({ logList }) => {
	// const [currentLogs, setCurrentLogs] = useState<ILog[] | null>(null);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const perPage = 1;

	const currentLogs = useMemo(() => {
		if (!logList) return [];
		setItemOffset((currentPage - 1) * perPage);
		const endOffset = itemOffset + perPage;
		setPageCount(Math.ceil(logList.length / perPage));
		return logList.slice(itemOffset, endOffset);
	}, [currentPage, itemOffset, logList]);

	return (
		<>
			<div className="explore-block__top ">
				<h4 className="block-title block-title--mr-auto">Logs</h4>
				{!!currentLogs?.length && (
					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={pageCount}
						setCurrentPage={setCurrentPage}
					/>
				)}
			</div>
			{currentLogs?.length ? (
				currentLogs.map((el) => <LogTabRow key={el.id} {...el} />)
			) : (
				<EmptyTable text="No Recent Logs Yet" />
			)}
			{!!currentLogs?.length && (
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={pageCount}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</>
	);
};

export default LogTab;
