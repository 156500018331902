/* eslint-disable */
import { Web3ReactProvider } from '@web3-react/core';
import React, { ReactNode, useEffect } from 'react';
import { PRIORITIZED_CONNECTORS } from 'services/utils/connections';
import { useEagerlyConnect } from './hooks';

export const Web3ContextProvider = ({ children }: { children: ReactNode }) => {
	useEagerlyConnect();

	const conect: any = Object.values(PRIORITIZED_CONNECTORS).map((connector) => [
		connector.connector,
		connector.hooks,
	]);
	return <Web3ReactProvider connectors={conect}>{children}</Web3ReactProvider>;
};

