import { FC } from 'react';

import { PageProfile } from 'layouts/page/Profile';
import { HelpAndSupport } from 'components/HelpAndSupport';

export const HelpAndSupportPage: FC = () => {
	return (
		<PageProfile title="Help & Support" text="Help & Support" link="/profile">
			<HelpAndSupport />
		</PageProfile>
	);
};
