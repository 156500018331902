import React, { useEffect, useMemo, useState } from 'react';
import { Routes, useNavigate } from 'react-router-dom';
import { Route, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenRequest } from 'redux/reducers/token/reducer';
import { getTokenData, getTokenLoading } from 'redux/reducers/token/selectors';
import TokenDetailedHeader from './TokenDetailedHeader/TokenDetailedHeader';
import { Tabs } from './TokenDetailedTubs/Tabs/Tabs';
import TokenDetailedTabTransfers from './TokenDetailedTubs/TokenDetailedTabTransfers/TokenDetailedTabTransfers';
import TokenDetailedTabHolders from './TokenDetailedTubs/TokenDetailedTabHolders/TokenDetailedTabHolders';
import Loader from '../../ui/Loader';

const TokenDetailed = () => {
	const navigate = useNavigate();
	const data = useSelector(getTokenData);
	const loading = useSelector(getTokenLoading);
	const location = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		if (location.pathname.split('/')[4] === undefined) {
			navigate(`${location.pathname}/transfers`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const hash = location.pathname.split('/')[3];

	useEffect(() => {
		dispatch(
			getTokenRequest({
				id: hash,
			}),
		);
	}, [dispatch, hash]);
	return (
		<section className="explore-home-section">
			<div className="container">
				{data ? <TokenDetailedHeader data={data} /> : null}
				{loading ? (
					<div style={{ position: 'relative' }} className="table-loader-wrapper">
						<Loader />
					</div>
				) : null}
				<div className="explore-block explore-block--p0">
					<Tabs />
					<Routes>
						<Route path="/transfers" element={<TokenDetailedTabTransfers />} />
						<Route path="/holders" element={<TokenDetailedTabHolders />} />
					</Routes>
				</div>
			</div>
		</section>
	);
};

export default TokenDetailed;
