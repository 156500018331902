import React, { FC, memo } from 'react';
import { IInternalTransaction } from 'services/api/blockchain/types';
import InternalTransactionRow from './InternalTransactionRow';

interface IInternalTransactionsBlockProps {
	itemsList: IInternalTransaction[];
}

const InternalTransactionsBlock: FC<IInternalTransactionsBlockProps> = ({ itemsList }) => {
	return (
		<div className="explore-blocks">
			{itemsList.map((item) => (
				<InternalTransactionRow key={item.block_id} {...item} />
			))}
		</div>
	);
};

export default memo(InternalTransactionsBlock);
