import { FC, ChangeEvent } from 'react';
import { TItemProps } from './type';

export const LanguageItem: FC<TItemProps> = ({ checked, langItem, setChecked }) => {
	const { image, text } = langItem;
	const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		setChecked(value);
	};
	return (
		<li className="language-list__item">
			<label className="language-list__label">
				<div className="language-list__icon">
					<img src={image} alt={text} />
				</div>
				<p className="language-list__name">{text}</p>
				<input
					onChange={handleClick}
					className="hidden"
					type="radio"
					name="language"
					value={checked}
					defaultChecked={text === 'English'}
				/>
				<span className="language-list__radio" />
			</label>
		</li>
	);
};
