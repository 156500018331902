import { FC } from 'react';
import { Content } from 'layouts/Content';
import { RunningStakes } from 'components/RunningStakes';

export const RunningStakesPage: FC = () => {
	return (
		<Content title="Gato Staking Program" text="Gato Staking Program" link="/">
			<RunningStakes />
		</Content>
	);
};
