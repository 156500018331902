import { FC } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Footer from 'layouts/Footer';
import { Header } from 'layouts-elements/Header';
import { getTheme } from 'redux/reducers/settings/selectors';
import { TopBlock } from 'layouts-elements/TopBlock';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import { TPageProps } from './types';

// ==========================================:
export const Content: FC<TPageProps> = ({ children, title, link, text, history }) => {
	const pageTitle = title ? `| ${title}` : '';
	const pageText = text || '';
	const pageLink = link || '';

	const theme = useSelector(getTheme);

	return (
		<>
			{/* <Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet> */}
			<div className={`wrapper ${theme}`}>
				<Header />
				{pageText ? <TopBlock text={pageText} link={pageLink} history={history} /> : ''}
				<main className="main">{children}</main>
				<Footer />
			</div>
		</>
	);
};
