import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';

const FEE_CONTRACT_ADDRESS = String(process.env.REACT_APP_FEE_CONTRACT_ADDRESS);
/* eslint-disable */

export const feeChecker = async ({
	wallet,
	amount,
	abi,
	selector,
	contractAddess,
	connector,
	provider,
	tokenAddress,
	setLoader,
}: any) => {
	try {
		// setLoader(true);

		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, FEE_CONTRACT_ADDRESS); // адресс не важен

		const approveFee = await contract.methods
			.getFeeAmountPerFunctionCall(String(selector), tokenAddress, String(contractAddess))
			.call();

		let result = approveFee;

		// notificationContainer(`Token ${String(tokenName)} was approved`, 'success');

		// setLoader(false);

		return result;
	} catch (error: any) {
		// setLoader(false);
		notificationContainer(`Approve failed: ${String(error?.message)}`, 'error');
	}
};
