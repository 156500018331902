import { Dividends } from 'components/Dividends';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const DividendsPage: FC = () => {
	return (
		<Content title="Earnings & Rewards" text="Earnings & Rewards" link="/">
			<Dividends />
		</Content>
	);
};
