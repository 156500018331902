import Transaction from 'components/Transactions/Transaction';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const TransactionPage: FC = () => {
	return (
		<Page title="Blocks">
			<Transaction />
		</Page>
	);
};
