import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSwapAssetsRequest, getSwapPairsRequest } from 'redux/reducers/swap/reducer';
import { getSwapAssets } from 'redux/reducers/swap/selectors';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { NetworkModal } from 'components/Exchange/NetworkModal';
import classNames from 'classnames';
import { getTokenStatus } from 'redux/reducers/coins/selectors';
import { getNetworkId } from 'redux/reducers/wallets/selectors';
import { NavPanel } from './NavPanel';
import { LiquidityForm } from './LiquidityForm';
import { Pool } from './Pool';
import { ExchangeForm } from './ExchangeForm';
import { SuccessModal } from './ManageModal/TokensModals/succes';
/* eslint-disable no-debugger */

const chainIdEnv = String(process.env.REACT_APP_CHAIN_ID);

export const Exchange: FC = () => {
	const dispatch = useDispatch();
	const swapAssets = useSelector(getSwapAssets);
	const network = useSelector(getNetworkId);
	const status = useSelector(getTokenStatus);
	const location = useLocation();
	const [exchangePage, setExchangePage] = useState(`${location.pathname.split('/')[1]}`);
	const [openModal, setOpenModal] = useState(false);
	const [openModalNetwork, setOpenModalNetwork] = useState(false);
	const { chainId } = useWeb3React<Web3Provider>();

	const locationLink = location.pathname.split('/')[1];

	// useEffect(() => {
	// 	if (chainId && String(chainId) !== chainIdEnv) {
	// 		setOpenModalNetwork(true);
	// 		return;
	// 	}
	// 	setOpenModalNetwork(false);
	// }, [chainId]);
	useEffect(() => {
		if (network !== chainIdEnv) {
			setOpenModalNetwork(true);
			return;
		}
		setOpenModalNetwork(false);
	}, [network, chainId]);

	useEffect(() => {
		if (status) {
			setOpenModal(true);
		}
	}, [status]);

	useEffect(() => {
		setExchangePage(locationLink);
	}, [locationLink]);

	useEffect(() => {
		if (!swapAssets) {
			dispatch(
				getSwapAssetsRequest({
					per_page: 100,
					is_active: 1,
				}),
			);
		}
		// if (locationLink === 'liquidity') {
		// 	dispatch(
		// 		getSwapAssetsRequest({
		// 			per_page: 100,
		// 		}),
		// 	);
		// } else {
		// 	dispatch(
		// 		getSwapAssetsRequest({
		// 			per_page: 100,
		// 			is_active: 1,
		// 		}),
		// 	);
		// }
		if (locationLink === 'exchange') {
			dispatch(getSwapPairsRequest({ per_page: 1000, lock_swap: 0 }));
			return;
		}
		dispatch(getSwapPairsRequest({ per_page: 1000, lock_add_liquidity: 0 }));
		// dispatch(getSwapPairsRequest({ per_page: 1000 }));
	}, [dispatch, locationLink]); // eslint-disable-line

	const currentNav: any = {
		exchange: <ExchangeForm />,
		pool: <Pool />,
		liquidity: <LiquidityForm />,
	};

	return (
		<section
			className={`general-section ${exchangePage === 'liquidity' ? 'general-section--pool' : ''}`}
		>
			<div className="container">
				<div
					className={classNames('inner-block', {
						'inner-block--bigger': exchangePage === 'pool-token',
					})}
				>
					<NavPanel linkName={exchangePage} />
					{currentNav[exchangePage]}
				</div>
			</div>
			{/* <NetworkModal openModal={openModalNetwork} setOpenModal={setOpenModalNetwork} /> */}
			<SuccessModal openModal={openModal} setOpenModal={setOpenModal} status={status} />
		</section>
	);
};
