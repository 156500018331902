import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransaction } from 'components/StakeIt/types';
import { numberInDecimal } from 'components/Exchange/LiquidityForm/utils';
import { noExponent } from './noExponent';

// const STAKE_CONTRACT_ADDRESS = '0xB4739787F92Eaf215281D6eadca2DCdBFF0F2055';
// const STAKE_CONTRACT_ADDRESS = '0xa0cc42dd452dc79d8b6198466c770f3c94ff15b2';
// const STAKE_CONTRACT_ADDRESS = '0xdd4445A0c480Fad46a345403af6984d15788be5C';
// const STAKE_CONTRACT_ADDRESS = '0xe246d191ffa1641a3f46db2b8e06eccf8e3af1ee';
const STAKE_CONTRACT_ADDRESS = '0xB24DD6821c90DC63653aB2B78C5821e5a84eD7F3';
// const STAKE_CONTRACT_ADDRESS = String(process.env.STAKE_CONTRACT_ADDRESS);

/* eslint-disable */
export const stakingApprove = async ({
	wallet,
	abi,
	amount,
	connector,
	contractAddress,
	setLoader,
	lockPeriod,
	amountFee,
	provider,
}: ITypeTransaction) => {
	try {
		setLoader(true);

		const web3 = new Web3(provider);
		const formatAmount = String(
			noExponent(
				Math.floor(Number(numberInDecimal(Number(amount), 18).toString())) + Number(amountFee),
			),
		);

		const contract = new web3.eth.Contract(abi, contractAddress);

		const data = await contract.methods.stake(lockPeriod).encodeABI();

		let result;

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				value: formatAmount,
				to: contractAddress,
				data,
			});
		}

		notificationContainer('Stake done', 'success');
		setLoader(false);
		return result;
	} catch (error: any) {
		setLoader(false);
		notificationContainer(`Stake failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};

export const claimApprove = async ({
	wallet,
	abi,
	amount,
	lockPeriod,
	connector,
	amountFee,
	provider,
	contractAddress,
	setLoader,
}: ITypeTransaction) => {
	try {
		setLoader(true);
		const web3 = new Web3(provider);
		debugger;
		const formatAmount = String(
			noExponent(Math.floor(Number(numberInDecimal(Number(amount), 18).toString()))),
		);

		const contract = new web3.eth.Contract(abi, contractAddress);

		const data = await contract.methods
			.claimCoinRewardByLockPeriod(lockPeriod, formatAmount)
			.encodeABI();

		let result;

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				value: amountFee,
				to: contractAddress,
				data,
			});
		}

		notificationContainer('Claim done', 'success');
		setLoader(false);
		return result;
	} catch (error: any) {
		setLoader(false);
		debugger;
		notificationContainer(`Stake failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};

export const getSelector = async ({ abi, to, provider }: any) => {
	try {
		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, to);

		const result = await contract.methods.SELECTOR_STAKE().call();

		return result;
	} catch (error: any) {
		notificationContainer(`Selector failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};
