// Important: decimals must be >= 1
export const toMaxDecimals = (value: number | string, decimals: number) => {
	const result = Number(value)
		.toFixed(decimals)
		.replace(/\.?0+$/, '');
	return result;
};
export const checkAfterPoint = (value: number | string, decimals: number) => {
	const matchChar = String(value).match(/[,]/);
	if (matchChar) {
		if (String(value).split(`${matchChar[0]}`)[1].length > decimals) {
			return true;
		}
	}
	return false;
};
export const toDecimalsAfterPoint = (value: number | string, decimals: number) => {
	const matchChar = String(value).match(/[,\\.]/);
	if (matchChar && String(value).split(`${matchChar[0]}`)[1].length > decimals) {
		const decimal = String(value).split(`${matchChar[0]}`)[1].slice(0, decimals);
		const newarr = [String(value).split(`${matchChar[0]}`)[0], String(decimal)];
		const newString = newarr.join('.');
		return newString;
	}
	return value;
};

export const numberExists = (value: number | string | null | undefined) => {
	return !!value || value === 0;
};

export const bigNumberFormater = (num: string | number): string => {
	const endsWithDecimal = num.toString().endsWith('.');
	const n = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 12,
	}).format(Number(num));
	const formattedNum = endsWithDecimal ? `${n}.` : n;
	if (Number(formattedNum) === 0) return '';

	return formattedNum;
};

export const revertBigNumberFormatter = (formattedNum: string): string => {
	// Remove any non-numeric characters (like commas) from the string
	let cleanedNum = formattedNum.replace(/[^0-9.-]/g, '');
	const parts = cleanedNum.split('.');
	if (parts.length > 2) {
		// Reassemble the string with only the first decimal point
		cleanedNum = `${parts[0]}.${parts.slice(1).join('').replace(/\./g, '')}`;
	}

	// Return the cleaned string
	return cleanedNum;
	// Convert the cleaned string to a number

	return cleanedNum;
};
