import { FC } from 'react';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import { TRefMemeber } from 'redux/reducers/settings/types';
import { TTableItem } from './type';

export const TableItem: FC<TTableItem> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Level</p>
				<div className="users-block">
					<p>{item.level}</p>
					<div className="users">
						{item?.referrals?.length
							? item.referrals.map((member: TRefMemeber, id) => {
									return id <= 4 ? (
										<div key={`${member.avatar}23${+1}`} className="users__item">
											<img src={member?.avatar ? member?.avatar : loginAvatar} alt="" />
										</div>
									) : null;
							  })
							: null}
						{item.referrals.length > 4 ? (
							<span className="users__count">{item.referrals.length - 5}+</span>
						) : null}
					</div>
				</div>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Total members</p>
				<p>{item.total}</p>
			</div>
		</div>
	);
};
