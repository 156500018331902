import { FC, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Loader from 'ui/Loader';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getUserBalanceRequest } from 'redux/reducers/wallets/reducer';

import { getUserBalanse, getWalletLoader } from 'redux/reducers/wallets/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getTLVRequest } from 'redux/reducers/swap/reducer';
import { getToken } from 'redux/reducers/swap/selectors';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import classNames from 'classnames';
import { ArrowUp } from 'assets/custom-icons';
import ChartBlockWallet from 'components/ChartBlockWallet';
import { TCryptoState } from './type';

export const CryptoItem: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const address = useSelector(getAddress);
	const userBalanse = useSelector(getUserBalanse);
	const walletLoader = useSelector(getWalletLoader);
	const token = location?.state as TCryptoState;
	const tlv = useSelector(getToken);

	const moneyFormat = useMoneyFormat();

	useEffect(() => {
		if (token && address.wallet) {
			dispatch(
				getUserBalanceRequest({
					token: String(token?.asset_id?.toLocaleLowerCase()),
					address: address?.wallet?.toLocaleLowerCase(),
				}),
			);
		}
	}, [dispatch, token, address]);

	useEffect(() => {
		if (userBalanse) {
			dispatch(getTLVRequest(userBalanse.balance[0].asset.id));
		}
	}, [dispatch, userBalanse]);

	return (
		<section className="earning-section earning-section--type2">
			<div className="container">
				{walletLoader ? (
					<div className="table-loader-wrapper">
						<Loader />
					</div>
				) : (
					<>
						<div className="earning-content">
							<div className="earning-block">
								<div className="token-box-wrapper">
									<div className="token-box">
										<div className="token-box__icon-4">
											<img
												className="token-box__icon-1 avatar--round"
												src={userBalanse?.balance[0]?.asset.logo}
												alt=""
											/>
										</div>
										<div className="token-box__info">
											<div className="token-box__name">
												{userBalanse?.balance[0]?.asset?.symbol}
											</div>
											<p className="token-box__text">{userBalanse?.balance[0]?.asset?.name}</p>
										</div>
									</div>
									<div className="token-box">
										<div className="token-box__info">
											<div className="token-box__name token-box__name--bigger">
												{convertExponentialToDecimal(Number(userBalanse?.balance[0]?.balance)) || 0}
											</div>
											<p className="token-box__text token-box__text--right">
												{convertExponentialToDecimal(
													Number(userBalanse?.balance[0]?.amount_in_usd),
												) || 0}{' '}
												$
											</p>
										</div>
									</div>
								</div>
								<h4 className="earning-block-title">My total earnings:</h4>
								<div className="earning-stats earning-stats--divident-balance">
									<div className="earning-stats__item">
										<div className="earning-stats__name">
											<p>In Staking</p>
										</div>
										<div className="earning-stats__number earning-stats__number--mob-column earning-stats__number--type2">
											0<span className="earning-stats__procent">≈ $0</span>
										</div>
									</div>
									<div className="earning-stats__item">
										<div className="earning-stats__name">
											<p>Reward</p>
										</div>
										<div className="earning-stats__number earning-stats__number--mob-column earning-stats__number--type2">
											{convertExponentialToDecimal(Number(userBalanse?.balance[0]?.balance)) || 0}
											<span className="earning-stats__procent">
												≈ $
												{convertExponentialToDecimal(
													Number(userBalanse?.balance[0]?.amount_in_usd),
												) || 0}
											</span>
										</div>
									</div>
								</div>
								<div className="gato-buttons">
									<NavLink to="/exchange" className="button button--full-width">
										Buy
									</NavLink>
									<NavLink to="#" className="button button--type3 button--full-width ">
										Transfer
									</NavLink>
									<NavLink to="/staking" className="button button--type3 button--full-width">
										Stake
									</NavLink>
								</div>
								{/* <!-- <div class="buy-gato-btn">
		<button class="button button--full-width button--big-height">Buy Gato</button>
	  </div> --> */}
							</div>
						</div>

						<ChartBlockWallet asset_address={userBalanse?.balance[0].asset_id} type="swap" />
						<ChartBlockWallet asset_address={userBalanse?.balance[0].asset_id} type="price" />
					</>
				)}
			</div>
		</section>
	);
};
