import GB from 'assets/images/flags/GB.png';
import DE from 'assets/images/flags/DE.png';
import FR from 'assets/images/flags/FR.png';
import IT from 'assets/images/flags/IT.png';
import ES from 'assets/images/flags/ES.png';

export type TItem = {
	image: string;
	text: string;
};

export const arrItem: Array<TItem> = [
	{ image: GB, text: 'English' },
	{ image: DE, text: 'German' },
	{ image: FR, text: 'French' },
	{ image: IT, text: 'Italian' },
	{ image: ES, text: 'Spanish' },
];
