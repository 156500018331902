/* eslint-disable */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLS, getLS } from 'components/Exchange/ExchangeForm/utils';
import { setNetwork } from 'redux/reducers/wallets/reducer';
import { setupNetwork } from 'services/utils/wallets';
import { useSwitchNetwork, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

declare let window: any;

const useNetworkChange = () => {
	const { switchNetwork } = useSwitchNetwork();
	const { chainId } = useWeb3React<Web3Provider>();
	const { isConnected } = useWeb3ModalAccount();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');
	const dispatch = useDispatch();
	const chainIdStorage = getLS('chainId');

	if (window.ethereum) {
		window.ethereum?.on('chainChanged', function (networkId: any) {
			dispatch(setNetwork(String(networkId)));
			setLS('chainId', networkId);
			return;
		});
	}

	if (chainIdStorage) {
		dispatch(setNetwork(String(chainIdStorage)));
	}
	useEffect(() => {
		if (chainId && chainId !== 4287) {
			try{
				switchNetwork(4287);
			} catch(err) {
				
			}
		}
	}, [isConnected, chainId]);
};

export default useNetworkChange;
