import { FC, useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { TPayload } from 'components/Wallets/FilterModal/type';
import { tryOnEmpty } from 'services/utils/custom';
import { getRecentLoader, getUserTransaction } from 'redux/reducers/wallets/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { getHistoryTranasactionRequest } from 'redux/reducers/wallets/reducer';
import { TUserTransactionItem } from 'redux/reducers/wallets/types';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'ui/Loader';
import ModalCalendar from 'ui/Popups/ModalCalendar';
import PaginationTable from 'ui/PaginationTable';
import { TRecent } from './type';
import { RItem } from './RItem';
import { FilterModal } from '../FilterModal';
/* eslint-disable no-debugger */
export const RecentTransactions: FC<TRecent> = ({ handleOpen }) => {
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const userTransaction = useSelector(getUserTransaction);
	const recentLoader = useSelector(getRecentLoader);
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);

	const [filter, setFiltered] = useState<any>({ status: '', coin: '', dateFrom: '', dateTo: '' });
	// const totalPages = balances.last_page || 1;
	const [currentPage, setCurrentPage] = useState<number>(1);

	const coinsList = useMemo(() => {
		if (!userTransaction?.data.length) {
			return [];
		}

		const coinsBaseQuote = userTransaction?.data.reduce(
			(coin: any, ccoin: TUserTransactionItem) => {
				return [...coin, ccoin.base_asset, ccoin.quote_asset];
			},
			[],
		);
		const coins = coinsBaseQuote.reduce((o, i) => {
			if (!o.find((v: any) => v?.address === i?.address)) {
				o.push(i);
			}
			return o;
		}, []);
		const filetered = coins.filter((item: any) => item?.address);
		return [{ id: 0, name: 'All' }, ...filetered];
	}, [userTransaction]);

	const handleOpenModal = () => {
		setOpenModal(!openModal);
	};

	useEffect(() => {
		if (isAuthenticated) {
			const payload = tryOnEmpty({ ...filter, coin: filter.coin === 0 ? '' : filter.coin });
			dispatch(
				getHistoryTranasactionRequest({
					...payload,
					current_page: String(currentPage),
					per_page: 15,
				}),
			);
		}
	}, [dispatch, isAuthenticated, filter, currentPage]);
	return (
		<>
			<div className="table-panel">
				<div className="table-panel__title">
					<NavLink to="/wallets" state="" className="icon-btn">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.57 5.93005L3.5 12.0001L9.57 18.0701"
								stroke="#8E8EA9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M20.5019 12H3.67188"
								stroke="#8E8EA9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</NavLink>
					<h3 className="block-title block-title--smaller ">Recent Transactions</h3>
				</div>
				<div className="filter-right-block">
					<button
						onClick={handleOpenModal}
						type="button"
						className="icon-btn icon-btn--ml0"
						aria-label="Icon"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5.09009 2H18.9099C20.0631 2 21 2.9492 21 4.11745V6.45394C21 7.31187 20.4775 8.37059 19.955 8.89995L15.4504 12.9341C14.8198 13.4634 14.4054 14.5221 14.4054 15.3801V19C14.4054 19.6389 13.991 20.4786 13.4685 20.8071L12.009 21.7381C10.6396 22.596 8.76577 21.6286 8.76577 19.9309V15.2523C8.76577 14.5039 8.35135 13.5547 7.91892 13.0253L3.93694 8.77218C3.41441 8.26107 3 7.29361 3 6.65473V4.22697C3 2.9492 3.93694 2 5.09009 2Z"
								stroke="#8E8EA9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<p>Filter</p>
					{userTransaction && (
						<PaginationTable
							className="block-pagination--header"
							currentPage={currentPage}
							lastPage={userTransaction?.last_page}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</div>
			</div>
			<div className="table table--wallet-transaction">
				<div className="table-header table-header--hidden">
					<div className="tr">
						<div className="td">Token</div>
						<div className="td">Time</div>
						<div className="td">Type</div>
						<div className="td">Transaction</div>
						<div className="td">Txn Hash</div>
						<div className="td td--right">Status</div>
					</div>
				</div>
				<div className="table-body table-position">
					{userTransaction && userTransaction?.data?.length ? (
						userTransaction?.data?.map((item: TUserTransactionItem) => (
							<RItem key={item.id} item={item} />
						))
					) : (
						<EmptyTable text="No Recent Transactions Yet" />
					)}
					{recentLoader && (
						<div className="table-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>

				<FilterModal
					openModal={openModal}
					setOpenModal={setOpenModal}
					coinsList={coinsList}
					setFiltered={setFiltered}
				/>
			</div>
			{userTransaction && (
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={userTransaction?.last_page}
					setCurrentPage={setCurrentPage}
				/>
			)}
		</>
	);
};
