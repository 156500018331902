/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLocaleTimeFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleTimeString();
export const getLocaleDateFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleDateString();
export const getTimeParserDateFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleDateString();
};
export const getTimeParserTimeFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleTimeString();
};

export const getTimeSum = (timeStart: any): string => {
	const one: any = new Date(timeStart);
	const two: any = new Date();
	const milliseconds = two - one;
	const seconds = milliseconds / 1000;
	const minutes = seconds / 60;
	const hours = minutes / 60;
	const days = hours / 24;
	return days < 1 ? 'today' : String(Math.floor(days));
};

export const utcDate = (date: string) => {
	return new Date(date).toLocaleString();
};
