import { Information } from 'components/Information';
import { PageProfile } from 'layouts/page/Profile';
import { FC } from 'react';

export const InformationPage: FC = () => {
	return (
		<PageProfile title="Login Information" text="Login Information" link="/">
			<Information />
		</PageProfile>
	);
};
