/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from 'redux/reducers/connectWallet/reducer';
import { setSlipping } from 'redux/reducers/wallets/reducer';
import { getTypeWallet } from 'redux/reducers/connectWallet/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { Web3Provider } from '@ethersproject/providers';
import {
	useWeb3ModalAccount,
	useWeb3ModalState,
	useWeb3ModalError,
} from '@web3modal/ethers5/react';
import { getLS } from 'components/Exchange/ExchangeForm/utils';
import { notificationContainer } from 'services/utils/notificationContainer';
/* eslint-disable */

const useWalletConnect = () => {
	const dispatch = useDispatch();
	const { login } = useAuth();
	const wallet = useSelector(getTypeWallet);
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const { account, isActive, connector, isActivating } = useWeb3React<Web3Provider>();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { open, selectedNetworkId } = useWeb3ModalState();
	const { error } = useWeb3ModalError();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');

	useEffect(() => {

		if (connectorName === 'WALLET_CONNECT' && !isConnected && !address) {
			login(connectorName);
		}
		if (connectorName === 'INJECTED' && !account) {
			login(connectorName);
		}
	}, [connectorName, login, account, isConnected]);
	useEffect(() => {

		if (isAuthenticated && connectorName === 'INJECTED' && account) {
			const payload = {
				account,
				active: isActive,
				loading: true,
			};

			dispatch(setAddress(payload));
		}
		if (isAuthenticated && connectorName === 'WALLET_CONNECT' && isConnected) {
			const payload = {
				account: address,
				active: isConnected,
				loading: true,
			};

			dispatch(setAddress(payload));
		}
	}, [account, connectorName, isConnected]);
	// dispatch(setSlipping(slippage))
};

export default useWalletConnect;
