import { FC, useEffect, useState } from 'react';

import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useDispatch, useSelector } from 'react-redux';
import { getTokensVolum } from 'redux/reducers/swap/selectors';
import { getTokensValueRequest } from 'redux/reducers/swap/reducer';
import PaginationTable from 'ui/PaginationTable';
import { Link } from 'react-router-dom';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { ITopTokensTableProps } from '../types';

const ExchangeVolumeTable: FC<ITopTokensTableProps> = ({ searchValue }) => {
	const dispatch = useDispatch();

	const tokens = useSelector(getTokensVolum);
	const moneyFormat = useMoneyFormat();
	const perPage = 18;

	const [currentPage, setCurrentPage] = useState<number>(1);

	const tokensArr = tokens ? Object.keys(tokens.data) : [];
	const tokensList: string[][] = [];
	const size = 3;
	if (tokens) {
		for (let i = 0; i < Math.ceil(tokensArr.length / size); i += 1) {
			tokensList[i] = tokensArr.slice(i * size, i * size + size);
		}
	}
	useEffect(() => {
		dispatch(
			getTokensValueRequest({
				per_page: perPage,
				current_page: tokens?.last_page && tokens?.last_page < currentPage ? 1 : currentPage,
				asset: searchValue,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, dispatch, searchValue]);

	useEffect(() => {
		if (tokens && currentPage > tokens.last_page) {
			setCurrentPage(tokens.last_page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokens]);

	return (
		<div className="tokens-table">
			<div className="tokens-table__top">
				<p className="tokens-table__text">Exchange volume 24h</p>
			</div>
			<div className="table table--type2 table--group-type table--tokens-group-type table--token-list-exchange">
				<div className="table-header">
					<div className="tr">
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Tot. SWAPs</div>
							<div className="td ">Volume 24H </div>
						</div>
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Tot. SWAPs</div>
							<div className="td ">Volume 24H </div>
						</div>
						<div className="td-group">
							<div className="td">Name</div>
							<div className="td ">Tot. SWAPs</div>
							<div className="td ">Volume 24H </div>
						</div>
					</div>
				</div>
				<div className="table-body table-position">
					{tokensList.length ? (
						tokensList.map((row) => (
							<div key={row[0]} className="tr">
								{row.map((el) => (
									<div key={el} className="td-group">
										<div className="td">
											<Link
												to={`/pool-token/${tokens?.data[el].asset_id || ''}`}
												className="token-box"
											>
												<div className="token-box__icon-3">
													<img
														className="token-box__icon-1"
														src={tokens?.data[el].asset.logo}
														alt=""
													/>
												</div>
												<div className="token-box__name token-box__name--small">
													{tokens?.data[el].asset.symbol}
												</div>
											</Link>
										</div>
										<div className="td ">
											<p>{moneyFormat(tokens?.data[el].total_swap_transaction, 0, 0)}</p>
										</div>
										<div className="td ">
											<p>${moneyFormat(tokens?.data[el].swap_size_24_h_in_usd, 0, 2)}</p>
										</div>
									</div>
								))}
							</div>
						))
					) : (
						<NoDataTable />
					)}

					{/* {tokensList.map((row) => (
							<div key={item?.id} className="tr">
								<div className="td">
									<Link to={`/pool-token/${item.asset_id}`} className="token-box">
										<div className="token-box__icon">
											<img
												className="token-box__icon-1"
												src={item?.asset.logo}
												alt={item?.asset.logo}
											/>
										</div>
										<div className="token-box__name">{item?.asset.symbol}</div>
									</Link>
								</div>
								<div className="td td--right">
									<p>${moneyFormat(item?.swap_size_24_h_in_usd, 2, 2, true)}</p>
								</div>
							</div>
						))
					)} */}
				</div>
			</div>

			{tokens && (
				<PaginationTable
					className="block-pagination--center block-pagination--table"
					currentPage={tokens.current_page}
					setCurrentPage={setCurrentPage}
					lastPage={tokens?.last_page}
				/>
			)}
		</div>
	);
};

export default ExchangeVolumeTable;
