import { FC, useEffect } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { NavigateList } from 'routes/routesList';
import useWalletConnect from 'hooks/useWalletConnect';
import useNetworkChange from 'hooks/useNetworkChange';
import useProvider from 'hooks/useProvider';

const App: FC = () => {
	useProvider();
	useWalletConnect();
	useNetworkChange();
	return (
		<BrowserRouter>
			{/* <LoadingBar className="loading-bar" /> */}
			<NavigateList />
		</BrowserRouter>
	);
};

export default App;
