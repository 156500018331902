/* eslint-disable no-debugger */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api, responseErrors } from 'services';
import {
	getTokenBalance,
	IWeb3GetTokenBalanceParams,
	getPairBalance,
} from 'services/utils/getTokenBalance';
import {
	getNetworksRequest,
	getNetworksSuccess,
	getNetworksPairRequest,
	getNetworksPairSuccess,
	getNetworksError,
	getTokenBalanseRequest,
	getTokenBalanceSuccess,
	getBridgeHistoryRequest,
	getBridgeHistorySuccess,
	// getTokenBalanceError,
} from './reducer';
import {
	INetworkPairData,
	TNetworkKeyFrom,
	IGetBridgeHistoryResponse,
	IPayloadBridg,
} from './types';

// 📌 Assets

function* getNetworksWorker() {
	try {
		yield put(showLoading());

		const response: TNetworkKeyFrom = yield call(api.bridge.getNetworks);

		yield put(getNetworksSuccess(response));
	} catch (error) {
		yield put(getNetworksError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getNetworksPairWorker() {
	try {
		yield put(showLoading());

		const response: INetworkPairData = yield call(api.bridge.getNetworksPair);

		yield put(getNetworksPairSuccess(response));
	} catch (error) {
		// yield put(getSwapAssetsError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTokenBalanceWorker({ payload }: any) {
	const { tokenAddress, rpc } = payload;
	try {
		const balance: string = yield call(getTokenBalance, payload);
		yield put(getTokenBalanceSuccess({ tokenAddress, balance, rpc }));
	} catch (error) {
		// yield put(getTokenBalanceError({ tokenAddress }));
	}
}

function* getBridgeHistoryWorker({ payload }: PayloadAction<IPayloadBridg>) {
	try {
		const response: IGetBridgeHistoryResponse = yield call(api.bridge.getBridgeHistory, payload);

		yield put(getBridgeHistorySuccess(response));
	} catch (error) {
		// yield put(getTokenBalanceError({ tokenAddress }));
	}
}

export function* bridgeSaga() {
	yield takeEvery(getNetworksRequest, getNetworksWorker);
	yield takeEvery(getTokenBalanseRequest, getTokenBalanceWorker);
	yield takeEvery(getNetworksPairRequest, getNetworksPairWorker);
	yield takeEvery(getBridgeHistoryRequest, getBridgeHistoryWorker);
}
