import { FC, useState } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { QuestionIcon } from 'assets/custom-icons';
import { useDispatch } from 'react-redux';
import { setSlipping } from 'redux/reducers/wallets/reducer';
import { PercentButtons } from './Precent';
import { TWalletsModal } from './type';

export const SettingsModal: FC<TWalletsModal> = ({ openModal, setOpenModal, slipping }) => {
	const dispatch = useDispatch();
	const closeModal = () => setOpenModal(false);

	const [fromValue, setFromValue] = useState(slipping || '0.5');
	const countFrom = (value: number) => {
		setFromValue(String(value));
	};

	const percentButtonCountValue = (percentValue: number): number => {
		return percentValue;
	};
	const handleSave = () => {
		// setLS('slippage', fromValue);
		dispatch(setSlipping(String(fromValue)));
		closeModal();
	};

	const handleCancel = () => {
		setFromValue(String(slipping));
		closeModal();
	};
	return (
		<ModalWarapper
			openModal={openModal}
			setOpenModal={setOpenModal}
			insideClass="popup-window__inside--pt-medium"
		>
			<div className="popup popup--padding-type2 popup--smaller">
				<div className="popup-header">
					<p className="popup-header__title">Settings</p>
					<button
						onClick={closeModal}
						type="button"
						className="popup-close"
						aria-label="Popup Close"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7 7L17 17"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7 17L17 7"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div>
				<div className="popup-body">
					<p className="popup-text">Swaps & Liquidity</p>
					<div className="site-setings">
						<div className="site-settings-wraper">
							<p className="site-settings-label">Slippage Tolerance</p>
							<span className="tooltip-item">
								<span className="tooltip-item__icon">
									<QuestionIcon />
								</span>
								<span className="tooltip tooltip--top tooltip--center">
									<span className="tooltip__text">
										<span className="tooltip__text-title">Please, note:</span> When a limit order
										expires, no cancellation fee is charged. In case of manually cancelling a limit
										order, a gas fee is charged at the time of signing the transaction.
									</span>
								</span>
							</span>
						</div>
						<div className="slippage-settings">
							<PercentButtons
								countFrom={countFrom}
								percentButtonCountValue={percentButtonCountValue}
								amount={fromValue}
								precents={[0.1, 0.5, 1.0]}
							/>
							<div className="slippage-value">
								<div className="slippage-value__input">
									<div className="input-wrapper">
										<input
											className="input-item input-item--type2 input-item--smaller"
											type="text"
											value={fromValue}
											onChange={(e) => setFromValue(e.currentTarget.value)}
										/>
									</div>
								</div>
								<p className="slippage-value__percent">%</p>
							</div>
						</div>
					</div>
				</div>
				<div className="popup-footer">
					<button
						onClick={handleCancel}
						type="button"
						className="button button--type3 button--full-width"
					>
						Cancel
					</button>
					<button onClick={handleSave} type="button" className="button button--full-width">
						Save
					</button>
				</div>
			</div>
		</ModalWarapper>
	);
};
