import { createSelector } from '@reduxjs/toolkit';
import { getLS } from 'components/Exchange/LiquidityForm/utils';
import { TStoreState } from 'redux/types';
import { TToken } from 'redux/reducers/coins/types';
import { IApiSwapAsset } from 'services/api/swap/types';

import { ISwapStore, ISwapTokenListUnsafe, TPoolData, TPoolsItem } from './types';

export const getSwap = (state: TStoreState): ISwapStore => state.swap;

// 📌 Assets
/* eslint-disable no-debugger */

export const getSwapAssets = (state: TStoreState) => {
	return getSwap(state).assets;
};

export const getSwapAssetList = (state: TStoreState) => {
	return getSwap(state).assets?.data;
};

export const getSwapAssetsLoading = (state: TStoreState) => {
	return getSwap(state).assetsLoading;
};

// 📌 Pairs

export const getSwapPairs = (state: TStoreState) => {
	return getSwap(state).pairs;
};

export const getSwapPairList = (state: TStoreState) => {
	return getSwap(state).pairs?.data;
};

export const getSwapPairsLoading = (state: TStoreState) => {
	return getSwap(state).pairsLoading;
};

// 📌 Token balances

export const getSwapBalances = (state: TStoreState) => {
	return getSwap(state).balances;
};

export const getSwapTokenList = createSelector(
	[getSwapAssetList, getSwapBalances],
	(assetList, balances): ISwapTokenListUnsafe => {
		return assetList?.map((asset) => {
			const swapBalance = balances.find(({ tokenAddress }) => {
				return tokenAddress === asset.address;
			});

			if (swapBalance) {
				const { balance } = swapBalance;
				return { ...asset, balance };
			}
			return asset;
		});
	},
);
export const getAddTokenList = createSelector(
	[getSwapAssetList, getSwapBalances],
	(assetList, balances): ISwapTokenListUnsafe => {
		// const filterAssetList: any = assetList?.filter((token) => token.is_active === 1);
		const tokenGets: Array<TToken | IApiSwapAsset> = getLS('tokens');
		// let newArrayTokens: Array<TToken | IApiSwapAsset> = [];

		const newArrayTokens: Array<TToken | IApiSwapAsset> | undefined =
			assetList && tokenGets ? [...assetList, ...tokenGets] : assetList;
		const arrFiltered = newArrayTokens?.reduce((accum: any, current: any) => {
			const obj = newArrayTokens?.find((item) => item.address === current.address);
			if (obj) {
				return newArrayTokens;
			}
			return newArrayTokens.concat([current]);
		}, []);

		const filteredArray: Array<TToken | IApiSwapAsset> | undefined = [];
		arrFiltered?.filter((item: any) => {
			if (!filteredArray.some((element) => element.address === item.address)) {
				filteredArray.push(item);
			}
			return item;
		});

		return filteredArray?.map((asset: any) => {
			const swapBalance = balances.find(({ tokenAddress }) => {
				return tokenAddress === asset.address;
			});

			if (swapBalance) {
				const { balance } = swapBalance;
				return { ...asset, balance };
			}
			return asset;
		});
	},
);

// 📌 Calculate rate

export const getCalculatedRate = (state: TStoreState) => {
	return getSwap(state).calculatedRate;
};

export const getCalculateLoading = (state: TStoreState) => {
	return getSwap(state).calculateLoading;
};

export const getCalculatedPerOneRate = (state: TStoreState) => {
	return getSwap(state).calculatedRatePerOne;
};

// Pair balance
// ====================================================:
export const getPairBalance = createSelector(
	[getSwap],
	(swap: ISwapStore): string | null => swap.pairBalance,
);

export const getTopPools = (state: TStoreState) => getSwap(state).topPools;
export const getTokens = (state: TStoreState) => getSwap(state).tokens;
export const getTokensVolum = (state: TStoreState) => getSwap(state).tokensValue;
export const topTokensLoader = (state: TStoreState) => getSwap(state).topTokensLoading;
export const topPoolsLoader = (state: TStoreState) => getSwap(state).topPoolsLoading;
export const getToken = (state: TStoreState) => getSwap(state).tlv[0];
export const getGraphData = (state: TStoreState) => getSwap(state).graphToken;
export const getGraphLoading = (state: TStoreState) => getSwap(state).graphLoading;
// Pools
export const getPools = createSelector(
	[getSwap],
	(swap: ISwapStore): TPoolData | null => swap.pools,
);
export const getPairLiquidity = createSelector(
	[getSwap],
	(swap: ISwapStore): TPoolsItem | undefined => swap.currentLiquidity?.pools[0],
);
