import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransaction } from 'components/Exchange/LiquidityForm/type';
import { ITypeTransactionRemove } from 'components/Exchange/RemoveLiqudity/type';
import { noExponent } from 'services/utils/noExponent';

import { ethers } from 'ethers';
/* eslint-disable */
const { BigNumber, constants, utils } = ethers;
const MINIMUM_LIQUIDITY = BigNumber.from(10).pow(3);

export const addLiquidity = async ({
	wallet,
	abi,
	to,
	firstTokenAddress,
	secondTokenAddress,
	amountADesired,
	amountBDesired,
	amountAMin = 1,
	amountBMin = 1,
	deadline = String(
		BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
	),
	provider,
	connector,
	firstType,
	secondType,
	tokenAsset,
	amountFee,
	code,
	type,
	setLoaderAdd,
}: ITypeTransaction) => {
	try {
		setLoaderAdd(true);
		// const provider =
		// 	connector === 'bscConnector' ? process.env.REACT_APP_NODE_1 : Web3.givenProvider;
		const valueEth = firstType ? amountADesired : amountBDesired;

		const web3 = new Web3(provider);
		const contract = new web3.eth.Contract(abi, to);

		let liquidity = '';
		debugger;
		if (type === 'GATO') {
			liquidity = await contract.methods
				.addLiquidityETH(
					tokenAsset.address,
					tokenAsset.tokenAmount,
					amountAMin,
					amountBMin,
					wallet,
					deadline,
				)
				.encodeABI();
		} else {
			liquidity = await contract.methods
				.addLiquidity(
					firstTokenAddress,
					secondTokenAddress,
					amountADesired,
					amountBDesired,
					amountAMin,
					amountBMin,
					wallet,
					deadline,
				)
				.encodeABI();
		}

		let result;

		const valueFee1 = noExponent(Number(valueEth) + Number(amountFee));
		const valueFee = BigNumber.from(valueEth).add(String(amountFee));

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				to,
				data: liquidity,
				value: type === 'GATO' ? String(valueFee) : amountFee,
			});
		}

		// }

		// notification({
		// 	type: 'success',
		// 	title: 'Successful',
		// 	message: 'Liquidity added',
		// });
		notificationContainer(`Liquidity added!`, 'success');
		setLoaderAdd(false);

		return result;
	} catch (error: any) {
		setLoaderAdd(false);
		notificationContainer(`Liquidity failed! ${String(error?.message)}`, 'error');
	}
};

const _getEtheriumProvider = (prov: any) => {
	return new ethers.providers.Web3Provider(prov);
};

const _getSigner = (provider: any, account: any) => {
	return provider.getSigner(account);
};

export const removeLiquidity = async ({
	wallet,
	abi,
	to,
	firstTokenAddress,
	secondTokenAddress,
	amountBDesired,
	amountAMin = 1000000,
	amountBMin = 1000000,
	deadline = String(
		BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
	),
	connector,
	tokenAsset,
	amountFee,
	provider,
	type,
	setLoader,
}: ITypeTransactionRemove) => {
	try {
		setLoader(true);

		let rmliquidity = '';

		const web3 = new Web3(provider);
		const contract = new web3.eth.Contract(abi, to);
		if (type === 'GATO') {
			rmliquidity = await contract.methods
				.removeLiquidityETH(
					tokenAsset,
					BigNumber.from(amountBDesired).sub(MINIMUM_LIQUIDITY),
					amountAMin,
					amountBMin,
					wallet,
					deadline,
				)
				.encodeABI();
		} else {
			rmliquidity = await contract.methods
				.removeLiquidity(
					firstTokenAddress,
					secondTokenAddress,
					BigNumber.from(amountBDesired).sub(MINIMUM_LIQUIDITY),
					amountAMin,
					amountBMin,
					wallet,
					deadline,
				)
				.encodeABI();
		}

		let result;
		const valueFee = BigNumber.from(amountBDesired).add(String(amountFee));
		if (connector) {
			result = await web3.eth.sendTransaction({
				to,
				from: wallet,
				data: rmliquidity,
				value: String(amountFee),
			});
		}

		notificationContainer(`Liquidity remove!`, 'success');
		setLoader(false);

		return result;
	} catch (error: any) {
		debugger;
		setLoader(false);
		notificationContainer(`Liquidity failed! ${String(error?.message)}`, 'error');
	}
};
