import { Liquidity } from 'components/Bridge/Liquidity';
import { Content } from 'layouts/Content';
import { FC } from 'react';

export const BridLiquiditygePage: FC = () => {
	return (
		<Content title="Bridge" text="Bridge" history="History">
			<Liquidity />
		</Content>
	);
};
