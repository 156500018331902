import { FC, useState, useEffect } from 'react';
import { TPoolsItem } from 'redux/reducers/swap/types';
import { NavLink } from 'react-router-dom';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getPoolsRequest } from 'redux/reducers/swap/reducer';
import { getPools } from 'redux/reducers/swap/selectors';
import { PoolsTable } from './PoolsTable';
/* eslint-disable no-debugger */

export const PoolsList: FC = () => {
	const [searchValue, setSearchValue] = useState('');

	const debouncedSearch = useDebounce(searchValue, 500);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	return (
		<section className="general-section general-section--pool">
			<div className="container">
				<div className="inner-block">
					<div className="swap swap--center swap--mb">
						<NavLink to="/exchange" type="button" className="swap__item">
							Exchange
						</NavLink>
						<button type="button" className="swap__item active">
							Pool
						</button>
						<NavLink to="/pool-token" type="button" className="swap__item">
							Tokens
						</NavLink>
					</div>

					<div className="general-block general-block--center general-block--transparent">
						<h4 className="general-block-title general-block-title--mb16">Pools</h4>
						<div className="popup-search">
							<div className="popup-search__input">
								<input
									type="text"
									className="input-item input-item--search input-item--left-icon"
									placeholder="Search Pool"
									value={searchValue}
									onChange={handleChange}
								/>
								<span className="input-icon">
									<svg
										width="18"
										height="19"
										viewBox="0 0 18 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.625 16.25C12.56 16.25 15.75 13.06 15.75 9.125C15.75 5.18997 12.56 2 8.625 2C4.68997 2 1.5 5.18997 1.5 9.125C1.5 13.06 4.68997 16.25 8.625 16.25Z"
											stroke="#8E8EA9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.5 17L15 15.5"
											stroke="#8E8EA9"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
						</div>
						<PoolsTable searchValue={debouncedSearch} />
					</div>
				</div>
			</div>
		</section>
	);
};
