/* eslint-disable */
import { FC, useEffect, useMemo, useState } from 'react';
import token from 'assets/images/profile/token.png';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import DividendsFilter from 'components/Dividends/DividendsFilter';
import {
	getReferralB2B,
	getReferralB2BBalance,
	getReferralWalletLoader,
} from 'redux/reducers/referral/selectors';
import {
	getReferralB2BRequest,
	setClaimLoader,
	getTrancationClaimRequest,
	getB2BDateRequest,
} from 'redux/reducers/referral/reducer';
import {
	TReferralB2BRequest,
	TResponseSeparateByLevelObj,
	IB2BPayload,
} from 'redux/reducers/referral/types';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import AbiFee from 'config/abi/FeeController.json';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { authInitState } from 'redux/reducers/auth/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useWeb3React } from '@web3-react/core';
import { claimApprove } from 'services/utils/claim';

import useRefreshToken from 'hooks/useRefreshToken';

import loginAvatar from 'assets/images/avatars/default-avatar.png';
import levelLogo from 'assets/images/profile/level.png';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { numberInDecimal } from 'components/Exchange/ExchangeForm/utils';
import { noExponent } from 'services/utils/noExponent';
import { B2CProps } from './types';
import { ButtonLevel } from './ButtonLevel';
import { B2BItem } from './B2BItem/B2BItem';
import { SwitcherBox } from '../SwitcherBox/SwitcherBox';

const CONTRACT_ADDRESS = String(process.env.REACT_APP_FEE_CONTRACT_ADDRESS);
const Web3 = require('web3');
const RPC_HOST = String(process.env.REACT_APP_RPC_URL);
const _web3 = new Web3(RPC_HOST);
const SELECTOR_B2B = '0xb92fef0a';
declare let window: any;

export const B2BReferrals: FC<B2CProps> = ({
	currentCoin,
	handleClick,
	handleChangeCoin,
	activeB2,
	setOpenTransactionModal,
	setOpenModal,
	setFee,
	openModal,
	handleStartTimer,
}) => {
	const dispatch = useDispatch();
	const B2BData = useSelector(getReferralB2B);

	const level = ['All level', 'level 1', 'level 2', 'level 3'];
	const [currentLevel, setLevel] = useState('All level');
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(1)));
	const [endDate, setEndDate] = useState<Date>(new Date());
	const tokenRefresh = useRefreshToken();
	const navigate = useNavigate();
	const B2BBalance = useSelector(getReferralB2BBalance);
	const referralWalletLoader = useSelector(getReferralWalletLoader);
	const user = useSelector(getUserSettingsData);
	const address = useSelector(getAddress);
	const { provider, account } = useWeb3React();
	const { address: addressWeb3, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const tokenAdressGATOCoin = user?.wallets[0]?.balances.find((item) => {
		return item?.asset?.address === '0x0000000000000000000000000000000000000000';
	});

	const handleData = () => {
		const payload: IB2BPayload = {
			onSuccess: (value: any) => {
				handlerConnection(value);
			},
		};
		dispatch(getB2BDateRequest(payload));
	};

	const handlerConnection = async (signature: any) => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		if (referralWalletLoader) {
			notificationContainer(`Wait plese claim is pending`, 'info');
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		if (!B2BBalance) {
			notificationContainer(`Don't have bonus`, 'info');
			return;
		} else if (B2BBalance && !Number(B2BBalance[0]?.amount)) {
			notificationContainer(`Don't have bonus`, 'info');
			return;
		}

		if (address.wallet && signature) {
			try {
				let currentAddress = connector === 'INJECTED' ? account : addressWeb3;

				let accounts;

				if (connector) {
					// accounts = await library?.provider.request({
					// 	method: 'eth_requestAccounts',
					// });
				}

				// const pairContract = new _web3.eth.Contract(AbiFee.abi, CONTRACT_ADDRESS);
				// const balance = await pairContract.methods.totalClaimedB2B().call();
				// debugger;

				const sendParams: any = {
					wallet: currentAddress,
					abi: AbiFee.abi,
					to: CONTRACT_ADDRESS,
					userId: String(user?.id),
					firstTokenAmount: String(signature),
					type: true,
					provider: currentProvider,
					amount: noExponent(
						Math.floor(
							Number(
								numberInDecimal(
									Number(B2BBalance[0]?.amount),
									Number(tokenAdressGATOCoin?.asset.decimals),
								).toString(),
							),
						),
					),
					// dedline: signature?.deadline,
					setOpenTransactionModal,
					connector,
				};

				const result = await claimApprove(sendParams);

				if (result) {
					dispatch(
						getTrancationClaimRequest({
							date: String(signature),
							address: String(currentAddress),
							signature: String(result),
							amount: String(
								noExponent(
									Math.floor(
										Number(
											numberInDecimal(
												Number(B2BBalance[0]?.amount),
												Number(tokenAdressGATOCoin?.asset.decimals),
											).toString(),
										),
									),
								),
							),
						}),
					);
					setOpenTransactionModal(false);
					// 	dispatch(setClaimLoader(true));
					// 	handleStartTimer();
					// 	// setHash(result?.transactionHash);
				}
			} catch (error: any) {
				notificationContainer(`Error: ${String(error?.message)}`, 'error');
				setOpenTransactionModal(false);
				dispatch(setClaimLoader(false));
				return;
			}
			// setOpenTransactionModal(true);
			return;
		}
		setOpenModal(false);
	};


	// useEffect(() => {
	// 	if (
	// 		tokenAdressGATOCoin?.asset.id &&
	// 		address.wallet &&
	// 		B2BData?.responseSeparateByToken?.length
	// 	) {
	// 		const payload: Ib2cBodyRequest = {
	// 			wallet_address: String(address?.wallet?.toLowerCase()),
	// 			asset_id: tokenAdressGATOCoin?.asset.id,
	// 		};
	// 		dispatch(getReferralB2BSignatureRequest(payload));
	// 	}
	// }, [dispatch, address.wallet]);

	useEffect(() => {
		if (startDate) {
			const payload: TReferralB2BRequest = {
				params: {
					start: format(startDate, 'yyyy-MM-dd'),
					end: format(endDate || new Date(), 'yyyy-MM-dd'),
				},
			};
			dispatch(getReferralB2BRequest(payload));
		}
	}, [dispatch, endDate, startDate]);

	const leveArray = B2BData?.responseSeparateByToken
		.filter((item) => String(Object.keys(item)) === String(currentLevel.split(' ')[1]))
		.map((item) => item[String(currentLevel.split(' ')[1])]);

	const dataLevel: Array<TResponseSeparateByLevelObj> | any = useMemo(() => {
		if (currentLevel === 'All level' && B2BData) {
			return Object.values(B2BData.responseSeparateByLevel);
		}
		return leveArray && leveArray;
	}, [B2BData, currentLevel, leveArray]);

	return (
		<>
			<div className="earning-content">
				<div className="earning-block">
					<div className="earning-profile">
						<div className="earning-avatars">
							<div className="earning-avatars__item">
								<img className="avatar-img" src={user?.data?.avatar_path || loginAvatar} alt="" />
							</div>
							<div className="earning-avatars__item earning-avatars__item--level">
								<img src={levelLogo} alt="" />
							</div>
						</div>
						<div className="profile-info">
							<p className="profile-name">
								{user?.data?.first_name} {user?.data?.last_name}
							</p>
							<div className="profile-level">
								<p className="profile-level__text">Platinum level</p>
								<p className="profile-level__number">1</p>
							</div>
						</div>
					</div>

					<div className="earning-stats">
						<div className="earning-stats__item">
							<div className="earning-stats__name">
								<p>Total Earn</p>
							</div>
							<div className="earning-stats__number">
								{/* {noExponent(String(referralWallet?.amount_total || 0))} */}0
							</div>
						</div>
						<div className="earning-stats__item">
							<div className="earning-stats__name">Extra earnings based on loyalty program</div>
							<div className="earning-stats__number">0</div>
						</div>
					</div>

					<div className="total-earn">
						<div className="total-earn__text">My unclaimed reward</div>
						<div className="total-earn__wrapper">
							<p className="total-earn__number">
								{B2BBalance && B2BBalance[0]?.amount
									? noExponent(String(B2BBalance ? B2BBalance[0]?.amount : ''))
									: 0}
							</p>
							<div className="total-earn__icon">
								<img className="icon-earn" src={token} alt="" />
							</div>
						</div>
					</div>
					<div className="dividents-btns dividents-btns--mt dividents-btns--right">
						<button
							className="button button--full-width button--width"
							type="button"
							onClick={(e) => handleData()}
							// disabled={referralWalletLoader}
						>
							Claim
						</button>
					</div>
				</div>
			</div>
			<SwitcherBox
				activeCoin={currentCoin}
				activeB2={activeB2}
				handleClick={handleClick}
				handleChangeCoin={handleChangeCoin}
			/>
			<DividendsFilter
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>

			<div className="referred-titile-wrapper">
				<h4 className="referred-titile">Your Total Referred Token Earnings</h4>
				<div className="referred-tokens">
					<p className="referred-tokens__number">
						{B2BData && Object.values(B2BData?.total)[0]?.amount}
					</p>
					<div className="referred-tokens__icon">
						<img src={token} alt="" />
					</div>
				</div>
			</div>
			<div style={{ marginTop: '10px' }}>
				{level.map((item) => (
					<ButtonLevel key={item} item={item} setLevel={setLevel} level={currentLevel} />
				))}
			</div>
			<div className="referred-container">
				{dataLevel?.length ? (
					dataLevel?.map((item: TResponseSeparateByLevelObj) => <B2BItem item={item} />)
				) : (
					<EmptyTable text="No Tokens" />
				)}
			</div>
		</>
	);
};
