import React, { FC } from 'react';

import { IAddressTokenBalance } from 'services/api/blockchain/types';
import { capitalize, shortNotation } from 'services/utils/textSlice';

interface ITokensTableProps {
	itemsList: IAddressTokenBalance[];
}

const TokensTable: FC<ITokensTableProps> = ({ itemsList }) => {
	return (
		<div className="table table--address-detail-tokens">
			<div className="table-header table-header--hidden">
				<div className="tr">
					<div className="td">Asset</div>
					<div className="td">Type</div>
					<div className="td">Amount</div>
					<div className="td">Symbol</div>
					<div className="td">Contract Address</div>
				</div>
			</div>
			<div className="table-body">
				{itemsList.map((el) => (
					<div key={el.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">Asset</p>
							<p>{el.token.name}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Type</p>
							<p>{el.token.type.toUpperCase()}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Amount</p>
							<p>{el.value}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Symbol</p>
							<p>{el.token.symbol.toUpperCase()}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Contract Address</p>
							<p> ({shortNotation(el.token_contract_address_hash, 8, 6)})</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TokensTable;
