import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	IChangePassData,
	ICheck2faEnables,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	IUserSettings,
	TReferralsData,
	INotifications,
	TChangePasswordPayload,
	TGetNationalityResponse,
} from 'redux/reducers/settings/types';
import { ISettingsApi } from './types';

// ==========================================:
export const settings: ISettingsApi = {
	userSettings: () =>
		http.get<IUserSettings>(endpoint.settings.USER_SETTINGS).then((response) => response.data),

	changePassword: (payload) =>
		http.post<IChangePassData>(endpoint.settings.CHANGE_PASSWORD, payload),
	editProfile: (payload) =>
		http.post<TChangePasswordPayload>(endpoint.settings.POST_USER_PROFILE, payload),
	changeAvatar: (payload) => http.post<string>(endpoint.settings.POST_USER_AVATAR, payload),

	// 2FA New
	sendEmailCode: (params) => {
		return http.post(endpoint.settings.SEND_EMAIL_CODE, params).then(({ data }) => data);
	},
	enableEmail2FA: (params) => {
		return http.post(endpoint.settings.ENABLE_EMAIL_2FA, params).then(({ data }) => data);
	},
	disableEmail2FA: (params) => {
		return http.post(endpoint.settings.DISABLE_EMAIL_2FA, params).then(({ data }) => data);
	},
	generateGoogle2FAKey: () => {
		return http.get(endpoint.settings.GENERATE_GOOGLE_2FA_KEY).then(({ data }) => data);
	},
	enableGoogle2FA: (params) => {
		return http.post(endpoint.settings.ENABLE_GOOGLE_2FA, params).then(({ data }) => data);
	},
	disableGoogle2FA: (params) => {
		return http.post(endpoint.settings.DISABLE_GOOGLE_2FA, params).then(({ data }) => data);
	},
	// 2FA Old (to delete)
	generate2faKey: () =>
		http
			.get<IGenerate2faKeyResponse>(endpoint.settings.GENERATE_2FA_KEY)
			.then((response) => response.data),
	check2faEnables: () =>
		http
			.get<ICheck2faEnables>(endpoint.settings.CHECK_ENABLED_2FA)
			.then((response) => response.data),
	enable2fa: (payload) => http.post<IEnable2faPayload>(endpoint.settings.ENABLE_2FA, payload),
	disable2fa: (payload) => http.post<IDisable2faPayload>(endpoint.settings.DISABLE_2FA, payload),

	setPhone: (payload) => http.post(endpoint.settings.SET_PHONE, payload.data),
	checkNotification: () =>
		http
			.get<INotifications>(endpoint.settings.CHECK_NOTIFICATION)
			.then((response) => response.data),
	updateNotification: (payload) =>
		http
			.put<INotifications>(endpoint.settings.UPDATE_NOTIFICATION, payload)
			.then((response) => response.data),
	getReferrals: () =>
		http.get<TReferralsData>(endpoint.settings.REFERRALS).then((response) => response.data),
	getNationality: () =>
		http
			.get<TGetNationalityResponse>(endpoint.settings.NATIONALITIES)
			.then((response) => response.data),
};
