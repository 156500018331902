import { ArrowLeft, ArrowRight } from 'assets/custom-icons';
import classNames from 'classnames';
import React, { FC } from 'react';

interface IPaginationTableProps {
	currentPage: number;
	lastPage: number;
	setCurrentPage: (value: number) => void;
	// eslint-disable-next-line react/require-default-props
	className?: string;
}

const PaginationTable: FC<IPaginationTableProps> = ({
	className = '',
	currentPage,
	lastPage,
	setCurrentPage,
}) => {
	const handlePageChange = (pageNumber: number) => {
		if (pageNumber <= lastPage && pageNumber > 0) {
			setCurrentPage(pageNumber);
		}
	};
	return (
		<div className={`block-pagination ${className}`}>
			<button
				type="button"
				className={classNames('block-pagination__btn', {
					active: currentPage !== 1,
				})}
				aria-label="Pagination"
				onClick={() => handlePageChange(currentPage - 1)}
			>
				<ArrowLeft />
			</button>
			<p className="block-pagination__text">
				Page {currentPage} of {lastPage || 1}
			</p>
			<button
				type="button"
				className={classNames('block-pagination__btn', {
					active: currentPage !== lastPage && lastPage !== 0,
				})}
				aria-label="Pagination"
				onClick={() => handlePageChange(currentPage + 1)}
			>
				<ArrowRight />
			</button>
		</div>
	);
};

export default PaginationTable;
