import { ILogin2FAResponse, ILoginResponse, T2FAType } from './types';

export const get2FATypeByResponse = (
	respData: ILoginResponse | ILogin2FAResponse,
): T2FAType | null => {
	const respDataAny: any = respData; // eslint-disable-line

	if (respDataAny.email_2fa_enabled) {
		return 'email';
	}
	if (respDataAny.google2fa_enabled) {
		return 'google';
	}
	return null;
};
