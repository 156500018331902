import { FC } from 'react';
import { Modals } from 'ui/Popups/Modals';
import { Link } from 'react-router-dom';
import Metamask from 'assets/images/exchange/metamask.svg';
import Trustwallet from 'assets/images/exchange/trustwallet.png';
import { ConnectionType } from 'services/utils/connections';
import { TWalletsModal } from './type';
import { ConnectToWallet } from '../ConnectToWallet';

export const WalletsModal: FC<TWalletsModal> = ({ openModal, setOpenModal }) => {
	return (
		<Modals
			openModal={openModal}
			setOpenModal={setOpenModal}
			title="Connect Wallet"
			paddingClass="popup--padding-16"
		>
			<div className="wallet-list">
				<ConnectToWallet
					title="MetaMask Wallet"
					image={Metamask}
					alt="metamask"
					connector={ConnectionType.INJECTED}
					setOpenModal={setOpenModal}
				/>
				<ConnectToWallet
					title="WalletConnect"
					image={Trustwallet}
					alt="WalletConnect"
					connector={ConnectionType.WALLET_CONNECT}
					currentClass="wallet-item__icon--trust"
					setOpenModal={setOpenModal}
				/>
				{/* <ConnectToWallet
					title="Coinbase Wallet"
					image={coinbase}
					alt="Coinbase Wallet"
					connector="injected"
					currentClass="wallet-item__icon--coinbase"
					setOpenModal={setOpenModal}
				/> */}
			</div>

			<div className="wallet-info">
				<p className="wallet-info__text">By connecting, I accept</p>
				<Link to="/" className="wallet-info__link">
					Terms of Use
				</Link>
			</div>
		</Modals>
	);
};
