import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { IToken } from 'components/Exchange/ExchangeForm/type';
import { noExponent } from 'services/utils/noExponent';
/* eslint-disable */
const { BigNumber, constants, utils } = ethers;
const { MaxUint256 } = constants;
const contractAdd = '0x54e621abd4bF9dF2DB1CE038A3aF25B71530C028';

export const addTransfer = async ({
	wallet,
	amount,
	abi,
	tokenName,
	tokenAddress,
	contractAddess,
	firstTokenAddress,
	toAddress,
	type,
	connector,
	provider,
	amountFee,
	gasData,
}: // setLoader,
any) => {
	try {
		// setLoader(true);

		const web3 = new Web3(provider);

		const contract = new web3.eth.Contract(abi, firstTokenAddress); // адресс не важен

		const approve = await contract.methods.transfer(toAddress, amount).encodeABI();
		const valueFee = noExponent(Number(amount) + Number(amountFee));
		let result;

		// const allowance = await contract.methods.allowance(wallet, spender).call();

		// if (allowance < amount) {
		// 	// Call approve if allowance is insufficient
		// 	await contract.methods.approve(spender, amount).send({ from: wallet });
		// 	console.log(`Approved ${amount} tokens for spender: ${spender}`);
		// } else {
		// 	console.log("Sufficient allowance, no need for approval.");
		// }

		if (connector) {
			result = await web3.eth.sendTransaction({
				value: type === 'coin' ? String(noExponent(Number(amount))) : '0',
				from: wallet,
				to: type === 'coin' ? toAddress : firstTokenAddress,
				gasPrice: parseInt(gasData.gasPrice, 16),
				gas: gasData.gasLimit,
				data: approve,
			});
		}

		notificationContainer(`Token ${String(tokenName)} was sending`, 'success');

		// setLoader(false);

		return result;
	} catch (error: any) {
		// setLoader(false);
		// notificationContainer(`Sending failed: ${String(error?.message)}`, 'error');
	}
};
