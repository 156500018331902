import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { TooltipInformation } from 'assets/custom-icons';
import { getAddressRequest } from 'redux/reducers/blockchain/reducer';
import { getAddress } from 'redux/reducers/blockchain/selectors';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import classNames from 'classnames';
import TransfersTab from 'components/Transactions/Transaction/TransfersTab';
import InternalTransactionsTab from 'components/Transactions/Transaction/InternalTransactionsTab';
import TransactionsTab from 'components/Transactions/Transaction/TransactionsTab';
import TokensTab from 'components/Transactions/Transaction/TokensTab';
import { getAssetsRequest } from 'redux/reducers/coins/reducer';
import { getAssetsList } from 'redux/reducers/coins/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TAssetsItem } from 'redux/reducers/coins/types';
import TokensDropdown from './TokensDropdown';

enum ETabTitle {
	'Transactions',
	'Token Transfers',
	'Tokens',
	'Internal Transactions',
}

const Address = () => {
	const dispatch = useDispatch();
	const { hash } = useParams();
	const address = useSelector(getAddress);
	const moneyFormat = useMoneyFormat();
	const assetsList = useSelector(getAssetsList);

	const [activeTab, setActiveTab] = useState(ETabTitle[0]);
	const handleTabClick = (value: string) => {
		setActiveTab(value);
	};

	const gatoCoin = useMemo(() => {
		if (!assetsList) return null;
		return assetsList.find((item: TAssetsItem) => item.symbol === 'GUSD' && item.type === 'token');
	}, [assetsList]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	useEffect(() => {
		if (hash) {
			dispatch(getAddressRequest(hash));
		}
	}, [dispatch, hash]);

	useEffect(() => {
		dispatch(getAssetsRequest());
		window.scrollTo(0, 0);
	}, [dispatch]);

	return (
		<section className="explore-home-section">
			<div className="container">
				<div className="explore-block explore-block--type2">
					<div className="explore-block__top">
						<div>
							<h4 className="block-title block-title--mr-auto">Address Details</h4>
							<p className="block-subtitle block-subtitle--word-break block-subtitle--type2 block-subtitle--fw-600">
								{address?.hash}
							</p>
						</div>
						<CopyToClipboard text={String(address?.hash)} onCopy={handleCopy}>
							<button type="button" className="token-copy-btn" aria-label="Copy token">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16 13.4V17.6C16 21.1 14.6 22.5 11.1 22.5H6.9C3.4 22.5 2 21.1 2 17.6V13.4C2 9.9 3.4 8.5 6.9 8.5H11.1C14.6 8.5 16 9.9 16 13.4Z"
										fill="#335BE9"
									/>
									<path
										opacity="0.4"
										d="M17.1017 2.5H12.9017C9.45172 2.5 8.05172 3.87 8.01172 7.25H11.1017C15.3017 7.25 17.2517 9.2 17.2517 13.4V16.49C20.6317 16.45 22.0017 15.05 22.0017 11.6V7.4C22.0017 3.9 20.6017 2.5 17.1017 2.5Z"
										fill="#335BE9"
									/>
								</svg>
							</button>
						</CopyToClipboard>
					</div>

					<div className="block-details-wrap">
						<div className="block-details-list">
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Address balance in GATO doesn`t include ERC20, ERC721, ERC1155 tokens).
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Balance
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>
											{moneyFormat(address?.fetchedCoinBalanceFormatted, 2, 2)} GATO (${' '}
											{gatoCoin &&
												moneyFormat(
													Number(address?.fetchedCoinBalanceFormatted) *
														Number(gatoCoin.price_in_usd || 0),
													2,
													2,
												)}{' '}
											USD)
										</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												All tokens in the account and total value.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Tokens
									</p>
								</div>
								<div className="block-details__right">
									{address?.address_token_balance && (
										<TokensDropdown tokens={address?.address_token_balance} />
									)}
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Number of transactions related to this address.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transactions
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status info-status--fw-500 info-status--fs-14">
										{address?.transactions.length || 0} Transactions
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Number of transfers to/from this address.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Transfers
									</p>
								</div>
								<div className="block-details__right">
									<div className="info-status info-status--fw-500 info-status--fs-14">
										{address?.token_transfer.length || 0} Tranfers
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">Gas used by the address.</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Gas Used
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{moneyFormat(address?.gasUsed, 0, 0)}</p>
									</div>
								</div>
							</div>
							<div className="block-details">
								<div className="block-details-name">
									<span className="tooltip-item">
										<span className="tooltip-item__icon">
											<TooltipInformation />
										</span>
										<span className="tooltip tooltip--top tooltip--left">
											<span className="tooltip__text">
												Block number in which the address was updated.
											</span>
										</span>
									</span>
									<p className="block-subtitle block-subtitle--fs-14 block-subtitle--mb-0 block-subtitle--mt-0">
										Last Balance Update
									</p>
								</div>
								<div className="block-details__right">
									<div className="block-details-value ">
										<p>{address?.fetched_coin_balance_block_number}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="explore-block explore-block--p0">
					<div className="explore-block-tabs">
						{Object.keys(ETabTitle).map((el, idx) => (
							<button
								onClick={() => handleTabClick(ETabTitle[idx])}
								type="button"
								key={el}
								className={classNames('explore-block-tabs__item', {
									active: activeTab === ETabTitle[idx],
								})}
							>
								{ETabTitle[idx]}
							</button>
						))}
					</div>
					<div className="explore-block__inner">
						{activeTab === ETabTitle[0] && (
							<TransactionsTab transferList={address?.transactions} hash={hash} />
						)}
						{activeTab === ETabTitle[1] && (
							<TransfersTab transferList={address?.token_transfer} hash={hash} />
						)}
						{activeTab === ETabTitle[2] && (
							<TokensTab tokensList={address?.address_token_balance} />
						)}
						{activeTab === ETabTitle[3] && (
							<InternalTransactionsTab itemList={address?.internal_transactions} hash={hash} />
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Address;
