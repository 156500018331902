import { FC } from 'react';
import { Link } from 'react-router-dom';

export const RunningStakes: FC = () => {
	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="back-to-page">
						<Link to="/staking" className="icon-btn">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.57 5.93005L3.5 12.0001L9.57 18.0701"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M20.5019 12H3.67188"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Link>

						<h3 className="block-title block-title--smaller ">Running</h3>
					</div>

					<div className="general-block">
						<div className="account-change account-change--staking">
							<div className="account-change__info">
								<p className="account-change__subtitle">10 days</p>
								<p className="account-change__item">9,195.0784363 GATO</p>
								<p className="account-change__subtitle">≈ $17,584.87</p>
							</div>
							<p className="account-change__percent">7.00 %</p>
						</div>

						<div className="account-change account-change--staking">
							<div className="account-change__info">
								<p className="account-change__subtitle">Fixed for 12 months</p>
								<p className="account-change__item">18,945.0654987 GATO</p>
								<p className="account-change__subtitle">≈ $32,078.54</p>
							</div>
							<p className="account-change__percent">12.00 %</p>
						</div>
					</div>

					{/* <button
						type="button"
						className="button button--big-height button--full-width button--mt button--transparent"
					>
						Back to Staking
					</button> */}
					<Link
						to="/staking"
						className="button button--big-height button--full-width button--mt button--transparent"
					>
						Back to Staking
					</Link>
				</div>
			</div>
		</section>
	);
};
