import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import level from 'assets/images/profile/level.png';
import token from 'assets/images/profile/token.png';
import EarningNav from 'components/Earnings/EarningNav';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getProfits } from 'redux/reducers/earnings/selectors';
import { getProfitsRequest } from 'redux/reducers/earnings/reducer';
import DividendsFilter from 'components/Dividends/DividendsFilter';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { dateToString } from './utils';

export const EarningsOverview: FC = () => {
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(1)));
	const [endDate, setEndDate] = useState<Date>(new Date());

	const profits = useSelector(getProfits);
	const moneyFormat = useMoneyFormat();

	const user = useSelector(getUserSettingsData);
	const gatoCoin = profits?.user?.wallets[0]?.balances?.find(
		(item) => item?.asset?.symbol === 'GATO' && item?.asset?.type === 'coin',
	);
	const gusdToken = profits?.user?.wallets[0]?.balances?.find(
		(item) => item?.asset?.symbol === 'GUSD' && item?.asset?.type === 'token',
	);

	useEffect(() => {
		if (startDate) {
			dispatch(
				getProfitsRequest({
					start: dateToString(startDate),
					end: dateToString(endDate || new Date()),
				}),
			);
		}
	}, [dispatch, endDate, startDate]);

	return (
		<section className="earning-section earning-section--type2">
			<div className="container">
				<EarningNav />
				<h3 className="earning-title">Overview</h3>
				<div className="earning-content">
					<DividendsFilter
						startDate={startDate}
						endDate={endDate}
						setStartDate={setStartDate}
						setEndDate={setEndDate}
					/>
					<div className="earning-block">
						<div className="earning-profile">
							<div className="earning-avatars">
								<div className="earning-avatars__item">
									<img className="avatar-img" src={user?.data?.avatar_path || loginAvatar} alt="" />
								</div>
								<div className="earning-avatars__item earning-avatars__item--level">
									<img src={level} alt="" />
								</div>
							</div>
							<div className="profile-info">
								<p className="profile-name">
									{user?.data?.first_name} {user?.data?.last_name}
								</p>
								<div className="profile-level">
									<p className="profile-level__text">Platinum level</p>
									<p className="profile-level__number">1</p>
								</div>
							</div>
						</div>

						<div className="earning-stats">
							<div className="earning-stats__item">
								<div className="earning-stats__name">Infinity referral b2c GATO</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gatoCoin && moneyFormat(profits?.referral_profit[gatoCoin?.asset.id], 2, 8)) ||
										'0.00'}
								</div>
							</div>
							{/* <div className="earning-stats__item">
								<div className="earning-stats__name">Infinity referal b2c GUSD</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gusdToken &&
										moneyFormat(profits?.referral_profit[gusdToken?.asset.id], 2, 18)) ||
										'0.00'}
								</div>
							</div> */}
							<div className="earning-stats__item">
								<div className="earning-stats__name">Dividends GATO</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gatoCoin && moneyFormat(profits?.dividends?.GATO, 2, 8)) || '0.00'}
								</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Dividends GLAM</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gatoCoin && moneyFormat(profits?.dividends?.GAMB, 2, 8)) || '0.00'}
								</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Dividends GFIVE</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gatoCoin && moneyFormat(profits?.dividends?.GTANK, 2, 8)) || '0.00'}
								</div>
							</div>
							{/* <div className="earning-stats__item">
								<p className="earning-stats__name">Dividends GUSD</p>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gusdToken && moneyFormat(profits?.dividends[gusdToken?.asset.id], 2, 18)) ||
										'0.00'}
								</div>
							</div> */}
							<div className="earning-stats__item">
								<div className="earning-stats__name">Staking reward</div>
								<div className="earning-stats__number earning-stats__number--type2">
									{(gatoCoin && moneyFormat(profits?.staking[gatoCoin?.asset.id], 2, 18)) || '0.00'}
								</div>
							</div>
							<div className="earning-stats__item">
								<div className="earning-stats__name">Infinity referral b2b</div>
								<div className="earning-stats__number earning-stats__number--type2">0</div>
							</div>
						</div>

						<div className="total-earn">
							<p className="total-earn__text">Total Earn</p>
							<div className="total-earn__wrapper">
								<p className="total-earn__number">
									{moneyFormat(profits?.total_profit_in_gato, 2, 18)}
								</p>
								<div className="total-earn__icon">
									<img src={token} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="switch-btns switch-btns--big">
					<NavLink className="switch-btns__item active" to="/staking">
						<span className="switch-btns__item-icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									opacity="0.4"
									d="M9.99 17.98C14.4028 17.98 17.98 14.4028 17.98 9.99C17.98 5.57724 14.4028 2 9.99 2C5.57724 2 2 5.57724 2 9.99C2 14.4028 5.57724 17.98 9.99 17.98Z"
									fill="#335BE9"
								/>
								<path
									d="M21.9681 15.99C21.9681 19.29 19.2881 21.97 15.9881 21.97C13.9481 21.97 12.1581 20.95 11.0781 19.4C15.4381 18.91 18.9081 15.44 19.3981 11.08C20.9481 12.16 21.9681 13.95 21.9681 15.99Z"
									fill="#335BE9"
								/>
								<path
									d="M11.45 9.71003L9.05 8.87003C8.81 8.79003 8.76 8.77003 8.76 8.42003C8.76 8.16003 8.94 7.95003 9.17 7.95003H10.67C10.99 7.95003 11.25 8.24003 11.25 8.60003C11.25 9.01003 11.59 9.35003 12 9.35003C12.41 9.35003 12.75 9.01003 12.75 8.60003C12.75 7.45003 11.86 6.51003 10.75 6.46003V6.41003C10.75 6.00003 10.41 5.66003 10 5.66003C9.59 5.66003 9.25 5.99003 9.25 6.41003V6.46003H9.16C8.11 6.46003 7.25 7.34003 7.25 8.43003C7.25 9.38003 7.67 9.99003 8.54 10.29L10.95 11.13C11.19 11.21 11.24 11.23 11.24 11.58C11.24 11.84 11.06 12.05 10.83 12.05H9.33C9.01 12.05 8.75 11.76 8.75 11.4C8.75 10.99 8.41 10.65 8 10.65C7.59 10.65 7.25 10.99 7.25 11.4C7.25 12.55 8.14 13.49 9.25 13.54V13.6C9.25 14.01 9.59 14.35 10 14.35C10.41 14.35 10.75 14.01 10.75 13.6V13.55H10.84C11.89 13.55 12.75 12.67 12.75 11.58C12.75 10.63 12.32 10.02 11.45 9.71003Z"
									fill="#335BE9"
								/>
							</svg>
						</span>
						<span>Staking</span>
					</NavLink>
					<NavLink className="switch-btns__item" to="/dividends">
						<span className="switch-btns__item-icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									opacity="0.4"
									d="M19.3009 7.91998V13.07C19.3009 16.15 17.5409 17.47 14.9009 17.47H6.11093C5.66093 17.47 5.23093 17.43 4.83093 17.34C4.58093 17.3 4.34094 17.23 4.12094 17.15C2.62094 16.59 1.71094 15.29 1.71094 13.07V7.91998C1.71094 4.83998 3.47093 3.52002 6.11093 3.52002H14.9009C17.1409 3.52002 18.7509 4.47001 19.1809 6.64001C19.2509 7.04001 19.3009 7.44998 19.3009 7.91998Z"
								/>
								<path d="M22.2981 10.92V16.07C22.2981 19.15 20.5381 20.47 17.8981 20.47H9.10812C8.36812 20.47 7.69813 20.37 7.11813 20.15C5.92813 19.71 5.11812 18.8 4.82812 17.34C5.22813 17.43 5.65812 17.47 6.10812 17.47H14.8981C17.5381 17.47 19.2981 16.15 19.2981 13.07V7.91998C19.2981 7.44998 19.2581 7.03001 19.1781 6.64001C21.0781 7.04001 22.2981 8.37998 22.2981 10.92Z" />
								<path d="M10.4994 13.14C11.9574 13.14 13.1394 11.958 13.1394 10.5C13.1394 9.04197 11.9574 7.85999 10.4994 7.85999C9.04136 7.85999 7.85938 9.04197 7.85938 10.5C7.85938 11.958 9.04136 13.14 10.4994 13.14Z" />
								<path d="M4.78125 8.25C4.37125 8.25 4.03125 8.59 4.03125 9V12C4.03125 12.41 4.37125 12.75 4.78125 12.75C5.19125 12.75 5.53125 12.41 5.53125 12V9C5.53125 8.59 5.20125 8.25 4.78125 8.25Z" />
								<path
									d="M16.2109 8.25C15.8009 8.25 15.4609 8.59 15.4609 9V12C15.4609 12.41 15.8009 12.75 16.2109 12.75C16.6209 12.75 16.9609 12.41 16.9609 12V9C16.9609 8.59 16.6309 8.25 16.2109 8.25Z"
									fill="white"
								/>
							</svg>
						</span>
						<span>Gato Dividends</span>
					</NavLink>
					<NavLink className="switch-btns__item" to="/referral">
						<span className="switch-btns__item-icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M16.6495 20.2V20.49C15.1695 21.44 13.3995 22 11.4995 22C9.56953 22 7.76953 21.42 6.26953 20.43V20.2C6.26953 17.87 8.59953 15.97 11.4595 15.97C14.3295 15.97 16.6495 17.87 16.6495 20.2Z" />
								<path
									opacity="0.4"
									d="M21 12.5C21 15.85 19.27 18.79 16.65 20.49V20.2C16.65 17.87 14.33 15.97 11.46 15.97C8.6 15.97 6.27 17.87 6.27 20.2V20.43C3.7 18.73 2 15.82 2 12.5C2 7.25 6.25 3 11.5 3C12.81 3 14.06 3.25999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C20.73 9.92998 21 11.19 21 12.5Z"
								/>
								<path d="M21.97 2.33002C21.25 1.51002 20.18 1 19 1C17.88 1 16.86 1.46002 16.13 2.21002C15.71 2.64002 15.39 3.15999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C21.17 8.49998 21.94 7.87 22.42 7.06C22.63 6.72 22.79 6.32999 22.88 5.92999C22.96 5.62999 23 5.32 23 5C23 3.98 22.61 3.04002 21.97 2.33002ZM20.49 5.72998H19.75V6.51001C19.75 6.92001 19.41 7.26001 19 7.26001C18.59 7.26001 18.25 6.92001 18.25 6.51001V5.72998H17.51C17.1 5.72998 16.76 5.38998 16.76 4.97998C16.76 4.56998 17.1 4.22998 17.51 4.22998H18.25V3.52002C18.25 3.11002 18.59 2.77002 19 2.77002C19.41 2.77002 19.75 3.11002 19.75 3.52002V4.22998H20.49C20.9 4.22998 21.24 4.56998 21.24 4.97998C21.24 5.38998 20.91 5.72998 20.49 5.72998Z" />
								<path d="M11.4584 14.73C13.0104 14.73 14.2684 13.4719 14.2684 11.92C14.2684 10.3681 13.0104 9.10999 11.4584 9.10999C9.90651 9.10999 8.64844 10.3681 8.64844 11.92C8.64844 13.4719 9.90651 14.73 11.4584 14.73Z" />
							</svg>
						</span>
						<span>Refer a Friend</span>
					</NavLink>
					<NavLink className="switch-btns__item" to="/infinity">
						<span className="switch-btns__item-icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" />
								<path d="M19.5 8C20.8807 8 22 6.88071 22 5.5C22 4.11929 20.8807 3 19.5 3C18.1193 3 17 4.11929 17 5.5C17 6.88071 18.1193 8 19.5 8Z" />
								<path
									opacity="0.4"
									d="M20.72 9.31001C21.35 9.11001 22 9.60002 22 10.27V15.51C22 19.01 20 20.51 17 20.51H7C4 20.51 2 19.01 2 15.51V8.51001C2 5.01001 4 3.51001 7 3.51001H14.61C15.26 3.51001 15.7 4.11002 15.58 4.74002C15.46 5.33002 15.48 5.96002 15.66 6.61002C16.03 7.95002 17.12 9.02001 18.46 9.37001C19.25 9.57001 20.02 9.53001 20.72 9.31001Z"
								/>
								<path d="M11.9988 12.87C11.1588 12.87 10.3088 12.61 9.6588 12.08L6.5288 9.58002C6.2088 9.32002 6.14881 8.85002 6.4088 8.53002C6.66881 8.21002 7.13879 8.15003 7.45879 8.41003L10.5888 10.91C11.3488 11.52 12.6388 11.52 13.3988 10.91L14.5788 9.97002C14.8988 9.71002 15.3788 9.76002 15.6288 10.09C15.8888 10.41 15.8388 10.89 15.5088 11.14L14.3288 12.08C13.6888 12.61 12.8388 12.87 11.9988 12.87Z" />
							</svg>
						</span>
						<span>Infinity Referral System </span>
					</NavLink>
				</div>
			</div>
		</section>
	);
};
