import Web3 from 'web3';
import { setLS, getLS } from 'components/Exchange/ExchangeForm/utils';
import { networks } from './networks';
// import { ethers } from 'ethers';
/* eslint-disable */
declare let window: any;

export const web3 = new Web3(process.env.REACT_APP_RPC_URL as string);
export const web3bsc = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/' as string);

const detecteProvider = () => {
	let provider;
	if (window?.ethereum) {
		provider = window?.ethereum;
	} else if (window?.web3) {
		provider = window?.web3.currentProvider;
	} else {
		console.log('No Ethereum browser detected! Check out MetaMask');
	}
	return provider;
};

// export const setupNetwork = async (chain?: string | number) => {
// 	// const provider = window?.ethereum;
// 	const provider = detecteProvider();

// 	if (provider) {
// 		const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
// 		const chainSwId: number = parseInt(String(chain), 10);
// 		const netChange = networks[Number(chain)];
// 		try {
// 			await provider.request({
// 				method: 'wallet_switchEthereumChain',
// 				params: [{ chainId: Web3.utils.toHex(chainSwId) }],
// 			});
// 			// setLS('chainId', chain);
// 			return true;
// 		} catch (switchError: any) {
// 			// 4902 error code indicates the chain is missing on the wallet
// 			if (switchError?.code === 4902 && chainSwId) {
// 				try {
// 					await provider.request({
// 						method: 'wallet_addEthereumChain',
// 						params: [
// 							// netChange,
// 							{
// 								chainId: Web3.utils.toHex(chainSwId),
// 								chainName: String(networks[chainSwId].chainName),
// 								nativeCurrency: {
// 									name: String(networks[chainSwId].nativeCurrency.name),
// 									symbol: String(networks[chainSwId].nativeCurrency.symbol),
// 									decimals: Number(networks[chainSwId].nativeCurrency.decimal),
// 								},
// 								rpcUrls: [...networks[chainSwId].rpcUrls],
// 								blockExplorerUrls: [...networks[chainSwId].blockExplorerUrls],
// 							},
// 						],
// 					});
// 					return true;
// 				} catch (error) {
// 					alert(error);
// 					alert('Failed to setup the network in Metamask:');
// 					console.error('Failed to setup the network in Metamask:', error);
// 					return false;
// 				}
// 			}
// 			return false;
// 		}
// 	} else {
// 		console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
// 		return false;
// 	}
// };
// import Web3 from 'web3';
// /* eslint-disable no-debugger */
// declare let window: any;

// // export const web3 = new Web3(
// // 	(window.location.hostname === 'localhost'
// // 		? process.env.REACT_APP_RPC_URL
// // 		: 'https://gatotest.corp.merehead.xyz:8545') as string,
// // );
// export const web3 = new Web3(process.env.REACT_APP_RPC_URL as string);
// // "wallet_switchEthereumChain"
// // wallet_addEthereumChain
// const detecteProvider = () => {
// 	let provider;
// 	if (window?.ethereum) {
// 		provider = window?.ethereum;
// 	} else if (window?.web3) {
// 		provider = window?.web3.currentProvider;
// 	} else {
// 	}
// 	return provider;
// };
export const setupNetwork = async (chain?: string | number) => {
	const provider = window?.ethereum;
	const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
	const networkName = String(process.env.REACT_APP_NETWORK_NAME);
	const chainSwId: number = parseInt(String(chain), 10);
	try {
		if (!window.ethereum) throw new Error('No crypto wallet found');
		await window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [
				{
					// chainId: `0x${chainId.toString(16)}`,
					// chainName: networkName,
					// nativeCurrency: {
					// 	name: 'GATO',
					// 	symbol: 'GATO',
					// 	decimals: 18,
					// },
					// rpcUrls: [String(process.env.REACT_APP_RPC_URL)],
					// blockExplorerUrls: [String(process.env.REACT_APP_EXPLORER)],
					// // rpcUrls: ['https://dev1.gatoblockchain.com:8545/'],
					// // blockExplorerUrls: ['https://dev1.gatoblockchain.com'],
					// // rpcUrls: ['https://dev1.gatoblockchain.com:8545/'],
					// // blockExplorerUrls: ['https://gatoscan.com'],
					chainId: Web3.utils.toHex(chainSwId),
					chainName: String(networks[chainSwId].chainName),
					nativeCurrency: {
						name: String(networks[chainSwId].nativeCurrency.name),
						symbol: String(networks[chainSwId].nativeCurrency.symbol),
						decimals: Number(networks[chainSwId].nativeCurrency.decimal),
					},
					rpcUrls: [...networks[chainSwId].rpcUrls],
					blockExplorerUrls: [...networks[chainSwId].blockExplorerUrls],
				},
			],
		});
		return true;
	} catch (err) {
		alert(err);
		return false;
	}
};
// export const setupNetwork = async () => {
// 	const provider = window?.ethereum;
// 	const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
// 	const networkName = String(process.env.REACT_APP_NETWORK_NAME);
// 	// const provider = detecteProvider();
// 	// debugger;
// 	try {
// 		if (!window.ethereum) throw new Error('No crypto wallet found');
// 		await window.ethereum.request({
// 			method: 'wallet_addEthereumChain',
// 			params: [
// 				{
// 					chainId: `0x${chainId.toString(16)}`,
// 					chainName: networkName,
// 					nativeCurrency: {
// 						name: 'tBNB',
// 						symbol: 'tBNB',
// 						decimals: 18,
// 					},
// 					rpcUrls: [String(process.env.REACT_APP_RPC_URL)],
// 					blockExplorerUrls: ['https://testnet.bscscan.com/'],
// 				},
// 			],
// 		});
// 		return true;
// 	} catch (err) {
// 		alert(err);
// 		return false;
// 	}
// };
// 	// try {
// 	// 	if (!window.ethereum) throw new Error('No crypto wallet found');
// 	// 	await window.ethereum.request({
// 	// 		method: 'wallet_addEthereumChain',
// 	// 		params: [
// 	// 			{
// 	// 				chainId: `0x${chainId.toString(16)}`,
// 	// 				chainName: 'Gato Testnet',
// 	// 				nativeCurrency: {
// 	// 					name: 'GATO',
// 	// 					symbol: 'GATO',
// 	// 					decimals: 18,
// 	// 				},
// 	// 				rpcUrls: ['https://dev2.gatoblockchain.com:8545/dev/'],
// 	// 				blockExplorerUrls: ['https://dev1.gatoblockchain.com'],
// 	// 			},
// 	// 		],
// 	// 	});
// 	// 	return true;
// 	// } catch (err) {
// 	// 	alert(err);
// 	// 	return false;
// 	// }
// 	// try {
// 	// 	if (!window.ethereum) throw new Error('No crypto wallet found');
// 	// 	await window.ethereum.request({
// 	// 		method: 'wallet_addEthereumChain',
// 	// 		params: [
// 	// 			{
// 	// 				chainId: `0x${Number(25).toString(16)}`,
// 	// 				chainName: 'Cronos',
// 	// 				nativeCurrency: {
// 	// 					name: 'CRONOS',
// 	// 					symbol: 'CRONOS',
// 	// 					decimals: 18,
// 	// 				},
// 	// 				rpcUrls: ['https://evm.cronos.org', 'https://cronos-rpc.elk.finance/'],
// 	// 				blockExplorerUrls: ['https://cronos-evm.publicnode.com'],
// 	// 			},
// 	// 		],
// 	// 	});
// 	// 	return true;
// 	// } catch (err) {
// 	// 	alert(err);
// 	// 	return false;
// 	// }
// 	if (provider) {
// 		const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
// 		try {
// 			await provider.request({
// 				method: 'wallet_addEthereumChain',
// 				params: [
// 					{
// 						chainId: `0x${chainId.toString(16)}`,
// 						chainName: 'Gato Testnet',
// 						nativeCurrency: {
// 							name: 'GATO',
// 							symbol: 'GATO',
// 							decimals: 18,
// 						},
// 						rpcUrls: [String(process.env.REACT_APP_RPC_URL)],
// 						// rpcUrls: ['https://dev2.gatoblockchain.com:8545/dev/'],
// 						blockExplorerUrls: [String(process.env.EXPLORER)],
// 					},
// 				],
// 			});

// 			return true;
// 		} catch (error) {
// 			alert('Failed to setup the network in Metamask:');
// 			console.error('Failed to setup the network in Metamask:', error);
// 			return false;
// 		}
// 	} else {
// 		console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
// 		return false;
// 	}
// };
// export const setupNetwork = async () => {
// 	const provider = window?.ethereum;
// 	// const provider = detecteProvider();
// 	if (provider) {
// 		const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
// 		try {
// 			await provider.request({
// 				method: 'wallet_switchEthereumChain',
// 				params: [{ chainId: `0x${chainId.toString(16)}` }],
// 			});
// 			return true;
// 		} catch (switchError: any) {
// 			// 4902 error code indicates the chain is missing on the wallet
// 			if (switchError?.code === 4902) {
// 				try {
// 					await provider.request({
// 						method: 'wallet_addEthereumChain',
// 						params: [
// 							{
// 								chainId: `0x${chainId.toString(16)}`,
// 								chainName: 'Gato Testnet',
// 								nativeCurrency: {
// 									name: 'GATO',
// 									symbol: 'GATO',
// 									decimals: 18,
// 								},
// 								rpcUrls: [
// 									// 'https://gatotest.corp.merehead.xyz:8545/new/',
// 									'https://dev2.gatoblockchain.com:8545/prod/',
// 									// 'http://gatotest.corp.merehead.xyz:29944',
// 								],
// 								// blockExplorerUrls: ['https://gatotest.corp.merehead.xyz/'],
// 								blockExplorerUrls: ['https://gatoscan.com/'],
// 							},
// 						],
// 					});
// 					return true;
// 				} catch (error) {
// 					console.error('Failed to setup the network in Metamask:', error);
// 					return false;
// 				}
// 			}
// 			return false;
// 		}
// 	} else {
// 		console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
// 		return false;
// 	}
// };

// // 'https://gatotest.corp.merehead.xyz:8545/',
// // method: 'wallet_addEthereumChain',
