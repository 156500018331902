import { TPoolsItem, TPair } from 'redux/reducers/swap/types';
import { BigNumber } from 'ethers';
import { noExponent } from 'services/utils/noExponent';
/* eslint-disable */
const calculateAmountOut = (
	amountIn: string,
	reserveBase0: string,
	reserveBase1: string,
	decimals: number,
) => {
	const reserve0 = noExponent(Math.floor(Number(reserveBase0)));
	const reserve1 = noExponent(Math.floor(Number(reserveBase1)));
	const amountWithDecimals = Number(Math.floor(Number(amountIn))) * 10 ** decimals;
	const amountInBN = BigNumber.from(String(noExponent(Math.floor(amountWithDecimals))));
	const amountInWithFee = amountInBN.mul('99');
	const numerator: any = amountInWithFee.mul(BigNumber.from(String(reserve1)));
	const denominator: any = BigNumber.from(String(reserve0)).mul(100).add(String(amountInWithFee));
	return Math.floor(Number(numerator / denominator));
};

type TlpToken = {
	amount0: string;
	amount1: string;
};
// convert desimals

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};

export const caluclateAmountPerToken = (lpToken: TlpToken, pair: TPair | undefined) => {
	const amountIn = '1';
	const reverseSum0 =
		Number(pair?.reserve_base) -
		Number(numberInDecimal(Number(lpToken.amount0), Number(pair?.asset_base.decimals)));
	const reverseSum1 =
		Number(pair?.reserve_quote) -
		Number(numberInDecimal(Number(lpToken.amount1), Number(pair?.asset_quote.decimals)));
	let value0 = 0;
	let value1 = 0;

	if (reverseSum0 && reverseSum1) {
		value0 = calculateAmountOut(
			amountIn,
			String(reverseSum0),
			String(reverseSum1),
			Number(pair?.asset_base.decimals),
		);
		value1 = calculateAmountOut(
			amountIn,
			String(reverseSum1),
			String(reverseSum0),
			Number(pair?.asset_quote.decimals),
		);
	}

	const perFrom = numberOutDecimal(value0, Number(pair?.asset_quote.decimals));
	const perTo = numberOutDecimal(value1, Number(pair?.asset_base.decimals));

	return { amount0: String(perFrom), amount1: String(perTo) };
};

// remove Liquidity

export const calculateRemoveLiquidityReceiveTokens = (
	liquidity: string,
	pair: TPoolsItem,
	_totalSupply: number,
) => {

	// const amountOut0 = Math.floor(
	// 	Number(
	// 		BigNumber.from(String(Math.floor(Number(noExponent(Math.floor(Number(liquidity))))))).mul(
	// 			String(noExponent(Math.floor(Number(pair?.pair?.reserve_base)))),
	// 		),
	// 	) / _totalSupply,
	// ); // using balances ensures pro-rata distribution

	const totalSp = BigNumber.from(String(noExponent(_totalSupply)));
	const amountOut0 = BigNumber.from(noExponent(liquidity))
		.mul(pair?.pair?.reserve_base)
		.div(totalSp)
		.toString();

	// const amountOut1 = Math.floor(
	// 	Number(
	// 		BigNumber.from(String(Math.floor(Number(noExponent(Math.floor(Number(liquidity))))))).mul(
	// 			String(noExponent(Math.floor(Number(pair?.pair?.reserve_quote)))),
	// 		),
	// 	) / _totalSupply,
	// );
	const amountOut1 = BigNumber.from(noExponent(liquidity))
		.mul(pair?.pair?.reserve_quote)
		.div(totalSp)
		.toString();

	const amount0 =
		numberOutDecimal(Number(amountOut0), Number(pair?.pair.asset_base?.decimals)) || 0;
	const amount1 =
		numberOutDecimal(Number(amountOut1), Number(pair?.pair?.asset_quote?.decimals)) || 0;

	return { amount0, amount1 };
};
