import { FC, useEffect, useState, ChangeEvent } from 'react';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettingsDataLoader } from 'redux/reducers/settings/selectors';
import { ChangePassword } from './ChangePassword';
import { TSubmitValue } from '../Profile/AccountDetails/type';
import { AccountDetails } from '../Profile/AccountDetails';

export const Information: FC = () => {
	const loadingDataLoader = useSelector(getUserSettingsDataLoader);

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<ChangePassword />
					{loadingDataLoader && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
