import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { RegistrForm } from 'components/Forms/RegistrForm';
import { Auth } from 'layouts/Auth';
import { IRegistrValues } from 'components/Forms/RegistrForm/types';
import { registrationRequest } from 'redux/reducers/auth/reducer';
import { RefferalModal } from 'components/Forms/RefferalModal';

/* eslint-disable no-debugger */
export const Registration: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { search } = location;

	const [openModal, setOpenModal] = useState(false);

	const referral = queryString.parse(search).ref || null;

	useEffect(() => {
		if (!referral) setOpenModal(true);
	}, [referral]);

	const handleRegistrSubmit = (values: IRegistrValues): void => {
		if (!values) return;
		const data = {
			...values,
			[`${String(values.type) === 'phone' ? 'phone' : 'email'}`]: values.phone
				? `+${values?.phone}`
				: String(values?.email).toLowerCase(),
		};
		if (referral) {
			data.referral = String(referral);
		}
		dispatch(
			registrationRequest({
				data,
				navigate,
			}),
		);
	};

	return (
		<Auth title="Auth">
			<section className="authorization-section">
				<div className="container">
					{referral ? (
						<div className="authorization-info">
							<p className="authorization-info__text">
								<span className="authorization-info__icon">
									<svg
										width="16"
										height="17"
										viewBox="0 0 16 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.9987 15.1663C11.6654 15.1663 14.6654 12.1663 14.6654 8.49967C14.6654 4.83301 11.6654 1.83301 7.9987 1.83301C4.33203 1.83301 1.33203 4.83301 1.33203 8.49967C1.33203 12.1663 4.33203 15.1663 7.9987 15.1663Z"
											stroke="#C0C0CF"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8 7.83301V11.1663"
											stroke="#C0C0CF"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8 5.83301H8.00599"
											stroke="#C0C0CF"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								You received an invitation to Gatochain
							</p>
						</div>
					) : null}
					<div className="authorization-box">
						<div className="authorization-form">
							<RegistrForm registrSubmit={handleRegistrSubmit} />
						</div>
					</div>
				</div>
			</section>
			<RefferalModal openModal={openModal} setOpenModal={setOpenModal} />
		</Auth>
	);
};
