import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CopySvg, PlusGrayButton, SearchIcon } from 'assets/custom-icons';
import { useDebounce } from 'hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';

import { getToken } from 'redux/reducers/swap/selectors';
import { trimLink } from 'services/utils/textSlice';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';
import ChartBlockAll from 'components/ChartBlockAll';
import { getTLVRequest } from 'redux/reducers/swap/reducer';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import TopPoolsTable from '../TopPoolsTable';
import TransactionsTable from '../TransactionsTable';

export const OneTokenOverview: FC = () => {
	const dispatch = useDispatch();
	const { tokenId } = useParams();
	const token = useSelector(getToken);
	const navigate = useNavigate();
	const moneyFormat = useMoneyFormat();

	const [searchValue, setSearchValue] = useState('');
	const [lastPrice, setLastPrice] = useState<{ last_price: number; percent: string }>({
		last_price: 0,
		percent: '0',
	});
	const debouncedSearch = useDebounce(searchValue, 500);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};

	const handleClickBuy = () => {
		if (token) {
			navigate('/exchange', {
				state: { initToSymbol: { address: token.address, symbol: token.symbol } },
			});
		}
	};

	useEffect(() => {
		if (tokenId) {
			dispatch(getTLVRequest(Number(tokenId)));
			window.scrollTo(0, 0);
		}
	}, [dispatch, tokenId]);

	return (
		token && (
			<div className="tokens">
				<p className="block-subtitle block-subtitle--type4">
					Click on token adress to see more information
				</p>
				<div className="breadcrumbs">
					<ul className="breadcrumbs__list">
						<li className="breadcrumbs__item">
							<Link to="/">Home</Link>
						</li>
						<li className="breadcrumbs__item active">
							<Link to="/pool-token">Tokens</Link>
						</li>
						<li className="breadcrumbs__item breadcrumbs__item--hide-arrow active">
							{token?.symbol}
							<Link to={`/token/details/${token.address}/transfers`}>
								<span className="breadcrumbs__hex">({token?.address}) </span>
							</Link>
						</li>
					</ul>
					<CopyToClipboard text={token?.address} onCopy={handleCopy}>
						<button type="button" className="tokens-breadcrumbs-button" aria-label="BreadCrumbs">
							<CopySvg />
						</button>
					</CopyToClipboard>
				</div>
				<div className="tokens-header tokens-header--mb-16">
					<div className="tokens-header__info">
						<div className="token-box">
							<div className="token-box__icon-3">
								<img className="token-box__icon-1" src={token.logo} alt="" />
							</div>
							<div className="token-box__name token-box__name--small">{token.symbol} </div>
						</div>
						<div className="token-prices">
							<p className="token-prices__number">${moneyFormat(lastPrice.last_price, 2, 9)}</p>
							<p
								className={`hour-change hour-change--${
									Number(lastPrice.percent) < 0 ? 'red' : 'green'
								}`}
							>
								<span>{lastPrice.percent}%</span>
							</p>
						</div>
					</div>
					<div className="tokens-wrapper">
						<div className="search-input search-input--tokens">
							<div className="input-wrapper">
								<input
									type="text"
									className="input-item input-item input-item--left-icon"
									placeholder="Search pools or tokens"
									value={searchValue}
									onChange={handleChange}
								/>
								<span className="input-icon">
									<SearchIcon />
								</span>
							</div>
						</div>
						<div className="token-top-btns">
							<Link to="/pool" className="button button--type4 button--full-width">
								<span className="token-btn-icon">
									<PlusGrayButton />
								</span>
								Add Liquidity
							</Link>
							<button
								onClick={handleClickBuy}
								className="button button--full-width button--type3"
								type="button"
							>
								SWAP
							</button>
							<button type="button" onClick={handleClickBuy} className="button button--full-width">
								Buy
							</button>
						</div>
					</div>
				</div>
				<ChartBlockAll setLastPrice={setLastPrice} type="price" />
				<ChartBlockAll setLastPrice={setLastPrice} type="swap" />
				<TopPoolsTable searchValue={debouncedSearch} id={token.symbol} title="Pools" />
				<TransactionsTable searchValue={debouncedSearch} />
			</div>
		)
	);
};
