import { FC } from 'react';
import { ChangePasswordForm } from 'components/Forms/ChangePassForm';
import { TChangePasswordFormData } from 'components/Forms/ChangePassForm/types';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserPassRequest } from 'redux/reducers/settings/reducer';
import { getChangePassLoader } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';

export const ChangePassword: FC = () => {
	const changePassLoader = useSelector(getChangePassLoader);
	const dispatch = useDispatch();

	const handleChangePass = (values: TChangePasswordFormData) => {
		dispatch(changeUserPassRequest(values));
	};
	return (
		<div className="authorization-form">
			<div className="authorization-form__header">
				<p className="form-title ">Change Password</p>
			</div>
			<ChangePasswordForm changePassSubmit={handleChangePass} />
			{changePassLoader && (
				<div className="auth-loader-wrapper">
					<Loader />
				</div>
			)}
		</div>
	);
};
