import { FC } from 'react';

export const NoDataTable: FC = () => {
	return (
		<div className="table-body-empty">
			<div className="table-body-empty__icon">
				<svg
					width="54"
					height="54"
					viewBox="0 0 54 54"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M49.5 22.5V33.75C49.5 45 45 49.5 33.75 49.5H20.25C9 49.5 4.5 45 4.5 33.75V20.25C4.5 9 9 4.5 20.25 4.5H31.5"
						stroke="#8E8EA9"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M49.5 22.5H40.5C33.75 22.5 31.5 20.25 31.5 13.5V4.5L49.5 22.5Z"
						stroke="#8E8EA9"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M15.75 29.25H29.25"
						stroke="#335BE9"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M15.75 38.25H24.75"
						stroke="#335BE9"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			<div className="table-body-empty__text">No Data</div>
		</div>
	);
};
