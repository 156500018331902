import { FC, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { TWalletData } from 'redux/reducers/wallets/types';
import { useSelector, useDispatch } from 'react-redux';
import btc from 'assets/images/tokens/btc.png';
import { toDecimalsAfterPoint } from 'services/utils/numbers';
import gatochain3 from 'assets/images/gatochain3.svg';
import gatochain2 from 'assets/images/gatochain2.svg';
import shape from 'assets/images/shape.png';
import { CloseEye, OpenEye } from 'assets/custom-icons';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import shape2 from 'assets/images/shape-2.png';
import shape3 from 'assets/images/shape-3.png';
import tick from 'assets/images/tick.png';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { HistoryModal } from 'components/Bridge/HistoryModal';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
/* eslint-disable */
export const Overview: FC = () => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const address = useSelector(getAddress);
	const walletsUser = useSelector(getWalletsUser);
	const [openModal, setOpenModal] = useState(false);
	const user = useSelector(getUserSettingsData);
	const [hideBalances, setHideBalances] = useState(false);
	const gatoCoin: TWalletData | undefined | null =
		walletsUser &&
		walletsUser.balances?.data?.find(
			(item: TWalletData) =>
				item.asset && item.asset.symbol === 'GATO' && item.asset.type === 'coin',
		);
	const handleHideBalances = () => {
		setHideBalances(!hideBalances);
	};

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
				}),
			);
		}
	}, [dispatch, isAuthenticated, address]);

	const handleOpenModal = () => {
		setOpenModal(!openModal);
	};
	return (
		<section className="earning-section earning-section--type2">
			<div className="container">
				<div className="switch-btns">
					<Link to="/overviews" className="switch-btns__item active ">
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M3.75 8H5.25C6.75 8 7.5 7.25 7.5 5.75V4.25C7.5 2.75 6.75 2 5.25 2H3.75C2.25 2 1.5 2.75 1.5 4.25V5.75C1.5 7.25 2.25 8 3.75 8Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M12.75 8H14.25C15.75 8 16.5 7.25 16.5 5.75V4.25C16.5 2.75 15.75 2 14.25 2H12.75C11.25 2 10.5 2.75 10.5 4.25V5.75C10.5 7.25 11.25 8 12.75 8Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M15.0914 16.6517L16.1521 15.591C17.2128 14.5303 17.2128 13.4697 16.1521 12.409L15.0914 11.3483C14.0308 10.2877 12.9701 10.2877 11.9095 11.3483L10.8488 12.409C9.78814 13.4697 9.78814 14.5303 10.8488 15.591L11.9095 16.6517C12.9701 17.7123 14.0308 17.7123 15.0914 16.6517Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.75 17H5.25C6.75 17 7.5 16.25 7.5 14.75V13.25C7.5 11.75 6.75 11 5.25 11H3.75C2.25 11 1.5 11.75 1.5 13.25V14.75C1.5 16.25 2.25 17 3.75 17Z"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Overview</span>
					</Link>
					<Link
						to="/wallets"
						state=""
						className="switch-btns__item switch-btns__item--mob-order-2 "
					>
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.125 10.8125C7.125 11.54 7.68751 12.125 8.37751 12.125H9.78749C10.3875 12.125 10.875 11.6151 10.875 10.9776C10.875 10.2951 10.575 10.0476 10.1325 9.89005L7.875 9.10255C7.4325 8.94505 7.13251 8.70505 7.13251 8.01505C7.13251 7.38505 7.61999 6.86755 8.21999 6.86755H9.63C10.32 6.86755 10.8825 7.45255 10.8825 8.18005"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9 6.125V12.875"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<circle cx="9" cy="9.5" r="6.75" stroke="#1A203F" strokeWidth="1.5" />
							</svg>
						</span>
						<span>Wallet</span>
					</Link>
					<Link
						to="/earnings-overview"
						className="switch-btns__item switch-btns__item--mob-order-3"
					>
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.5234 14.735H5.47344C5.15844 14.735 4.80594 14.4875 4.70094 14.1875L1.59594 5.50252C1.15344 4.25752 1.67094 3.87502 2.73594 4.64002L5.66094 6.73252C6.14844 7.07002 6.70344 6.89752 6.91344 6.35002L8.23344 2.83252C8.65344 1.70752 9.35094 1.70752 9.77094 2.83252L11.0909 6.35002C11.3009 6.89752 11.8559 7.07002 12.3359 6.73252L15.0809 4.77502C16.2509 3.93502 16.8134 4.36252 16.3334 5.72002L13.3034 14.2025C13.1909 14.4875 12.8384 14.735 12.5234 14.735Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.875 17H13.125"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7.125 11H10.875"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Earnings & Rewards</span>
					</Link>
					<Link to="/exchange" className="switch-btns__item">
						<span className="switch-btns__item-icon">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.5 6.875C16.5 9.5675 14.3175 11.75 11.625 11.75C11.4975 11.75 11.3625 11.7425 11.235 11.735C11.0475 9.35751 9.14249 7.45249 6.76499 7.26499C6.75749 7.13749 6.75 7.0025 6.75 6.875C6.75 4.1825 8.9325 2 11.625 2C14.3175 2 16.5 4.1825 16.5 6.875Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M11.25 12.125C11.25 14.8175 9.0675 17 6.375 17C3.6825 17 1.5 14.8175 1.5 12.125C1.5 9.4325 3.6825 7.25 6.375 7.25C6.5025 7.25 6.63749 7.25749 6.76499 7.26499C9.14249 7.45249 11.0475 9.35751 11.235 11.735C11.2425 11.8625 11.25 11.9975 11.25 12.125Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M4.1925 2H2.25C1.8375 2 1.5 2.3375 1.5 2.75V4.6925C1.5 5.36 2.30999 5.69749 2.78249 5.22499L4.72499 3.28249C5.18999 2.80999 4.86 2 4.1925 2Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M13.8083 16.9999H15.7508C16.1633 16.9999 16.5008 16.6624 16.5008 16.2499V14.3074C16.5008 13.6399 15.6908 13.3025 15.2183 13.775L13.2758 15.7174C12.8108 16.1899 13.1408 16.9999 13.8083 16.9999Z"
									stroke="#1A203F"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span>Exchange</span>
					</Link>
				</div>
				<div className="table-panel">
					<div className="table-panel__title">
						<h3 className="block-title block-title--smaller ">Wallet</h3>
					</div>
					<div className="filter-right-block">
						<NavLink to="/wallets" state="recent" className="back-history">
							<span className="back-history__icon">
								<svg
									width="16"
									height="17"
									viewBox="0 0 16 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M14 5.16683V11.8335C14 13.8335 13 15.1668 10.6667 15.1668H5.33333C3 15.1668 2 13.8335 2 11.8335V5.16683C2 3.16683 3 1.8335 5.33333 1.8335H10.6667C13 1.8335 14 3.16683 14 5.16683Z"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M9.66797 3.5V4.83333C9.66797 5.56667 10.268 6.16667 11.0013 6.16667H12.3346"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.33203 9.1665H7.9987"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.33203 11.8335H10.6654"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							History
						</NavLink>
					</div>
				</div>
				<div className="overview-blocks">
					<div className="overview-blocks__left">
						<div className="overview-block">
							<div className="overview-header">
								<div className="hide-balance">
									<p className="hide-balance__text">Total Estimated Balance</p>
									<button type="button" onClick={handleHideBalances} className="hide-btn">
										{hideBalances ? <OpenEye /> : <CloseEye />}
									</button>
								</div>
								<button type="button" className="icon-btn">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
											stroke="#8E8EA9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M15.7089 15.18L12.6089 13.33C12.0689 13.01 11.6289 12.24 11.6289 11.61V7.51001"
											stroke="#8E8EA9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</div>
							<div className="wallet-balance wallet-balance--mb-0">
								<div className="receive-price">
									<p className="receive-price__number">
										{hideBalances
											? `$${toDecimalsAfterPoint(String(walletsUser?.balanceInUsd || 0), 6)}`
											: '**********'}
									</p>
								</div>
							</div>
						</div>
						<div className="overview-block">
							<div className="overview-header">
								<div className="hide-balance">
									<p className="hide-balance__text">Gato Balance</p>
									<button type="button" onClick={handleHideBalances} className="hide-btn">
										{hideBalances ? <OpenEye /> : <CloseEye />}
									</button>
								</div>
							</div>
							<div className="wallet-balance">
								<div className="receive-price">
									<p className="receive-price__number">
										{hideBalances
											? `${toDecimalsAfterPoint(String(gatoCoin?.balance || 0), 6)}`
											: '**********'}
									</p>
									<div className="receive-price__icon">
										<img src={gatochain2} alt="" />
									</div>
								</div>
								<p className="wallet-balance__value">
									≈{' '}
									{hideBalances
										? `$${toDecimalsAfterPoint(String(gatoCoin?.amount_in_usd || 0), 6)}`
										: '**********'}
								</p>
							</div>

							<div className="overview-block__lvl">
								<div className="text-icon text-icon--mb16">
									<div className="text-icon__wrapper">
										<p className="text-icon__text1">12%</p>
										<p className="text-icon__text2">of your portfolio Gato Tokens</p>
									</div>
									<div className="text-icon__icon">
										<img src={tick} alt="" />
									</div>
								</div>
								<div className="profile-level profile-level--space-b">
									<p className="profile-level__text">Platinum level</p>
									<p className="profile-level__number">6</p>
								</div>
							</div>
							<div className="overview-block__text">
								<div className="form-text">
									<p>
										Your Loyalty Level is based on the ratio of Gato Tokens to the other assets in
										your account.
									</p>
								</div>
							</div>
						</div>
						<div className="overview-block overview-block--grey">
							<div className="overview-header overview-header--mb32 overview-header--flex-start">
								<p className="overview-header__text">To achieve next level</p>
								<div className="overview-header__group">
									<div className="wallet-balance wallet-balance--mb-0">
										<div className="receive-price">
											<p className="receive-price__number receive-price__number--low receive-price__number--white">
												29.49230
											</p>
											<div className="receive-price__icon">
												<img src={gatochain3} alt="" />
											</div>
										</div>
										<p className="wallet-balance__percent">($96.16)</p>
									</div>
								</div>
							</div>

							<NavLink to="/exchange" className="button button--full-width">
								Buy Gato
							</NavLink>
						</div>
					</div>
					<div className="overview-blocks__right">
						<div className="overview-block">
							<div className="overview-header overview-header--br-b overview-header--mb16">
								<div className="overview-header__group">
									<h4 className="form-title form-title--fw-700 form-title--small">
										Borrow Benefits
									</h4>
									<p className="form-text form-text--mt-8 form-text--fw-600 form-text--red">
										Interest Rate
									</p>
								</div>
								<div className="overview-header__icon">
									<img src={shape} alt="" />
								</div>
							</div>
							<div className="overview-info ">
								<div className="form-text">
									<p>High LTV Interest Rate</p>
								</div>
								<p className="overview-info__procent">12.9%</p>
							</div>
							<div className="overview-info">
								<div className="form-text">
									<p>Low LTV Interest Rate</p>
								</div>
								<p className="overview-info__procent">-</p>
							</div>
						</div>
						<div className="overview-block">
							<div className="overview-header overview-header--br-b overview-header--mb16">
								<div className="overview-header__group">
									<h4 className="form-title form-title--fw-700 form-title--small">
										Exchange Benefits
									</h4>
									<p className="form-text form-text--mt-8 form-text--fw-600 form-text--blue">
										Utilize the GATO Exchange
									</p>
								</div>
								<div className="overview-header__icon">
									<img src={shape2} alt="" />
								</div>
							</div>
							<div className="overview-info ">
								<p className="overview-info__text">Exchange Cashback</p>
								<p className="overview-info__procent">0.10%</p>
							</div>
						</div>
						<div className="overview-block">
							<div className="overview-header overview-header--br-b overview-header--mb16">
								<div className="overview-header__group">
									<h4 className="form-title form-title--fw-700 form-title--small">
										Earn Benefits*
									</h4>
									<p className="form-text form-text--mt-8 form-text--fw-600 form-text--green">
										Interest Rate
									</p>
								</div>
								<div className="overview-header__icon">
									<img src={shape3} alt="" />
								</div>
							</div>
							<div className="overview-info ">
								<div className="form-text">
									<p>Cryptocurrencies</p>
								</div>
								<p className="overview-info__procent">4.25%</p>
							</div>
							<div className="overview-info ">
								<div className="form-text">
									<p>Fiat Currencies</p>
								</div>
								<p className="overview-info__procent">4.25%</p>
							</div>
							<div className="overview-info ">
								<div className="form-text">
									<p>Stablecoins</p>
								</div>
								<p className="overview-info__procent">8.25%</p>
							</div>
							<div className="overview-info">
								<div className="form-text">
									<p>GATO Tokens</p>
								</div>
								<p className="overview-info__procent">7%</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<HistoryModal openModal={openModal} setOpenModal={setOpenModal} />
		</section>
	);
};
