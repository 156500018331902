import { FC, ChangeEvent } from 'react';
import { AttentionY, AttentionRed } from 'assets/custom-icons';
import { trimLink } from 'services/utils/textSlice';
import { TTokenParams } from './type';

export const ConfirmToken: FC<TTokenParams> = ({ address, check, setCheck }) => {
	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;
		setCheck(checked);
	};
	return (
		<div className="popup-body">
			<div className="operation-details-box">
				<div className="operation-warning">
					<div className="operation-warning__icon">
						<AttentionY />
					</div>
					<p className="operation-warning__text">
						<span className="operation-warning__text-mb">
							Anyone can create a GEP20 token on GATO with any name, including creating fake
							versions of existing tokens and tokens that claim to represent projects that do not
							have a token.
						</span>
						<span>If you purchase an arbitrary token, you may be unable to sell is back.</span>
					</p>
				</div>
			</div>
			<p className="popup-warning">
				<span className="popup-warning__icon">
					<AttentionRed />
				</span>
				Unknown Source
			</p>
			<div className="popup-gato-view">
				<div className="popup-gato-view__info">
					<p>{trimLink(address)}</p>
				</div>
				{/* <a href="#" className="popup-gato-view__link">
					(View on Gato)
				</a> */}
			</div>
			<div className="checkbox checkbox--popup">
				<label className="checkbox__label">
					<input
						checked={check}
						onChange={handleCheckboxChange}
						type="checkbox"
						className="hidden"
					/>
					<span className="checkbox__item">
						<span className="checkbox__item-icon">
							<svg
								className="fill"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 26 26"
								width="26"
								height="26"
								fill="#1a1a1a"
							>
								<path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
							</svg>
						</span>
					</span>
					<span className="checkbox__text">I understand</span>
				</label>
			</div>
		</div>
	);
};
