import { FC } from 'react';
import empty from 'assets/images/empty-img.png';
import { TEmptyTable } from './type';

export const EmptyTable: FC<TEmptyTable> = ({ text }) => {
	return (
		<div className="table-body-empty">
			<div className="table-body-empty__icon table-body-empty__icon--bigger">
				<img src={empty} alt="" />
			</div>
			<div className="table-body-empty__text">{text}</div>
		</div>
	);
};
