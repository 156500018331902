import { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toMaxDecimals } from 'services/utils/numbers';
import { ReactComponent as CrossIcon } from 'assets/custom-icons/cross.svg';
import { ReactComponent as MagnifierIcon } from 'assets/custom-icons/magnifier.svg';
import { ITokenListProps } from './types';
/*eslint-disable */

export const TokenList: FC<ITokenListProps> = ({ title, list, onChange, onClose, openManage }) => {
	const [search, setSearch] = useState('');

	const filteredList = useMemo(() => {
		const normalizedSearch = search?.toLowerCase().trim();
		return list?.filter(({ chain_id }: any) => {
			// const isSymbolMatch = symbol?.toLowerCase().includes(normalizedSearch);
			const isIdMatch = String(chain_id).includes(normalizedSearch);
			return isIdMatch;
		});
	}, [list, search]);

	return (
		<div className="popup popup--smaller popup--padding-16 swap-token-list">
			<div className="popup-header">
				<p className="popup-header__title">Select Source Chain</p>

				<button className="popup-close" type="button" onClick={onClose}>
					<CrossIcon />
				</button>
			</div>

			<div className="popup-body">
				<div className="popup-search">
					<div className="popup-search__input">
						<input
							type="text"
							className="input-item input-item--search input-item--left-icon"
							placeholder="Search chain by name or ID"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>

						<span className="input-icon">
							<MagnifierIcon />
						</span>
					</div>
				</div>
				<div
					className={classNames('balance-list', {
						'balance-list--centered': !filteredList?.length,
					})}
				>
					{filteredList?.map((token) => (
						<div key={`${String(title)}`} className="balance-item">
							<button
								type="button"
								className="token-block token-block--biger"
								onClick={() => onChange(token)}
							>
								<span className="token-block__icon">
									<img src={token?.network_logo} alt="" />
								</span>

								<span className="token-block__info">
									{/* <span className="token-block__name">{token.symbol}</span> */}
									<span className="token-block__purpose">{token.network_name}</span>
								</span>
							</button>
						</div>
					))}

					{!filteredList?.length && <p className="swap-token-list__no-tokens">No chains found</p>}
				</div>
			</div>
		</div>
	);
};
