import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
/* eslint-disable */

export const getSelector = async ({ abi, contractAddess, provider, selector }: any) => {
	try {
		const web3 = new Web3(provider);
		const contractSelector = new web3.eth.Contract(abi, String(contractAddess));
		const result = await contractSelector.methods[selector]().call();

		return result;
	} catch (error: any) {
		console.log(`failed: ${String(error?.message)}`, 'error');
	}
};
