import {
	ICreateNewPasswordFormData,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordResponse,
	IGenerateSmsResponsePayload,
	IipConfirmPayloadData,
	ILoginPayload,
	IResetTwoFaPayload,
	ILoginResponse,
	IRegistrationData,
	IRegistrResponse,
	IResendEmail,
	IResendPhone,
	ILogin2FAResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) => {
		return http
			.post<ILoginPayload, { data: ILoginResponse | ILogin2FAResponse }>(
				endpoint.auth.LOGIN,
				payload,
			)
			.then(({ data }) => data);
	},

	registration: (payload) =>
		http
			.post<IRegistrationData, { data: IRegistrResponse }>(endpoint.auth.REGISTRATION, payload)
			.then((response) => response.data),

	forgotPassword: async (payload) => {
		return http
			.post<any, { data: IForgotPasswordResponse }>(
				payload.email ? endpoint.auth.RESET_PASSWORD : endpoint.auth.RESET_PHONE_PASSWORD,
				payload,
			)
			.then((response) => response.data);
	},

	emailConfirm: (payload) => {
		return http.get<IEmailConfirmPayload>(endpoint.auth.EMAIL_CONFIRM(payload.token));
	},
	resendEmail: (payload) =>
		http.post<IResendEmail, IRegistrResponse>(endpoint.auth.RESEND_EMAIL, payload),

	resendPhone: (payload) =>
		http.post<IResendPhone, IRegistrResponse>(endpoint.auth.RESEND_EMAIL, payload),

	confirmPhone: (payload) => http.post<any>(endpoint.auth.CONFIRM_PHONE, payload),

	checkSmsNewPasswod: (payload) => http.post<any>(endpoint.auth.NEW_PASSWORD_PHONE_TOKEN, payload),

	emailResetConfirmToken: (payload) =>
		http.post<IEmailResetConfirmTokenPayload>(endpoint.auth.RESET_EMAIL_CONFIRM, {
			token: payload.token,
		}),

	ipConfirm: (payload) => http.put<IipConfirmPayloadData>(endpoint.auth.IP_CONFIRM, payload),

	newPassword: (payload) => {
		return http
			.put<ICreateNewPasswordFormData, { data: IRegistrResponse }>(
				payload.type === 'phone' ? endpoint.auth.NEW_PASSWORD_PHONE : endpoint.auth.NEW_PASSWORD,
				payload,
			)
			.then((response) => response.data);
	},

	logout: () => http.post(endpoint.auth.LOGOUT),

	generateSms: (payload) =>
		http
			.post<IGenerateSmsResponsePayload>(endpoint.auth.GENERATE_SMS, payload)
			.then((response) => response.data),
	resetTwoFa: (payload) =>
		http.post<IResetTwoFaPayload>(endpoint.auth.RESET_TWOFA, { ...payload?.data }),

	refreshToken: (payload) =>
		http.get(endpoint.auth.REFRESH_TOKEN, { params: { token: payload } }).then(({ data }) => data),
	refrralQrcode: () => http.get(endpoint.auth.REFERAL_QRCODE).then(({ data }) => data),
};
