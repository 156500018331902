// import { isAuth, isGuest } from 'routes/authMiddlewares';
import useRouteRaplace from 'hooks/useRouteRaplace';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { HomePage } from 'pages/HomePage';
import { BlogPage } from 'pages/BlogPage';
import { FAQPage } from 'pages/FAQPage';
import { HelpPage } from 'pages/HelpPage';
import { Registration } from 'pages/Auth/Registration';
import { Confirm } from 'pages/Auth/Confirm';
import PhoneConfirm from 'pages/Auth/PhoneConfirm';
import Login from 'pages/Auth/Login';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import EmailConfirm from 'pages/Auth/EmailConfirm';
import { TermsServicePage } from 'pages/TermsServicePage';
import { useRoutes, Navigate } from 'react-router-dom';
import { ProfilePage } from 'pages/Profile';
import { SecurityPage } from 'pages/Profile/Security';
import { ReferralPage } from 'pages/Profile/Referral';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { CreateNewPassword } from 'pages/Auth/CreateNewPassword';
import { ConfirmNewPass } from 'pages/Auth/ConfirmNewPass';
import { TransactionsPage } from 'pages/TransactionsPage';
import { TokenPage } from 'pages/TokenPage';
import { BridLiquiditygePage } from 'pages/BridLiquiditygePage';
import { AccountDetailsPage } from 'pages/Profile/AccountDetailsPage';
import { AccountLanguagePage } from 'pages/Profile/AccountLanguagePage';
import { OverviewPage } from 'pages/OverviewPage';
import { OverviewEarningPage } from 'pages/OverviewEarningPage';
import { PoolPage } from 'pages/PoolPage';
import { InfinityPage } from 'pages/InfinityPage';
import { CryptoPage } from 'pages/CryptoPage';
import { DividendsPage } from 'pages/DividendsPage';
import { StakingPage } from 'pages/StakingPage';
import { RunningStakesPage } from 'pages/RunningStakesPage';
import { StakeItPage } from 'pages/StakeItPage';
import { StakePerDividentsPage } from 'pages/StakePerDividentsPage';
import { LiquidityPage } from 'pages/LiquidityPage';
import { TwoFactorPage } from 'pages/Profile/TwoFactorPage';
import { AccountSettingsPage } from 'pages/Profile/AccountSettingsPage';
import { HowItWorksPage } from 'pages/Profile/HowItWorksPage';
import { HelpAndSupportPage } from 'pages/Profile/HelpAndSupportPage';
import { InformationPage } from 'pages/Profile/InformationPage';
import { CheckEmailResetPassword } from 'pages/Auth/CheckEmailResetPassword';
import { TransferPage } from 'pages/TransferPage';
import { BridgePage } from 'pages/BridgePage';
import { RemoveLiquidityPage } from 'pages/RemoveLiquidityPage';
import { TokenPoolPage } from 'pages/TokenPoolPage/index';
import { TokenOverviewPage } from 'pages/TokenOverviewPage';
import { PoolListPage } from 'pages/PoolListPage';
import { BlocksAllPage } from 'pages/BlocksAllPage';
import { TransactionPage } from 'pages/TransactionPage';
import AddressPage from 'pages/AddressPage';
import { ErrorPage } from 'pages/ErrorPage/ErrorPage';
import { AutoSwapPage } from 'pages/AutoSwapPage';
import { ExchangePage } from '../pages/ExchangePage/index';
import { WalletsPage } from '../pages/WalletsPage/index';
import { BlockPage } from '../pages/BlockPage';
import { TokenDetailsPage } from '../pages/TokenDetailsPage';
import { TokenManagerPage } from '../pages/TokenManagerPage';

export const navList = {
	Home: {
		path: `/`,
		element: HomePage,
	},
	Trade: {
		path: `/`,
		element: HomePage,
	},
	Blog: {
		path: `/blog`,
		element: BlogPage,
	},
	FAQ: {
		path: `/faq`,
		element: FAQPage,
	},
	Help: {
		path: `/help`,
		element: HelpPage,
	},
	PrivacyPolicy: {
		path: `/privacy-policy`,
		element: PrivacyPolicyPage,
	},
	TermsService: {
		path: `/terms-service`,
		element: TermsServicePage,
	},
};

export const NavigateList = () => {
	const authenticated = useSelector(getAuthIsAuthenticated);

	useRouteRaplace();

	const routes = useRoutes([
		{
			path: `/`,
			element: <HomePage />,
		},
		{
			path: 'registration',
			element: <Registration />,
		},
		{
			path: `confirm`,
			element: <Confirm />,
		},
		{
			path: `confirm-phone`,
			element: <PhoneConfirm />,
		},
		{
			path: `confirmation/email`,
			element: <EmailConfirm />,
		},
		{
			path: `login`,
			element: authenticated ? <Navigate to="/" /> : <Login />,
		},
		{
			path: `reset-password`,
			element: !authenticated ? <ResetPassword /> : <Navigate to="/" />,
		},
		{
			path: `recovery_password`,
			element: !authenticated ? <CreateNewPassword /> : <Navigate to="/" />,
		},
		{
			path: `confirm-new-password`,
			element: !authenticated ? <ConfirmNewPass /> : <Navigate to="/" />,
		},
		{
			path: `check-email`,
			element: <CheckEmailResetPassword />,
		},
		{
			path: `/blog`,
			element: <BlogPage />,
		},
		{
			path: `/blocks`,
			children: [
				{ index: true, element: <BlocksAllPage /> },
				{
					path: `:hash`,
					element: <BlockPage />,
				},
			],
		},
		{
			path: `/transactions`,
			children: [
				{ index: true, element: <TransactionsPage /> },
				{
					path: `:hash`,
					element: <TransactionPage />,
				},
			],
		},
		{
			path: `/tokens/`,
			element: <TokenPage />,
		},
		{ path: '/address/:hash', element: <AddressPage /> },
		{
			path: `/liquidity`,
			element: authenticated ? <LiquidityPage /> : <Navigate to="/login" />,
		},
		{
			path: `/overviews`,
			element: authenticated ? <OverviewPage /> : <Navigate to="/login" />,
			// element: <OverviewPage />,
		},
		{
			path: `/earnings-overview`,
			element: authenticated ? <OverviewEarningPage /> : <Navigate to="/login" />,
		},
		{
			path: `/faq`,
			element: <FAQPage />,
		},
		{
			path: `/help`,
			element: <HelpPage />,
		},
		{
			path: `/privacy-policy`,
			element: <PrivacyPolicyPage />,
		},
		{
			path: `/terms-service`,
			element: <TermsServicePage />,
		},
		{
			path: `/profile`,
			element: authenticated ? <ProfilePage /> : <Navigate to="/login" />,
		},
		{
			path: `/account-settings`,
			element: authenticated ? <AccountSettingsPage /> : <Navigate to="/login" />,
		},
		{
			path: `/account-details`,
			element: authenticated ? <AccountDetailsPage /> : <Navigate to="/login" />,
		},
		{
			path: `/account-language`,
			element: authenticated ? <AccountLanguagePage /> : <Navigate to="/login" />,
		},
		{
			path: `/security`,
			element: authenticated ? <SecurityPage /> : <Navigate to="/login" />,
		},
		{
			path: `/two-factor`,
			element: authenticated ? <TwoFactorPage /> : <Navigate to="/login" />,
		},
		{
			path: `/referral`,
			element: authenticated ? <ReferralPage /> : <Navigate to="/login" />,
		},
		{
			path: `/how-it-works`,
			element: authenticated ? <HowItWorksPage /> : <Navigate to="/login" />,
		},
		{
			path: `/help-and-support`,
			element: authenticated ? <HelpAndSupportPage /> : <Navigate to="/login" />,
		},
		{
			path: `/exchange`,
			element: authenticated ? <ExchangePage /> : <Navigate to="/login" />,
		},
		{
			path: `/auto-swap`,
			element: authenticated ? <AutoSwapPage /> : <Navigate to="/login" />,
		},
		{
			path: `/all-pools`,
			element: authenticated ? <PoolListPage /> : <Navigate to="/login" />,
		},
		{
			path: `/remove-liquidity`,
			element: authenticated ? <RemoveLiquidityPage /> : <Navigate to="/login" />,
		},

		{
			path: `/infinity`,
			element: authenticated ? <InfinityPage /> : <Navigate to="/login" />,
		},
		{
			path: `/dividends`,
			element: <DividendsPage />,
		},
		{
			path: `/pool`,
			element: <PoolPage />,
		},
		{
			path: `/pool-token`,
			children: [
				{ index: true, element: authenticated ? <TokenPoolPage /> : <Navigate to="/login" /> },
				{
					path: `:tokenId`,
					element: <TokenOverviewPage />,
				},
			],
		},
		{
			path: `/staking`,
			element: authenticated ? <StakingPage /> : <Navigate to="/login" />,
		},
		{
			path: `/running-stakes`,
			element: <RunningStakesPage />,
		},
		{
			path: `/information`,
			element: authenticated ? <InformationPage /> : <Navigate to="/login" />,
		},
		{
			path: `/stake-it`,
			element: authenticated ? <StakeItPage /> : <Navigate to="/login" />,
		},
		{
			path: `/wallets`,
			children: [
				{ index: true, element: authenticated ? <WalletsPage /> : <Navigate to="/login" /> },
				{
					path: `:crypto`,
					element: <CryptoPage />,
				},
			],
		},
		{
			path: `/bridge-transfer`,
			element: <BridgePage />,
		},
		{
			path: `/bridge-liquidity`,
			element: <BridLiquiditygePage />,
		},
		{
			path: `/token/details/:id/*`,
			element: <TokenDetailsPage />,
		},
		{
			path: `/token-manager`,
			element: authenticated ? <TokenManagerPage /> : <Navigate to="/login" />,
		},
		{
			path: `/create-token`,
			element: authenticated ? <TokenManagerPage /> : <Navigate to="/login" />,
		},
		{ path: `/stake-per-dividents`, element: <StakePerDividentsPage /> },
		{
			path: `/non-page`,
			element: authenticated ? <ErrorPage /> : <Navigate to="/" />,
		},
		{
			path: `/transfer`,
			element: authenticated ? <TransferPage /> : <Navigate to="/login" />,
		},
	]);
	return routes;
};
