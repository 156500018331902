import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { TLink, TActive } from './type';

// ==========================================:
export const ActiveLink: FC<TLink> = ({ children, to, classLink }) => {
	const setActive = ({ isActive }: TActive): string => {
		return isActive ? `${String(classLink)} active` : `${String(classLink)}`;
	};
	return (
		<NavLink to={to} className={setActive}>
			{children}
		</NavLink>
	);
};
