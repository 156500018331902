import { utcDate } from 'services/utils/dateAndTimeHelpers';

export const dateToString: (date: Date) => string = (date) => {
	return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};
export const upgrateDate = (date: Date) => {
	const dateUpgrate = new Date(String(date));
	const sumDate = dateUpgrate.setDate(dateUpgrate.getDate() + 14);
	const endDate = new Date(sumDate);
	const year = endDate.getFullYear();
	const month = `0${endDate.getMonth() + 1}`.slice(-2);
	const day = `0${endDate.getDate()}`.slice(-2);

	const formattedDate = `${year}-${month}-${day}`;
	return String(`${formattedDate}`);
};
