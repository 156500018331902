/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IApiGraphRequestPayload } from 'services/api/swap/types';
import { IWeb3GetTokenBalanceParams } from 'services/utils/getTokenBalance';
import {
	IBridgeStore,
	INetworkPairData,
	TNetworkKeyFrom,
	IBalance,
	IGetBridgeHistoryResponse,
	IPayloadBridg,
	// IGetTokenBalanceErrorPayload,
} from './types';

export const initialState: IBridgeStore = {
	assetsPair: [],
	assetsPairLoading: false,

	balances: '',

	networks: null,
	networksLoader: false,

	history: null,
	historyLoading: false,
};

const bridge = createSlice({
	name: '@@bridge',
	initialState,
	reducers: {
		getNetworksRequest: (state) => {
			state.networksLoader = true;
		},
		getNetworksSuccess: (state, action: PayloadAction<TNetworkKeyFrom>) => {
			state.networks = action.payload;
			state.networksLoader = false;
		},
		getNetworksError: (state) => {
			state.networksLoader = false;
		},
		getNetworksPairRequest: (state) => {
			state.assetsPairLoading = true;
		},
		getNetworksPairSuccess: (state, action: PayloadAction<INetworkPairData>) => {
			state.assetsPair = action.payload;
			state.assetsPairLoading = false;
		},
		getTokenBalanseRequest: (state, action: PayloadAction<IWeb3GetTokenBalanceParams>) => {},
		getTokenBalanceSuccess: (state, { payload }: PayloadAction<IBalance>) => {
			// const newBalances = state.balances.filter(({ tokenAddress }) => {
			// 	debugger;
			// 	return tokenAddress !== payload.tokenAddress;
			// });
			// newBalances.push(payload);
			state.balances = payload.balance;
		},
		// getTokenBalanceError: (state, { payload }: PayloadAction<IGetTokenBalanceErrorPayload>) => {
		// 	const newBalances = state.balances.filter(({ tokenAddress }) => {
		// 		return tokenAddress !== payload.tokenAddress;
		// 	});
		// 	state.balances = newBalances;
		// },
		getBridgeHistoryRequest: (state, action: PayloadAction<IPayloadBridg>) => {
			state.historyLoading = true;
		},
		getBridgeHistorySuccess: (state, action: PayloadAction<IGetBridgeHistoryResponse>) => {
			state.history = action.payload;
			state.historyLoading = false;
		},
	},
});

export default bridge.reducer;
export const {
	getNetworksRequest,
	getNetworksSuccess,
	getNetworksError,
	getNetworksPairRequest,
	getTokenBalanseRequest,
	getNetworksPairSuccess,
	getTokenBalanceSuccess,
	getBridgeHistoryRequest,
	getBridgeHistorySuccess,
	// getTokenBalanceError,
} = bridge.actions;
