import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { TPoolItem } from './type';

export const PoolItem: FC<TPoolItem> = ({ data }) => {
	const { asset_base, asset_quote } = data;
	const moneyFormat = useMoneyFormat();
	const navigate = useNavigate();

	const changeLink = () => {
		navigate('/liquidity', {
			state: { initFromSymbol: asset_base?.symbol, initToSymbol: asset_quote?.symbol },
		});
	};

	return (
		<div className="tr" onClick={changeLink}>
			<div className="td">
				<div className="token-box">
					<div className="token-box__icon">
						<img className="token-box__icon-1" src={asset_base.logo} alt="" />
						<img className="token-box__icon-2" src={asset_quote.logo} alt="" />
					</div>
					<div className="token-box__name pool--name-size">
						{asset_base.symbol}-{asset_quote.symbol}
					</div>
				</div>
			</div>
			<div className="td td--right">
				<p>${moneyFormat(data.swap_size_24_h_in_usd, 2, 2, true)}</p>
			</div>
		</div>
	);
};
