import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryModals } from 'ui/HistoryModals';
import { NavLink } from 'react-router-dom';
import bnb from 'assets/images/tokens/bnb.png';
import { getBridgeHistoryRequest } from 'redux/reducers/bridge/reducer';
import { getBridgeHistory } from 'redux/reducers/bridge/selectors';
import PaginationTable from 'ui/PaginationTable';
import logos_metamask from 'assets/images/logos_metamask-icon.svg';
import avalanche from 'assets/images/tokens-list/avalanche.png';
import { TPopupModal } from './type';
import { TransferHistoryItem } from './TrnasferHostory/TransferHistoryItem';
/* eslint-disable */

export const HistoryModal: FC<TPopupModal> = ({ openModal, setOpenModal }) => {
	const [swithcer, setSwitcher] = useState('transfer');
	const dispatch = useDispatch();
	const historyList = useSelector(getBridgeHistory);
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (openModal) {
			dispatch(getBridgeHistoryRequest({ per_page: 2, current_page: currentPage }));
		}
	}, [dispatch, openModal, currentPage]);

	const closeModal = () => setOpenModal(false);
	return (
		<HistoryModals
			openModal={openModal}
			setOpenModal={setOpenModal}
			paddingClass="popup--padding-16"
			insideClass="popup-window__inside--pt-medium"
		>
			<div className="popup-header">
				<button type="button" className="popup-reset">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.55 21.67C18.84 20.54 22 16.64 22 12C22 6.48 17.56 2 12 2C5.33 2 2 7.56 2 7.56M2 7.56V3M2 7.56H4.01H6.44"
							stroke="#8E8EA9"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M2 12C2 17.52 6.48 22 12 22"
							stroke="#8E8EA9"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeDasharray="3 3"
						/>
					</svg>
				</button>
				<button type="button" onClick={closeModal} className="popup-close">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7 7L17 17"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 17L17 7"
							stroke="#1A203F"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div className="popup-body">
				<div className="swap swap--center swap--smaller">
					<button
						name="transfer"
						type="button"
						className={`swap__item ${swithcer === 'transfer' ? 'active' : ''}`}
						onClick={() => setSwitcher('transfer')}
					>
						Transfer History
					</button>
					<button
						name="liquidity"
						type="button"
						className={`swap__item ${swithcer === 'liquidity' ? 'active' : ''}`}
						onClick={() => setSwitcher('liquidity')}
					>
						Liquidity History
					</button>
				</div>
				{swithcer === 'transfer' ? (
					<div className="popup-remove-container">
						{historyList?.data?.length &&
							historyList?.data?.map((item) => <TransferHistoryItem data={item} />)}
					</div>
				) : (
					<div className="popup-remove-container">
						<div className="popup-remove-block">
							<div className="popup-remove-info">
								<p className="popup-remove-info__name">Liquidity</p>
								<p className="popup-remove-info__text">
									Remove 40 USDT from BSC
									<NavLink to="/" className="popup-remove-info__icon">
										<svg
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.6665 8.5H11.3332"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</NavLink>
								</p>
							</div>
							<div className="popup-remove-wrapper">
								<div className="popup-remove-info">
									<p className="popup-remove-info__name">Date</p>
									<p className="popup-remove-info__text">2021-11-17 22:21:40</p>
								</div>
								<div className="popup-remove-info popup-remove-info--right">
									<p className="popup-remove-info__name">Status</p>
									<p className="popup-info-status popup-info-status--completed">Completed</p>
								</div>
							</div>
						</div>
						<div className="popup-remove-block">
							<div className="popup-remove-info">
								<p className="popup-remove-info__name">Liquidity</p>
								<p className="popup-remove-info__text">
									Remove 40 USDT from BSC
									<NavLink to="/" className="popup-remove-info__icon">
										<svg
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.6665 8.5H11.3332"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</NavLink>
								</p>
							</div>
							<div className="popup-remove-wrapper">
								<div className="popup-remove-info">
									<p className="popup-remove-info__name">Date</p>
									<p className="popup-remove-info__text">2021-11-17 22:21:40</p>
								</div>
								<div className="popup-remove-info popup-remove-info--right">
									<p className="popup-remove-info__name">Status</p>
									<p className="popup-info-status popup-info-status--completed">Completed</p>
								</div>
							</div>
						</div>
						<div className="popup-remove-block">
							<div className="popup-remove-info">
								<p className="popup-remove-info__name">Liquidity</p>
								<p className="popup-remove-info__text">
									Remove 40 USDT from BSC
									<NavLink to="/" className="popup-remove-info__icon">
										<svg
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.6665 8.5H11.3332"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</NavLink>
								</p>
							</div>
							<div className="popup-remove-wrapper">
								<div className="popup-remove-info">
									<p className="popup-remove-info__name">Date</p>
									<p className="popup-remove-info__text">2021-11-17 22:21:40</p>
								</div>
								<div className="popup-remove-info popup-remove-info--right">
									<p className="popup-remove-info__name">Status</p>
									<p className="popup-info-status popup-info-status--completed">Completed</p>
								</div>
							</div>
						</div>
						<div className="popup-remove-block">
							<div className="popup-remove-info">
								<p className="popup-remove-info__name">Liquidity</p>
								<p className="popup-remove-info__text">
									Remove 40 USDT from BSC
									<NavLink to="/" className="popup-remove-info__icon">
										<svg
											width="17"
											height="17"
											viewBox="0 0 17 17"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M5.6665 8.5H11.3332"
												stroke="#335BE9"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</NavLink>
								</p>
							</div>
							<div className="popup-remove-wrapper">
								<div className="popup-remove-info">
									<p className="popup-remove-info__name">Date</p>
									<p className="popup-remove-info__text">2021-11-17 22:21:40</p>
								</div>
								<div className="popup-remove-info popup-remove-info--right">
									<p className="popup-remove-info__name">Status</p>
									<p className="popup-info-status popup-info-status--completed">Completed</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="popup-footer popup-footer--center">
				<PaginationTable
					className="block-pagination--center"
					currentPage={currentPage}
					lastPage={Number(historyList?.last_page)}
					setCurrentPage={setCurrentPage}
				/>
				{/* <div className="block-pagination block-pagination--center">
					<button type="button" className="block-pagination__btn">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.57031 18.0703L3.50031 12.0003L9.57031 5.93031"
								stroke="#335BE9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M20.5 12L3.67 12"
								stroke="#335BE9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<p className="block-pagination__text">Page 1</p>
					<button type="button" className="block-pagination__btn active">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697"
								stroke="#335BE9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3.5 12H20.33"
								stroke="#335BE9"
								strokeWidth="1.5"
								strokeMiterlimit="10"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div> */}
			</div>
		</HistoryModals>
	);
};
