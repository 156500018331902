/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import axios from 'axios';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { responseErrors } from 'services/http/responseErrors';
import {
	TReferallResopnse,
	TReferallWalletsData,
	TReferralItemRequest,
	TTranactonClaimRequest,
	TReferralRequestData,
	TReferralB2BRequest,
	TReferralB2BResponse,
	TReferralB2BBalanceResponse,
	TB2CSignatureResponsce,
	Ib2cBodyRequest,
	TB2BTimeResponse,
	IB2BPayload,
} from './types';

import {
	referralInitState,
	getReferralRequest,
	getReferralWalletRequest,
	getReferralSuccess,
	getReferralWalletSuccess,
	getTrancationClaimRequest,
	getReferralB2BRequest,
	getReferralB2BSuccess,
	getReferralB2BBalanceSuccess,
	getReferralB2BBalanceRequest,
	getReferralB2СSignatureRequest,
	getReferralB2СSignatureSuccess,
	getReferralB2BSignatureRequest,
	getReferralB2BSignatureSuccess,
	getB2BDateRequest,
	getB2BDateSuccess,
	getB2CDateRequest,
	getB2CDateSuccess,
} from './reducer';

function* getReferralWorker({ payload }: PayloadAction<TReferralRequestData>) {
	try {
		const response: TReferallResopnse = yield call(api.referral.getReferral, payload);

		yield put(getReferralSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

function* getReferralWalletWorker({ payload }: PayloadAction<TReferralItemRequest>) {
	try {
		const response: TReferallWalletsData = yield call(api.referral.getReferralWallet, payload);

		yield put(getReferralWalletSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

function* sendTransactionClaimWorker({ payload }: PayloadAction<TTranactonClaimRequest>) {
	try {
		yield call(api.referral.sendTranaction, payload);

		yield put(getReferralWalletRequest({ type: 'coin' }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(referralInitState());
	}
}

function* getReferralB2BWorker({ payload }: PayloadAction<TReferralB2BRequest>) {
	try {
		const { params } = payload;
		const response: TReferralB2BResponse = yield call(api.referral.getB2BReferral, params);

		yield put(getReferralB2BSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

function* getReferralB2BBalanceWorker() {
	try {
		const response: TReferralB2BBalanceResponse = yield call(api.referral.getB2BReferralBalance);

		yield put(getReferralB2BBalanceSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

function* getReferralB2СSignatureeWorker({ payload }: PayloadAction<Ib2cBodyRequest>) {
	try {
		const response: TB2CSignatureResponsce = yield call(
			api.referral.getB2CReferralSignature,
			payload,
		);

		yield put(getReferralB2СSignatureSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}
function* getReferralB2BSignatureeWorker({ payload }: PayloadAction<Ib2cBodyRequest>) {
	try {
		const response: TB2CSignatureResponsce = yield call(
			api.referral.getB2BReferralSignature,
			payload,
		);

		yield put(getReferralB2BSignatureSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

function* getB2BDateRequestWorker({ payload }: PayloadAction<IB2BPayload>) {
	try {
		const { onSuccess } = payload;
		const response: TB2BTimeResponse = yield call(api.referral.getB2BReferralTime);

		yield put(getB2BDateSuccess(response));
		onSuccess?.(response);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(referralInitState());
	}
}

// function* getB2CDateWorker({ payload }: PayloadAction<IB2BPayload>) {
// 	try {
// 		const { onSuccess } = payload;
// 		const response: TB2BTimeResponse = yield call(api.referral.getB2CReferraClaim);

// 		yield put(getB2CDateSuccess(response));
// 		yield put(onSuccess(response));
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 		yield put(referralInitState());
// 	}
// }

export function* referralSaga() {
	yield takeEvery(getReferralRequest.type, getReferralWorker);
	yield takeEvery(getReferralWalletRequest.type, getReferralWalletWorker);
	yield takeEvery(getTrancationClaimRequest.type, sendTransactionClaimWorker);
	yield takeEvery(getReferralB2BRequest.type, getReferralB2BWorker);
	yield takeEvery(getReferralB2BBalanceRequest.type, getReferralB2BBalanceWorker);
	yield takeEvery(getReferralB2СSignatureRequest.type, getReferralB2СSignatureeWorker);
	yield takeEvery(getReferralB2BSignatureRequest.type, getReferralB2BSignatureeWorker);
	yield takeEvery(getB2BDateRequest.type, getB2BDateRequestWorker);
	// yield takeEvery(getB2CDateRequest.type, getB2CDateWorker);
}
