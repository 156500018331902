import { notificationContainer } from 'services/utils/notificationContainer';
import Web3 from 'web3';
import { ITypeTransaction } from 'components/StakePerDividents/types';
import { numberInDecimal } from 'components/Exchange/LiquidityForm/utils';
import { BigNumber } from 'ethers';
import { noExponent } from './noExponent';

/* eslint-disable */

export const stakingApprove = async ({
	wallet,
	abi: DividendsFactory,
	amount,
	connector,
	tokenAddress,
	setOpenConfirmModal,
	lockPeriod,
	contractAddress,
	decimal,
	amountFee,
	coin,
	provider,
}: ITypeTransaction) => {
	try {
		setOpenConfirmModal?.(true);

		const web3 = new Web3(provider);

		const formatAmount = String(
			noExponent(Math.floor(Number(numberInDecimal(Number(amount), Number(decimal)).toString()))),
		);

		const contract = new web3.eth.Contract(DividendsFactory, wallet);

		let result;

		const sum = BigNumber.from(formatAmount).add(String(amountFee)).toString();

		const data = await contract.methods.deposit(tokenAddress, lockPeriod, formatAmount).encodeABI();

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				value: coin ? sum : amountFee,
				to: contractAddress,
				data,
			});
		}

		notificationContainer('Stake per dividents done', 'success');
		setOpenConfirmModal?.(false);
		return result;
	} catch (error: any) {
		setOpenConfirmModal?.(false);
		notificationContainer(`Stake failed! ${String(error?.message.split(':')[0])}`, 'error');
		debugger;
	}
};

export const unstakingApprove = async ({
	wallet,
	abi: DividendsFactory,
	amount,
	connector,
	firstTokenAddress,
	setOpenConfirmModal,
	lockPeriod,
	to,
	decimal,
	selector,
	coin,
	provider,
}: any) => {
	try {
		setOpenConfirmModal?.(true);

		const web3 = new Web3(provider);

		// const formatAmount = String(
		// 	noExponent(Math.floor(Number(numberInDecimal(Number(amount), Number(decimal)).toString()))),
		// );

		const contract = new web3.eth.Contract(DividendsFactory);

		let data = '';
		debugger;
		data = await contract.methods.withdrawWithoutReward(firstTokenAddress).encodeABI();

		let result;

		if (connector) {
			result = await web3.eth.sendTransaction({
				from: wallet,
				to: to,
				data,
			});
		}

		notificationContainer('Stake per dividents done', 'success');
		setOpenConfirmModal?.(false);
		return result?.transactionHash;
	} catch (error: any) {
		setOpenConfirmModal?.(false);
		notificationContainer(`Stake failed! ${String(error?.message.split(':')[0])}`, 'error');
		debugger;
	}
};

export const getSelector = async ({ abi, to, provider, selector }: any) => {
	try {
		const web3 = new Web3(provider);

		let result = '';

		const contract = new web3.eth.Contract(abi, to);

		if (selector === 'SELECTOR_DIVIDENTS_UNSTAKE') {
			result = await contract.methods.SELECTOR_DIVIDENTS_UNSTAKE().call();
			return result;
		}
		result = await contract.methods.SELECTOR_DIVIDENTS_STAKE().call();

		return result;
	} catch (error: any) {
		notificationContainer(`Selector failed! ${String(error?.message.split(':')[0])}`, 'error');
	}
};
