/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';

import { IApiSearchResponse, IPayloadOptions } from './types';
import { getSearchRequest, getSearchSuccess, searchInitState } from './reducer';
import { getTokensRequest } from '../token/reducer';
import { notificationContainer } from '../../../services/utils/notificationContainer';

function* getSearchWorker(action: PayloadAction<IPayloadOptions>) {
	const { payload } = action;
	try {
		const response: IApiSearchResponse = yield call(api.search.getSearch, payload);
		if (response?.addresses.length !== 0) {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			payload.navigate(`/address/${response?.addresses[0].hash}/`);
		}
		if (response?.blocks.length !== 0 && !/[a-zа-яё]/i.test(payload.text)) {
			// Block
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			payload.navigate(`/blocks/${response?.blocks[0].hash}`);
		}
		if (response?.tokens.length !== 0) {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			payload.navigate(`/token/details/${response?.tokens[0].contract_address_hash}/transfers/`);
		}
		if (response?.transactions.length !== 0) {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			payload.navigate(`/transactions/${response?.transactions[0].hash}/`);
		}

		if (payload.text.length < 40 && payload.text.length > 1 && /[a-zа-яё]/i.test(payload.text)) {
			payload.navigate(`/tokens/?page=1&search=${payload.text}`);
			yield put(getTokensRequest({ per_page: 8, current_page: 1, search: payload.text }));

			// // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			// payload.navigate(`/token/details/${response?.tokens[0].contract_address_hash}/transfers/`);
		}
		if (
			response?.addresses.length === 0 &&
			response?.transactions.length === 0 &&
			response?.blocks.length === 0 &&
			response?.tokens.length === 0
		) {
			if (!window.location.href.includes('/tokens/?page')) {
				notificationContainer('Not found', 'error');
			}
		}
		yield put(getSearchSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(searchInitState());
	} finally {
		// yield put(hideLoading());
	}
}

export function* searchSaga() {
	yield takeEvery(getSearchRequest.type, getSearchWorker);
}
