import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { api, responseErrors } from 'services';
import {
	IBlock,
	IApiTransactionsResponse,
	ITransaction,
	IApiBlocksResponse,
	IApiBlocksRequest,
	IApiTransactionsRequest,
	IApiTransactionsCountResponse,
	ITransactionsGraph,
	IApiStatisticResponse,
	IApiBlocksCountResponse,
	IApiPrevNextResponse,
	IApiAddressResponse,
} from '../../../services/api/blockchain/types';

import {
	getAddressRequest,
	getAddressSuccess,
	getBlockRequest,
	getBlocksCountRequest,
	getBlocksCountSuccess,
	getBlocksLastRequest,
	getBlocksLastSuccess,
	getBlocksPrevNextRequest,
	getBlocksPrevNextSuccess,
	getBlocksRequest,
	getBlocksSuccess,
	getBlockSuccess,
	getStatisticRequest,
	getStatisticSuccess,
	getTransactionRequest,
	getTransactionsCountRequest,
	getTransactionsCountSuccess,
	getTransactionsGraphRequest,
	getTransactionsGraphSuccess,
	getTransactionsLastRequest,
	getTransactionsLastSuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
	getTransactionSuccess,
} from './reducer';

function* getBlocksWorker({ payload }: PayloadAction<IApiBlocksRequest>) {
	try {
		const response: IApiBlocksResponse = yield call(api.blockchain.getBlocks, {
			...payload,
			per_page: '15',
		});
		yield put(getBlocksSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBlocksLastWorker() {
	try {
		const response: IBlock[] = yield call(api.blockchain.getBlocksLast);
		yield put(getBlocksLastSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionsWorker({ payload }: PayloadAction<IApiTransactionsRequest>) {
	try {
		const response: IApiTransactionsResponse = yield call(api.blockchain.getTransactions, {
			...payload,
			per_page: '15',
		});
		yield put(getTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionsLastWorker() {
	try {
		const response: ITransaction[] = yield call(api.blockchain.getTransactionsLast);
		yield put(getTransactionsLastSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionsCountWorker() {
	try {
		const response: IApiTransactionsCountResponse = yield call(api.blockchain.getTransactionsCount);
		yield put(getTransactionsCountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionsGraphWorker() {
	try {
		const response: ITransactionsGraph[] = yield call(api.blockchain.getTransactionsGraph);
		yield put(getTransactionsGraphSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getStatisticWorker() {
	try {
		const response: IApiStatisticResponse = yield call(api.blockchain.getStatistic);
		yield put(getStatisticSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBlockWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IBlock = yield call(api.blockchain.getBlock, payload);
		yield put(getBlockSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionWorker({ payload }: PayloadAction<string>) {
	try {
		const response: ITransaction = yield call(api.blockchain.getTransaction, payload);
		yield put(getTransactionSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBlocksCountWorker() {
	try {
		const response: IApiBlocksCountResponse = yield call(api.blockchain.getBlocksCount);
		yield put(getBlocksCountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getBlocksPrevNextWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IApiPrevNextResponse = yield call(api.blockchain.getBlockPrevNext, payload);
		yield put(getBlocksPrevNextSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getAddressWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IApiAddressResponse = yield call(api.blockchain.getAddress, payload);
		yield put(getAddressSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* blockchainSaga() {
	yield takeEvery(getBlocksRequest, getBlocksWorker);
	yield takeEvery(getBlocksLastRequest, getBlocksLastWorker);
	yield takeEvery(getTransactionsRequest, getTransactionsWorker);
	yield takeEvery(getTransactionsLastRequest, getTransactionsLastWorker);
	yield takeEvery(getTransactionsCountRequest, getTransactionsCountWorker);
	yield takeEvery(getTransactionsGraphRequest, getTransactionsGraphWorker);
	yield takeEvery(getStatisticRequest, getStatisticWorker);
	yield takeEvery(getBlockRequest, getBlockWorker);
	yield takeEvery(getTransactionRequest, getTransactionWorker);
	yield takeEvery(getBlocksCountRequest, getBlocksCountWorker);
	yield takeEvery(getBlocksPrevNextRequest, getBlocksPrevNextWorker);
	yield takeEvery(getAddressRequest, getAddressWorker);
}
