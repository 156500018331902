import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import logos_metamask from 'assets/images/logos_metamask-icon.svg';
import { numberOutDecimal } from '../../utils';
// import avalanche from 'assets/images/tokens-list/avalanche.png';
// import bnb from 'assets/images/tokens/bnb.png';
import { THistoryData } from '../type';

export const TransferHistoryItem: FC<THistoryData> = ({ data }) => {
	const { sent_token_info, receive_date, status, received_token_info, network, amount } = data;

	const statusInfo = (value: boolean) => {
		return (
			<p
				className={`popup-info-status ${
					value ? 'popup-info-status--completed' : 'popup-info-status--cancel'
				}`}
			>
				{value ? 'Completed' : 'Cancel'}
			</p>
		);
	};

	const amountToken = received_token_info?.decimals || sent_token_info?.decimals || 18;
	return (
		<div className="popup-remove-block">
			<div className="popup-remove-info">
				<p className="popup-remove-info__name">Transfer</p>
				<div className="popup-remove-tokens">
					<div className="swaping-token">
						<div className="swaping-token__top">
							<div className="swaping-token__icon">
								<img src={network?.network_logo_from} alt="" />
							</div>
							<div className="swaping-token__name">{network?.network_from}</div>
							<NavLink to="/" className="swaping-token__link">
								<svg
									width="17"
									height="17"
									viewBox="0 0 17 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.6665 8.5H11.3332"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</NavLink>
						</div>
						<div className="swaping-token__bottom">
							<p className="swaping-token__count swaping-token__count--minus">
								-{numberOutDecimal(Number(amount), amountToken)}
								{sent_token_info?.symbol}
							</p>
						</div>
					</div>
					<span className="swaping-icon">
						<svg
							width="25"
							height="25"
							viewBox="0 0 25 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21.3271 10.0271L15.3677 5.09251C14.2817 4.19336 12.5303 4.88913 12.5303 6.21951V8.86038C1.52572 8.86038 3.21873 21.0938 3.21873 21.0938C3.21873 21.0938 6.60474 13.4479 12.5303 13.4479V16.088C12.5303 17.4184 14.2817 18.1142 15.3669 17.2158L21.3263 12.2811C21.4992 12.1379 21.6373 11.9637 21.7318 11.7697C21.8262 11.5757 21.875 11.3661 21.875 11.1541C21.875 10.9422 21.8262 10.7326 21.7318 10.5386C21.6373 10.3445 21.4992 10.1704 21.3263 10.0271H21.3271Z"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
					<div className="swaping-token">
						<div className="swaping-token__top">
							<div className="swaping-token__icon">
								<img src={network?.network_logo_to} alt="" />
							</div>
							<div className="swaping-token__name">{network?.network_to}</div>
							<div className="swaping-token__link">
								<svg
									width="17"
									height="17"
									viewBox="0 0 17 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.6177 12.3962H11.6873C13.8264 12.3962 15.5831 10.6466 15.5831 8.50033C15.5831 6.36116 13.8335 4.60449 11.6873 4.60449H10.6177"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M6.37484 4.60449H5.31234C3.16609 4.60449 1.4165 6.35408 1.4165 8.50033C1.4165 10.6395 3.16609 12.3962 5.31234 12.3962H6.37484"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.6665 8.5H11.3332"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div className="swaping-token__bottom">
							<p className="swaping-token__count swaping-token__count--plus">
								+{numberOutDecimal(Number(amount), amountToken)} {received_token_info?.symbol}
							</p>
							<span className="swaping-token__wallet-icon">
								<img src={logos_metamask} alt="" />
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="popup-remove-wrapper">
				<div className="popup-remove-info">
					<p className="popup-remove-info__name">Date</p>
					<p className="popup-remove-info__text">{receive_date}</p>
				</div>
				<div className="popup-remove-info popup-remove-info--right">
					<p className="popup-remove-info__name">Status</p>
					{statusInfo(status)}
				</div>
			</div>
		</div>
	);
};
