import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { NoDataTable } from 'layouts-elements/NoDataTable';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopPoolsRequest } from 'redux/reducers/swap/reducer';
import { getTopPools } from 'redux/reducers/swap/selectors';
import { IApiPool } from 'services/api/swap/types';
import PaginationTable from 'ui/PaginationTable';
import { ITopPoolsTableProps } from '../types';

const TopPoolsTable: FC<ITopPoolsTableProps> = ({ searchValue, id, title }) => {
	const dispatch = useDispatch();
	const pools = useSelector(getTopPools);

	const moneyFormat = useMoneyFormat();
	const perPage = 18;
	const [currentPage, setCurrentPage] = useState<number>(1);

	const poolsList: IApiPool[][] = [];
	const poolsArr = pools?.data || [];
	const size = 3;
	if (pools) {
		for (let i = 0; i < Math.ceil(poolsArr.length / size); i += 1) {
			poolsList[i] = poolsArr.slice(i * size, i * size + size);
		}
	}

	useEffect(() => {
		let search = '';
		if (searchValue) {
			search = searchValue;
		} else if (id) {
			search = id;
		}
		dispatch(getTopPoolsRequest({ per_page: perPage, current_page: currentPage, search }));
	}, [currentPage, dispatch, id, searchValue]);

	useEffect(() => {
		if (pools && currentPage > pools.last_page) {
			setCurrentPage(pools.last_page);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pools]);

	return (
		<div className="tokens-table">
			<div className="tokens-table__top">
				<p className="tokens-table__text">{title}</p>
			</div>
			<div className="table table--type2 table--group-type table--tokens-group-type table--volume-pools">
				<div className="table-header">
					<div className="tr">
						<div className="td-group">
							<div className="td">Pool</div>
							<div className="td ">Tot. Trans.</div>
							<div className="td ">Tot. Vol. </div>
						</div>
						<div className="td-group">
							<div className="td">Pool</div>
							<div className="td ">Tot. Trans.</div>
							<div className="td ">Tot. Vol. </div>
						</div>
						<div className="td-group">
							<div className="td">Pool</div>
							<div className="td ">Tot. Trans.</div>
							<div className="td ">Tot. Vol. </div>
						</div>
					</div>
				</div>
				<div className="table-body table-position">
					{poolsList && !!poolsList.length ? (
						poolsList.map((row) => (
							<div key={row[0].id} className="tr">
								{row.map((item) => (
									<div key={item.id} className="td-group">
										<div className="td">
											<div className="token-box">
												<div className="token-box__icon-3">
													<img
														className="token-box__icon-1"
														src={item?.asset_base.logo}
														alt={item?.asset_base.name}
													/>
													<img
														className="token-box__icon-2"
														src={item?.asset_quote.logo}
														alt={item?.asset_quote.name}
													/>
												</div>
												<div className="token-box__name token-box__name--small">
													{item?.asset_base.symbol}-{item?.asset_quote.symbol}
												</div>
											</div>
										</div>
										<div className="td ">
											<p>{item?.transactions_24_h}</p>
										</div>
										<div className="td ">
											<p>${moneyFormat(item?.swap_size_24_h_in_usd, 2, 2, true)}</p>
										</div>
									</div>
								))}
							</div>
						))
					) : (
						<NoDataTable />
					)}
				</div>
			</div>
			{poolsList && (
				<PaginationTable
					className="block-pagination--center block-pagination--table"
					currentPage={pools?.current_page || 1}
					setCurrentPage={setCurrentPage}
					lastPage={pools?.last_page || 1}
				/>
			)}
		</div>
	);
};

export default TopPoolsTable;
