import { FC } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon, QuestionIcon } from 'assets/custom-icons';
import { useDispatch } from 'react-redux';
import { TConfirmModal } from './type';

export const PendingModal: FC<TConfirmModal> = ({ setOpenModal, toValue, coinName, fee }) => {
	const dispatch = useDispatch();
	const closeModal = () => setOpenModal(false);

	// const [fromValue, setFromValue] = useState(slipping || '0.5');
	// const countFrom = (value: number) => {
	// 	setFromValue(String(value));
	// };

	// const percentButtonCountValue = (percentValue: number): number => {
	// 	return percentValue;
	// };
	// const handleSave = () => {
	// 	dispatch(setSlipping(String(fromValue)));
	// 	closeModal();
	// };

	// const handleCancel = () => {
	// 	setFromValue(String(slipping));
	// 	closeModal();
	// };
	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<button
				onClick={closeModal}
				type="button"
				className="popup-close popup-close--type2"
				aria-label="Close"
			>
				<CloseButtonIcon />
			</button>

			<div className="popup-body">
				<div className="popup-message popup-message--waiting">
					<div className="popup-message__icon">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
								stroke="#FFA51E"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M15.7089 15.18L12.6089 13.33C12.0689 13.01 11.6289 12.24 11.6289 11.61V7.51001"
								stroke="#FFA51E"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<p className="popup-message__title popup-message__title--mt0">Waiting For Confirmation</p>
				</div>
				<div className="form-text form-text--center form-text--fw-600">
					<p>
						Claiming <span className="green-text">{toValue || 0}</span>{' '}
						{coinName?.toLocaleUpperCase()}
					</p>
				</div>
				<div className="form-text form-text--center form-text--grey form-text--fw400">
					<p>Confirm this transaction in your wallet</p>
				</div>
				{fee ? (
					<div className="form-text form-text--center form-text--grey form-text--fw400">
						<p>Comission {fee}</p>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
