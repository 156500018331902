import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import settings from './reducers/settings/reducer';
import coins from './reducers/coins/reducer';
import auth from './reducers/auth/reducer';
import swap from './reducers/swap/reducer';
import connectWallet from './reducers/connectWallet/reducer';
import wallets from './reducers/wallets/reducer';
import tokens from './reducers/token/reducer';
import blockchain from './reducers/blockchain/reducer';
import search from './reducers/search/reducer';
import sockets from './reducers/sockets/reducer';
import referral from './reducers/referral/reducer';
import earnings from './reducers/earnings/reducer';
import bridge from './reducers/bridge/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		settings,
		coins,
		auth,
		swap,
		connectWallet,
		wallets,
		tokens,
		blockchain,
		search,
		sockets,
		referral,
		earnings,
		bridge,
	});

export default createRootReducer;
