import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import { Web3ContextProvider } from 'ui/Web3Context/Web3ContextProvider';
import { Buffer } from 'buffer';
import App from './components/App';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';

import reportWebVitals from './reportWebVitals';

declare let window: any;

if (window.ethereum) {
	window.ethereum.autoRefreshOnNetworkChange = false;
}

window.Buffer = Buffer;

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement);

root.render(
	<Web3ContextProvider>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{/* <ConnectedRouterProvider history={history}> */}
				{/* <LoadingBar className="loading-bar" /> */}
				<App />
				<ToastContainer />
				{/* </ConnectedRouterProvider> */}
			</PersistGate>
		</ReduxProvider>
	</Web3ContextProvider>,
);

reportWebVitals();
