/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconCoinGato from 'assets/images/tokens/gato-logo.png';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import { Link } from 'react-router-dom';

import { getToken, getTokensLoading } from 'redux/reducers/token/selectors';
import { getTokenRequest, getTokensRequest } from 'redux/reducers/token/reducer';
// import usePagination from 'hooks/usePagination';
// import Pagination from 'ui/Pagination';

// import { IAsset } from 'redux/reducers/tokens/types';
// import Loader from 'ui/Loader';

export const TokensTable: FC = () => {
	const dispatch = useDispatch();

	const { tokens } = useSelector(getToken);

	useEffect(() => {
		const payload = {
			per_page: 10,
			current_page: 1,
			search: '',
		};

		dispatch(getTokensRequest(payload));
	}, [dispatch]);

	// const loading = useSelector(getAssetsLoader);
	// const { assets, total } = assetsResponse || {};

	// const { perPage, currentPage } = usePagination();
	// const totalPages = total && Math.ceil(total / perPage);

	// const assetsList: IAsset[][] = [];
	const size = 3;
	// if (assets) {
	// 	for (let i = 0; i < Math.ceil(assets.length / size); i += 1) {
	// 		assetsList[i] = assets.slice(i * size, i * size + size);
	// 	}
	// }

	// const onActiveCheck = (e: ChangeEvent<HTMLInputElement>) => {
	// 	dispatch(toggleActivityRequest(Number(e.target.value)));
	// };

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--my-token-table">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Token</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body table--position">
							{/* {loading && (
								<div className="loader-wrapper loader-wrapper__cover">
									<Loader />
								</div>
							)} */}

							{/* {assetsList?.map((row) => (
								<div key={Math.random()} className="tr">
									{row.map((asset) => (
										<div key={asset.id} className="td-group">
											<div className="td">
												<p className="td-hidden-name">Pairs</p>
												<Link to={`/tokens/${asset?.id}`} className="token-line">
													<img className="token-line__icon" src={asset.logo} alt="bnb" />
													<span className="token-line__text">{asset.symbol}</span>
													<span className="token-line__transcription">{asset.name}</span>
												</Link>
											</div>
											<div className="td ">
												<p className="td-hidden-name">Activate / Deactivate</p>
												<div className="switch switch--center">
													<label className="switch__label">
														<input
															type="checkbox"
															checked={asset.is_active_global}
															value={asset.id}
															onChange={onActiveCheck}
															className="hidden"
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									))}
								</div>
							))} */}

							{tokens &&
								tokens?.data.map((token: any) => (
									<div key={token.id} className="tr">
										<div className="td">
											<p className="td-hidden-name">Pairs</p>
											<Link
												to={`/token/details/${token?.contract_address_hash}/transfers`}
												className="token-line"
											>
												<img className="token-line__icon" src={token?.asset?.logo} alt="bnb" />
												<span className="token-line__text">{token.symbol}</span>
												<span className="token-line__transcription">{token.name}</span>
											</Link>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
			<NotificationInfo />
			{/* {!!assetsList.length && !!totalPages && totalPages > 1 && (
				<Pagination pageCount={totalPages} />
			)} */}
		</>
	);
};
