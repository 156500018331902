import { FC, useState, SyntheticEvent, ChangeEvent } from 'react';
import { IButtonsSelect } from './types';

const ButtonsSelect: FC<IButtonsSelect> = ({ variants, value: defaultValue, onChange }) => {
	const [value, setValue] = useState<string>(String(defaultValue));
	const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value: targetValue },
		} = e;
		const prevValue = value;
		if (!/^\d*$/.test(targetValue)) {
			setValue(prevValue);
			return;
		}
		setValue(targetValue);
		onChange && onChange(targetValue === '' ? undefined : +targetValue);
	};

	const onButtonClick = (e: SyntheticEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLButtonElement;
		const { value: targetValue } = target;
		setValue(targetValue);
		onChange && onChange(targetValue === '' ? undefined : +targetValue);
	};

	return (
		<div className="input-wrapper input-wrapper--flex input-wrapper--w322 buttons-select">
			{variants.map((v) => (
				<button
					key={v}
					value={v}
					onClick={onButtonClick}
					type="button"
					className={`${String(value) === String(v) ? 'button' : ''}`}
				>
					{v}
				</button>
			))}
			<input
				onChange={onInputChange}
				className="input-item input-item--text-center active"
				type="number"
				value={value}
			/>
		</div>
	);
};

export default ButtonsSelect;
