import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TAssetsApi } from './types';

export const assets: TAssetsApi = {
	getAssets: () => http.get(endpoint.assets.GET_ASSETS).then((response) => response.data),
	getAssetsById: (id) =>
		http.get(endpoint.assets.GET_ASSETS_BY_ID(id)).then((response) => response.data),
	getAssetsPairs: ({ id }) =>
		http.get(endpoint.assets.ASSET_PAIRS(id)).then((response) => response.data),
	getGraph: ({ idPairs, period }) =>
		http.get(endpoint.assets.GET_GRAPH(idPairs, period)).then((response) => response.data),
	postAddToken: (payload) =>
		http.post(endpoint.assets.POST_ASSET, payload).then((response) => response.data),
};
