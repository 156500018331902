export const noExponent = (n: string | number) => {
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(+n)) return n;
	const sign = +n < 0 ? '-' : '';
	const toStr = n.toString();
	if (!/e/i.test(toStr)) {
		return n;
	}
	const [lead, decimal, pow] = n
		.toString()
		.replace(/,/, '')
		.replace(/^-/, '')
		.replace(/^([0-9]+)(e.*)/, '$1.$2.$3.$4.$5.$6')
		.split(/e|\./);

	const result =
		+pow < 0
			? `${sign}0.${'0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0))}${lead}${decimal}`
			: sign +
			  lead +
			  (+pow >= decimal.length
					? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
					: `${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`);
	return result;
};
