import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateNewPassIsLoad } from 'redux/reducers/auth/selectors';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';
import { Auth } from 'layouts/Auth';
import CreateNewPassForm from 'components/Forms/CreateNewPassForm';
import { ICreateNewPasswordFormData } from 'components/Forms/CreateNewPassForm/types';
import Loader from 'ui/Loader';

// ==========================================:
export const CreateNewPassword: FC = () => {
	const createNewPassLoad = useSelector(getCreateNewPassIsLoad);
	const dispatch = useDispatch();

	const handleCreateNewPass = (values: ICreateNewPasswordFormData) => {
		dispatch(createNewPasswordRequest(values));
	};

	return (
		<Auth title="Create new password">
			<section className="authorization-section">
				<div className="container">
					<div className="authorization-box">
						<div className="authorization-form">
							<CreateNewPassForm createNewPassSubmit={handleCreateNewPass} />
							{createNewPassLoad && (
								<div className="auth-loader-wrapper">
									<Loader />
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</Auth>
	);
};
