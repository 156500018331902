import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransfersRequest } from 'redux/reducers/token/reducer';
import { useLocation } from 'react-router';
import {
	getTokenData,
	getTokenTransfersData,
	getTokenTransfersLoading,
} from 'redux/reducers/token/selectors';
import PaginationTable from 'ui/PaginationTable';
import Item from './Item/Item';

const TokenDetailedTabTransfers = () => {
	const dispatch = useDispatch();
	const perPage = 8;
	const data = useSelector(getTokenTransfersData);
	const symbol = useSelector(getTokenData)?.symbol;
	const loading = useSelector(getTokenTransfersLoading);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const location = useLocation();
	useEffect(() => {
		dispatch(
			getTransfersRequest({
				per_page: perPage,
				current_page: currentPage,
				id: location.pathname.split('/')[3],
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);
	return (
		<div className="explore-block__inner">
			<div className="explore-block__top">
				<h4 className="block-title block-title--mr-auto">Token Transfers</h4>
				<div className="block-pagination block-pagination--header">
					{data && (
						<PaginationTable
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							lastPage={data?.last_page}
						/>
					)}
				</div>
			</div>
			<div className="explore-blocks ">
				{data ? data?.data.map((item) => <Item key={item.id} item={item} symbol={symbol} />) : null}
				{loading ? <h2>Loading...</h2> : null}
			</div>
			<div className="block-pagination block-pagination--footer block-pagination--pb16">
				{data && (
					<PaginationTable
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						lastPage={data?.last_page}
					/>
				)}
			</div>
		</div>
	);
};

export default TokenDetailedTabTransfers;
