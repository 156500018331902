/* eslint-disable */
import { FC, useEffect, useMemo, useState, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import {
	getSwapAssetList,
	getSwapPairList,
	getAddTokenList,
	getPairBalance,
} from 'redux/reducers/swap/selectors';
import { ISwapTokenUnsafe } from 'redux/reducers/swap/types';
import { feeChecker } from 'services/utils/feeChecker';
import { noExponent } from 'services/utils/noExponent';
import { addLiquidity } from 'services/utils/liquidity';
import { IApiSwapPair } from 'services/api/swap/types';
import { ApproveTokens } from 'services/utils/approve';
import { useLocation } from 'react-router';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import AbiFee from 'config/abi/FeeController.json';
/* eslint-disable no-debugger */
import { usePrevious } from 'hooks/usePrevious';

import {
	clearTokenBalances,
	getTokenBalancesLiquidity,
	sendTrancationRequest,
	getPairBalanceRequest,
	getTryTokensRequset,
} from 'redux/reducers/swap/reducer';
import { IExchangeLocationStateWithObject } from '../types';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { toMaxDecimals, checkAfterPoint, toDecimalsAfterPoint } from 'services/utils/numbers';
import { PlusGrayButton } from 'assets/custom-icons';
import AbiTokens from 'config/abi/GUSD.json';
import { NavLink } from 'react-router-dom';
import { getNetworkId } from 'redux/reducers/wallets/selectors';

import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';
import { useWeb3React } from '@web3-react/core';
import {
	tryInsufficientLiquidity,
	getLiqudityValue,
	getPriceFromToValue,
	getTokensWithoutAddress,
	calculateReceiveLiquidity,
	getTokensWithoutSymbol,
	numberInDecimal,
	getInitTokenSymbols,
	getTokenBySymbol,
	getIsTokenPairInList,
	getCurrentPair,
	toDecimals,
} from './utils';

import { ConfirmModal } from './ConfirmModal';
import { IExchangeLocationState } from '../types';
// import { SuccessModal } from './TransactionsModals/succes';

import { TokenInput } from '../TokenInput';
import { ITypeTransaction, IToken } from './type';
import { ReceiveModal } from './TransactionsModals/receive';

const Web3 = require('web3');

const chainId = String(process.env.REACT_APP_CHAIN_ID);

const RPC_HOST = String(process.env.REACT_APP_RPC_URL);

const ROUTER_CONTRACT = String(process.env.REACT_APP_ROUTER_ADDRESS);

const SELECTOR_ADD_LIQUIDITY = '0x0afe67b2';

const _web3 = new Web3(RPC_HOST);

// const RouterContract = new _web3.eth.Contract(AbiRouter.abi, ROUTER_CONTRACT);

declare let window: any;

export const LiquidityForm: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const pairBalance = useSelector(getPairBalance);
	const assetList = useSelector(getSwapAssetList);
	const tokenListNoCoin = useSelector(getAddTokenList);
	const tokenList = tokenListNoCoin?.filter(({ type }) => type === 'token');
	const pairList = useSelector(getSwapPairList);
	const walletAddress = useSelector(getAddress);
	const prevWalletAddress = usePrevious(walletAddress?.wallet);
	const network = useSelector(getNetworkId);
	const tokenRefresh = useRefreshToken();
	const { provider, account } = useWeb3React();
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const locationState = location.state as IExchangeLocationStateWithObject | null;
	const [initFromSymbol, initToSymbol] = getInitTokenSymbols(locationState, assetList);

	const [fromTokenSymbol, setFromTokenSymbol] = useState(initFromSymbol);
	const [toTokenSymbol, setToTokenSymbol] = useState(initToSymbol);
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const [procent, setProcent] = useState('');
	const [loader, setLoader] = useState(false);
	const [loaderAdd, setLoaderAdd] = useState(false);

	const [disableBtn, setDisableBtn] = useState('');

	// const [slipping, setSlipping] = useState(slippingT||'0.1');
	const [selectFromWallet, setSelectFromWallet] = useState<any | null>(null);
	const assetCoin = tokenListNoCoin?.find((item) => item.type === 'coin');

	const fromTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, toTokenSymbol);
	}, [tokenList, toTokenSymbol]);

	const toTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, fromTokenSymbol);
	}, [tokenList, fromTokenSymbol]);

	const fromToken = getTokenBySymbol(tokenList, fromTokenSymbol);
	const toToken = getTokenBySymbol(tokenList, toTokenSymbol);
	const [perOne, setPerOne] = useState({
		from: { ...fromToken, from: 0 },
		to: { ...toToken, to: 0 },
	});
	const isPairInList = getIsTokenPairInList(fromToken, toToken, pairList);
	// const pairRate = getRateForTokenPair(isPairInList, calculatedRate, fromToken, toToken);

	const prevTokenFrom = usePrevious(fromToken);
	const prevTokenTo = usePrevious(toToken);

	const tryNetwork = network !== chainId;

	const currentPair = useMemo(() => {
		return getCurrentPair(pairList, fromToken, toToken);
	}, [pairList, fromToken, toToken]);

	useEffect(() => {
		setPerOne({
			from: { ...fromToken, from: 0 },
			to: { ...toToken, to: 0 },
		});
		setFromValue('');
		setToValue('');
		setProcent('');
	}, [toTokenSymbol, fromTokenSymbol]);

	useEffect(() => {
		if (currentPair && toValue && fromValue) {
			const procentValue = calculateReceiveLiquidity(
				fromValue || '0',
				toValue || '0',
				fromToken,
				currentPair,
				String(currentPair?.total_supply),
				pairBalance,
			);
			Number(procentValue) ? setProcent(String(procentValue || 0)) : undefined;
		}
	}, [toValue, currentPair, fromValue]);

	useEffect(() => {
		setDisableBtn(
			String(
				tryInsufficientLiquidity(
					currentPair,
					fromValue,
					toValue,
					fromToken,
					toToken,
					pairList,
					assetList,
				),
			),
		);
	}, [currentPair, fromValue, toValue]);

	useEffect(() => {
		if (fromToken || currentPair || toValue) {
			const isPairFromTo = getPriceFromToValue(fromValue, toValue, fromToken, toToken, currentPair);

			setPerOne(isPairFromTo);
		}
	}, [fromToken, currentPair, toValue, fromValue]);

	const handleFromValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (checkAfterPoint(e.target.value, Number(fromToken?.decimals))) return;
		setFromValue(e.target.value);
	};

	const handleToValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (checkAfterPoint(e.target.value, Number(toToken?.decimals))) return;
		setToValue(e.target.value);
	};

	const [currentPercent, setCurrentPercent] = useState(0.25);
	const [openModal, setOpenModal] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openTransactionModal, setOpenTransactionModal] = useState(false);

	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};

	const checkStatus = (check: boolean) => {
		if (check) {
			setDisableBtn('');
			setOpenConfirmModal(!openConfirmModal);
			return;
		}
		notificationContainer(`Pool is not available at the moment`, 'info');
		setDisableBtn('Pool is not available at the moment');
	};
	const handlerConfirm = (e: MouseEvent<HTMLButtonElement>) => {
		const payload = {
			params: {
				token1: String(fromToken?.address),
				token2: String(toToken?.address),
			},
			checkStatus,
		};
		dispatch(getTryTokensRequset(payload));
	};

	const handleMax = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'from') {
			setFromValue(
				String(toDecimalsAfterPoint(String(fromToken?.balance), Number(fromToken?.decimals))),
			);
			return;
		}
		setToValue(String(toDecimalsAfterPoint(String(toToken?.balance), Number(toToken?.decimals))));
	};

	useEffect(() => {
		if (currentPair && walletAddress.wallet) {
			dispatch(
				getPairBalanceRequest({
					pairId: currentPair.pair_id,
					walletAddress: walletAddress?.wallet,
				}),
			);
		}
	}, [dispatch, currentPair, walletAddress]);

	useEffect(() => {
		if (prevWalletAddress !== walletAddress.wallet) {
			dispatch(clearTokenBalances());
		}

		if (assetList && walletAddress.wallet) {
			dispatch(getTokenBalancesLiquidity('liquidity'));
		}
	}, [walletAddress.wallet, assetList, dispatch]); // eslint-disable-line

	useEffect(() => {
		if (currentPair) {
			const oppositeValue = getLiqudityValue(fromValue, toValue, fromToken, currentPair);
			if (oppositeValue) {
				setToValue(
					String(
						toDecimalsAfterPoint(
							String(noExponent(Number(oppositeValue))),
							Number(toToken?.decimals),
						),
					),
				);
			}
		}
	}, [fromValue, toValue, currentPair]);

	// Reset tokens to initial/available, if not in list
	useEffect(() => {
		if (fromToken && toToken) return;

		const initFromToken = getTokenBySymbol(tokenList, initFromSymbol);
		const initToToken = getTokenBySymbol(tokenList, initToSymbol);

		const fromTokenSet =
			!toToken && initToToken ? getTokensWithoutSymbol(tokenList, initToSymbol) : tokenList;

		let toTokenSet = tokenList;

		const newFromSymbol = initFromToken ? initFromSymbol : fromTokenSet?.[0];

		if (!fromToken && newFromSymbol) {
			setFromTokenSymbol(newFromSymbol);
			toTokenSet = getTokensWithoutSymbol(toTokenSet, newFromSymbol);
		}

		const newToSymbol = initToToken ? initToSymbol : toTokenSet?.[0];

		if (!toToken && newToSymbol) {
			setToTokenSymbol(newToSymbol);
		}
	}, [tokenList, fromToken, toToken, initFromSymbol, initToSymbol]);

	useEffect(() => {
		if (prevTokenFrom?.address !== fromToken?.address) {
			if (fromToken?.address === toToken?.address) {
				const changeToken = getTokensWithoutAddress(tokenList, fromToken?.address);
				changeToken ? setToTokenSymbol(changeToken[0]) : setToTokenSymbol(fromToken);
				return;
			}
		}
		if (prevTokenTo?.address !== toToken?.address) {
			if (fromToken?.address === toToken?.address) {
				const changeToken = getTokensWithoutAddress(tokenList, toToken?.address);
				changeToken ? setFromTokenSymbol(changeToken[0]) : setFromTokenSymbol(toToken);
			}
		}
	}, [fromToken, toToken]);

	const disabled = !fromValue || !toValue || disableBtn !== '';

	const countFrom = (value: number) => {
		setFromValue(String(toDecimalsAfterPoint(value, Number(fromToken?.decimals))));

		// setFromValue(String(value));
	};
	const countTo = (value: number) => {
		setToValue(toMaxDecimals(String(value), 12));

		// setFromValue(String(value));
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!toMaxDecimals(String(fromToken?.balance), 6) || !Number(percentValue)) {
			return 0;
		}

		return Number(fromToken?.balance) * percentValue;
	};

	const handleAddLiquidity = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		try {
			let accounts;
			let currentAddress = connector === 'INJECTED' ? account : address;
			let currentChain = connector === 'INJECTED' ? chainId : chainWeb3;
			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			const amountInWithDecimals = numberInDecimal(Number(toValue), Number(toToken?.decimals));

			// const amountOut = await RouterContract.methods
			// 	.getAmountsOut(String(Math.round(amountInWithDecimals)), [
			// 		String(fromToken?.address),
			// 		String(toToken?.address),
			// 	])
			// 	.call();

			const paramsToken: IToken = {
				wallet: String(currentAddress),
				amount: String(
					noExponent(
						Math.round(
							Number(numberInDecimal(Number(fromValue), Number(fromToken?.decimals)).toString()),
						),
					),
				),
				// amount: String(Math.round(Number(amountOut[1].toString()) * (1 - Number(slipping) / 100))),
				// amount:'1',
				abi: AbiTokens.abi,
				tokenName: String(fromToken?.name),
				tokenAddress: String(fromToken?.address), // Address
				contractAddess: String(ROUTER_CONTRACT), // Address contract
				provider: currentProvider,
				chainid: String(currentChain),
				setLoader,
				connector,
			};
			const paramsToken2: IToken = {
				wallet: String(currentAddress),
				amount: String(noExponent(Math.round(Number(amountInWithDecimals.toString())))),
				// amount: String(Math.round(Number(amountOut[1].toString()) * (1 - Number(slipping) / 100))),
				// amount:'1',
				abi: AbiTokens.abi,
				tokenName: String(toToken?.name),
				tokenAddress: String(toToken?.address), // Address
				contractAddess: String(ROUTER_CONTRACT), // Address contract
				setLoader,
				chainid: String(currentChain),
				provider: currentProvider,
				connector,
			};
			let aprove1 = undefined;
			let aprove2 = undefined;
			debugger;
			if (fromToken?.symbol !== 'GATO') {
				aprove1 = await ApproveTokens(paramsToken);
			}
			// if (!aprove1) return;

			if (toToken?.symbol !== 'GATO') {
				aprove2 = await ApproveTokens(paramsToken2);
			}

			if (aprove1?.status || aprove2?.status) {
				setLoader(false);
			}
			// if (!aprove1 || !aprove2) return;

			const assetCoinAdd = fromToken?.symbol !== 'GATO' ? fromToken?.address : toToken?.address;

			const paramsFee: any = {
				wallet: currentAddress,
				amount: String(amountInWithDecimals),
				selector: SELECTOR_ADD_LIQUIDITY,
				abi: AbiFee.abi,
				tokenAddress: assetCoinAdd,
				contractAddess: String(ROUTER_CONTRACT), // Address contract
				provider: currentProvider,
				setLoader,
				connector,
			};

			const amountFee = await feeChecker(paramsFee);

			if (!amountFee) {
				return;
			}

			const sendParams: ITypeTransaction = {
				wallet: String(currentAddress),
				abi: AbiRouter.abi,
				tokenAbi: AbiTokens.abi,
				to: ROUTER_CONTRACT,
				amountADesired: toDecimals(String(fromValue), Number(fromToken?.decimals)).toString(),
				// firstTokenAmount:String(Math.round(Number(fromValue)).toString()),
				amountBDesired: toDecimals(String(toValue), Number(toToken?.decimals)).toString(),
				// secondTokenAmount: String(Number(amountOut[1].toString())),
				firstType: fromToken?.symbol === 'GATO',
				secondType: toToken?.symbol === 'GATO',
				provider: currentProvider,
				firstTokenAddress: String(fromToken?.address),
				tokenAsset:
					fromToken?.symbol === 'GATO'
						? {
								...toToken,
								tokenAmount: toDecimals(String(toValue), Number(toToken?.decimals)).toString(),
						  }
						: {
								...fromToken,
								tokenAmount: toDecimals(String(fromValue), Number(fromToken?.decimals)).toString(),
						  },
				type: fromToken?.symbol === 'GATO' || toToken?.symbol === 'GATO' ? 'GATO' : 'token',
				secondTokenAddress: String(toToken?.address),
				code: fromToken?.symbol,
				amountFee,
				setLoaderAdd,
				connector,
			};
			debugger;
			const result = await addLiquidity(sendParams);

			const carrentValueSened = (
				base_quote: string,
				pair: IApiSwapPair,
				from: ISwapTokenUnsafe,
			) => {
				if (base_quote === 'base') {
					return pair?.asset_base.address === from?.address ? Number(fromValue) : Number(toValue);
				}

				return pair?.asset_base.address === from?.address ? Number(toValue) : Number(fromValue);
			};
			const carrentIdSened = (
				base_quote: string,
				pair: IApiSwapPair,
				from: ISwapTokenUnsafe,
				to: ISwapTokenUnsafe,
			) => {
				if (base_quote === 'base') {
					return pair?.asset_base.address === from?.address ? Number(from?.id) : Number(to?.id);
				}

				return pair?.asset_base.address === from?.address ? Number(to?.id) : Number(from?.id);
			};

			if (result) {
				dispatch(
					sendTrancationRequest({
						type: 'add_liquidity',
						tx: String(result?.transactionHash) || '',
						//tx: '',
						base_amount: Number(fromValue),
						quote_amount: Number(toValue),
						base_id: Number(fromToken?.id),
						quote_id: Number(toToken?.id),
						// base_amount: currentPair
						// 	? Number(carrentValueSened('base', currentPair, fromToken))
						// 	: Number(fromValue),
						// quote_amount: currentPair
						// 	? Number(carrentValueSened('quote', currentPair, fromToken))
						// 	: Number(toValue),
						// base_id: currentPair
						// 	? Number(carrentIdSened('base', currentPair, fromToken, toToken))
						// 	: Number(fromToken?.id),
						// quote_id: currentPair
						// 	? Number(carrentIdSened('base', currentPair, fromToken, toToken))
						// 	: Number(toToken?.id),
					}),
				);
				setOpenConfirmModal(false);
				// setOpenTransactionModal(true);
				// setHash(result?.transactionHash);
			}
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
			// setOpenTransactionModal(true);
		}
	};

	const replaceTokenVsCoin = (item: any) => {
		if (item?.symbol === 'GATO') {
			return tokenListNoCoin?.find(({ type }) => type === 'coin');
		}
		return item;
	};
	return (
		<>
			<div className="general-block general-block--center">
				<div className="block-header block-header--mb0">
					<h4 className="general-block-title">Add Liquidity</h4>
					<div className="block-header__wrapper">
						<NavLink to="/pool" className="block-settings-btn">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.55 21.67C18.84 20.54 22 16.64 22 12C22 6.48 17.56 2 12 2C5.33 2 2 7.56 2 7.56M2 7.56V3M2 7.56H4.01H6.44"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M2 12C2 17.52 6.48 22 12 22"
									stroke="#8E8EA9"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeDasharray="3 3"
								/>
							</svg>
						</NavLink>
					</div>
				</div>
				<p className="block-subtitle">Add liquidity to receive LP tokens</p>
				<div className="block-content">
					<div className="exchange-item">
						<TokenInput
							tokenList={tokenList}
							value={fromToken}
							onChange={(token) => setFromTokenSymbol(token)}
							title="From"
							manage
							coin={assetCoin}
						/>
						<div className="input input--exchange">
							<label>
								<div className="input-wrapper">
									<input
										className="input-item input-item--exchange input-item--get-info"
										value={fromValue}
										onChange={handleFromValueChange}
										type="number"
										min="0"
										placeholder="0"
									/>
									<button
										onClick={handleMax}
										name="from"
										type="button"
										className="exchange-max-btn"
									>
										MAX
									</button>
								</div>
							</label>
						</div>
					</div>

					<ConvertPercentButtons
						countFrom={countFrom}
						percentButtonCountValue={percentButtonCountValue}
						amount={fromValue}
						currentPercent={currentPercent}
						setCurrentPercent={setCurrentPercent}
					/>

					<div className="exchange-transfer exchange-transfer--2-line">
						<p className="exchange-transfer__text">
							Availability: {toMaxDecimals(String(replaceTokenVsCoin(fromToken)?.balance || 0), 8)}{' '}
							{fromToken?.symbol}
						</p>

						<button type="button" className="transfer-btn">
							<PlusGrayButton />
						</button>
					</div>

					<div className="exchange-item">
						<TokenInput
							tokenList={tokenList}
							value={toToken}
							onChange={(token) => setToTokenSymbol(token)}
							title="To"
							manage
							coin={assetCoin}
						/>

						<div className="input input--exchange">
							<label>
								<div className="input-wrapper">
									<input
										className="input-item input-item--exchange"
										value={toDecimalsAfterPoint(toValue, 6)}
										onChange={handleToValueChange}
										type="number"
										min="0"
										placeholder="0"
									/>
									<button onClick={handleMax} name="to" type="button" className="exchange-max-btn">
										MAX
									</button>
								</div>
							</label>
						</div>
					</div>
					<ConvertPercentButtons
						countFrom={countFrom}
						percentButtonCountValue={percentButtonCountValue}
						amount={fromValue}
						currentPercent={currentPercent}
						setCurrentPercent={setCurrentPercent}
					/>
					<div className="exchange-transfer">
						<p className="exchange-transfer__text">
							Availability: {toMaxDecimals(String(replaceTokenVsCoin(toToken)?.balance || 0), 12)}{' '}
							{toToken?.symbol}
						</p>
					</div>
				</div>
				<div className="operation-details-box">
					<p className="block-subtitle block-subtitle--type2 block-subtitle--no-mt block-subtitle--mb-8">
						Prices and pool share:
					</p>
					<div className="operation-details-group">
						{/* <div className="operation-info">
							<div className="token-block">
								<div className="token-block__icon">
									<img src={fromToken?.logo} alt="" />
								</div>
								<div className="token-block__icon">
									<img src={toToken?.logo} alt="" />
								</div>
								<div className="token-block__info">
									<p className="token-block__name">{`${fromTokenSymbol} - ${toTokenSymbol}`}</p>
								</div>
							</div>
							<p className="operation-info__number">
								{convertExponentialToDecimal(Number(pairBalance))}
							</p>
						</div> */}
						<div className="operation-info">
							<p className="operation-info__text">{`${toTokenSymbol?.symbol} per ${fromTokenSymbol?.symbol}`}</p>
							<p className="operation-info__number">
								{convertExponentialToDecimal(perOne?.from.from)}
							</p>
						</div>
						<div className="operation-info">
							<p className="operation-info__text">{`${fromTokenSymbol?.symbol} per ${toTokenSymbol?.symbol}`}</p>
							<p className="operation-info__number">{convertExponentialToDecimal(perOne?.to.to)}</p>
						</div>
						<div className="operation-info operation-info--no-mb">
							<p className="operation-info__text">Share of Pool</p>
							<p className="operation-info__number"> &lt;{procent || 0}%</p>
						</div>
					</div>
				</div>
				<div className="block-footer">
					<button
						onClick={handlerConfirm}
						disabled={disabled || tryNetwork}
						type="button"
						className="button button--big-height button--full-width"
					>
						{disableBtn || 'Supply'}
					</button>
				</div>
				<ConfirmModal
					openModal={openConfirmModal}
					setOpenModal={setOpenConfirmModal}
					itemIn={fromToken}
					fromValue={fromValue}
					itemOut={toToken}
					toValue={toValue}
					perOne={perOne}
					aproveSwap={handleAddLiquidity}
					procent={procent}
					loader={loader}
				/>
				<ReceiveModal openModal={loaderAdd} setOpenModal={setLoaderAdd} />
			</div>
			<div className="general-info-block">
				<p className="general-info-text">
					By adding liquidity you`ll earn 0,17% of all trades on this pair proportional to your
					share of the pool. Fees are added to the pool, accrue in real time and can be claimed by
					withdrawing your liquidity.
				</p>
			</div>
		</>
	);
};
