import { FC } from 'react';
import { Content } from 'layouts/Content';
import { StakePerDividents } from 'components/StakePerDividents';

export const StakePerDividentsPage: FC = () => {
	return (
		<Content title="Gato Staking Program" text="Gato Staking Program" link="/">
			<StakePerDividents />
		</Content>
	);
};
