import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { NavLink } from 'react-router-dom';

export const CheckEmailReset: FC = () => {
	const [userInfo, setUserInfo] = useState<any>(null);
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		if (location.state) {
			setUserInfo(location.state);
		}
	}, [location]);

	const handleClick = () => {
		dispatch(forgotPasswordRequest({ email: String(userInfo?.email).toLowerCase() }));
	};

	return (
		<>
			<div className="authorization-form__header">
				<p className="form-title form-title--center">Please check Email</p>
				<div className="form-text form-text--center form-text--fixed-width">
					<p>We’ve sent you the link to reset your password {userInfo?.email}</p>
				</div>
			</div>
			<div className="authorization-form__body">
				<div className="confirm-request">
					<p className="confirm-request__text">
						Didn’t receive email? Please check Spam or{' '}
						<button onClick={handleClick} type="button" className="confirm-request__link">
							request for a new one
						</button>{' '}
						in several minutes
					</p>
				</div>
			</div>
			<div className="form-footer">
				<NavLink to="/" className="button button--big-height button--full-width">
					Done
				</NavLink>
			</div>
		</>
	);
};
