/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import Popup from 'reactjs-popup';
import { TPopupProps } from './types';

export const ModalWarapper: FC<TPopupProps> = ({
	openModal,
	setOpenModal,
	children,
	insideClass = '',
}) => {
	const closeModal = () => setOpenModal(false);
	return (
		<Popup open={openModal} onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className={`popup-window__inside ${insideClass}`}>{children}</div>
			</div>
		</Popup>
	);
};
