import { useEffect } from 'react';

const useOutsideClick = (ref: { current: HTMLElement | null }, handler: () => void) => {
	const listener = (event: Event) => {
		const target = event.target as HTMLDivElement;
		if (ref.current && !ref.current.contains(target)) {
			handler();
		}
	};

	useEffect(() => {
		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useOutsideClick;
