import Web3 from 'web3';

const chainId = parseInt(String(process.env.REACT_APP_CHAIN_ID), 10);
const chainBscId = parseInt(String(process.env.REACT_APP_BSC_CHAIN_ID), 10);

const REACT_APP_NETWORK_NAME = String(process.env.REACT_APP_NETWORK_NAME);
/* eslint-disable */

export type TChain = {
	chainId: string;
	chainName: string;
	nativeCurrency: {
		name: string;
		symbol: string;
		decimal: number;
	};
	rpcUrls: any;
	blockExplorerUrls: any;
};
export type TChainObj = {
	[any: number]: TChain;
};

export const networks: TChainObj = {
	4287: {
		chainId: Web3.utils.toHex(chainId),
		chainName: String(REACT_APP_NETWORK_NAME),
		nativeCurrency: {
			name: 'GATO',
			symbol: 'GATO',
			decimal: 18,
		},
		rpcUrls: [String(process.env.REACT_APP_RPC_URL)],
		blockExplorerUrls: [String(process.env.REACT_APP_EXPLORER)],
	},
	97: {
		chainId: Web3.utils.toHex(chainBscId),
		chainName: 'BNB Smart Chain Testnet',
		nativeCurrency: {
			name: 'tBNB',
			symbol: 'tBNB',
			decimal: 18,
		},
		rpcUrls: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545'],
		blockExplorerUrls: ['https://testnet.bscscan.com/'],
	},
};
