import { AxiosResponse } from 'axios';
import {
	TransactionsRequest,
	TPoolPairDataRequest,
	TTransactionResponse,
	TTokenHashDataRequest,
	TTryTokenRequest,
	TTryTokenResponse,
	TAutoSwapFeeParams,
} from 'redux/reducers/swap/types';

export interface ISwapApi {
	getAssets: (params?: IApiGetSwapAssetsParams) => Promise<IApiGetSwapAssetsResponse>;
	getPairs: (params?: IApiGetSwapPairsParamsUnsafe) => Promise<IApiGetSwapPairsResponse>;
	getTopPools: (params?: IApiGetSwapTopPoolsParams) => Promise<IApiGetSwapTopPoolsResponse>;
	getTopTokens: (params?: IApiGetSwapTopTokensParams) => Promise<IApiGetSwapTopTokensResponse>;
	getTokensList: (params: IApiGetSwapTopTokensParams) => Promise<IApiGetSwapTokensResponse>;

	calculateRate: (params: IApiCalculateRateParams) => Promise<IApiCalculateRateResponse>;

	sendTransaction: (payload: TransactionsRequest) => Promise<TTransactionResponse>;
	getPools: (params: string) => Promise<any>;
	getPairLiquidity: (params: TPoolPairDataRequest) => Promise<any>;
	getTLV: (payload: number) => Promise<AxiosResponse>;
	getGraphToken: (params: IApiGraphRequest) => Promise<IApiGraphResponse>;
	getGraphTokenHash: (payload: TTokenHashDataRequest) => Promise<AxiosResponse>;
	getTryTokens: (payload: TTryTokenRequest) => Promise<TTryTokenResponse>;

	postAutoSwapFee: (params: TAutoSwapFeeParams) => Promise<AxiosResponse>;
}
// 📌 Assets

export interface IApiGetSwapAssetsParams {
	current_page?: number;
	per_page?: number;
	is_active?: number;
}
export interface IApiGetSwapTopPoolsParams {
	current_page?: number;
	per_page?: number;
	search?: string;
}

export interface IApiGetSwapTopTokensParams {
	current_page?: number;
	per_page?: number;
	asset?: string;
}

export interface IApiGetSwapTopTokensResponse {
	current_page: number;
	data: IApiToken[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
}

export interface IApiGetSwapTokensResponse {
	current_page: number;
	data: ITokensData;
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
}

export interface ITokensData {
	[key: string]: IApiToken;
}

export interface IApiToken {
	id: number;
	asset_id: number;
	swap_size_24_h_in_usd: string;
	created_at: string;
	updated_at: string;
	total_swap_transaction: number;
	total_pool_transaction: number;
	total_swap_pool_transaction: number;
	price_in_stable_coin_24_h_before: string;
	change_price_24_hours_percent: string;
	asset: IApiSwapAsset;
}

export interface IApiGetSwapTopPoolsResponse {
	current_page: number;
	data: IApiPool[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
}

export interface IApiPool {
	id: number;
	pair_id: number;
	base_id: number;
	quote_id: number;
	base_size_24_h_in_usd: string;
	quote_size_24_h_in_usd: string;
	swap_size_24_h_in_usd: string;
	transactions_24_h: string;
	created_at: string;
	updated_at: string;
	asset_base: IApiSwapAsset;
	asset_quote: IApiSwapAsset;
}

export interface IApiGetSwapAssetsResponse {
	current_page: number;
	data: IApiSwapAsset[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: Array<{
		url: string | null;
		label: string;
		active: boolean;
	}>;
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
}

export interface IApiSwapAsset {
	id: number;
	name: string;
	symbol: string;
	address: string;
	type: string;
	chainId: string;
	decimals: number;
	logo: string;
	is_active: number;
	// created_at: null;
	// updated_at: null;
	price_in_stable_coin: string;
	reserve_usd_in_pools_24h: string;
	status: string;
	is_active_global: true;
	created_by_admin: true;
	total_supply: null;
	target_wallet_address: null;
	website_url: null;
	description: null;
	mint: null;
	contract_hash: null;
	commission_type: null;
	commission_value: null;
	max_amount: null;
	price_in_usd: string;
}

// 📌 Pairs

export interface IApiGetSwapPairsParams {
	current_page?: number;
	per_page?: number;
	is_active?: number;
	lock_swap?: number;
	lock_add_liquidity?: number;
}

export type IApiGetSwapPairsParamsUnsafe = IApiGetSwapPairsParams | undefined;

export interface IApiGetSwapPairsResponse {
	current_page: number;
	data: IApiSwapPair[];
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	links: Array<{
		url: string | null;
		label: string;
		active: boolean;
	}>;
	next_page_url: string | null;
	path: string;
	per_page: number;
	prev_page_url: string | null;
	to: number;
	total: number;
}

export interface IApiSwapPair {
	id: number;
	pair_id: string;
	base_id: string;
	quote_id: string;
	reserve_base: string;
	reserve_quote: string;
	block_timestamp_date_time: string;
	liquidity: number;
	// created_at: null;
	// updated_at: null;
	asset_base: IApiSwapAsset;
	asset_quote: IApiSwapAsset;
	created_at?: string | null;
	decimals?: string | null;
	lp_name?: string | null;
	lp_symbol?: string | null;
	pairBalance?: string;
	reserve_usd?: string;
	total_locked_usd?: string;
	total_supply: string;
}

// 📌 Calculate rate

export interface IApiCalculateRateParams {
	firstAddress: string;
	secondAddress: string;
	amount: string;
}

export interface IApiCalculateRateResponse {
	price: string;
}

export enum EPeriod {
	day = 'day',
	week = 'week',
	month = 'month',
}
export interface IApiGraphRequest {
	asset_address: string;
	graphPoints: string;
	period: string;
}

export interface IApiGraphRequestPayload {
	apiParams: {
		asset_address: string;
		graphPoints: string;
		period: string;
		from?: string;
		to?: string;
	};
	type: 'price' | 'swap';
}

export interface IApiGraphResponse {
	swap_volumes_graph: ISwapVolumesGraphData[];
	price_graph: IPriceGraph[];
	last_price: number;
	percent: string;
}

export interface ISwapVolumesGraphData {
	created_at: string;
	amount_in_usd: number;
}

export interface IPriceGraph {
	price_in_usd: number;
	time: string;
}
