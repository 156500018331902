import { FC, useEffect } from 'react';
import { getNationalityRequest } from 'redux/reducers/settings/reducer';
import { PageProfile } from 'layouts/page/Profile';
import { AccountDetails } from 'components/Profile/AccountDetails';
import { useDispatch } from 'react-redux';

export const AccountDetailsPage: FC = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getNationalityRequest());
	}, [dispatch]);
	return (
		<PageProfile title="Account Settings" text="Account Settings" link="/profile">
			<AccountDetails />
		</PageProfile>
	);
};
