import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { api } from 'services';
// import { removeEmptyStrings } from 'services/utils/form';
import { notificationContainer } from 'services/utils/notificationContainer';
import Loader from 'ui/Loader';
import ButtonsSelect from 'ui/ButtonsSelect';
import FilePicker from 'ui/FilePicker';
// import TokenCreationConfirm from 'components/Tokens/Create/ConfirmModal';
// import { getAssetsRequest } from 'redux/reducers/tokens/reducer';
import { getMintRequest } from 'redux/reducers/token/reducer';
import { IValues } from './types';

export const CreateTokenForm: FC = () => {
	const dispatch = useDispatch();
	const [confirmOpened, setConfirmOpened] = useState<any>(null);
	const [logoSrc, setLogoSrc] = useState<string>();
	const initialValues: IValues = {
		name: '',
		decimals: 2,
		target_wallet_address: '',
		description: '',
		symbol: '',
		mint: false,
		total_supply: '',
		website_url: '',
	};

	const validationSchema = yup.object().shape({
		name: yup.string().required('Name is required!'),
		decimals: yup
			.number()
			.min(0, 'Decimal can not be lower then 0')
			.max(18, 'Decimal can not be greater then 18')
			.typeError('Decimal can be only number')
			.required('Decimal is required'),
		target_wallet_address: yup.string().required('Wallet address is required'),
		symbol: yup.string().required('Symbol is required'),
		total_supply: yup
			.number()
			.typeError('Total supply must be an integer')
			.required('Total supply is required'),
	});

	const openConfirm = () => {
		setConfirmOpened(true);
	};

	const closeConfirm = () => {
		setConfirmOpened(false);
	};

	const onLogoChange = (file: File | null, src?: string) => {
		console.log('on Logo Change', file, src);
	};

	// TODO

	// decimal amout change handler
	const onButtonsChange = (value: number | undefined) => {
		console.log(value);
	};

	// create button click handler

	const onCreateClick = () => {
		console.log('token create button');
		dispatch(getMintRequest());
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				// closeConfirm();
			}}
		>
			{({ errors, values, setValues, validateForm, setTouched, isSubmitting }) => {
				return (
					<Form className="create-token-form">
						{isSubmitting ? (
							<div className="loader-wrapper">
								<Loader />
							</div>
						) : (
							<div className="create-token-container">
								<div className="create-token-box">
									<div className="input">
										<p className="input__name">Name *</p>
										<div className="input-wrapper">
											<Field className="input-item" type="text" name="name" placeholder="Name" />
											<ErrorMessage className="error-message" name="name" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Decimal</p>
										<ButtonsSelect
											variants={[2, 6, 18]}
											value={values.decimals}
											onChange={onButtonsChange}
										/>
										<span className="error-message">{errors.decimals}</span>
									</div>
									<div className="input">
										<p className="input__name">Wallet address *</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												type="text"
												name="target_wallet_address"
												placeholder="Enter address"
											/>
											<ErrorMessage
												className="error-message"
												name="target_wallet_address"
												component="span"
											/>
										</div>
									</div>
									<div className="input">
										<p className="input__name">Description</p>
										<div className="input-wrapper">
											<div className="textarea">
												<Field
													className="input-item"
													type="text"
													name="description"
													component="textarea"
													placeholder="Enter description"
												/>
												<ErrorMessage
													className="error-message"
													name="description"
													component="span"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="create-token-box">
									<div className="input">
										<p className="input__name">Symbol *</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="symbol"
												type="text"
												placeholder="Symbol"
											/>
											<ErrorMessage className="error-message" name="symbol" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Total supply value *</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="total_supply"
												type="text"
												placeholder="Enter value"
											/>
											<ErrorMessage
												className="error-message"
												name="total_supply"
												component="span"
											/>
										</div>
									</div>
									<div className="input">
										<p className="input__name">Website URL</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												name="website_url"
												type="text"
												placeholder="Enter URL"
											/>
											<ErrorMessage className="error-message" name="website_url" component="span" />
										</div>
									</div>
									<div className="input">
										<p className="input__name">Add Logo</p>
										<FilePicker value={values.logo || null} onChange={onLogoChange} />
									</div>
								</div>
							</div>
						)}
						<div className="create-token-footer">
							<button
								type="submit"
								className="button button--w370 button--size2"
								disabled={isSubmitting}
							>
								Create
							</button>
							<div className="switcher-block rr">
								<p className="switcher-block__name">Mint more tokens</p>
								<div className="switch">
									<label className="switch__label">
										<Field name="mint" component="input" type="checkbox" className="hidden" />
										<span className="switch__toggler" />
									</label>
								</div>
							</div>
						</div>
						{/* {confirmOpened && (
							<TokenCreationConfirm token={values} logoSrc={logoSrc} onCancel={closeConfirm} />
						)} */}
					</Form>
				);
			}}
		</Formik>
	);
};
