import { FC, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
// import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { TChangePassSubmitValue, TChangePasswordForm } from './types';
/* eslint-disable no-debugger */
// ==========================================:
export const ChangePasswordForm: FC<TChangePasswordForm> = ({ changePassSubmit }) => {
	const user = useSelector(getUserSettingsData);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const navigate = useNavigate();

	const initialValues = {
		phone: user?.phone || '',
		currentPassword: '',
		password: '',
		confirmPassword: '',
	};

	const validationSchema = yup.object().shape({
		currentPassword: yup
			.string()
			.required('Password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Max password length is 25 characters.'),
		password: yup
			.string()
			.required('New Password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Max password length is 25 characters.'),
		confirmPassword: yup
			.string()
			.required('Confirm New Password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.oneOf([yup.ref('password'), null], 'Password doesn`t match')
			.max(25, 'Max password length is 25 characters.'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: TChangePassSubmitValue, { resetForm, setSubmitting }) => {
				const dataValues = {
					data: {
						password: values.password,
						c_password: values.confirmPassword,
						old_password: values.currentPassword,
					},
					navigate,
				};
				changePassSubmit(dataValues);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form>
						<div className="authorization-form__body">
							<div className="authorization-form__group">
								{user?.phone_verified_at ? (
									<div className="input input--disable">
										<p className="input__name">Phone Number</p>
										<div className="input-wrapper" />
										<PhoneInput
											country="us"
											value={user?.phone}
											inputClass="input-item"
											disabled
										/>{' '}
										<p className="input-description">
											For security reasons please contact{' '}
											<NavLink to="/help-and-support">Gatochain support</NavLink> if you need to
											change your email adress
										</p>
									</div>
								) : (
									<div className="input input--disable">
										<p className="input__name">Email</p>
										<div className="input-wrapper">
											<input
												className="input-item"
												type="text"
												placeholder="Email"
												disabled
												value={user?.email || ''}
											/>
										</div>
										<p className="input-description">
											For security reasons please contact{' '}
											<NavLink to="/help-and-support">Gatochain support</NavLink> if you need to
											change your email adress
										</p>
									</div>
								)}
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="Current Password"
									name="currentPassword"
									required
									title="Current Password"
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
								/>
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="New Password"
									name="password"
									required
									title="New Password"
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
								/>

								<Field
									type={isShowConfirmPass ? 'text' : 'password'}
									placeholder="Confirm New Password"
									name="confirmPassword"
									required
									title="Confirm New Password"
									component={Input}
									ariaLabel="show-confirm-password"
									showPass={isShowConfirmPass}
									setIsShowPass={handleConfirmPassDisplay}
								/>
							</div>
						</div>
						<div className="form-footer">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								aria-label="form-submit"
								type="submit"
								className="button button--big-height button--full-width"
							>
								Confirm
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
