/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiGenerateGoogle2FAKeyResponse } from 'services/api/settings/types';
import { DEFAULT_TRANSACTION_SPEED_STANDARD, THEME_DARK } from './constants';
import {
	TChangePasswordPayload,
	ICheck2faEnables,
	IDisable2faPayload,
	IEnable2faPayload,
	IGenerate2faKeyResponse,
	ISettingsStore,
	ISetUserPhoneRequestPayload,
	IUserSettings,
	INotifications,
	TDefaultTransactionSpeed,
	TTheme,
	TReferralsData,
	ISendEmailCodeRequestPayload,
	IEmail2FARequestPayload,
	IGenerateGoogle2FAKeyRequestPayload,
	IGoogle2FARequestPayload,
	TEditProfilePayload,
	TGetNationalityResponse,
} from './types';

// ==========================================:
export const initialState: ISettingsStore = {
	userSettingsData: null,
	data2fa: null,
	google2FAKey: null,
	check2faEnables: null,
	userSettingsDataLoader: false,
	displayDisable2faForm: false,
	account2faLoader: false,
	check2faEnablesLoader: false,
	changePassLoader: false,
	setPhoneLoader: false,
	setNotificationLoader: false,
	notifications: null,
	theme: THEME_DARK,
	slippageTolerance: 0.5,
	defaultTransactionSpeed: DEFAULT_TRANSACTION_SPEED_STANDARD,
	partialOrderExecution: false,
	referrals: null,
	referralsLoader: false,
	actions2FALoading: false,
	nationality: null,
};

// ==========================================:
const settings = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		userSettingsRequest: (state) => {
			const userSettingsState = state;

			userSettingsState.userSettingsDataLoader = true;
		},
		userSettingsSuccess: (state, action: PayloadAction<IUserSettings>) => {
			const { payload } = action;
			const userSettingsState = state;

			userSettingsState.userSettingsDataLoader = false;
			userSettingsState.userSettingsData = payload;
		},
		userSettingsFailure: (state) => {
			const userSettingsState = state;

			userSettingsState.userSettingsDataLoader = false;
		},

		changeUserPassRequest: (state, action: PayloadAction<TChangePasswordPayload>) => {
			const changeUserPassState = state;

			changeUserPassState.changePassLoader = true;
		},
		changeUserPassSuccess: (state) => {
			const changeUserPassState = state;

			changeUserPassState.changePassLoader = false;
		},
		editProfileRequest: (state, action: PayloadAction<TEditProfilePayload>) => {
			const userSettingsState = state;

			// userSettingsState.userSettingsDataLoader = true;
		},
		editProfileSuccess: (state) => {
			const userSettingsState = state;

			// userSettingsState.userSettingsDataLoader = false;
		},

		// 2FA New
		sendEmailCodeRequest: (state, action: PayloadAction<ISendEmailCodeRequestPayload>) => {
			state.actions2FALoading = true;
		},
		sendEmailCodeSuccess: unsetActions2FALoading,
		sendEmailCodeError: unsetActions2FALoading,

		enableEmail2FARequest: (state, action: PayloadAction<IEmail2FARequestPayload>) => {
			state.actions2FALoading = true;
		},
		enableEmail2FASuccess: (state) => {
			state.actions2FALoading = false;

			if (state.userSettingsData?.user) {
				state.userSettingsData.user.email_2fa_enabled = true;
			}
		},
		enableEmail2FAError: unsetActions2FALoading,

		disableEmail2FARequest: (state, action: PayloadAction<IEmail2FARequestPayload>) => {
			state.actions2FALoading = true;
		},
		disableEmail2FASuccess: (state) => {
			state.actions2FALoading = false;

			if (state.userSettingsData?.user) {
				state.userSettingsData.user.email_2fa_enabled = false;
			}
		},
		disableEmail2FAError: unsetActions2FALoading,

		generateGoogle2FAKeyRequest: (
			state,
			action: PayloadAction<IGenerateGoogle2FAKeyRequestPayload>,
		) => {
			state.actions2FALoading = true;
		},
		generateGoogle2FAKeySuccess: (
			state,
			{ payload }: PayloadAction<IApiGenerateGoogle2FAKeyResponse>,
		) => {
			state.actions2FALoading = false;
			state.google2FAKey = payload;
		},
		generateGoogle2FAKeyError: (state) => {
			state.actions2FALoading = false;
			state.google2FAKey = null;
		},

		enableGoogle2FARequest: (state, action: PayloadAction<IGoogle2FARequestPayload>) => {
			state.actions2FALoading = true;
		},
		enableGoogle2FASuccess: (state) => {
			state.actions2FALoading = false;

			if (state.userSettingsData?.user) {
				state.userSettingsData.user.google2fa_enabled = true;
			}
		},
		enableGoogle2FAError: unsetActions2FALoading,

		disableGoogle2FARequest: (state, action: PayloadAction<IGoogle2FARequestPayload>) => {
			state.actions2FALoading = true;
		},
		disableGoogle2FASuccess: (state) => {
			state.actions2FALoading = false;

			if (state.userSettingsData?.user) {
				state.userSettingsData.user.google2fa_enabled = false;
			}
		},
		disableGoogle2FAError: unsetActions2FALoading,

		// 2FA Old (to delete)
		generate2faKeyRequest: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
		},
		generate2faKeySuccess: (state, action: PayloadAction<IGenerate2faKeyResponse>) => {
			const { payload } = action;
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.data2fa = payload;
		},
		enable2faRequest: (state, action: PayloadAction<IEnable2faPayload>) => {
			const enable2faState = state;

			enable2faState.account2faLoader = true;
		},
		enable2faSuccess: (state) => {
			const enable2faState = state;

			enable2faState.account2faLoader = false;
			enable2faState.displayDisable2faForm = false;
		},
		disable2faRequest: (state, action: PayloadAction<IDisable2faPayload>) => {
			const disable2faState = state;

			disable2faState.account2faLoader = true;
		},
		disable2faSuccess: (state) => {
			const disable2faState = state;

			disable2faState.account2faLoader = false;
			disable2faState.data2fa = null;
		},
		check2faEnablesRequest: (state) => {
			const check2faEnablesState = state;

			check2faEnablesState.check2faEnablesLoader = true;
		},
		check2faEnablesSuccess: (state, action: PayloadAction<ICheck2faEnables>) => {
			const { payload } = action;
			const check2faEnablesState = state;

			check2faEnablesState.check2faEnablesLoader = false;
			check2faEnablesState.data2fa =
				check2faEnablesState.check2faEnables === '2fa_disable' ? null : state.data2fa;
			check2faEnablesState.check2faEnables = payload.status;
		},
		// 2FA Old (to delete?)
		displayDisableForm: (state, action: PayloadAction<boolean>) => {
			const { payload } = action;
			const displayDisableFormState = state;

			displayDisableFormState.displayDisable2faForm = payload;
		},
		resetGoogle2fa: (state) => {
			const resetGoogle2faState = state;

			resetGoogle2faState.data2fa = null;
			resetGoogle2faState.account2faLoader = false;
			resetGoogle2faState.check2faEnablesLoader = false;
			resetGoogle2faState.displayDisable2faForm = false;
		},

		setPhoneRequest: (state, action: PayloadAction<ISetUserPhoneRequestPayload>) => {
			const setPhoneState = state;

			setPhoneState.setPhoneLoader = true;
		},
		updateNotificationRequest: (state, action: PayloadAction<INotifications>) => {
			const notification = state;

			notification.setNotificationLoader = true;
		},
		checkNotificationRequest: (state) => {
			const checkNotificationState = state;

			checkNotificationState.setNotificationLoader = true;
		},
		checkNotificationSuccess: (state, action: PayloadAction<INotifications>) => {
			const { payload } = action;
			const checkNotificationState = state;

			checkNotificationState.notifications = payload;
			checkNotificationState.setNotificationLoader = false;
		},
		changeTheme: (state, action: PayloadAction<TTheme>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.theme = payload;
		},
		changeSlippageTolerance: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.slippageTolerance = payload;
		},
		setDefaultTransactionSpeed: (state, action: PayloadAction<TDefaultTransactionSpeed>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.defaultTransactionSpeed = payload;
		},
		setPartialOrderExecution: (state, action: PayloadAction<boolean>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.partialOrderExecution = payload;
		},
		getUserReferralsInfoRequest: (state) => {
			const settingsState = state;

			// settingsState.partialOrderExecution = payload;
		},
		getUserReferralsInfoSuccess: (state, action: PayloadAction<TReferralsData>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.referrals = payload;
		},

		getNationalityRequest: (state) => {},
		getNationalitySuccess: (state, action: PayloadAction<TGetNationalityResponse>) => {
			const nationalityState = state;
			nationalityState.nationality = action.payload;
		},

		themeInitState: () => initialState,

		settingsInitState: () => initialState,
	},
});

function unsetActions2FALoading(state: ISettingsStore) {
	state.actions2FALoading = false;
}

export default settings.reducer;
export const {
	userSettingsRequest,
	userSettingsSuccess,
	changeUserPassRequest,
	changeUserPassSuccess,
	sendEmailCodeRequest,
	sendEmailCodeSuccess,
	sendEmailCodeError,
	enableEmail2FARequest,
	enableEmail2FASuccess,
	enableEmail2FAError,
	disableEmail2FARequest,
	disableEmail2FASuccess,
	disableEmail2FAError,
	generateGoogle2FAKeyRequest,
	generateGoogle2FAKeySuccess,
	generateGoogle2FAKeyError,
	enableGoogle2FARequest,
	enableGoogle2FASuccess,
	enableGoogle2FAError,
	disableGoogle2FARequest,
	disableGoogle2FASuccess,
	disableGoogle2FAError,
	generate2faKeyRequest,
	generate2faKeySuccess,
	check2faEnablesRequest,
	check2faEnablesSuccess,
	displayDisableForm,
	enable2faRequest,
	enable2faSuccess,
	disable2faRequest,
	disable2faSuccess,
	settingsInitState,
	setPhoneRequest,
	resetGoogle2fa,
	updateNotificationRequest,
	checkNotificationRequest,
	checkNotificationSuccess,
	changeTheme,
	changeSlippageTolerance,
	setDefaultTransactionSpeed,
	setPartialOrderExecution,
	themeInitState,
	getUserReferralsInfoRequest,
	getUserReferralsInfoSuccess,
	editProfileRequest,
	editProfileSuccess,
	getNationalityRequest,
	getNationalitySuccess,
	userSettingsFailure,
} = settings.actions;
