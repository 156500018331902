import { FC } from 'react';
import { ISwitched2FAProps } from './types';

export const Switched2FA: FC<ISwitched2FAProps> = ({ note, onClose }) => {
	const isOn = note === '2fa-on';

	return (
		<div className="popup popup--padding-type2 popup--smaller">
			<button
				className="popup-close popup-close--type2"
				type="button"
				onClick={onClose}
				aria-label="BreadCrumbs"
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7 7L17 17"
						stroke="#1A203F"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7 17L17 7"
						stroke="#1A203F"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>

			<div className="popup-body">
				<div className="popup-message">
					<div className="popup-message__icon">
						<svg
							width="55"
							height="54"
							viewBox="0 0 55 54"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27.5 49.5C39.875 49.5 50 39.375 50 27C50 14.625 39.875 4.5 27.5 4.5C15.125 4.5 5 14.625 5 27C5 39.375 15.125 49.5 27.5 49.5Z"
								stroke="#3FDBB1"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M17.9375 27.0001L24.305 33.3676L37.0625 20.6326"
								stroke="#3FDBB1"
								strokeWidth="3"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<p className="popup-message__title">
						{isOn ? '2FA switched on' : 'Two-factor authentication turned off'}
					</p>
				</div>

				<div className="form-text form-text--center form-text--mt">
					<p>
						{isOn
							? "We strongly recommend that you don't turn this function off, as this will lower the security level of your account"
							: 'We strongly recommend turning this function on, as this will raise the security level of your account.'}
					</p>
				</div>
			</div>

			<div className="popup-footer">
				<button className="button button--full-width" type="button" onClick={onClose}>
					Confirm
				</button>
			</div>
		</div>
	);
};
