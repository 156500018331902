/* eslint-disable */

// import { Chain, CurrentConfig } from "../config";
import type { AddEthereumChainParameter } from '@web3-react/types';

const REACT_APP_EXPLORER = process.env.REACT_APP_EXPLORER;
const REACT_APP_NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;

// // Chains
// const MAINNET_CHAIN_ID = 1;
// const POLYGON_CHAIN_ID = 137;
// const GATO_CHAIN_ID = 54321;
// // const GATO_PROD_CHAIN_ID = 4286;

// // export const INPUT_CHAIN_ID =
// //   CurrentConfig.chain === Chain.POLYGON ? POLYGON_CHAIN_ID : MAINNET_CHAIN_ID;
// // export const INPUT_CHAIN_URL =
// //   CurrentConfig.chain === Chain.POLYGON
// //     ? CurrentConfig.rpc.polygon
// //     : CurrentConfig.rpc.mainnet;
// export const INPUT_CHAIN_ID =
//   CurrentConfig.chain === Chain.MAINNET ? MAINNET_CHAIN_ID : GATO_CHAIN_ID;
// export const INPUT_CHAIN_URL =
//   CurrentConfig.chain === Chain.MAINNET
//     ? CurrentConfig.rpc.mainnet
//     : CurrentConfig.rpc.gato;

// export const CHAIN_TO_URL_MAP = {
//   [GATO_CHAIN_ID]: CurrentConfig.rpc.gato,
//   [POLYGON_CHAIN_ID]: CurrentConfig.rpc.polygon,
//   [MAINNET_CHAIN_ID]: CurrentConfig.rpc.mainnet,

//   // [GATO_PROD_CHAIN_ID]: CurrentConfig.rpc.gatoprod,
// };

// type ChainInfo = {
//   explorer: string;
//   label: string;
//   nativeCurrency: {
//     name: string;
//     symbol: string;
//     decimals: 18;
//   };
//   rpcUrl: string;
// };

// export const CHAIN_INFO: { [key: string]: ChainInfo } = {
//   [GATO_CHAIN_ID]: {
//     explorer: "https://gatoscan.com/api/",
//     label: "Gato",
//     nativeCurrency: { name: "GATO", symbol: "GATO", decimals: 18 },
//     rpcUrl: CurrentConfig.rpc.gato,
//   },
//   [MAINNET_CHAIN_ID]: {
//     explorer: "https://etherscan.io/",
//     label: "Ethereum",
//     nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
//     rpcUrl: CurrentConfig.rpc.mainnet,
//   },
//   [POLYGON_CHAIN_ID]: {
//     explorer: "https://polygonscan.com/",
//     label: "Polygon",
//     nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
//     rpcUrl: CurrentConfig.rpc.polygon,
//   },

//   // [GATO_PROD_CHAIN_ID]: {
//   //   explorer: "https://gatoscan.com/",
//   //   label: "Gato",
//   //   nativeCurrency: { name: "GATO", symbol: "GATO", decimals: 18 },
//   //   rpcUrl: CurrentConfig.rpc.gato,
//   // },
// };

// // URLs
// export const METAMASK_URL = "https://metamask.io/";
/* eslint-disable */
const GATO: AddEthereumChainParameter['nativeCurrency'] = {
	name: 'GATO',
	symbol: 'GATO',
	decimals: 18,
};

interface BasicChainInformation {
	urls: string[];
	name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
	nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
	blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}
const getInfuraUrlFor = (network: string) =>
	process.env.infuraKey ? `https://${network}.infura.io/v3/${process.env.infuraKey}` : undefined;

type ChainConfig = {
	[chainId: number]: BasicChainInformation | ExtendedChainInformation;
};

export const TESTNET_CHAINS: ChainConfig = {
	4287: {
		urls: ['https://dev.gatoscan.com/api/rpc'],
		name: 'GATO',
		nativeCurrency: GATO,
		blockExplorerUrls: ['https://dev.gatoscan.com/'],
	},
	97: {
		urls: [String(getInfuraUrlFor('goerli'))].filter(Boolean),
		name: 'Görli',
	},
};

import { Chain, CurrentConfig } from './config';

// Chains
const BSC_CHAIN_ID = 97;
const GATO_CHAIN_ID = 4287;

// export const INPUT_CHAIN_ID = CurrentConfig.chain === Chain.GATO ? GATO_CHAIN_ID : BSC_CHAIN_ID;
export const INPUT_CHAIN_ID = CurrentConfig.chain === Chain.GATO ? GATO_CHAIN_ID : GATO_CHAIN_ID;
// export const INPUT_CHAIN_URL =
// 	CurrentConfig.chain === Chain.GATO ? CurrentConfig.rpc.gato : CurrentConfig.rpc.bsc;
export const INPUT_CHAIN_URL =
	CurrentConfig.chain === Chain.GATO ? CurrentConfig.rpc.gato : CurrentConfig.rpc.gato;

export const CHAIN_TO_URL_MAP = {
	[GATO_CHAIN_ID]: CurrentConfig.rpc.gato,
	[BSC_CHAIN_ID]: CurrentConfig.rpc.bsc,
};

type ChainInfo = {
	explorer: string;
	label: string;
	nativeCurrency: {
		name: string;
		symbol: string;
		decimals: 18;
	};
	rpcUrl: string;
};

export const CHAIN_INFO: { [key: string]: ChainInfo } = {
	[GATO_CHAIN_ID]: {
		explorer: String(REACT_APP_EXPLORER),
		label: String(REACT_APP_NETWORK_NAME),
		nativeCurrency: { name: 'GATO', symbol: 'GATO', decimals: 18 },
		rpcUrl: CurrentConfig.rpc.gato,
	},
	[BSC_CHAIN_ID]: {
		explorer: 'https://testnet.bscscan.com/',
		label: 'BNB Smart Chain Testnet',
		nativeCurrency: { name: 'tBNB', symbol: 'tBNB', decimals: 18 },
		rpcUrl: CurrentConfig.rpc.bsc,
	},
};

// URLs
export const METAMASK_URL = 'https://metamask.io/';
