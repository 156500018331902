import { FC } from 'react';
import amico from 'assets/images/amico.svg';
import { CloseButtonIcon } from 'assets/custom-icons';
import { TConfirmModal } from './type';

export const PendingModal: FC<TConfirmModal> = ({
	setOpenModal,
	from,
	fromValue,
	to,
	toValue = '',
}) => {
	const closeModal = () => setOpenModal(false);
	return (
		<div className="popup popup--smaller popup--padding-16">
			<div className="popup-header">
				<p className="popup-header__title">Approve Tokens</p>
				<button type="button" onClick={closeModal} className="popup-close" aria-label="Close">
					<CloseButtonIcon />
				</button>
			</div>

			<div className="popup-body">
				<div className="popup-icon popup-icon--big popup-icon--mb40">
					<img src={amico} alt="clock" />
				</div>
				<div className="submitting-liqud-info submitting-liqud-info--fz-16 submitting-liqud-info--mb-8">
					Waiting For Confirmation
				</div>
				<div
					className="submitting-liqud-info submitting-liqud-info--fz-14 submitting-liqud-info--mb-8
                  submitting-liqud-info--fw700 submitting-liqud-info--lh170"
				>
					Supplying {fromValue} {from} {toValue ? ` and ${toValue} ${String(to)}` : null}
				</div>
				<p className="block-subtitle block-subtitle--center">
					Confirm this transaction in your wallet
				</p>
			</div>
		</div>
	);
};
