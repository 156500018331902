import { AES, enc } from 'crypto-ts';

const ababagalamaga = 'vUZFZ35VbP5R76ybm94s5jPx';

const coder = (value: any) => {
	const cryptedText = AES.encrypt(JSON.stringify(value), ababagalamaga);
	return cryptedText.toString();
};
const deCoder = (value: any) => {
	const bytes = AES.decrypt(value, ababagalamaga);
	const decrypted = bytes.toString(enc.Utf8);

	return JSON.parse(decrypted);
};

export const getLS = (key: string): any => {
	try {
		const lsValue: string | null = localStorage.getItem(`${key}`);
		if (lsValue) {
			const mike = deCoder(lsValue);
			return mike;
		}
		throw new Error(`I do not find chain_${key}`);
	} catch (error) {
		return null;
	}
};

export const setLS = (key: string, value: any): any => {
	try {
		localStorage.setItem(`${key}`, coder(value));
	} catch (error) {
		null;
	}
};

export const removeLS = (key: string): void => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		// Обробка помилок, якщо щось пішло не так
		console.error('Error removing item from local storage:', error);
	}
};

export const localTimeStorage = (operation: string, nameKey: string) => {
	if (operation === 'get') {
		return getLS(nameKey);
	}
	if (operation === '') {
		removeLS(nameKey);
		return 'success';
	}
	if (operation === 'set') {
		setLS(nameKey, new Date());
		return 'success';
	}
	return null;
};

export const checkTimeAndPerformAction = (startTimeValue: any) => {
	const timeValue = new Date(startTimeValue);

	const currentTime = new Date();

	const timePeriodMilliseconds = 60 * 1000; // Одиниця часу в мілісекундах (1 minet)

	const timeValueUTC = new Date(timeValue.getTime() + timeValue.getTimezoneOffset() * 60000);
	const currentTimeUTC = new Date(currentTime.getTime() + currentTime.getTimezoneOffset() * 60000);

	const timeDifferenceMilliseconds = currentTimeUTC.getTime() - timeValueUTC.getTime();

	if (timeDifferenceMilliseconds >= timePeriodMilliseconds) {
		return 0; // Time is end
	}
	const remainingTimeSeconds = Math.floor(
		(timePeriodMilliseconds - timeDifferenceMilliseconds) / 1000,
	);
	return remainingTimeSeconds;
};
