import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { ITokensApi } from './types';

export const tokens: ITokensApi = {
	getTokens: (params) => {
		return http.get(endpoint.tokens.TOKENS, { params }).then(({ data }) => data);
	},
	getToken: (params) => {
		return http.get(endpoint.tokens.TOKEN(params.id)).then(({ data }) => data);
	},
	getTokenTransfers: (params) => {
		return http
			.get(endpoint.tokens.TOKEN_TRANSACTIONS(params.id, params.per_page, params.current_page))
			.then(({ data }) => data);
	},
	getTokenHolders: (params) => {
		return http
			.get(endpoint.tokens.TOKEN_HOLDERS(params.id, params.per_page, params.current_page))
			.then(({ data }) => data);
	},
	postMintGato: () => http.post(endpoint.bridge.POST_MINT_GATO).then(({ data }) => data),
};
