import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IConnectStore, TWallets } from 'redux/reducers/connectWallet/types';
import { TWalletData } from '../wallets/types';

// ==========================================:
const getConnectState = (state: TStoreState): IConnectStore => state.connectWallet;

export const getAddress = createSelector(
	[getConnectState],
	(connectWallet: IConnectStore): TWallets => connectWallet.wallet,
);

// ====================================================:
export const getBalances = createSelector(
	[getConnectState],
	(connectWallet: IConnectStore): TWalletData[] => connectWallet.balances,
);

// ====================================================:
export const getTypeWallet = createSelector(
	[getConnectState],
	(connectWallet: IConnectStore): string => connectWallet.type,
);
