import { FC } from 'react';
import Popup from 'reactjs-popup';
import { TPopupProps } from './types';

export const Modals: FC<TPopupProps> = ({
	openModal,
	setOpenModal,
	children,
	title,
	insideClass = '',
	paddingClass = '',
}) => {
	const closeModal = () => setOpenModal(false);
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className={`popup-window__inside ${insideClass}`}>
					<div
						className={`popup ${
							title === 'Connect Wallet' ? '' : 'popup--smaller'
						} ${paddingClass}`}
					>
						<div className="popup-header">
							<p className="popup-header__title">{title}</p>
							<button className="popup-close" type="button" onClick={closeModal} aria-label="Close">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7 7L17 17"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7 17L17 7"
										stroke="#1A203F"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
						</div>
						<div className="popup-body">{children}</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
