import { FC } from 'react';
import { ISwitcer } from './types';

export const SwitcherBox: FC<ISwitcer> = ({
	handleClick,
	activeB2,
	handleChangeCoin,
	activeCoin,
}) => {
	return (
		<div className="switch-box">
			<div className="swap swap--medium">
				<button
					type="button"
					name="B2C"
					onClick={(e) => handleClick(e)}
					className={`swap__item ${activeB2 === 'B2C' ? 'active' : ''}`}
				>
					B2C
					<span className="hint-block">
						<span className="hint-block__icon">
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.49935 13.3333C10.721 13.3333 13.3327 10.7216 13.3327 7.49996C13.3327 4.2783 10.721 1.66663 7.49935 1.66663C4.27769 1.66663 1.66602 4.2783 1.66602 7.49996C1.66602 10.7216 4.27769 13.3333 7.49935 13.3333Z"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.80273 5.74997C5.93988 5.36011 6.21057 5.03136 6.56687 4.82196C6.92318 4.61256 7.34209 4.53601 7.74942 4.60588C8.15675 4.67575 8.52621 4.88752 8.79236 5.20369C9.05851 5.51986 9.20418 5.92002 9.20357 6.3333C9.20357 7.49997 7.45357 8.0833 7.45357 8.0833"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7.5 10.4166H7.50667"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span className="tooltip tooltip--top tooltip--center">
							<span className="tooltip__text">
								<span className="tooltip__text-title">
									The Infinity referral system will offer you the chance to collect a percentage
									cash back from all the transactions that your referrals are performing on the
									blockchain whether it was buying, selling, swapping, collecting NFTs or more.
								</span>
							</span>
						</span>
					</span>
				</button>
				<button
					type="button"
					name="B2B"
					onClick={(e) => handleClick(e)}
					className={`swap__item ${activeB2 === 'B2B' ? 'active' : ''}`}
				>
					B2B
					<span className="hint-block">
						<span className="hint-block__icon">
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.49935 13.3333C10.721 13.3333 13.3327 10.7216 13.3327 7.49996C13.3327 4.2783 10.721 1.66663 7.49935 1.66663C4.27769 1.66663 1.66602 4.2783 1.66602 7.49996C1.66602 10.7216 4.27769 13.3333 7.49935 13.3333Z"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.80273 5.74997C5.93988 5.36011 6.21057 5.03136 6.56687 4.82196C6.92318 4.61256 7.34209 4.53601 7.74942 4.60588C8.15675 4.67575 8.52621 4.88752 8.79236 5.20369C9.05851 5.51986 9.20418 5.92002 9.20357 6.3333C9.20357 7.49997 7.45357 8.0833 7.45357 8.0833"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7.5 10.4166H7.50667"
									strokeWidth="1.2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						<span className="tooltip tooltip--top tooltip--center">
							<span className="tooltip__text">
								<span className="tooltip__text-title">
									Our blockchain is built not only for end users, but for businesses and entities
									that are looking to be a part of the blockchain as well. GATO blockchain system
									gives you the chance to gain a percentage of all the blockchain transaction fees
									that are related to the business you have invited.
								</span>
							</span>
						</span>
					</span>
				</button>
			</div>

			{/* {activeB2 === 'B2C' && (
				<div className="swap swap--medium">
					<button
						type="button"
						name="COIN"
						onClick={(e) => handleChangeCoin(e)}
						className={`swap__item ${activeCoin === 'COIN' ? 'active' : ''}`}
					>
						Gato coin
					</button>
					<button
						type="button"
						name="GUSD"
						onClick={(e) => handleChangeCoin(e)}
						className={`swap__item ${activeCoin === 'GUSD' ? 'active' : ''}`}
					>
						GUSD
					</button>
				</div>
			)} */}
		</div>
	);
};
