import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useRouteRaplace = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const link = location.pathname.split('/')[1];

	useEffect(() => {
		if (link === 'tx') {
			const hash = location.pathname.split('/')[2];
			navigate(`/transactions/${hash}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, link]);
};

export default useRouteRaplace;
