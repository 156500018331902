import { FC } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { Link } from 'react-router-dom';
// import metamask from 'assets/images/exchange/metamask.svg';
import { CloseButtonIcon } from 'assets/custom-icons';
import { TConfirmLiqudityModal } from './type';

export const ReceiveModal: FC<TConfirmLiqudityModal> = ({ setOpenModal, openModal }) => {
	const closeModal = () => setOpenModal(false);
	return (
		<ModalWarapper
			openModal={openModal}
			setOpenModal={setOpenModal}
			insideClass="popup-window__inside--pt-medium"
		>
			<div className="popup popup--smaller popup--padding-16">
				<div className="popup-header">
					<p className="popup-header__title">You will receive</p>
					<button type="button" onClick={closeModal} className="popup-close" aria-label="Close">
						<CloseButtonIcon />
					</button>
				</div>

				<div className="popup-body">
					<div className="receive">
						<div className="popup-remove-block popup-remove-block--gap0">
							<div className="popup-icon popup-icon--mb16">
								<svg
									width="70"
									height="70"
									viewBox="0 0 70 70"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27.1833 18.9584L34.65 11.4917L42.1167 18.9584"
										stroke="#335BE9"
										strokeWidth="3"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M34.6499 41.3583V11.6958"
										stroke="#335BE9"
										strokeWidth="3"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.6667 35C11.6667 47.8917 20.4167 58.3333 35.0001 58.3333C49.5834 58.3333 58.3334 47.8917 58.3334 35"
										stroke="#C0C0CF"
										strokeWidth="3"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<div className="submitting-liqud-info submitting-liqud-info--fz-16 submitting-liqud-info--mb-8">
								Transaction Submitted
							</div>
							<div className="link-block link-block--center link-block--mb24">
								<Link to="/" className="link-block__item link-block__item--fz14">
									View on GatoScan
								</Link>
							</div>
							{/* <button type="button" className="wallet-item wallet-item--smaller wallet-item--mb0">
								<span className="wallet-item__text wallet-item__text--type2">
									Add GATO-LP to Metamask
								</span>
								<span className="wallet-item__icon wallet-item__icon--trust">
									<img src={metamask} alt="metamask" />
								</span>
							</button> */}
						</div>
					</div>
					<div className="popup-footer">
						<button onClick={closeModal} type="button" className="button button--full-width">
							Close
						</button>
					</div>
				</div>
			</div>
		</ModalWarapper>
	);
};
