import { FC } from 'react';
import { Content } from 'layouts/Content';
import { StakeIt } from 'components/StakeIt';

export const StakeItPage: FC = () => {
	return (
		<Content title="Gato Staking Program" text="Gato Staking Program" link="/">
			<StakeIt />
		</Content>
	);
};
