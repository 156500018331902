import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

export const Tabs: FC<any> = () => {
	const location = useLocation();
	return (
		<div className="explore-block-tabs">
			<Link
				to="transfers"
				className={`explore-block-tabs__item ${
					location.pathname.split('/')[4] === 'transfers' ? 'active' : ''
				}`}
			>
				Token Transfers
			</Link>
			<Link
				to="holders"
				className={`explore-block-tabs__item ${
					location.pathname.split('/')[4] === 'holders' ? 'active' : ''
				}`}
			>
				Token Holders
			</Link>
		</div>
	);
};
