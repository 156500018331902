import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHoldersRequest } from 'redux/reducers/token/reducer';
import { useLocation } from 'react-router';
import {
	getTokenData,
	getTokenHoldersData,
	getTokenHoldersLoading,
} from 'redux/reducers/token/selectors';
import PaginationTable from 'ui/PaginationTable';
import Item from './Item/Item';

const TokenDetailedTabHolders = () => {
	const dispatch = useDispatch();
	const data = useSelector(getTokenHoldersData);
	const symbol = useSelector(getTokenData)?.symbol;
	const loading = useSelector(getTokenHoldersLoading);
	const perPage = 6;
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		dispatch(
			getHoldersRequest({
				per_page: perPage,
				current_page: currentPage,
				id: location.pathname.split('/')[3],
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<>
			<div className="explore-block__inner">
				<div className="explore-block__top">
					<h4 className="block-title block-title--mr-auto">Token Holders</h4>
					<div className="block-pagination block-pagination--header">
						{data && (
							<PaginationTable
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								lastPage={data?.last_page}
							/>
						)}
					</div>
				</div>
				<div className="tokens-holder-box">
					{data
						? data?.data.map((item) => <Item key={item.id} item={item} symbol={symbol} />)
						: null}
					{loading ? <h2>Loading...</h2> : null}
				</div>
				<div className="block-pagination block-pagination--footer block-pagination--pb16">
					{data && (
						<PaginationTable
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							lastPage={data?.last_page}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default TokenDetailedTabHolders;
