import { FC, useState, useEffect } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon } from 'assets/custom-icons';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { PendingModal } from './pending';
/* eslint-disable */

import { TModal } from './type';

export const TransactionModal: FC<TModal> = ({
	openModal,
	setOpenModal,
	toValue,
	coinName,
	fee,
}) => {
	return (
		<ModalWarapper openModal={openModal} setOpenModal={setOpenModal}>
			<PendingModal setOpenModal={setOpenModal} toValue={toValue} coinName={coinName} fee={fee} />
		</ModalWarapper>
	);
};
