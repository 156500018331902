/* eslint-disable import/no-unresolved */
import { FC } from 'react';
// import { closeModal } from '../index';
import useAuth from 'hooks/useAuth';
import { isIOS } from 'react-device-detect';
// import { injected } from 'services/utils/web3React';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { NavLink } from 'react-router-dom';
import { TConnectWallet } from './type';

/* eslint-disable */

declare let window: any;

export const ConnectToWallet: FC<TConnectWallet> = ({
	alt,
	connector,
	title,
	image,
	setOpenModal,
}) => {
	const { login } = useAuth();
	const { open, close } = useWeb3Modal();
	const connectToWallet = async (connect: string) => {
		// if(connect==="walletconnect"){
		// 	notificationContainer("Sorry WalletConnect uprgrating to v2. Try to connect later","info")
		// 	return;
		// }
		if (connect === 'WALLET_CONNECT') {
			try {
				window.localStorage.setItem('connectorLocalStorageKey', connect);
				await open({ view: 'Connect' });
				setOpenModal(false);
			} catch (error: any) {
				console.log(error);
			}
		} else {
			login(connect);
		}
		setOpenModal(false);
	};

	// useEffect(() => {
	// 	if (connectorName === 'injected') {
	// 	}
	// }, [connectorName]);
	// const adressAccount = useSelector(getAddress);

	// const [address, setAddressAccount] = useState('');
	// useEffect(() => {
	// 	if (adressAccount.wallet) setAddressAccount(adressAccount?.wallet);
	// }, [adressAccount]);

	// const dispatch = useDispatch();
	// const closeModal = () => setOpenModal(false);
	// // const injectedConnector = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42] });
	// const { chainId, account, activate, active, library, deactivate } = useWeb3React<Web3Provider>();

	// const connectToWallet = async () => {
	// 	try {
	// 		await activate(injected);
	// 	} catch (ex) {
	// 		console.log(ex);
	// 	}
	// };
	// useEffect(() => {
	// 	if (active) {
	// 		// dispatch(setAddress(String(account)));
	// 		closeModal();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [active, dispatch]);
	// const connectToWallet = async () => {

	// 	if (typeof window?.ethereum !== 'undefined') {
	// 		const ethereum = window?.ethereum;
	// 		try {
	// 			await ethereum.request({
	// 				method: 'eth_requestAccounts',
	// 				// params: [{ chainId: `0x${chainId.toString(16)}` }],
	// 			});
	// 			const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
	// 			const account = provider.getSigner();
	// 			const Address = await account.getAddress();

	// 			dispatch(setAddress(Address));
	// 			closeModal();

	// 			return true;
	// 		} catch (error) {
	// 			notificationContainer("download metamask",'error')
	// 			console.error('Failed to setup the network in Metamask:', error);
	// 			return false;
	// 		}
	// 	}
	// };

	// async function excute(params:type) {

	// }
	return (
		<>
			{alt === 'metamask' ? (
				<div className="wallet-item wallet-item--block">
					<button
						type="button"
						className="wallet-item__btn"
						onClick={() => connectToWallet(connector)}
					>
						<span className="wallet-item__text">{title}</span>
						<span className="wallet-item__icon">
							<img src={image} alt={alt} />
						</span>
					</button>
					<p className="wallet-item__info">
						To connect to MetaMask from browser use{' '}
						<a
							href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
							target="_blank"
							rel="noopener noreferrer"
						>
							Google chrome
						</a>{' '}
						extension
					</p>
					<p className="wallet-item__info padding--top">
						To connect to MetaMask from mobile use{' '}
						<a
							href={`${
								isIOS
									? 'https://apps.apple.com/ua/app/metamask-blockchain-wallet/id1438144202'
									: 'https://play.google.com/store/apps/details?id=io.metamask'
							}`}
							target="_blank"
							rel="noopener noreferrer"
						>{`${isIOS ? 'App store' : 'MetaMask app'}`}</a>
					</p>
					<p className="wallet-item__info padding--top">
						To connect wallet from MetaMask App go to Browser section and copy paste this address:{' '}
						<NavLink to="#">{`https://${window.location.hostname}`}</NavLink>
					</p>
				</div>
			) : (
				<div className="wallet-item wallet-item--block">
					<button
						className="wallet-item__btn"
						type="button"
						onClick={() => connectToWallet(connector)}
					>
						<span className="wallet-item__text">{title}</span>
						<span className="wallet-item__icon">
							<img src={image} alt={alt} />
						</span>
					</button>
					{alt !== 'WalletConnect' && (
						<p className="wallet-item__info">Connection to {title} under development</p>
					)}
				</div>
			)}
		</>
	);
};
