/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useState, useEffect } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { CloseButtonIcon } from 'assets/custom-icons';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { PendingModal } from '../TransactionsModals/pending';

import { TConfirmModal } from './type';

export const ConfirmModal: FC<TConfirmModal> = ({
	openModal,
	setOpenModal,
	itemIn,
	itemOut,
	toValue,
	fromValue,
	slipping,
	displayedRate,
	aproveSwap,
	updatePrice,
	loader,
}) => {
	const closeModal = () => setOpenModal(false);
	const [viewUpdate, setViewUpdate] = useState(false);

	useEffect(() => {
		let timeId: NodeJS.Timeout;
		if (openModal && !viewUpdate) {
			timeId = setTimeout(() => {
				setViewUpdate(true);
			}, 10000);
		}
		return () => {
			clearTimeout(timeId);
		};
	}, [openModal, viewUpdate]);

	const handaleAccept = () => {
		setViewUpdate(false);
		updatePrice();
	};

	return (
		<ModalWarapper openModal={openModal} setOpenModal={setOpenModal}>
			<>
				{loader ? (
					<PendingModal
						setOpenModal={setOpenModal}
						from={itemIn?.symbol}
						to={itemOut?.symbol}
						fromValue={fromValue}
						toValue={toValue}
					/>
				) : (
					<div className="popup ">
						<div className="popup-header">
							<p className="popup-header__title">Confirm Exchange</p>
							<button onClick={closeModal} type="button" className="popup-close">
								<CloseButtonIcon />
							</button>
						</div>
						<div className="popup-body">
							<div className="popup-small-block">
								<div className="exchange-item">
									<div className="select select--exchange">
										<div className="select__current">
											<div className="token-block">
												<div className="token-block__icon">
													<img src={itemIn?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__purpose">From</p>
													<p className="token-block__name">{itemIn?.symbol}</p>
												</div>
											</div>
											<span className="select__current-arrow">
												<svg
													width="12"
													height="8"
													viewBox="0 0 12 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1.41406 1.75L5.91406 6.25L10.4141 1.75"
														stroke="#1A203F"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										</div>
									</div>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info item--padding"
													type="number"
													placeholder="0.0"
													value={fromValue}
													readOnly
												/>
											</div>
										</label>
									</div>
								</div>
								<div className="small-arrow-down">
									<svg
										width="16"
										height="17"
										viewBox="0 0 16 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.0465 10.1201L7.99979 14.1668L3.95312 10.1201"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8 2.8335V14.0535"
											stroke="#1A203F"
											strokeWidth="1.5"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
								<div className="exchange-item">
									<div className="select select--exchange">
										<div className="select__current">
											<div className="token-block">
												<div className="token-block__icon">
													<img src={itemOut?.logo} alt="" />
												</div>
												<div className="token-block__info">
													<p className="token-block__purpose">To</p>
													<p className="token-block__name">{itemOut?.symbol}</p>
												</div>
											</div>
											<span className="select__current-arrow">
												<svg
													width="12"
													height="8"
													viewBox="0 0 12 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1.41406 1.75L5.91406 6.25L10.4141 1.75"
														stroke="#1A203F"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										</div>
									</div>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info item--padding"
													type="number"
													placeholder="0.0"
													value={convertExponentialToDecimal(Number(toValue))}
													readOnly
												/>
											</div>
										</label>
									</div>
								</div>
							</div>
							{viewUpdate && (
								<div className="popup-small-block popup-small-block--p12">
									<div className="small-block">
										<p className="small-block__text">
											<span className="small-block__icon">
												<svg
													width="18"
													height="18"
													viewBox="0 0 18 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M9 6.75V10.5"
														stroke="#FFA51E"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8.99958 16.0575H4.45458C1.85208 16.0575 0.764583 14.1975 2.02458 11.925L4.36458 7.70996L6.56958 3.74996C7.90458 1.34246 10.0946 1.34246 11.4296 3.74996L13.6346 7.71746L15.9746 11.9325C17.2346 14.205 16.1396 16.065 13.5446 16.065H8.99958V16.0575Z"
														stroke="#FFA51E"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M8.99609 12.75H9.00283"
														stroke="#FFA51E"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
											Price Updated
										</p>
										<button onClick={handaleAccept} type="button" className="button">
											Accept
										</button>
									</div>
								</div>
							)}

							<p className="block-subtitle block-subtitle--mb-8">
								Input is estimated. You will sell at most{' '}
								<span className="green-text green-text--fw-600">
									{fromValue} {itemIn?.symbol}
								</span>{' '}
								or the transaction will revert.
							</p>
							<div className="operation-info">
								<p className="operation-info__text operation-info__text--bold">Price</p>
								<p className="operation-info__number operation-info__number--flex">
									{displayedRate || 0}{' '}
									<span>
										{itemIn?.symbol} per {itemOut?.symbol}
									</span>
								</p>
							</div>
							<div className="operation-info">
								<div className="operation-wrapper">
									<p className="operation-info__text operation-info__text--bold">Minimum sold</p>
									<div className="small-buttons">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clipPath="url(#clip0_8390_157320)">
														<path
															d="M7.9987 14.6666C11.6806 14.6666 14.6654 11.6818 14.6654 7.99992C14.6654 4.31802 11.6806 1.33325 7.9987 1.33325C4.3168 1.33325 1.33203 4.31802 1.33203 7.99992C1.33203 11.6818 4.3168 14.6666 7.9987 14.6666Z"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M6.05859 5.99989C6.21533 5.55434 6.5247 5.17863 6.9319 4.93931C7.3391 4.7 7.81786 4.61252 8.28338 4.69237C8.7489 4.77222 9.17114 5.01424 9.47531 5.37558C9.77949 5.73691 9.94596 6.19424 9.94526 6.66656C9.94526 7.99989 7.94526 8.66656 7.94526 8.66656"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8 11.3333H8.00667"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</g>
													<defs>
														<clipPath id="clip0_8390_157320">
															<rect width="16" height="16" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</span>
											<span className="tooltip tooltip--top">
												<span className="tooltip__text">
													<span className="tooltip__text-title">
														Your transaction will revert if there is a large, unfavorable price
														movement before it is confirmed.
													</span>
												</span>
											</span>
										</span>
									</div>
								</div>

								<p className="operation-info__number">
									{fromValue} <span> {itemIn?.symbol}</span>
								</p>
							</div>
							<div className="operation-info">
								<div className="operation-wrapper">
									<p className="operation-info__text operation-info__text--bold">Price Impact</p>
									<div className="small-buttons">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clipPath="url(#clip0_8390_157320)">
														<path
															d="M7.9987 14.6666C11.6806 14.6666 14.6654 11.6818 14.6654 7.99992C14.6654 4.31802 11.6806 1.33325 7.9987 1.33325C4.3168 1.33325 1.33203 4.31802 1.33203 7.99992C1.33203 11.6818 4.3168 14.6666 7.9987 14.6666Z"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M6.05859 5.99989C6.21533 5.55434 6.5247 5.17863 6.9319 4.93931C7.3391 4.7 7.81786 4.61252 8.28338 4.69237C8.7489 4.77222 9.17114 5.01424 9.47531 5.37558C9.77949 5.73691 9.94596 6.19424 9.94526 6.66656C9.94526 7.99989 7.94526 8.66656 7.94526 8.66656"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8 11.3333H8.00667"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</g>
													<defs>
														<clipPath id="clip0_8390_157320">
															<rect width="16" height="16" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</span>
											<span className="tooltip tooltip--top">
												<span className="tooltip__text">
													<span className="tooltip__text-title">
														The difference between the market price and estimated price due to trade
														size.
													</span>
												</span>
											</span>
										</span>
									</div>
								</div>

								<p className="operation-info__number operation-info__number--blue">{slipping}%</p>
							</div>
							{/* <div className="operation-info">
								<div className="operation-wrapper">
									<p className="operation-info__text operation-info__text--bold">
										Liquidity Provider Fee
									</p>
									<div className="small-buttons">
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clipPath="url(#clip0_8390_157320)">
														<path
															d="M7.9987 14.6666C11.6806 14.6666 14.6654 11.6818 14.6654 7.99992C14.6654 4.31802 11.6806 1.33325 7.9987 1.33325C4.3168 1.33325 1.33203 4.31802 1.33203 7.99992C1.33203 11.6818 4.3168 14.6666 7.9987 14.6666Z"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M6.05859 5.99989C6.21533 5.55434 6.5247 5.17863 6.9319 4.93931C7.3391 4.7 7.81786 4.61252 8.28338 4.69237C8.7489 4.77222 9.17114 5.01424 9.47531 5.37558C9.77949 5.73691 9.94596 6.19424 9.94526 6.66656C9.94526 7.99989 7.94526 8.66656 7.94526 8.66656"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8 11.3333H8.00667"
															stroke="#9996BE"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</g>
													<defs>
														<clipPath id="clip0_8390_157320">
															<rect width="16" height="16" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</span>
											<span className="tooltip tooltip--top">
												<span className="tooltip__text">
													<span className="tooltip__text-title">Please, note:</span> When a limit
													order expires, no cancellation fee is charged. In case of manually
													cancelling a limit order, a gas fee is charged at the time of signing the
													transaction.
												</span>
											</span>
										</span>
									</div>
								</div>

								<p className="operation-info__number">
									0.000010215 <span>ETH</span>
								</p>
							</div> */}
						</div>
						<div className="popup-footer">
							<button onClick={aproveSwap} type="button" className="button button--full-width">
								Confirm Exchange
							</button>
						</div>
					</div>
				)}
			</>
		</ModalWarapper>
	);
};
