import { FC } from 'react';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import howItWorksImg from 'assets/images/how-it-work.png';

export const HowItWorks: FC = () => {
	return (
		<section className="how-work-sections">
			<div className="container">
				<div className="how-work-wrapper">
					<div className="how-work-info">
						<h4 className="how-work-info__title">How It Works</h4>

						<p className="how-work-info__text">
							Buy crypto with card or transfer assets from an external wallet. We support USD, EUR
							and GBP bank transfers.
						</p>

						<div className="how-work-img how-work-img--mobile">
							<img src={howItWorksImg} alt="" />
						</div>

						<button type="button" className="button button--full-width button--type5">
							Details
						</button>
					</div>

					<div className="how-work-img">
						<img src={howItWorksImg} alt="" />
					</div>
				</div>
			</div>
			<NotificationInfo />
		</section>
	);
};
