export interface ITopTokensTableProps {
	searchValue: string;
}

export interface ITopPoolsTableProps {
	searchValue: string;
	id?: string;
	title?: string;
}

export enum EType {
	swaps = 'swap',
	adds = 'add_liquidity',
	removes = 'delete_liquidity',
	// deposits = 'deposit',
}
