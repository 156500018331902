import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as GatoChain2Icon } from 'assets/images/gatochain2.svg';
import EarningNav from 'components/Earnings/EarningNav';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import { TWalletData } from 'redux/reducers/wallets/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { getStakingRequest } from 'redux/reducers/earnings/reducer';
import { getpaidRewardForAll, getStake, getSumByAssets } from 'redux/reducers/earnings/selectors';
import Loader from 'ui/Loader';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
import StakeBlock from './StakeBlock';

export const Staking: FC = () => {
	const dispatch = useDispatch();
	const stake = useSelector(getStake);
	const balance = useSelector(getSumByAssets);
	const walletLoader = useSelector(getWalletLoader);
	const comProfit = useSelector(getpaidRewardForAll);
	const address = useSelector(getAddress);
	const walletsUser = useSelector(getWalletsUser);
	const moneyFormat = useMoneyFormat();

	const gatoCoin = walletsUser?.balances?.data?.find(
		(item: TWalletData) => item?.asset?.symbol === 'GATO' && item?.asset?.type === 'coin',
	);

	useEffect(() => {
		if (address.wallet) {
			dispatch(getStakingRequest({ wallet_address: address.wallet.toLowerCase() }));
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
					current_page: '1',
					per_page: 1000,
				}),
			);
		}
	}, [dispatch, address.wallet]);

	return (
		<>
			<section className="statistics-section statistics-section--no-br statistics-section--staking switch-btns--staking ">
				<div className="container">
					<EarningNav />
					<div className="staking-info">
						<p className="staking-info__text">
							Gato Staking, dedicated to increasing user staking income
						</p>
						<div className="staking-info__wrapper">
							<div className="staking-info__item">
								<p className="staking-info__name">Locked Staking balance</p>
								<p className="staking-info__number">
									{balance.length ? moneyFormat(balance[0].balance, 2, 6) : '0.000000'} GATO
								</p>
							</div>
							<div className="staking-info__item">
								<p className="staking-info__name">Cumulative profit</p>
								<p className="staking-info__number staking-info__number--blue">
									{comProfit.length ? moneyFormat(comProfit[0].reward, 2, 6) : '0.000000'} GATO
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="loked-staking">
				<div className="container">
					<div className="earning-title-wrapper">
						<h3 className="earning-title earning-title--m0">Locked Staking</h3>
						{address.wallet && (
							<Link to="/stake-it" className="button button--regular-width">
								Staking
							</Link>
						)}
					</div>
					<div className="earning-content table-position">
						<div className="earning-block">
							{walletLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)}
							{address.wallet ? (
								<>
									<div className="token-box-wrapper">
										<div className="token-box">
											<div className="token-box__icon-4">
												<GatoChain2Icon />
											</div>
											<div className="token-box__info">
												<div className="token-box__name">GATO</div>
											</div>
										</div>

										<div className="token-box__info">
											<p className="token-box__text token-box__text--right">Total gato Balance</p>
											<div className="token-box__name token-box__name--fw700 token-box__text--right">
												{moneyFormat(gatoCoin?.balance, 2, 6)} GATO
											</div>
											<p className="token-box__text token-box__text--right">
												≈ ${moneyFormat(gatoCoin && gatoCoin.price_in_usd, 2, 2)}
											</p>
										</div>
									</div>
									<h4 className="earning-block-title">My total earnings:</h4>
									<div className="earning-stats earning-stats--divident-balance">
										<div className="earning-stats__item">
											<div className="earning-stats__name earning-stats__name--mob-column">
												<p>Locked for staking</p>
											</div>
											<div className="earning-stats__number earning-stats__number--flex-column earning-stats__number--type2">
												{balance.length ? moneyFormat(balance[0].balance, 2, 6) : '0.000000'} GATO
												<span className="earning-stats__procent">
													≈ $
													{balance.length ? moneyFormat(balance[0].sumBalanceInUsd, 2, 2) : '0.00'}
												</span>
											</div>
										</div>
										<div className="earning-stats__item">
											<div className="earning-stats__name">
												<p>Reward</p>
											</div>
											<div className="earning-stats__number  earning-stats__number--type2">
												{balance.length ? moneyFormat(balance[0].reward, 2, 6) : '0.00'}
											</div>
										</div>
									</div>
								</>
							) : (
								<EmptyTable text="Please connect wallet" />
							)}
						</div>
						<div className="earning-block-wrapper">
							{stake?.data.map((el) => (
								<StakeBlock key={el.id} listItem={el} tokenId={Number(gatoCoin?.asset.id)} />
							))}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
