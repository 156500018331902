/* eslint-disable import/no-unresolved */
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { notificationContainer } from 'services/utils/notificationContainer';
/* eslint-disable */

const REACT_APP_RPC_URL = process.env.REACT_APP_RPC_URL;
const REACT_APP_EXPLORER = process.env.REACT_APP_EXPLORER;
const REACT_APP_NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;

const useProvider = () => {
	// 1. Get projectId
	const projectId = 'a5df8e672197214fd08f40b35a65ed55';

	// 2. Set chains
	const gato = {
		chainId: 4287,
		name: String(REACT_APP_NETWORK_NAME),
		currency: 'GATO',
		explorerUrl: String(REACT_APP_EXPLORER),
		rpcUrl: String(REACT_APP_RPC_URL),
	};
	const mainnet = {
		chainId: 1,
		name: 'Ethereum',
		currency: 'ETH',
		explorerUrl: 'https://etherscan.io',
		rpcUrl: 'https://cloudflare-eth.com',
	};
	const bsc = {
		chainId: 97,
		name: 'https://testnet.bscscan.com',
		currency: 'tBNB',
		explorerUrl: 'https://testnet.bscscan.com',
		rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
	};

	// 3. Create a metadata object
	const metadata = {
		name: 'Gatoscan',
		description: 'My Website description',
		url: 'https://gatoscan.com', // origin must match your domain & subdomain
		icons: ['https://avatars.mywebsite.com/'],
	};

	// 4. Create Ethers config
	const ethersConfig = defaultConfig({
		/*Required*/
		metadata,

		/*Optional*/
		enableEIP6963: true, // true by default
		enableInjected: true, // true by default
		enableCoinbase: true,
		// defaultChainId: 4287,
	});

	// 5. Create a Web3Modal instance
	createWeb3Modal({
		ethersConfig,
		chains: [gato, bsc],
		// defaultChain: gato,
		projectId,
		enableAnalytics: true, // Optional - defaults to your Cloud configuration\
	});
	// useEffect(()=>{
	// 	const initialWeb3Modal = async ()=>{
	// 		const web3Modal = new Web3Modal({
	// 			ne
	// 		})
	// 	}
	// },[])
};

export default useProvider;
