/* eslint-disable */
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import AbiFee from 'config/abi/FeeController.json';
import { feeChecker } from 'services/utils/feeChecker';
import { ITypeTransaction } from 'components/StakeIt/types';
import { constants } from 'ethers';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { getStakingRequest, sendStakeTransactionRequest } from 'redux/reducers/earnings/reducer';
import { IStakingDataItem } from 'redux/reducers/earnings/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import { toMaxDecimals } from 'services/utils/numbers';
import { claimApprove } from 'services/utils/staking';
import ConfirmStakePopUp from 'components/StakeIt/ConfirmStakePopUp';
import { useWeb3React } from '@web3-react/core';
import { api } from 'services';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import useRefreshToken from 'hooks/useRefreshToken';
import AbiStaking from 'config/abi/StakingPools.json';

interface IStakeBlockProps {
	listItem: IStakingDataItem;
	tokenId: number;
}

const STAKE_CONTRACT_ADDRESS = String(process.env.REACT_APP_STAKE_CONTRACT_ADDRESS);

const UNTAKE_SELECTOR = '0x317879f9';

declare let window: any;

/* eslint-disable */
const StakeBlock: FC<IStakeBlockProps> = ({ listItem, tokenId }) => {
	const { period, apy, balance, reward, balance_in_usd, id, asset_address } = listItem;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const moneyFormat = useMoneyFormat();
	const [loader, setLoader] = useState(false);
	const amountClaim = Number(balance) + Number(reward);
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const tokenRefresh = useRefreshToken();
	const { provider, account } = useWeb3React();
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const handleClaim = async () => {
		if (amountClaim <= 0) {
			notificationContainer(`Don't have reward`, 'info');
			return;
		}
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;
		tokenRefresh();
		try {
			let currentAddress = connector === 'INJECTED' ? account : address;
			let accounts;
			if (connector) {
				// accounts = await window?.ethereum.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			const fetchUpdate = await api.earnings.updateReward({
				staking_id: id,
			});
			if (fetchUpdate.status !== 'success') return;

			await dispatch(getStakingRequest({ wallet_address: String(currentAddress).toLowerCase() }));

			const paramsFee: any = {
				wallet: String(currentAddress),
				selector: UNTAKE_SELECTOR,
				abi: AbiFee.abi,
				contractAddess: String(STAKE_CONTRACT_ADDRESS), // Address contract
				provider: currentProvider,
				tokenAddress: String(constants.AddressZero),
				setLoader,
				connector,
			};
			debugger;
			const amountFee = await feeChecker(paramsFee);
			debugger;

			if (!amountFee) {
				return;
			}

			const sendParams: ITypeTransaction = {
				wallet: String(currentAddress),
				connector,
				abi: AbiStaking.abi,
				amount: amountClaim,
				contractAddress: STAKE_CONTRACT_ADDRESS,
				provider: currentProvider,
				lockPeriod: String(period),
				amountFee,
				setLoader,
			};

			const result = await claimApprove(sendParams);

			if (result) {
				dispatch(
					sendStakeTransactionRequest({
						request: {
							tx: String(result?.transactionHash),
							period: String(period),
							type: 'claim_staking',
							base_amount: amountClaim,
							base_id: tokenId,
						},
						wallet: String(currentAddress)?.toLowerCase(),
					}),
				);
			}
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
		}
	};

	return (
		<div className="earning-block earning-block--full-width earning-block--mt0">
			<div className="earning-stats earning-stats--divident-balance">
				<div className="earning-stats__item">
					<div className="earning-stats__name">
						<p>{period} days</p>
						<span className="earning-stats__increment">{toMaxDecimals(apy, 6)} %</span>
					</div>
					<div className="earning-stats__number earning-stats__number--flex-column earning-stats__number--type2">
						{moneyFormat(balance, 2, 9)} GATO
						<span className="earning-stats__procent">≈ ${moneyFormat(balance_in_usd, 2, 2)}</span>
					</div>
				</div>
				<div className="earning-stats__item">
					<div className="earning-stats__name">
						<p>Reward</p>
					</div>
					<div className="earning-stats__number  earning-stats__number--type2">
						{moneyFormat(reward, 2, 9)}
					</div>
				</div>
			</div>
			<div className="dividents-btns dividents-btns--right">
				<button onClick={handleClaim} className="button button--full-width" type="button">
					Claim
				</button>

				<Link to="/stake-it" className="button button--type3 button--full-width">
					Stake it
				</Link>
			</div>
			<ConfirmStakePopUp
				openModal={loader}
				setOpenModal={setLoader}
				title="Claim"
				text={`${moneyFormat(amountClaim, 2, 9)} GATO`}
			/>
		</div>
	);
};

export default StakeBlock;
