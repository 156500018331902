import { FC, useRef, useState } from 'react';
import ModalCalendar from 'ui/Popups/ModalCalendar';
import { format } from 'date-fns';
import { SvgCalendar } from 'ui/SvgIcon/PopUpIcons';
import useOutsideClick from 'hooks/useOutsideClick';
import { getLastDayOfMonth } from './utils';
import { IDividendsFilter } from './types';

const DividendsFilter: FC<IDividendsFilter> = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
}) => {
	const currentDate = new Date();
	const [monthDate, setMonthDate] = useState<Date>(new Date());
	const [openCalendar, setOpenCalendar] = useState(false);
	const calendarRef = useRef<HTMLDivElement | null>(null);
	useOutsideClick(calendarRef, () => setOpenCalendar(false));

	const handleToggleCalendar = () => setOpenCalendar((prev) => !prev);

	const handleStartDate = (_startDate: Date) => {
		setStartDate(_startDate);
		const newMonthDate = new Date(monthDate);
		newMonthDate.setMonth(_startDate.getMonth());
		newMonthDate.setFullYear(_startDate.getFullYear());
		setMonthDate(newMonthDate);
	};

	const updateDates = (newMonthDate: Date) => {
		const month = newMonthDate.getMonth();
		const newStartDate = new Date(newMonthDate.getFullYear(), month, 1);
		const newEndDate = new Date(monthDate.getFullYear(), month, getLastDayOfMonth(newMonthDate));
		setMonthDate(newMonthDate);
		setStartDate(newStartDate);
		setEndDate(new Date(newEndDate) < new Date() ? newEndDate : new Date());
	};

	const handlePrevMonth = () => {
		const newMonthDate = new Date(monthDate);
		newMonthDate.setMonth(monthDate.getMonth() - 1);
		updateDates(newMonthDate);
	};

	const handleNextMonth = () => {
		const newMonthDate = new Date(monthDate);
		newMonthDate.setMonth(monthDate.getMonth() + 1);
		updateDates(newMonthDate);
	};

	const nextBtnDisabled =
		monthDate.getMonth() === currentDate.getMonth() &&
		monthDate.getFullYear() === currentDate.getFullYear();

	return (
		<div className="date-filters">
			<div ref={calendarRef} className="calendar-fields">
				<div className="calendar-fields__item">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon "
								onClick={handleToggleCalendar}
								type="text"
								placeholder="From"
								readOnly
								value={startDate ? format(startDate, 'yyyy.MM.dd') : ''}
							/>
							<button
								type="button"
								onClick={handleToggleCalendar}
								className="input-icon input-icon--calendar input-icon--right"
								aria-label="Calendar"
							>
								<SvgCalendar />
							</button>
						</div>
					</div>
				</div>
				<div className="calendar-fields__item">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--right-icon "
								type="text"
								onClick={handleToggleCalendar}
								placeholder="To"
								readOnly
								value={endDate ? format(endDate, 'yyyy.MM.dd') : ''}
							/>
							<button
								type="button"
								onClick={handleToggleCalendar}
								aria-label="Calendar"
								className="input-icon input-icon--calendar input-icon--right"
							>
								<SvgCalendar />
							</button>
						</div>
					</div>
				</div>
				<div className="calendar-container">
					{openCalendar && (
						<ModalCalendar
							setStart={handleStartDate}
							setEnd={setEndDate}
							setOpenModal={setOpenCalendar}
							start={startDate}
							end={endDate}
						/>
					)}
				</div>
			</div>
			<div className="block-pagination block-pagination--month">
				<button
					type="button"
					className="block-pagination__btn active"
					onClick={handlePrevMonth}
					aria-label="Pagination"
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.57031 18.0703L3.50031 12.0003L9.57031 5.93031"
							stroke="#335BE9"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M20.5 12L3.67 12"
							stroke="#335BE9"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
				<p className="block-pagination__text">
					{monthDate.toLocaleDateString('en', { month: 'long' })}, {monthDate.getFullYear()}
				</p>
				<button
					type="button"
					className={`block-pagination__btn ${nextBtnDisabled ? '' : 'active'}`}
					onClick={handleNextMonth}
					aria-label="Pagination"
					disabled={nextBtnDisabled}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.4297 5.92969L20.4997 11.9997L14.4297 18.0697"
							stroke="#335BE9"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M3.5 12H20.33"
							stroke="#335BE9"
							strokeWidth="1.5"
							strokeMiterlimit="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default DividendsFilter;
