import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ItemProps } from './index';
import { noExponent } from '../../../../../services/utils/noExponent';

const Item: FC<ItemProps> = ({ item, symbol }) => {
	return (
		<>
			<div className="block-item block-item--transaction block-item--token-details">
				<div className="transactions-info">
					<div className="transactions-info__top">
						<p className="transactions-info__wallet transactions-info__wallet--tokens">
							<Link to={`/address/${item.address_hash}`}>{item.address_hash}</Link>
						</p>
					</div>
					<div className="transactions-info__bottom">
						<div className="transactions-swap transactions-swap--type2 transactions-swap--mt0">
							<div className="transactions-swap__count">
								<p className="transactions-swap__count-text">
									{noExponent(item.value)} {symbol}{' '}
								</p>
								<span className="transactions-swap__count-token transactions-swap__count-token--grey">
									{item.percent ? item.percent : 0} %
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
