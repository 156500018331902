import { AutoSwap } from 'components/AutoSwap';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const AutoSwapPage: FC = () => {
	return (
		<Page title="Auto Swap" text="Auto Swap" link="/">
			<AutoSwap />
		</Page>
	);
};
