import { RemoveLiquidity } from 'components/Exchange/RemoveLiqudity';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const RemoveLiquidityPage: FC = () => {
	return (
		<Page title="Pool" text="Pool" link="/">
			<RemoveLiquidity />
		</Page>
	);
};
