import { FC, useState, MouseEvent, useEffect } from 'react';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import arbitrum from 'assets/images/tokens/arbitrum.png';
import { TokensTable } from './TokensTable';
import { CreateToken } from './CreateToken/CreateToken';

export const TokenManager: FC = () => {
	const [openModal, setOpenModal] = useState(false);
	const location = useLocation();
	const [route, setRote] = useState('list');
	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};
	useEffect(() => {
		if (location && location.pathname === '/token-manager') {
			setRote('list');
		} else {
			setRote('create');
		}
	}, [location]);

	const block: any = {
		create: <CreateToken />,
		list: <TokensTable />,
	};
	return (
		<section className="general-section general-section--pool">
			<div className="container">
				<div className="inner-block">
					<div className="swap swap--center swap--mb">
						<NavLink to="/token-manager" className="swap__item">
							Token List
						</NavLink>
						<NavLink to="/create-token" className="swap__item">
							Create Token
						</NavLink>
					</div>
				</div>
				{block[route]}
			</div>
		</section>
	);
};
