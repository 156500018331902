import { FC } from 'react';
import toastifyError from 'assets/images/icons/toastify_error.svg';
import toastifySuccess from 'assets/images/icons/toastify_success.svg';
import toastifyInfo from 'assets/images/icons/toastify_info.svg';
import { IToastifyComponent } from './types';
/* eslint-disable no-debugger */
// ==========================================:
const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
	return (
		<div className="notification notification--info notification--change-block">
			{toastProps?.type === 'error' && (
				// <div className="toastify-component__icon toastify-component__icon--error">
				// 	<img src={toastifyError} width="30" height="30" alt="" />
				// </div>
				<div className="notification__icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.9994 21.4098H5.93944C2.46944 21.4098 1.01944 18.9298 2.69944 15.8998L5.81944 10.2798L8.75944 4.99979C10.5394 1.78979 13.4594 1.78979 15.2394 4.99979L18.1794 10.2898L21.2994 15.9098C22.9794 18.9398 21.5194 21.4198 18.0594 21.4198H11.9994V21.4098Z"
							fill="#EB5757"
						/>
						<path
							d="M12 9V14"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M11.9961 17H12.0051"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			)}
			{toastProps?.type === 'success' && (
				// <div className="toastify-component__icon toastify-component__icon--success">
				// 	<img src={toastifySuccess} width="30" height="30" alt="" />
				// </div>
				<div className="notification__icon">
					<svg
						width="16"
						height="17"
						viewBox="0 0 16 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8 5.83301V9.16634"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7.99609 11.167H8.00208"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			)}
			{toastProps?.type === 'info' && (
				<div className="notification__icon">
					<svg
						width="16"
						height="17"
						viewBox="0 0 16 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.99967 15.1663C11.6663 15.1663 14.6663 12.1663 14.6663 8.49967C14.6663 4.83301 11.6663 1.83301 7.99967 1.83301C4.33301 1.83301 1.33301 4.83301 1.33301 8.49967C1.33301 12.1663 4.33301 15.1663 7.99967 15.1663Z"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8 5.83301V9.16634"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7.99609 11.167H8.00208"
							stroke="#335BE9"
							strokeWidth="1.2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				// <div className="toastify-component__icon toastify-component__icon--info">
				// 	<img src={toastifyInfo} width="30" height="30" alt="" />
				// </div>
			)}
			<div className="notification__info">
				<div
					className={`notification__title ${
						toastProps?.type === 'error' ? 'notification__title--blue' : ''
					}`}
				>
					<p>
						{toastProps?.type === 'error' && `Error`}
						{toastProps?.type === 'success' && `Successful`}
						{toastProps?.type === 'info' && `Information`}
					</p>
				</div>
				<div className="notification__text">
					<p>{message}</p>
				</div>
			</div>
			{/* <div className="toastify-component__content">
				<h3 className="toastify-component__title">
					{toastProps?.type === 'error' && `Error`}
					{toastProps?.type === 'success' && `Successful`}
					{toastProps?.type === 'info' && `Information`}
				</h3>
				<p className="toastify-component__text">{message}</p>
			</div> */}
		</div>
	);
};

export default ToastifyComponent;
