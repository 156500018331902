import { FC, useState } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { QuestionIcon } from 'assets/custom-icons';
import { useDispatch } from 'react-redux';
import { setSlipping } from 'redux/reducers/wallets/reducer';
import { useNavigate } from 'react-router';
import { TCommingModal } from './type';

export const CommingSoonModal: FC<TCommingModal> = ({ openModal, setOpenModal }) => {
	const navigate = useNavigate();

	const closeModal = () => {
		setOpenModal(false);
		navigate(-1);
	};
	return (
		<ModalWarapper
			openModal={openModal}
			setOpenModal={setOpenModal}
			insideClass="popup-window__inside--pt-medium"
		>
			<div className="popup popup--padding-type2 popup--smaller">
				<div className="popup-header">
					<p className="popup-header__title">Information</p>
					<button
						onClick={closeModal}
						type="button"
						className="popup-close"
						aria-label="Popup Close"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7 7L17 17"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7 17L17 7"
								stroke="#1A203F"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</div>
				<div>
					<p className="popup-text--center">This page is coming soon...</p>
				</div>
				{/* <div className="popup-footer">
					<button
						onClick={closeModal}
						type="button"
						className="button button--type3 button--full-width"
					>
						Cancel
					</button>
					<button onClick={closeModal} type="button" className="button button--full-width">
						Save
					</button>
				</div> */}
			</div>
		</ModalWarapper>
	);
};
