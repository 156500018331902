import React, { FC } from 'react';
import { formatDistance } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ItemProps } from './index';
import { noExponent } from '../../../../../services/utils/noExponent';

const Item: FC<ItemProps> = ({ item, symbol }) => {
	const navigate = useNavigate();
	return (
		<>
			<div className="block-item block-item--transaction">
				<div className="block-item__left">
					<div className="block-number block-number--transfer ">
						<p className="block-number__hex">Token Transfer</p>
						<p className="block-number__type">Success</p>
					</div>
					<div className="transactions-info">
						<div className="transactions-info__top">
							<div
								className="transactions-info__wallet"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									navigate(`/transactions/${item.transaction_hash}`);
								}}
							>
								<p className="transactions-info__wallet">{item.transaction_hash}</p>
							</div>
						</div>
						<div className="transactions-info__center">
							<div className="transactions-info__transfer">
								<p className="transactions-info__transfer-name">Sender</p>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										navigate(`/address/${item.from_address_hash}`);
									}}
								>
									<p className="transactions-info__wallet">{item.from_address_hash}</p>
								</div>
							</div>
							<div className="transactions-info__arrow">
								<svg
									width="16"
									height="17"
									viewBox="0 0 16 17"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.62109 4.45312L13.6678 8.49979L9.62109 12.5465"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M2.33203 8.5H13.552"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<div className="transactions-info__transfer">
								<p className="transactions-info__transfer-name">Receiver</p>
								<div
									className="transactions-info__wallet"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										navigate(`/address/${item.to_address_hash}`);
									}}
								>
									<p className="transactions-info__wallet">{item.to_address_hash}</p>
								</div>
							</div>
						</div>
						<div className="transactions-info__bottom">
							<div className="transactions-swap transactions-swap--type2">
								<div className="transactions-swap__count">
									<p style={{ wordBreak: 'break-all' }} className="transactions-swap__count-text">
										{noExponent(item.amount)}
									</p>
									<span className="transactions-swap__count-token">
										<a href="!#">{symbol?.toUpperCase()}</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="block-item__right">
					<div className="block-period block-period--flex-end">
						<div className="block-period__number">
							<div
								style={{ cursor: 'pointer' }}
								onClick={() => {
									navigate(`/blocks/${item.block_hash}`);
								}}
							>
								<p> Block #{item.block_number}</p>
							</div>
						</div>
						<div className="block-period__time">
							{' '}
							{formatDistance(Date.parse(String(item.created_at)), new Date(), { addSuffix: true })}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
