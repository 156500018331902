import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IModalProps } from './types';

const modalNode = document.querySelector('#popup-root');

export const Modal: FC<IModalProps> = ({
	isOpen,
	onClose, // the best thing -> useCallback in top component
	disableCloseOutside,
	disableCloseEsc,
	children,
}) => {
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.code === 'Escape') onClose();
		};

		if (isOpen && !disableCloseEsc) {
			window.addEventListener('keydown', handleKeyDown);
		}
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [isOpen, onClose, disableCloseEsc]);

	const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget && !disableCloseOutside) {
			onClose();
		}
	};

	return isOpen && modalNode
		? createPortal(
				<div className="popup-window">
					<div className="popup-window__inside" onClick={handleOverlayClick}>
						{children}
					</div>
				</div>,
				modalNode,
		  )
		: null;
};
