/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	IApiDataResponseToken,
	IApiTokensResponse,
	IPayloadOptions,
	IPayloadToken,
	IPayloadTokenHolders,
	IPayloadTokenTransfers,
	IPayloadTokenMint,
} from './types';
import {
	tokensInitState,
	getTokensRequest,
	getTokensSuccess,
	getTokenSuccess,
	getTokenRequest,
	getTransfersRequest,
	getHoldersRequest,
	getHoldersSuccess,
	getTransfersSuccess,
	getMintRequest,
} from './reducer';

function* getTokensWorker(action: PayloadAction<IPayloadOptions>) {
	const { payload } = action;
	try {
		const response: IApiTokensResponse = yield call(api.tokens.getTokens, payload);
		yield put(getTokensSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		yield put(tokensInitState());
	} finally {
		// yield put(hideLoading());
	}
}

function* getTokenWorker(action: PayloadAction<IPayloadToken>) {
	const { payload } = action;
	try {
		const response: IApiDataResponseToken = yield call(api.tokens.getToken, payload);
		yield put(getTokenSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		// yield put(tokensInitState());
	} finally {
		// yield put(hideLoading());
	}
}
function* getTransfersWorker(action: PayloadAction<IPayloadTokenTransfers>) {
	const { payload } = action;
	try {
		const response: IApiDataResponseToken = yield call(api.tokens.getTokenTransfers, payload);
		yield put(getTransfersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		// yield put(tokensInitState());
	} finally {
		// yield put(hideLoading());
	}
}
function* getHoldersWorker(action: PayloadAction<IPayloadTokenHolders>) {
	const { payload } = action;
	try {
		const response: IApiDataResponseToken = yield call(api.tokens.getTokenHolders, payload);
		yield put(getHoldersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		// yield put(tokensInitState());
	} finally {
		// yield put(hideLoading());
	}
}

function* getMintWorker(action: PayloadAction<IPayloadTokenMint>) {
	const { payload } = action;
	try {
		yield call(api.tokens.postMintGato);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
		// yield put(tokensInitState());
	} finally {
		// yield put(hideLoading());
	}
}

export function* tokensSaga() {
	yield takeEvery(getTokensRequest.type, getTokensWorker);
	yield takeEvery(getTokenRequest.type, getTokenWorker);
	yield takeEvery(getTransfersRequest.type, getTransfersWorker);
	yield takeEvery(getHoldersRequest.type, getHoldersWorker);
	yield takeEvery(getMintRequest.type, getMintWorker);
}
