import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { MainNav } from 'layouts-elements/MainNav';
import { AuthHeader } from 'layouts-elements/Header/AuthHeader';
import AppLogo from 'ui/AppLogo';
import { StaticHeader } from './StaticHeader';
/* eslint-disable */

// ==========================================:
export const Header: FC = () => {
	const isAuth = useSelector(getAuthIsAuthenticated);

	const [open, setOpen] = useState<boolean>(false);

	const handleMenu = () => {
		setOpen((prev) => {
			return !prev;
		});
	};

	return (
		<header className={`header ${isAuth ? 'header--autorized' : ''}`}>
			<div className="header__container  ">
				<div className="header-left">
					<button type="button" onClick={handleMenu} className="nav-btn">
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3 18.5H21"
								stroke="#8192AA"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 12.5H21"
								stroke="#8192AA"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M3 6.5H21"
								stroke="#8192AA"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
					<AppLogo />
				</div>
				<MainNav open={open} />
				{isAuth ? <AuthHeader /> : <StaticHeader />}
			</div>
		</header>
	);
};
