import { FC, useState, MouseEvent } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLocation } from 'react-router';
import yup from 'services/utils/capsLockValidate';
import queryString from 'query-string';
import Input from 'ui/Formik/Input';
import { IRegistrValues, IRegistrForm } from './types';

export const RegistrForm: FC<IRegistrForm> = ({ registrSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const [currentTab, setCurrentTab] = useState('email');
	const location = useLocation();
	const { search } = location;

	const referral = queryString.parse(search).ref || null;
	const diasableRef = !referral;

	const initialValues = {
		[`${currentTab}`]: '',
		password: '',
		type: currentTab,
		captcha: '00000000',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.capsLockValidate(isCapsLockOn, 'CapsLock is on')
			.required('required email')
			.email('Invalid email format')
			.max(60, 'email max characters'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length 25'),
	});
	const validationSchema2 = yup.object().shape({
		phone: yup.string().required('required phone').max(15, 'Password max length'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleCurrentTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={currentTab === 'email' ? validationSchema : validationSchema2}
			onSubmit={(values: IRegistrValues, { resetForm, setSubmitting }) => {
				registrSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			enableReinitialize
			validateOnBlur
		>
			{({ isSubmitting, isValid, dirty, handleChange }) => {
				return (
					<Form>
						<div className="authorization-form__header">
							<p className="form-title form-title--center">Welcome</p>
							<div className="form-text form-text--center">
								<p>Sign up to GATOCHAIN.</p>
							</div>
						</div>
						<div className="authorization-form__body">
							<div className="authorization-type">
								<button
									type="button"
									className={`authorization-type__item ${currentTab === 'email' ? 'active' : ''}`}
									name="email"
									onClick={handleCurrentTab}
								>
									Email
								</button>
								<button
									type="button"
									className={`authorization-type__item ${currentTab === 'phone' ? 'active' : ''}`}
									name="phone"
									onClick={handleCurrentTab}
								>
									Phone Number
								</button>
							</div>

							<div className="authorization-form__group">
								{currentTab === 'email' ? (
									<Field
										type="email"
										placeholder="Email"
										name="email"
										title="Email"
										required
										component={Input}
									/>
								) : (
									<Field
										type="phone"
										placeholder="Phone"
										name="phone"
										title="Phone"
										required
										onChange={handleChange}
										component={Input}
										values
									/>
								)}
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="Password"
									name="password"
									required
									title="Password"
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
								/>
							</div>
						</div>
						<div className="form-footer">
							<button
								disabled={diasableRef || !(isValid && dirty) || isSubmitting}
								// disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--big-height button--full-width"
							>
								Create Account
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
