/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConnectStore, TBalancesPayload, TWallets } from './types';

// ==========================================:
export const initialState: IConnectStore = {
	wallet: {
		auth: null,
		wallet: '' || null || undefined,
		loading: false,
		error: null || undefined,
		active: false,
		type: null,
		account: '' || null || undefined,
	},
	balances: [],
	type: '',
};

// ==========================================:
const connectWallet = createSlice({
	name: '@@connectWallet',
	initialState,
	reducers: {
		setAddress: (state, action: PayloadAction<TWallets>) => {
			const connectState = state;
			// const { payload } = action;

			// connectState.wallet = {
			// 	...connectState.wallet,
			// 	wallet: payload.account,
			// 	loading: payload.loading,
			// 	active: payload.active,
			// 	auth: null,
			// 	error: {},
			// };
		},
		AddWalletAddressSuccess: (state, action: PayloadAction<TWallets & TBalancesPayload>) => {
			const connectState = state;
			const { payload } = action;

			connectState.wallet = {
				...connectState.wallet,
				wallet: payload.account,
				loading: false,
				active: payload.active,
				type: payload?.type,
				auth: null,
				error: {},
				typeConection: payload.typeConection,
			};
			connectState.balances = payload.balances;
		},
		setLogoutWallet: (state) => {
			const connectState = state;
			connectState.wallet = initialState.wallet;
			connectState.type = '';
		},
		setTypeWallet: (state, { payload }: PayloadAction<string>) => {
			const connectState = state;
			connectState.type = payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			const connectState = state;
			connectState.wallet.loading = payload;
		},
		connectInitState: () => initialState,
	},
});

export default connectWallet.reducer;
export const {
	setAddress,
	connectInitState,
	setLogoutWallet,
	AddWalletAddressSuccess,
	setTypeWallet,
	setLoading,
} = connectWallet.actions;
