import { ISwapTokenListUnsafe, ISwapTokenUnsafe } from 'redux/reducers/swap/types';
/* eslint-disable */
import { IApiSwapAsset } from 'services/api/swap/types';

import { IExchangeLocationStateWithObject } from '../Exchange/types';

import { TArrayPair } from './type';
import { INetworkPairData, TPairNetwork } from 'redux/reducers/bridge/types';

const wrapedGato = String(process.env.REACT_APP_WRAPED_GATO);

// 📌 Tokens

export const getInitTokenSymbols = (
	locationState: IExchangeLocationStateWithObject | null,
	asset: IApiSwapAsset[] | undefined,
) => {
	const GUSD_address = { address: '0x9399c6598e1d80ab5aad44aa5aaa2d7771351944', symbol: 'GUSD' };
	const GATO_address = { address: wrapedGato, symbol: 'GATO' };

	const defaultFromSymbol =
		locationState?.initToSymbol?.address !== 'GUSD' ? GUSD_address : GATO_address;
	const defaultToSymbol =
		locationState?.initFromSymbol?.address !== 'GATO' ? GATO_address : GUSD_address;

	const initFromSymbolToken = locationState?.initFromSymbol || defaultFromSymbol;
	const initToSymbolToken = locationState?.initToSymbol || defaultToSymbol;

	const findeInitBySymbolFrom = asset?.find(
		({ address }) => address === initFromSymbolToken?.address,
	);
	const findeInitBySymbolTo = asset?.find(({ address }) => address === initToSymbolToken?.address);

	return [findeInitBySymbolFrom, findeInitBySymbolTo];
};

export const getTokenBySymbol = (tokens: ISwapTokenListUnsafe, searchSymbol?: ISwapTokenUnsafe) => {
	if (!tokens || !searchSymbol) {
		return undefined;
	}
	return tokens.find(({ address }) => address === searchSymbol.address);
};

export const getTokenNetworkBySymbol = (
	tokens: Array<TPairNetwork>,
	searchSymbol?: TPairNetwork,
) => {
	if (!tokens || !searchSymbol) {
		return undefined;
	}
	return tokens.find(
		({ asset_from, asset }) =>
			asset_from === searchSymbol?.asset_from ||
			(asset?.symbol === 'GGGGG' && searchSymbol?.asset_from === 'GFIVE'),
	);

	// return tokens.find(({ symbol }) => symbol === searchSymbol.asset_from);
};

export const getInitialChainFrom = (chains: any, searchSymbol?: string, asset?: TPairNetwork) => {
	let currentChains;
	if (!chains) {
		return undefined;
	}

	if (!searchSymbol) {
		let randomChain: any = Object.values(chains)[0];
		return randomChain[0];
	}
	if (asset) {
		currentChains =
			asset.network_from === searchSymbol ? chains[asset.chain_id_to] : chains[asset.chain_id_from];
	}

	return currentChains?.find((chain: any) => chain?.network_name === searchSymbol);
};
export const getInitialChainTo = (chains: any, searchSymbol?: string, asset?: TPairNetwork) => {
	let currentChains;
	let currentName = '';
	if (!chains) {
		return undefined;
	}
	// if (!searchSymbol) {
	// 	return chains[0];
	// }
	if (asset) {
		currentChains =
			asset.network_from === searchSymbol ? chains[asset.chain_id_from] : chains[asset.chain_id_to];

		currentName = asset?.network_from === searchSymbol ? asset.network_to : asset.network_from;
	}

	return currentChains?.find((chain: any) => chain?.network_name === String(currentName));
};

export const getInitialByAsset = (
	assets: INetworkPairData,
	searchSymbol?: string,
	network?: string,
) => {
	let newAssets;
	if (!assets) {
		return undefined;
	}
	if (network) {
		newAssets = assets?.filter(
			({ network_from, network_to }) => network_from === network || network_to === network,
		);
	}
	if (!searchSymbol) {
		return newAssets ? newAssets[0] : assets[0];
	}

	let result =
		newAssets?.find(
			(asset: any) => asset?.asset_from === searchSymbol || asset?.asset_to === searchSymbol,
		) || assets[0];

	return assets?.find(({ asset_from }) => asset_from === searchSymbol) || result;
};

export const getChainById = (chains: INetworkPairData, searchSymbol?: string) => {
	if (!chains) {
		return undefined;
	}
	if (!searchSymbol) {
		return chains.find(({ network_from }) => network_from === 'GATO');
	}

	return chains.find(({ id }) => id === 24);
};

export const getTokensWithoutSymbol = (
	tokens: ISwapTokenListUnsafe,
	withoutSymbol?: ISwapTokenUnsafe,
	pair?: TArrayPair,
) => {
	if (!tokens || !withoutSymbol) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutSymbol.address);
};
export const getTokensWithoutAddress = (tokens: ISwapTokenListUnsafe, withoutAddress?: string) => {
	if (!tokens || !withoutAddress) {
		return tokens;
	}
	return tokens.filter(({ address }) => address !== withoutAddress);
};

// 📌 Calculate rate

export const maxNoExponent = 1e21;
// export const outputDecimals = 8;
export const LIQUIDITY = 18;
export const outputDecimals = 12;
export const minSignificant = 1 * 10 ** -outputDecimals;
export const testDecimalGato = 6;
export const testGatoDesimals = 8;

export const numberOutDecimal = (from: number, decimal: number) => {
	return Number(from) / 10 ** Number(decimal);
};
export const numberInDecimal = (from: number, decimal: number) => {
	return Number(from) * 10 ** Number(decimal);
};
