import { abiErc20Balance } from 'services/config/abi/erc20TokenBalance';
import { TPairParams } from 'redux/reducers/swap/types';
import { web3, web3bsc } from './wallets';

export interface IWeb3GetTokenBalanceParams {
	tokenAddress: string;
	walletAddress: string;
	tokenDecimals: number;
	type: string;
	rpc?: string | any;
}
/* eslint-disable */
export const getTokenBalance = async (params: IWeb3GetTokenBalanceParams): Promise<string> => {
	const { tokenAddress, walletAddress, tokenDecimals, type, rpc } = params;
	// eslint-disable-next-line
	// debugger;
	const web3New = rpc ? web3bsc : web3;
	let balance: string = '';
	if (type === 'coin') {
		balance = await web3New.eth.getBalance(walletAddress);
	} else {
		const contract = new web3New.eth.Contract(abiErc20Balance as any, tokenAddress);
		balance = await contract.methods.balanceOf(walletAddress).call();
	}

	return String(Number(web3.utils.toBN(balance)) / 10 ** tokenDecimals);
	// return web3.utils.fromWei(balance);
};
/* eslint-disable */
export const getPairBalance = async (params: TPairParams): Promise<string> => {
	const { pairId, walletAddress, tokenDecimals = 18 } = params;
	// eslint-disable-next-line
	const contract = new web3.eth.Contract(abiErc20Balance as any, pairId);
	const pairBalance: string = await contract.methods.balanceOf(walletAddress).call();
	return String(Number(web3.utils.toBN(pairBalance)) / 10 ** tokenDecimals);
	// return web3.utils.fromWei(balance);
	//return pairBalance;
};

export const getCoinBalance = async (address: string): Promise<string> => {
	// eslint-disable-next-line
	// debugger;
	let balance: string = '';
	// if (type === 'coin') {
	balance = await web3.eth.getBalance(address);
	// } else {
	// const contract = new web3.eth.Contract(abiErc20Balance as any, address);
	// balance = await contract.methods.balanceOf(address).encodeABI();
	//}

	return String(Number(web3.utils.toBN(balance)) / 10 ** 18);
	// return web3.utils.fromWei(balance);
};
