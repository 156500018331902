export const textSearchValid = (first: string, search: string): boolean => {
	let fullName = '';
	if (first !== undefined) {
		fullName = `${first}`;
	} else {
		return false;
	}

	const regex = new RegExp(`${search}`, 'i');
	if (regex.test(fullName)) {
		return true;
	}
	return false;
};
