import { ArrowDown } from 'assets/custom-icons';
import classNames from 'classnames';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getHistoryTranasactionRequest } from 'redux/reducers/wallets/reducer';
import { getUserTransaction } from 'redux/reducers/wallets/selectors';
import { TUserTransactionItem } from 'redux/reducers/wallets/types';
import { tryOnEmpty } from 'services/utils/custom';
import { capitalize } from 'services/utils/textSlice';
import PaginationTable from 'ui/PaginationTable';
import { EType } from '../types';
import TransactionRow from './TransactionRow';

const TransactionsTable: FC<{ searchValue: string }> = ({ searchValue }) => {
	const dispatch = useDispatch();
	const userTransaction = useSelector(getUserTransaction);
	const perPage = 18;
	const { tokenId } = useParams();

	const [filter, setFilter] = useState({
		type: '',
		coin: !searchValue ? tokenId : searchValue,
	});
	const [currentPage, setCurrentPage] = useState<number>(1);

	const transactionList: TUserTransactionItem[][] = [];
	const transactionArr = userTransaction?.data || [];
	const size = 3;
	if (userTransaction) {
		for (let i = 0; i < Math.ceil(transactionArr.length / size); i += 1) {
			transactionList[i] = transactionArr.slice(i * size, i * size + size);
		}
	}

	const handleFilterTab = (value: string) => {
		setFilter({ ...filter, type: value });
		setCurrentPage(1);
	};

	useEffect(() => {
		const payload = tryOnEmpty({ ...filter });
		dispatch(
			getHistoryTranasactionRequest({
				...payload,
				per_page: perPage,
				current_page: currentPage,
				page_pool_token: 1,
			}),
		);
	}, [currentPage, dispatch, filter]);

	return (
		<div className="tokens-table tokens-table--mb0">
			<div className="tokens-table__top">
				<p className="tokens-table__text">Transactions</p>
			</div>
			<div className="table-top">
				<div className="table-btns">
					<button
						type="button"
						onClick={() => handleFilterTab('')}
						className={classNames('table-btns__item', { active: filter.type === '' })}
					>
						All
					</button>
					{Object.entries(EType).map((el) => (
						<button
							key={el[0]}
							type="button"
							onClick={() => handleFilterTab(el[1])}
							className={classNames('table-btns__item', { active: filter.type === el[1] })}
						>
							{capitalize(el[0])}
						</button>
					))}
				</div>
				<div className="table-top__text">
					Time
					<div className="table-top__icon">
						<ArrowDown />
					</div>
				</div>
			</div>
			<div className="table table--group-type table--token-transaction">
				<div className="table-body">
					{transactionList.length ? (
						transactionList.map((row) => (
							<div key={row[0].id} className="tr">
								{row.map((item) => (
									<TransactionRow key={item.id} {...item} />
								))}
							</div>
						))
					) : (
						<EmptyTable text="No Recent Transactions Yet" />
					)}
				</div>
			</div>
			{userTransaction && (
				<PaginationTable
					className="block-pagination--center block-pagination--table"
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					lastPage={userTransaction?.last_page}
				/>
			)}
		</div>
	);
};

export default TransactionsTable;
