import React, { FC, useMemo, useState } from 'react';
import { ITokenTransfer } from 'services/api/blockchain/types';
import PaginationTable from 'ui/PaginationTable';
import TransfersBlock from 'components/Transactions/TransfersBlock';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import SelectToFrom from 'ui/SelectToFrom';

interface ITransfersTabProps {
	transferList: ITokenTransfer[] | undefined;
	// eslint-disable-next-line react/require-default-props
	hash?: string | undefined;
}

const TransfersTab: FC<ITransfersTabProps> = ({ transferList, hash }) => {
	const [itemOffset, setItemOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [searchValue, setSearchValue] = useState<string>('all');
	const perPage = 6;

	const filteredList = useMemo(() => {
		if (!transferList) return [];
		setCurrentPage(1);
		if (searchValue === 'to') {
			return transferList.filter((el) => el.to_address_hash === hash);
		}
		if (searchValue === 'from') {
			return transferList.filter((el) => el.from_address_hash === hash);
		}
		return transferList;
	}, [hash, searchValue, transferList]);

	const currentTransfers = useMemo(() => {
		setItemOffset((currentPage - 1) * perPage);
		const endOffset = itemOffset + perPage;
		setPageCount(Math.ceil(filteredList.length / perPage));
		return filteredList.slice(itemOffset, endOffset);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, filteredList]);

	return (
		currentTransfers && (
			<>
				<div className="explore-block__top ">
					<h4 className="block-title block-title--mr-auto">Token Transfers</h4>
					{hash && <SelectToFrom search={searchValue} setSearch={setSearchValue} />}
					<PaginationTable
						className="block-pagination--header"
						currentPage={currentPage}
						lastPage={pageCount}
						setCurrentPage={setCurrentPage}
					/>
				</div>
				{currentTransfers.length ? (
					<TransfersBlock itemsList={currentTransfers} />
				) : (
					<EmptyTable text="No Recent Transfers Yet" />
				)}
				<PaginationTable
					className="block-pagination--footer"
					currentPage={currentPage}
					lastPage={pageCount}
					setCurrentPage={setCurrentPage}
				/>
			</>
		)
	);
};

export default TransfersTab;
