import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IHolders, ITokensStore, TTokenData, TTokensData, TTransactions } from './types';

// ==========================================:

const getTokensState = (state: TStoreState): ITokensStore => state.tokens;
export const getToken = createSelector([getTokensState], (tokens: ITokensStore) => tokens);

export const getTokensData = createSelector(
	[getToken],
	(tokens: ITokensStore): TTokensData | null => tokens.tokens,
);

export const getTokensLoading = createSelector(
	[getToken],
	(tokens: ITokensStore): boolean => tokens.tokensLoading,
);

export const getTokenData = createSelector(
	[getToken],
	(tokens: ITokensStore): TTokenData => tokens.token,
);

export const getTokenLoading = createSelector(
	[getToken],
	(tokens: ITokensStore): boolean => tokens.tokenLoading,
);
export const getTokenTransfersData = createSelector(
	[getToken],
	(tokens: ITokensStore): TTransactions => tokens.transfers,
);

export const getTokenTransfersLoading = createSelector(
	[getToken],
	(tokens: ITokensStore): boolean => tokens.transfersLoading,
);

export const getTokenHoldersData = createSelector(
	[getToken],
	(tokens: ITokensStore): IHolders => tokens.holders,
);

export const getTokenHoldersLoading = createSelector(
	[getToken],
	(tokens: ITokensStore): boolean => tokens.holdersLoading,
);
