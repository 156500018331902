/* eslint-disable */
import { FC, useEffect, useMemo, useState, MouseEvent, useCallback, FocusEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { swapApprove } from 'services/utils/swap';
import { ApproveTokens } from 'services/utils/approve';
import { feeChecker } from 'services/utils/feeChecker';
import { useLocation, useNavigate } from 'react-router';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import { noExponent } from 'services/utils/noExponent';
import { ethers } from 'ethers';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { usePrevious } from 'hooks/usePrevious';
import {
	getCalculatedRate,
	getSwapAssetList,
	getSwapPairList,
	getSwapTokenList,
	getCalculatedPerOneRate,
} from 'redux/reducers/swap/selectors';
import {
	calculateRateRequest,
	calculateRatePerOneRequest,
	clearTokenBalances,
	getTokenBalances,
	sendTrancationRequest,
	sendTrancationTokneHashRequest,
} from 'redux/reducers/swap/reducer';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getSlipping, getNetworkId } from 'redux/reducers/wallets/selectors';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	toMaxDecimals,
	checkAfterPoint,
	toDecimalsAfterPoint,
	bigNumberFormater,
	revertBigNumberFormatter,
} from 'services/utils/numbers';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { RefreshIcon, GearIcon, ReverseIcon, QuestionIcon } from 'assets/custom-icons';
import AbiPairs from 'config/abi/GatoswapPair.json';
import AbiFee from 'config/abi/FeeController.json';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';
import AbiTokens from '../../../config/abi/GUSD.json';
import { SettingsModal } from '../SettingsModal';
import { ConfirmModal } from './ConfirmModal';
import { IExchangeLocationStateWithObject } from '../types';
import { SuccessModal } from './TransactionsModals/succes';
import { ITypeTransaction, IToken } from './type';
import {
	getCurrentPair,
	tryInsufficientLiquidity,
	numberInDecimal,
	getInitTokenSymbols,
	getTokenBySymbol,
	getTokensWithoutSymbol,
	getIsTokenPairInList,
	getRateForTokenPair,
	getRateByDirection,
	getOppositeValue,
	numberOutDecimal,
	initialTokenPair,
} from './utils';

import { TokenInput } from '../TokenInput';

const { BigNumber, constants, utils } = ethers;

const Web3 = require('web3');

const chainId = String(process.env.REACT_APP_CHAIN_ID);

const RPC_HOST = String(process.env.REACT_APP_RPC_URL);

const ROUTER_CONTRACT = String(process.env.REACT_APP_ROUTER_ADDRESS);
// const ROUTER_CONTRACT = '0x54e621abd4bF9dF2DB1CE038A3aF25B71530C028';

const SELECTOR_SWAP_EXACT = '0x4336d5e8';

const _web3 = new Web3(RPC_HOST);

const RouterContract = new _web3.eth.Contract(AbiRouter.abi, ROUTER_CONTRACT);

declare let window: any;

export const ExchangeForm: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const assetList = useSelector(getSwapAssetList);
	const tokenListNoCoin = useSelector(getSwapTokenList);
	const tokenList = tokenListNoCoin;
	const pairList = useSelector(getSwapPairList);
	const walletAddress = useSelector(getAddress);
	const calculatedRate = useSelector(getCalculatedRate);
	const calculatedPerOneRate = useSelector(getCalculatedPerOneRate);
	const slipping = useSelector(getSlipping);
	const network = useSelector(getNetworkId);
	const tokenRefresh = useRefreshToken();
	const { provider, account, chainId } = useWeb3React<Web3Provider>();
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const prevWalletAddress = usePrevious(walletAddress?.wallet);

	const locationState = location?.state as IExchangeLocationStateWithObject | null;

	const [initFromSymbol, initToSymbol] = getInitTokenSymbols(locationState, assetList);

	const [fromTokenSymbol, setFromTokenSymbol] = useState(initFromSymbol);
	const [toTokenSymbol, setToTokenSymbol] = useState(initToSymbol);
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const [fromDecimalsValue, setFromDecimalsValue] = useState('');
	const [toDecimalsValue, setDesimalsToValue] = useState('');
	const [isLastFromValue, setIsLastFromValue] = useState(true);
	const [isFromPerToRate, setIsFromPerToRate] = useState(true);
	const [hash, setHash] = useState('');
	const [loader, setLoader] = useState(false);
	const [disableBtn, setDisableBtn] = useState('');
	const [timer, setTimer] = useState<any>(null);
	const [selectFromWallet, setSelectFromWallet] = useState<any | null>(null);
	const prevFromValue = usePrevious(fromValue);
	const prevToValue = usePrevious(toValue);

	const [fromInputFocus, setFromInputFocus] = useState<boolean>(false);
	const [toInputFocus, setToInputFocus] = useState<boolean>(false);

	useEffect(() => {
		if (fromInputFocus === false) {
			setFromValue(bigNumberFormater(toDecimalsAfterPoint(noExponent(fromValue), 12)));
		} else {
			setFromValue(revertBigNumberFormatter(fromValue));
		}
	}, [fromInputFocus]);

	useEffect(() => {
		if (toInputFocus === false) {
			// setToValue(bigNumberFormater(toDecimalsAfterPoint(noExponent(toValue), 12)))
		} else {
			// setToValue(revertBigNumberFormatter(toValue));
		}
	}, [toInputFocus]);

	const assetCoin = tokenListNoCoin?.find((item) => item.type === 'coin');

	const fromTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, toTokenSymbol);
	}, [tokenList, toTokenSymbol, pairList]);

	const toTokenList = useMemo(() => {
		return getTokensWithoutSymbol(tokenList, fromTokenSymbol);
	}, [tokenList, fromTokenSymbol, pairList]);

	// const tryNetwork = network !== chainId;

	const fromToken = getTokenBySymbol(fromTokenList, fromTokenSymbol);
	const toToken = getTokenBySymbol(toTokenList, toTokenSymbol);
	const isPairInList = getIsTokenPairInList(fromToken, toToken, pairList);
	const pairRate = getRateForTokenPair(isPairInList, calculatedRate, fromToken, toToken);
	const pairRatePerOne = getRateForTokenPair(
		isPairInList,
		calculatedPerOneRate,
		fromToken,
		toToken,
	);

	const displayedRate = getRateByDirection(isFromPerToRate, pairRatePerOne);

	const handleFromValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// if (checkAfterPoint(e.target.value, Number(fromToken?.decimals))) return;

		if (checkAfterPoint(e.target.value, 15)) return;

		setFromValue(revertBigNumberFormatter(e.target.value));
		setIsLastFromValue(true);
	};

	const handleToValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// if (checkAfterPoint(e.target.value, Number(toToken?.decimals))) return;
		if (checkAfterPoint(e.target.value, 15)) return;
		setToValue(bigNumberFormater(revertBigNumberFormatter(e.target.value)));
		setIsLastFromValue(false);
	};

	const handleReverse = () => {
		setFromTokenSymbol(toTokenSymbol);
		setToTokenSymbol(fromTokenSymbol);

		if (isLastFromValue) {
			setToValue(revertBigNumberFormatter(fromValue));
		} else {
			setFromValue(toValue);
		}
		setIsLastFromValue((state) => !state);
	};
	const disabled = !fromValue || !toValue || disableBtn !== '';

	const [currentPercent, setCurrentPercent] = useState(0.25);
	const [openModal, setOpenModal] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openTransactionModal, setOpenTransactionModal] = useState(false);
	const [count, setCount] = useState<number>(0);

	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};
	const handlerConfirm = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenConfirmModal(!openConfirmModal);
	};

	const currentPair = useMemo(() => {
		return getCurrentPair(pairList, fromToken, toToken);
	}, [pairList, fromToken, toToken]);

	const handleMax = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'from') {
			setIsLastFromValue(true);
			setFromValue(
				// String(toDecimalsAfterPoint(String(fromToken?.balance), Number(fromToken?.decimals))),
				bigNumberFormater(
					String(toDecimalsAfterPoint(String(replaceTokenVsCoin(fromToken)?.balance || ''), 15)),
				),
			);

			return;
		}
		setIsLastFromValue(false);
		// setToValue(String(toDecimalsAfterPoint(String(toToken?.balance), Number(toToken?.decimals))));
		setToValue(
			bigNumberFormater(
				String(toDecimalsAfterPoint(String(replaceTokenVsCoin(toToken)?.balance || ''), 15)),
			),
		);
	};

	useEffect(() => {
		setDisableBtn(
			String(
				tryInsufficientLiquidity(
					currentPair,
					revertBigNumberFormatter(fromValue),
					revertBigNumberFormatter(toValue),
					replaceTokenVsCoin(fromToken),
					toToken,
				),
			),
		);
	}, [currentPair, fromValue, toValue, fromToken, toToken]);

	useEffect(() => {
		if (prevWalletAddress !== walletAddress.wallet) {
			dispatch(clearTokenBalances());
		}
		dispatch(getTokenBalances(''));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [walletAddress.wallet, assetList, dispatch]); // eslint-disable-line

	useEffect(() => {
		if (fromToken?.address && toToken?.address && (fromValue || toValue)) {
			const payload = {
				firstAddress: isLastFromValue ? fromToken.address : toToken.address,
				secondAddress: isLastFromValue ? toToken.address : fromToken.address,
				amount: isLastFromValue
					? revertBigNumberFormatter(fromValue)
					: revertBigNumberFormatter(toValue),
			};
			if (timer) {
				clearTimeout(timer);
				setTimer(null);
			}
			setTimer(
				setTimeout(() => {
					if (payload.amount) {
						dispatch(calculateRateRequest(payload));
						//  if(isLastFromValue && prevFromValue !== fromValue){
						// 	dispatch(calculateRateRequest(payload));
						//  }else if(!isLastFromValue && prevToValue !== toValue){
						// 	dispatch(calculateRateRequest(payload));
						//  }
					}
				}, 300),
			);
		}
	}, [fromToken?.address, toToken?.address, isLastFromValue, fromValue, toValue, dispatch]);

	const updatePrice = () => {
		if (fromToken?.address && toToken?.address && (fromValue || toValue)) {
			const payload = {
				firstAddress: isLastFromValue ? fromToken.address : toToken.address,
				secondAddress: isLastFromValue ? toToken.address : fromToken.address,
				amount: isLastFromValue ? fromValue : toValue,
			};
			dispatch(calculateRateRequest(payload));
		}
	};

	useEffect(() => {
		if (fromToken?.address && toToken?.address) {
			const payload = {
				firstAddress: isLastFromValue ? fromToken.address : toToken.address,
				secondAddress: isLastFromValue ? toToken.address : fromToken.address,
				amount: '1',
			};
			dispatch(calculateRatePerOneRequest(payload));
		}
	}, [dispatch, isFromPerToRate, fromToken?.address, toToken?.address]);

	useEffect(() => {
		const pairRate = getRateForTokenPair(isPairInList, calculatedRate, fromToken, toToken);

		const oppositeValue = getOppositeValue(
			revertBigNumberFormatter(fromValue),
			revertBigNumberFormatter(toValue),
			isLastFromValue,
			pairRate,
		);
		// const amountInWithDecimals = numberInDecimal(Number(fromValue),Number(fromToken?.decimals));

		if (isLastFromValue) {
			const multiValue = Number(oppositeValue) * (1 - Number(slipping) / 100);
			// setToValue(multiValue?String(toDecimalsAfterPoint(noExponent(multiValue), Number(toToken?.decimals))):'');
			setToValue(
				multiValue
					? bigNumberFormater(String(toDecimalsAfterPoint(noExponent(multiValue), 15)))
					: '',
			);
		} else {
			// setFromValue(String(toDecimalsAfterPoint(oppositeValue, Number(fromToken?.decimals))));
			setFromValue(bigNumberFormater(String(toDecimalsAfterPoint(oppositeValue, 15))));
		}
	}, [fromValue, toValue, isLastFromValue, pairRate, slipping]);

	// Reset tokens to initial/available, if not in list
	useEffect(() => {
		if (fromToken && toToken) return;

		const initFromToken = getTokenBySymbol(fromTokenList, initFromSymbol);
		const initToToken = getTokenBySymbol(toTokenList, initToSymbol);

		const fromTokenSet =
			!toToken && initToToken ? getTokensWithoutSymbol(fromTokenList, initToSymbol) : fromTokenList;

		let toTokenSet = toTokenList;

		// const newFromSymbol = initFromToken ? initFromSymbol : initialTokenPair(pairList, fromTokenSet);
		const newFromSymbol = initFromToken ? initFromSymbol : fromTokenSet?.[0];

		if (!fromToken && newFromSymbol) {
			setFromTokenSymbol(newFromSymbol);
			toTokenSet = getTokensWithoutSymbol(toTokenSet, newFromSymbol);
		}

		// const newToSymbol = initToToken
		// 	? initToSymbol
		// 	: initialTokenPair(pairList, toTokenSet, newFromSymbol);
		const newToSymbol = initToToken ? initToSymbol : toTokenSet?.[0];

		if (!toToken && newToSymbol) {
			setToTokenSymbol(newToSymbol);
		}
	}, [fromTokenList, fromToken, toTokenList, toToken, initFromSymbol, initToSymbol]);

	const countFrom = (value: number) => {
		setIsLastFromValue(true);

		if (value === 0) {
			notificationContainer(`Not enough balance`, 'error');
			return;
		}
		// setFromValue(String(toDecimalsAfterPoint(value, Number(fromToken?.decimals))));
		const valueResut = value.toString().replace(/,/gi, '');
		setFromValue(bigNumberFormater(toMaxDecimals(revertBigNumberFormatter(String(value)), 15)));
	};
	const countTo = (value: number) => {
		setToValue(toMaxDecimals(String(value), 12));

		// setFromValue(String(value));
	};

	const percentButtonCountValue = (percentValue: number): number => {
		// if (!toMaxDecimals(String(fromToken?.balance), 6) || !Number(percentValue)) {
		// 	return 0;
		// }

		const token = getTokenBySymbol(fromTokenList, fromTokenSymbol);
		const token1 = replaceTokenVsCoin(token);
		if (!toMaxDecimals(String(token1?.balance), 15) || !Number(percentValue)) {
			return 0;
		}

		return Number(token1?.balance) * percentValue;
	};

	const handleSwap = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();
		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		const currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		if (!currentPair) return;

		try {
			// const accounts = [] || '';

			const currentAddress = connector === 'INJECTED' ? account : address;
			const currentChain = connector === 'INJECTED' ? chainId : chainWeb3;
			// 4286

			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			const pairContract = new _web3.eth.Contract(AbiPairs.abi, currentPair.pair_id);

			const amountInWithDecimals = numberInDecimal(
				Number(revertBigNumberFormatter(fromValue)),
				Number(fromToken?.decimals),
			).toString();
			// const amountInWithDecimalsTo = numberInDecimal(Number(toValue), Number(toToken?.decimals));

			// const amountOut = await RouterContract.methods
			// 	.getAmountsOut(String(noExponent(Math.round(Number(amountInWithDecimals)))), [
			// 		String(fromToken?.address),
			// 		String(toToken?.address),
			// 	])
			// 	.call();

			const amountPairOut = await pairContract.methods.getReserves().call();

			const getTokenFirst = await pairContract.methods.token0().call();

			let amountOut = 0;
			if (String(getTokenFirst)?.toLowerCase() === fromToken?.address?.toLowerCase()) {
				amountOut = await RouterContract.methods
					.getAmountOut(
						BigNumber.from(noExponent(amountInWithDecimals)),
						amountPairOut._reserve0,
						amountPairOut._reserve1,
					)
					.call();
			} else {
				amountOut = await RouterContract.methods
					.getAmountOut(
						BigNumber.from(noExponent(amountInWithDecimals)),
						amountPairOut._reserve1,
						amountPairOut._reserve0,
					)
					.call();
			}

			const paramsToken: IToken = {
				wallet: String(currentAddress),
				amount: String(noExponent(amountInWithDecimals)),
				abi: AbiTokens.abi,
				tokenName: String(fromToken?.name),
				tokenAddress: String(fromToken?.address), // Address
				contractAddess: String(ROUTER_CONTRACT), // Address contract
				provider: currentProvider,
				chainid: currentChain,
				setLoader,
				connector,
			};

			// setOpenConfirmModal(false);
			const hash = fromToken?.symbol === 'GATO' ? '' : await ApproveTokens(paramsToken);

			if (hash) {
				dispatch(
					sendTrancationTokneHashRequest({
						hash: String(hash.transactionHash),
						asset_address: String(fromToken?.address),
						wallet_address: String(currentAddress)?.toLowerCase(),
					}),
				);
			}

			const paramsFee: any = {
				wallet: String(currentAddress),
				amount: String(noExponent(amountInWithDecimals)),
				selector: SELECTOR_SWAP_EXACT,
				abi: AbiFee.abi,
				tokenAddress:
					fromToken?.symbol === 'GATO' ? assetCoin?.address : String(fromToken?.address),
				contractAddess: String(ROUTER_CONTRACT), // Address contract
				provider: currentProvider,
				type: fromToken?.type,
				code: fromToken?.symbol,
				setLoader,
				connector,
			};

			const amountFee = await feeChecker(paramsFee);
			// debugger;
			if (!amountFee) {
				return;
			}
			const feeNum = amountFee;
			// const truncatedValue = amountFee.slice(0, 10);
			// const roundedLastDigit = (parseInt(truncatedValue) + 1) % 10;
			// const roundedValue = truncatedValue.slice(0, -1) + roundedLastDigit;
			// const weiValue = utils.parseUnits(amountFee, 18); // 18 - кількість десяткових знаків в Ethereum
			// const roundedWeiValue = weiValue.div(utils.parseUnits('1', 6)).toString();
			// amountFee + '000000';
			// debugger;

			const sendParams: ITypeTransaction = {
				wallet: String(currentAddress),
				abi: AbiRouter.abi,
				tokenAbi: AbiTokens.abi,
				to: ROUTER_CONTRACT,
				firstTokenAmount: String(
					noExponent(
						Math.floor(
							Number(
								numberInDecimal(
									Number(revertBigNumberFormatter(fromValue)),
									Number(fromToken?.decimals),
								).toString(),
							),
						),
					),
				),
				secondTokenAmount: String(
					noExponent(Math.floor(Number(amountOut.toString()) * (1 - Number(slipping) / 100))),
				),
				firstTokenAddress: String(fromToken?.address),
				secondTokenAddress: String(toToken?.address),
				provider: currentProvider,
				type: fromToken?.symbol === 'GATO' ? fromToken?.symbol : toToken?.symbol,
				fromCode: fromToken?.symbol,
				feeAmount: feeNum,
				setLoader,
				connector,
			};

			const result = await swapApprove(sendParams);
			if (result) {
				dispatch(
					sendTrancationRequest({
						tx: String(result?.transactionHash),
						base_amount: Number(fromValue),
						quote_amount: Number(toValue),
						base_id: Number(fromToken?.id),
						quote_id: Number(toToken?.id),
						type: 'swap',
					}),
				);

				setOpenTransactionModal(true);
				setHash(result?.transactionHash);
				return;
			}

			// setOpenTransactionModal(true);
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
			setOpenTransactionModal(true);
		}
	};

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleFromInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);

		setFromInputFocus(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);

		setToInputFocus(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const replaceTokenVsCoin = (item: any) => {
		if (item?.symbol === 'GATO') {
			return tokenListNoCoin?.find(({ type }) => type === 'coin');
		}
		return item;
	};

	return (
		<div className="general-block general-block--center">
			<div className="block-header">
				<h4 className="general-block-title">Exchange</h4>
				{/* <div className="block-header__wrapper">
					<div className="swap swap--small">
						<button type="button" className="swap__item active">
							ETH
						</button>
						<button type="button" className="swap__item">
							USD
						</button>
					</div>
					
				</div> */}
			</div>

			<div className="block-content">
				<div className="exchange-item">
					<TokenInput
						tokenList={fromTokenList}
						value={fromToken}
						toValue={toToken}
						pairList={pairList}
						onChange={(token) => setFromTokenSymbol(token)}
						title="From"
						sortToken
						coin={assetCoin}
					/>

					<div className="input input--exchange">
						<label>
							<div className="input-wrapper">
								<input
									className="input-item input-item--exchange input-item--get-info"
									value={fromValue}
									onChange={handleFromValueChange}
									type="text"
									onFocus={handleFromInputFocus}
									onBlur={() => setFromInputFocus(false)}
									placeholder="0"
								/>
								<button onClick={handleMax} name="from" type="button" className="exchange-max-btn">
									MAX
								</button>
							</div>
						</label>
						<p className="available-in-pool">
							Availability In Pool:{' '}
							{toDecimalsAfterPoint(
								noExponent(
									numberOutDecimal(
										Number(
											fromToken?.address === currentPair?.asset_base.address
												? currentPair?.reserve_base
												: currentPair?.reserve_quote,
										),
										Number(fromToken?.decimals),
									),
								),
								6,
							) || 0}{' '}
							{fromToken?.symbol}
						</p>
					</div>
				</div>

				<ConvertPercentButtons
					countFrom={countFrom}
					percentButtonCountValue={percentButtonCountValue}
					amount={revertBigNumberFormatter(fromValue)}
					currentPercent={currentPercent}
					setCurrentPercent={setCurrentPercent}
				/>

				<div className="exchange-transfer">
					<p className="exchange-transfer__text">
						Availability:{' '}
						{toDecimalsAfterPoint(String(replaceTokenVsCoin(fromToken)?.balance || 0), 6)}{' '}
						{fromToken?.symbol}
					</p>

					<button
						type="button"
						className="transfer-btn transfer-btn--round"
						onClick={handleReverse}
					>
						<ReverseIcon />
					</button>
				</div>

				<div className="exchange-item">
					<TokenInput
						tokenList={toTokenList}
						value={toToken}
						toValue={fromToken}
						pairList={pairList}
						onChange={(token) => setToTokenSymbol(token)}
						title="To"
						sortToken
						coin={assetCoin}
					/>

					<div className="input input--exchange">
						<label>
							<div className="input-wrapper">
								<input
									className="input-item input-item--exchange"
									value={toValue}
									onChange={handleToValueChange}
									type="text"
									// readOnly
									placeholder="0"
								/>
								<button onClick={handleMax} name="to" type="button" className="exchange-max-btn">
									MAX
								</button>
							</div>
						</label>
						<p className="available-in-pool">
							Availability In Pool:{' '}
							{toDecimalsAfterPoint(
								noExponent(
									numberOutDecimal(
										Number(
											toToken?.address === currentPair?.asset_quote.address
												? currentPair?.reserve_quote
												: currentPair?.reserve_base,
										),
										Number(toToken?.decimals),
									),
								),
								6,
							) || 0}{' '}
							{toToken?.symbol}
						</p>
					</div>
				</div>
				{/* <ConvertPercentButtons
					countFrom={countFrom}
					percentButtonCountValue={percentButtonCountValue}
					amount={revertBigNumberFormatter(fromValue)}
					currentPercent={currentPercent}
					setCurrentPercent={setCurrentPercent}
				/> */}
				<div className="exchange-transfer">
					<p className="exchange-transfer__text">
						Availability:{' '}
						{toDecimalsAfterPoint(String(replaceTokenVsCoin(toToken)?.balance || 0), 6)}{' '}
						{toToken?.symbol}
					</p>
				</div>

				<div className="operation-info">
					{displayedRate && (
						<>
							<p className="operation-info__text operation-info__text--bold operation-info__text--fs14">
								Price
							</p>
							<p className="operation-info__number operation-info__number--flex operation-info__number--fs14">
								{/* {isFromPerToRate?(displayedRate/(10**6)).toFixed(8):(displayedRate*(10**6)).toFixed(8)||0}{' '} */}
								{toDecimalsAfterPoint(displayedRate.toFixed(12), 6) || 0}{' '}
								<span>
									{isFromPerToRate ? fromToken?.symbol : toToken?.symbol} per{' '}
									{isFromPerToRate ? toToken?.symbol : fromToken?.symbol}
								</span>
								<button
									className="operation-info__icon"
									type="button"
									onClick={() => setIsFromPerToRate((state) => !state)}
								>
									<RefreshIcon />
								</button>
							</p>
						</>
					)}
				</div>
				<div className="operation-info operation-info">
					<div className="operation-wrapper">
						<p className="operation-info__text operation-info__text--bold operation-info__text--fs14">
							Slippage Tolerance
						</p>
						<div className="small-buttons">
							<span className="tooltip-item">
								<span className="tooltip-item__icon">
									<QuestionIcon />
								</span>
								<span className="tooltip tooltip--top tooltip--center">
									<span className="tooltip__text">
										<span className="tooltip__text-title">
											Slippage Tolerance is the pricing difference between the price at the
											confirmation time and the actual price of the transaction users are willing to
											accept when exchanging (swapping).
										</span>
									</span>
								</span>
							</span>
							<button
								type="button"
								className="block-settings-btn block-settings-btn--small"
								onClick={(e) => handlerSettings(e)}
							>
								<GearIcon />
							</button>
						</div>
					</div>

					<p className="operation-info__number operation-info__number--fs14 operation-info__number--blue">
						{slipping || '0.1'}%
					</p>
				</div>
			</div>

			<div className="block-footer">
				{prevWalletAddress ? (
					<button
						disabled={!!disableBtn}
						onClick={handlerConfirm}
						type="button"
						className="button button--big-height button--full-width"
					>
						{fromValue ? (
							<span>{!disableBtn ? 'Send transaction' : disableBtn}</span>
						) : (
							'Enter an amount'
						)}
					</button>
				) : (
					<ConnectWalletButton />
				)}
			</div>
			<SettingsModal openModal={openModal} setOpenModal={setOpenModal} slipping={slipping} />
			<ConfirmModal
				openModal={openConfirmModal}
				setOpenModal={setOpenConfirmModal}
				itemIn={fromToken}
				fromValue={revertBigNumberFormatter(fromValue)}
				itemOut={toToken}
				toValue={revertBigNumberFormatter(toValue)}
				displayedRate={displayedRate ? convertExponentialToDecimal(displayedRate) : 0}
				aproveSwap={handleSwap}
				updatePrice={updatePrice}
				slipping={slipping}
				loader={loader}
			/>
			<SuccessModal
				openModal={openTransactionModal}
				setOpenModal={setOpenTransactionModal}
				loader={loader}
				hash={hash}
			/>
		</div>
	);
};

