import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	disableEmail2FARequest,
	disableGoogle2FARequest,
	enableEmail2FARequest,
	enableGoogle2FARequest,
	generateGoogle2FAKeyRequest,
	sendEmailCodeRequest,
} from 'redux/reducers/settings/reducer';
import {
	getUserSettingsDataLoader,
	getUserSettingsData,
	getActions2FALoading,
} from 'redux/reducers/settings/selectors';
import {
	IEmail2FARequestPayload,
	IGenerateGoogle2FAKeyRequestPayload,
	IGoogle2FARequestPayload,
	ISendEmailCodeRequestPayload,
} from 'redux/reducers/settings/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import Loader from 'ui/Loader';
import { Modal } from 'ui/Modal';
import { Enter2FACode } from './Enter2FACode';
import { TEnter2FACodeAction } from './Enter2FACode/types';
import { Switched2FA } from './Switched2FA';
import { TSwitched2FANote } from './Switched2FA/types';
import { TurnOn2FA } from './TurnOn2FA';

export const TwoFactor: FC = () => {
	const dispatch = useDispatch();
	const userSettings = useSelector(getUserSettingsData);
	const settingsLoading = useSelector(getUserSettingsDataLoader);
	const actsLoading = useSelector(getActions2FALoading);

	const [isGoogleModal, setIsGoogleModal] = useState(false);
	const [enterCodeAct, setEnterCodeAct] = useState<TEnter2FACodeAction | null>(null);
	const [switchedModalNote, setSwitchedModalNote] = useState<TSwitched2FANote | null>(null);

	const isGoogle2FA = userSettings?.google2fa_enabled;
	const isEmail2FA = userSettings?.email_2fa_enabled;
	const isShowAnyModal = isGoogleModal || !!enterCodeAct || !!switchedModalNote;
	const anyLoading = (settingsLoading || actsLoading) && !isShowAnyModal;

	const toggleGoogleModal = () => setIsGoogleModal((state) => !state);
	const hideCodeModal = () => setEnterCodeAct(null);
	const hideSwitchedModal = () => setSwitchedModalNote(null);

	const handleChangeGoogle2FA = () => {
		if (isEmail2FA) return;

		if (isGoogle2FA) {
			setEnterCodeAct('google-off');
			return;
		}
		const params: IGenerateGoogle2FAKeyRequestPayload = {
			onSuccess: () => setIsGoogleModal(true),
		};
		dispatch(generateGoogle2FAKeyRequest(params));
	};

	const handleChangeEmail2FA = () => {
		if (isGoogle2FA || !userSettings?.email) {
			return;
		}
		const action = isEmail2FA ? 'email-off' : 'email-on';
		const params: ISendEmailCodeRequestPayload = {
			apiParams: { email: userSettings.email },
			onSuccess: () => setEnterCodeAct(action),
			onAlreadySent: () => {
				setEnterCodeAct(action);
				notificationContainer('Email code already sent', 'info');
			},
		};
		dispatch(sendEmailCodeRequest(params));
	};

	const handleGoogleModalSubmit = (code: string) => {
		const payload: IGoogle2FARequestPayload = {
			apiParams: { totp: code },
			onSuccess: () => {
				toggleGoogleModal();
				setSwitchedModalNote('2fa-on');
			},
		};
		dispatch(enableGoogle2FARequest(payload));
	};

	const handleCodeSubmit = (code: string) => {
		if (enterCodeAct === 'google-off') {
			const payload: IGoogle2FARequestPayload = {
				apiParams: { totp: code },
				onSuccess: () => {
					hideCodeModal();
					setSwitchedModalNote('2fa-off');
				},
			};
			dispatch(disableGoogle2FARequest(payload));
		} else if (enterCodeAct === 'email-on') {
			const payload: IEmail2FARequestPayload = {
				apiParams: { email_code: code },
				onSuccess: () => {
					hideCodeModal();
					setSwitchedModalNote('2fa-on');
				},
			};
			dispatch(enableEmail2FARequest(payload));
		} else if (enterCodeAct === 'email-off') {
			const payload: IEmail2FARequestPayload = {
				apiParams: { email_code: code },
				onSuccess: () => {
					hideCodeModal();
					setSwitchedModalNote('2fa-off');
				},
			};
			dispatch(disableEmail2FARequest(payload));
		}
	};

	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="general-block">
						<div className="account-change account-change--2-factor">
							<div className="account-change__info">
								<p className="account-change__item">Two-Factor Authentication</p>
							</div>

							<div className="authentication-box">
								<div className="authentication-item">
									<p className="authentication-item__name">Turn on Google Authenticator.</p>

									<div className="switch">
										<label className="switch__label">
											<input
												type="checkbox"
												className="hidden"
												checked={isGoogle2FA}
												onChange={handleChangeGoogle2FA}
											/>
											<div className="switch__toggler" />
										</label>
									</div>
								</div>

								<div className="authentication-item">
									<p className="authentication-item__name">Turn on email verification.</p>
									<div className="switch">
										<label className="switch__label">
											<input
												type="checkbox"
												className="hidden"
												checked={isEmail2FA}
												onChange={handleChangeEmail2FA}
											/>
											<div className="switch__toggler" />
										</label>
									</div>
								</div>
							</div>

							{anyLoading && (
								<div className="auth-loader-wrapper">
									<Loader />
								</div>
							)}

							<Modal isOpen={isGoogleModal} onClose={toggleGoogleModal} disableCloseOutside>
								<TurnOn2FA onSubmit={handleGoogleModalSubmit} onClose={toggleGoogleModal} />
							</Modal>

							<Modal isOpen={!!enterCodeAct} onClose={hideCodeModal} disableCloseOutside>
								<Enter2FACode
									onSubmit={handleCodeSubmit}
									onClose={hideCodeModal}
									action={enterCodeAct as TEnter2FACodeAction}
								/>
							</Modal>

							<Modal isOpen={!!switchedModalNote} onClose={hideSwitchedModal}>
								<Switched2FA
									onClose={hideSwitchedModal}
									note={switchedModalNote as TSwitched2FANote}
								/>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
