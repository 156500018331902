/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	authInitState,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmStatusCallback,
	emailConfirmStatusRequest,
	emailConfirmSuccess,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	generateSmsRequest,
	forgotTwoFaRequest,
	loginRequest,
	loginSuccess,
	logoutRequest,
	registrationRequest,
	registrationSuccess,
	phoneConfirmRequest,
	resendEmailRequest,
	resendPhoneRequest,
	phoneConfirmSmsNewPassRequest,
	loginGoTo2FASuccess,
	forgotPasswordEmailRequest,
	getRefreshTokenSuccess,
	getRefreshTokenRequest,
	getQrcodeRequest,
	getQrcodeSuccess,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IRegistrationPayload,
	ILogoutPayload,
	IForgotPasswordPayload,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IRefreshTokenResponse,
	IGenerateSmsRequestPayload,
	IResetTwoFaPayload,
	IRegistrResponse,
	IResendPhone,
	IResendEmail,
	IGenerateSmsChecktPayload,
	ITokenResponse,
	ILogin2FAResponse,
	IRefreshTokenRequest,
} from './types';
import { get2FATypeByResponse } from './utils';
/* eslint-disable no-debugger */

// =============================================================:
function* loginRequestWorker({ payload }: PayloadAction<ILoginPayload>) {
	const { data: apiParams, on2FA, onEmailAlreadySent } = payload;

	try {
		yield put(showLoading());
		const data: ILoginResponse | ILogin2FAResponse = yield call(api.auth.login, apiParams);

		const type2FA = get2FATypeByResponse(data);

		if (type2FA) {
			yield put(loginGoTo2FASuccess());
			on2FA?.({ apiParams, type2FA });
		} else {
			yield put(loginSuccess(data as ILoginResponse));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.response?.status === 502 || error.response?.status === 500) {
				notificationContainer('Server error', 'error');
				yield put(authInitState());
				return;
			}
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				// payload.history.push('/ip-confirmation');
			} else if (
				error?.response?.data?.errors &&
				error?.response?.data?.errors[0] === 'invalid_totp_code'
			) {
				notificationContainer(`Invalid 2FA code`, 'error');
				yield put(authInitState());
				return;
				// const code: string = error?.response?.data?.errors?.totp[0];
				// notificationContainer(`Errors.${code}`, 'info');
			} else if (error?.response?.data?.errors[0] === 'invalid_credentials') {
				notificationContainer('Invalid credentials', 'error');
				yield put(authInitState());
				return;
			} else if (
				error?.response?.data?.errors[0] === 'you_need_confirmed_account!_check_your_email'
			) {
				notificationContainer('You need confirmed account! check your email', 'info');
				yield put(authInitState());
				return;
			} else if (
				error?.response?.data?.errors[0] === 'you_need_confirmed_account!_check_your_sms_code'
			) {
				notificationContainer('You need confirmed account check your sms code', 'info');
				yield put(authInitState());
				return;
			} else if (error.response?.data?.errors?.[0] === 'already_sent_code') {
				// Backend bug? Need to send a code with each login?
				yield put(loginGoTo2FASuccess());
				onEmailAlreadySent?.({ apiParams });
				yield put(authInitState());
			} else {
				if (error?.response?.data?.errors) {
					yield put(authInitState());
					callErrorFunc(error?.response?.data?.errors);
					return;
				}
				yield put(authInitState());
				responseErrors(error);
			}
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

function handleRegistrationError(error: any): string | null {
	if (error?.response?.data?.errors) {
		const errors = error?.response?.data.errors;
		if (errors.phone && errors.phone[0] === 'the_phone_has_already_been_taken') {
			return 'The phone has already been taken';
		}
		if (errors.email && errors.email[0] === 'the_email_has_already_been_taken') {
			return 'The email has already been taken';
		}
		if (errors.phone && errors.phone[0] === 'the_phone_must_be_at_least10_characters') {
			return 'The phone must be at least 10 characters';
		}
		callErrorFunc(errors);
	}
	return null;
}

// =============================================================:
function* registrationRequestWorker(action: PayloadAction<IRegistrationPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: IRegistrResponse = yield call(api.auth.registration, payload.data);
		yield put(registrationSuccess(response));

		notificationContainer('Registered successfully', 'info');

		if (payload?.data?.email) {
			payload.navigate('/confirm', {
				state: {
					email: payload.data.email,
					password: payload.data.password,
					type: 'register',
				},
			});
		} else if (payload?.data?.phone) {
			payload.navigate('/confirm-phone', {
				state: {
					phone: payload.data.phone,
					password: payload.data.password,
					type: 'register',
				},
			});
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = handleRegistrationError(error);
			if (errorMessage) {
				notificationContainer(errorMessage, 'error');
			} else {
				responseErrors(error);
			}
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

/* eslint-disable */

// =============================================================:
function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;
	const { email, phone, navigate } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, { [`${email ? 'email' : 'phone'}`]: email || phone });
		yield put(forgotPasswordSuccess());

		if (email && navigate) {
			notificationContainer('Check the email', 'info');
			navigate('/check-email', {
				state: {
					email: payload.email,
				},
			});
			return;
		}
		if (phone && navigate) {
			notificationContainer('Check the phone', 'info');
			navigate('/confirm-phone', {
				state: {
					token: true,
					phone: payload.phone,
				},
			});

			return;
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 404 &&
				error?.response?.data?.errors[0] === 'model_not_found' &&
				phone
			) {
				notificationContainer('Incorrect phone number', 'info');
				yield put(authInitState());
				return;
			}
			if (
				error?.response?.status === 404 &&
				error?.response?.data?.errors[0] === 'model_not_found' &&
				email
			) {
				notificationContainer('Incorrect email address', 'info');
				yield put(authInitState());
				return;
			}
			yield put(authInitState());
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* forgotPasswordEmailRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;
	const { email, phone, navigate } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, { [`${email ? 'email' : 'phone'}`]: email || phone });
		yield put(forgotPasswordSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* emailConfirmRequestWorker(action: PayloadAction<IEmailConfirmPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.emailConfirm, payload);
		yield put(emailConfirmStatusCallback());

		yield put(emailConfirmSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* emailResetConfirmTokenRequestWorker(
	action: PayloadAction<IEmailResetConfirmTokenPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.emailResetConfirmToken, payload);
		yield put(emailConfirmStatusCallback());
		yield put(emailResetConfirmTokenSuccess());

		// payload.history.replace('/email-confirm-check');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				yield put(authInitState());
				if (error?.response?.data?.errors) {
					yield put(authInitState());
					callErrorFunc(error?.response?.data?.errors);
					return;
				}
				responseErrors(error);
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* createNewPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, payload.data);
		yield put(createNewPasswordSuccess());
		payload.navigate('/confirm-new-password');

		notificationContainer('Password successfully changed', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<ILogoutPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.navigate('/');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* generateSmsRequestWorker(action: PayloadAction<IGenerateSmsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		yield call(api.auth.generateSms, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// if (error?.response?.status === 400) {
			// 	notificationContainer('Please, try again after countdown finish!', 'error');
			// 	return;
			// }

			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* smsNewPasswordCheckPhoneWorker(action: PayloadAction<IGenerateSmsChecktPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: ITokenResponse = yield call(api.auth.checkSmsNewPasswod, payload);
		if (response.data) {
			const message: ITokenResponse = response.data;
			if (message.status === 'error') {
				notificationContainer('Error token', 'error');
				return;
			}
		}

		payload.navigate(`/recovery_password?${payload.token}`, { state: 'phone' });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}

			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* confirmPhoneRequestWorker(action: PayloadAction<IGenerateSmsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		yield call(api.auth.confirmPhone, payload?.data);
		if (payload?.setConfirm) {
			payload?.setConfirm(true);
		}
		notificationContainer('Registered successfully', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resetTwoFaRequestWorker(action: PayloadAction<IResetTwoFaPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.resetTwoFa, payload);
		// notificationContainer(String(L.translate(`Success.2fa_successfully`)), 'success');
		// payload.history.push({ pathname: '/forgot-2fa', state: '2fa_success' });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resendEmailWorker(action: PayloadAction<IResendEmail>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IRegistrResponse = yield call(api.auth.resendEmail, payload);

		yield put(registrationSuccess(response));
		notificationContainer(`Send_successfully`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				if (
					error?.response?.data?.errors[0] === 'you_can_request_confirm_email_in_several_minutes'
				) {
					notificationContainer('You can request confirm email in several minutes', 'info');
					return;
				}
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}

			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resendPhoneWorker(action: PayloadAction<IResendPhone>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IRegistrResponse = yield call(api.auth.resendPhone, payload);

		yield put(registrationSuccess(response));
		notificationContainer(`Send_successfully`, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				if (error?.response?.data?.errors[0] === 'you_can_request_confirm_sms_in_several minutes') {
					notificationContainer('You can request confirm sms in several minutes', 'info');
					return;
				}
				callErrorFunc(error?.response?.data?.errors);
				return;
			}

			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* refreshTokenWorker({ payload }: PayloadAction<IRefreshTokenRequest>) {
	try {
		const response: IRefreshTokenResponse = yield call(api.auth.refreshToken, payload.token);
		yield put(getRefreshTokenSuccess(response));
	} catch (error) {
		// yield put(authInitState());
	}
}

function* getQrcodeWorker() {
	try {
		const response: string = yield call(api.auth.refrralQrcode);
		yield put(getQrcodeSuccess(response));
	} catch (error) {
		// yield put(authInitState());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(registrationRequest.type, registrationRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(emailConfirmRequest.type, emailConfirmRequestWorker);
	yield takeEvery(emailResetConfirmTokenRequest.type, emailResetConfirmTokenRequestWorker);
	yield takeEvery(createNewPasswordRequest.type, createNewPasswordWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(generateSmsRequest.type, generateSmsRequestWorker);
	yield takeEvery(forgotTwoFaRequest.type, resetTwoFaRequestWorker);
	yield takeEvery(phoneConfirmRequest.type, confirmPhoneRequestWorker);
	yield takeEvery(resendEmailRequest.type, resendEmailWorker);
	yield takeEvery(resendPhoneRequest.type, resendPhoneWorker);
	yield takeEvery(phoneConfirmSmsNewPassRequest.type, smsNewPasswordCheckPhoneWorker);
	yield takeEvery(forgotPasswordEmailRequest.type, forgotPasswordEmailRequestWorker);
	yield takeEvery(getRefreshTokenRequest, refreshTokenWorker);
	yield takeEvery(getQrcodeRequest, getQrcodeWorker);
}
