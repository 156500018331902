/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable */
import { FC, useEffect, useMemo, useState, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useDispatch, useSelector } from 'react-redux';
import AbiTokens from 'config/abi/GUSD.json';
import { ReactComponent as GatoChain2Icon } from 'assets/images/gatochain2.svg';
import { ArrowBack, TooltipNotation } from 'assets/custom-icons';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getAuthIsAuthenticated, getAuthTokenRefresh } from 'redux/reducers/auth/selectors';
import { feeChecker } from 'services/utils/feeChecker';
import { getDividendsRequest } from 'redux/reducers/earnings/reducer';
import { getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import AbiFee from 'config/abi/FeeController.json';
import { TWalletData } from 'redux/reducers/wallets/types';
import { noExponent } from 'services/utils/noExponent';
import { stakingApprove, getSelector } from 'services/utils/stakingPerDividents';
import { toMaxDecimals } from 'services/utils/numbers';
import classNames from 'classnames';
import { getApy } from 'redux/reducers/earnings/selectors';
import { getDividends } from 'redux/reducers/earnings/selectors';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import {
	getStakingDividentsRequest,
	sendStakeTransactionRequest,
} from 'redux/reducers/earnings/reducer';
import { ApproveTokens } from 'services/utils/approve';
import {
	numberInDecimal,
	getInitTokenSymbols,
	getTokenBySymbol,
	getTokensWithoutSymbol,
	getIsTokenPairInList,
	getRateForTokenPair,
	getRateByDirection,
	getOppositeValue,
	numberOutDecimal,
} from '../Exchange/ExchangeForm/utils';
import { IStakingDividentsRequest } from 'redux/reducers/earnings/types';
import { useDebounce } from 'hooks/useDebounce';
import Loader from 'ui/Loader';
import AbiStakingDividents from 'config/abi/Dividends.json';
import { useWeb3React } from '@web3-react/core';
import { ITypeTransaction, IToken } from './types';
import ConfirmStakePopUp from './ConfirmStakePopUp';
import { authInitState } from 'redux/reducers/auth/reducer';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import { upgrateDate } from 'components/Dividends/utils';
import useRefreshToken from 'hooks/useRefreshToken';
// gato = 12 digits

declare let window: any;
const PERCENTS = [0.25, 0.5, 0.75, 1];
const DURATION = ['1', '2', '3', '6', '12'];
const Gato_Coin = '0x561d0f5fd6cda5D9112ccA9231208684D4F8800E';
const Gato_Ambassador = '0xDDDC21dfa46b7C7B723C09cd351F5E864b1EB8B3';
const Gato_Tank = '0x9fA3574F8D42038Faee2CFFE77b8e34A25Cb460B';

const Web3 = require('web3');

const RPC_HOST = String(process.env.REACT_APP_RPC_URL);

const DIVIDENT_CONTRACT = String(process.env.REACT_APP_DIVIDENTS_CONTRACT_ADDRESS);

const _web3 = new Web3(RPC_HOST);

// const RouterContract = new _web3.eth.Contract(AbiRouter.abi, ROUTER_CONTRACT);

export const StakePerDividents: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tokenRefresh = useRefreshToken();
	const dividents = useSelector(getDividends);
	const [loader, setLoader] = useState(false);
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const address = useSelector(getAddress);
	const walletsUser = useSelector(getWalletsUser);
	const walletLoader = useSelector(getWalletLoader);
	const apy = useSelector(getApy);
	const [value, setValue] = useState('');
	const [monts, setMonts] = useState('1');
	const { provider, account } = useWeb3React();
	const [activeCurrency, setActiveCurrency] = useState('GATO');
	const [token, setToken] = useState<TWalletData | undefined>(undefined);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const { address: addressWeb3, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();

	const debouncedAmount = useDebounce(value, 500);
	const debouncedDays = useDebounce(monts, 500);

	const handleDuration = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setMonts(name);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};
	useEffect(() => {
		if (walletsUser && !token) {
			const gatoCoin = walletsUser?.balances?.data?.find(
				(item: TWalletData) => item?.asset?.symbol === 'GATO' && item?.asset?.type === 'coin',
			);
			setToken(gatoCoin);
		}
	}, [walletsUser]);

	useEffect(() => {
		if (address.wallet && token) {
			dispatch(
				getDividendsRequest({
					wallet_address: address.wallet?.toLowerCase(),
					asset_address: String(token?.asset.address),
				}),
			);
		}
	}, [dispatch, address, token]);

	const [currentPercent, setCurrentPercent] = useState(0);
	const handleClickPercentButtons = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		setCurrentPercent(Number(name));
		setValue(String(Number(name) * Number(token?.balance)));
	};

	const currentContract = (tokenType: TWalletData | undefined) => {
		if (tokenType?.asset.symbol === 'GGGGG') {
			return Gato_Tank;
		} else if (tokenType?.asset.symbol === 'GLAM') {
			return Gato_Ambassador;
		}
		return Gato_Coin;
	};

	const handleMax = () => {
		setValue(toMaxDecimals(String(token?.balance), 12));
	};

	const minValue = 50;

	const handleStake = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		let currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
		if (!currentProvider) return;

		if (address.wallet) {
			try {
				let currentAddress = connector === 'INJECTED' ? account : addressWeb3;
				let accounts;
				if (connector) {
					// accounts = await library?.provider.request({
					// 	method: 'eth_requestAccounts',
					// });
				}
				const amountInWithDecimals = numberInDecimal(Number(value), Number(18));

				const paramsSelector: any = {
					to: String(DIVIDENT_CONTRACT),
					abi: AbiStakingDividents.abi,
					provider: currentProvider,
				};

				const selector = await getSelector(paramsSelector);
				if (!selector) {
					notificationContainer(`Somthing wrong with selector`, 'error');
				}
				debugger;
				const paramsFee: any = {
					wallet: currentAddress,
					selector: String(selector),
					abi: AbiFee.abi,
					tokenAddress: String(token?.asset?.address),
					contractAddess: String(DIVIDENT_CONTRACT), // Address contract
					provider: currentProvider,
					setLoader,
					connector,
				};

				const amountFee = await feeChecker(paramsFee);
				// if (amountFee) {
				// 	setFee(String(numberOutDecimal(amountFee, Number(tokenAdressGATOCoin?.asset.decimals))));
				// }

				const paramsToken: IToken = {
					wallet: String(currentAddress),
					amount: String(noExponent(Math.round(Number(amountInWithDecimals.toString())))),
					abi: AbiTokens.abi,
					tokenName: String(token?.asset.name),
					tokenAddress: String(token?.asset?.address), // Address
					// contractAddess: String(process.env.REACT_APP_ROUTER_ADDRESS), // Address contract
					contractAddess: String(DIVIDENT_CONTRACT), // Address contract
					provider: currentProvider,
					setLoader,
					connector,
				};
				setOpenConfirmModal(true);

				let hash = undefined;
				if (token?.asset.symbol !== 'GATO') {
					hash = await ApproveTokens(paramsToken);
				}

				if (hash?.status) {
					setLoader(false);
				} else if (hash === undefined && token?.asset.symbol !== 'GATO') {
					setOpenConfirmModal(false);
					return;
				}

				const sendParams: ITypeTransaction = {
					wallet: String(currentAddress),
					connector,
					abi: AbiStakingDividents.abi,
					amount: Number(value),
					lockPeriod: monts,
					tokenAddress: String(token?.asset?.address),
					provider: currentProvider,
					contractAddress: String(DIVIDENT_CONTRACT),
					coin: token?.asset.symbol === 'GATO' && token?.asset.type === 'coin',
					amountFee,
					decimal: token?.asset.decimals,
					setOpenConfirmModal,
				};

				const result = await stakingApprove(sendParams);

				if (result) {
					const payload: IStakingDividentsRequest = {
						tx: String(result?.transactionHash),
						period: Number(monts),
					};
					dispatch(getStakingDividentsRequest(payload));
					// navigate('/staking');
				}
			} catch (error: any) {
				notificationContainer(`Error: ${String(error?.message)}`, 'error');
			}
		}
	};

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
					current_page: '1',
					per_page: 1000,
				}),
			);
		}
		window.scrollTo(0, 0);
	}, [dispatch, isAuthenticated, address]);

	const handleClick = (tokenCode: string) => {
		setActiveCurrency(tokenCode);

		setValue('');
		setCurrentPercent(0);
		const gatoCoin = walletsUser?.balances?.data?.find(
			(item: TWalletData) =>
				(item?.asset?.symbol === tokenCode && item?.asset?.type === 'coin') ||
				item?.asset?.symbol === tokenCode,
		);
		setToken(gatoCoin);
	};

	const unfrozeBtn = dividents && dividents?.staking?.unfroze;
	const disableAllBtn = dividents?.staking ? true : false;
	const disableBtn = unfrozeBtn || disableAllBtn;

	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="back-to-page">
						<Link to="/dividends" className="icon-btn">
							<ArrowBack />
						</Link>
						<h3 className="block-title block-title--smaller ">Stake per GATO</h3>
					</div>
					<div className="swap swap--mb">
						<button
							onClick={() => handleClick('GATO')}
							className={`swap__item ${activeCurrency === 'GATO' ? 'active' : null}`}
						>
							Coin
						</button>
						<button
							onClick={() => handleClick('GGGG')}
							className={`swap__item ${activeCurrency === 'GGGG' ? 'active' : null}`}
						>
							GFIVE
						</button>
						<button
							onClick={() => handleClick('GLAM')}
							className={`swap__item ${activeCurrency === 'GLAM' ? 'active' : null}`}
						>
							GLAM
						</button>
					</div>

					<div className="general-block general-block--center">
						<div className="table-position">
							{walletLoader && (
								<div className="table-loader-wrapper">
									<Loader />
								</div>
							)}
							<div className="token-box-wrapper">
								<div className="token-box">
									<div className="token-box__icon-4">
										{activeCurrency === 'GATO' ? (
											<GatoChain2Icon className="token-box__icon-1" />
										) : (
											<img
												className="token-box__icon-1"
												src={token?.asset.logo ? token?.asset.logo : ''}
												alt=""
											/>
										)}
									</div>
									<div className="token-box__info">
										<div className="token-box__name">{activeCurrency}</div>
									</div>
								</div>
								<div className="token-box">
									<div className="token-box__info">
										<p className="token-box__text token-box__text--right">Amount available</p>
										<div className="token-box__name token-box__name--fw700 token-box__text--right">
											{toMaxDecimals(String(token?.balance || 0), 6)} {activeCurrency}
										</div>
									</div>
								</div>
							</div>

							<div className="block-content">
								<div className="token-box-wrapper">
									<div className="token-box">
										<div className="token-box__info">
											<p className="token-box__text token-box__text--tooltip">
												Duration (monts)
												<span className="tooltip-item">
													<span className="tooltip-item__icon">
														<TooltipNotation />
													</span>
													<span className="tooltip tooltip--top tooltip--left">
														<span className="tooltip__text">
															Staking requires a lockup period, when your crypto can't be
															transferred for a certain period of time
														</span>
													</span>
												</span>
											</p>

											<div className="percent-buttons">
												{DURATION.map((mont) => (
													<button
														key={mont}
														type="button"
														className={classNames('percent-buttons__item', {
															active: mont === monts,
														})}
														name={mont}
														onClick={handleDuration}
													>
														{mont}
													</button>
												))}
											</div>
										</div>
									</div>
								</div>
								<div className="earning--date-period">
									{dividents?.staking && (
										<p>
											Stake period{' '}
											<span>
												{dividents?.staking.start_staking} -{' '}
												{upgrateDate(new Date(String(dividents?.staking?.end_staking)))}
											</span>
										</p>
									)}
								</div>

								<p className="block-text">Amount</p>

								<div className="exchange-item exchange-item--small-height">
									<p className="staking-name">{activeCurrency}</p>
									<div className="input input--exchange">
										<label>
											<div className="input-wrapper">
												<input
													className="input-item input-item--exchange input-item--get-info input-item--small-height"
													type="number"
													placeholder={`Min. ${minValue}`}
													// min="50"
													value={noExponent(toMaxDecimals(String(value), 6))}
													onChange={handleValueChange}
												/>
												<button type="button" onClick={handleMax} className="exchange-max-btn">
													MAX
												</button>
											</div>
										</label>
									</div>
								</div>
								<div className="percent-btns">
									{PERCENTS.map((percent) => (
										<button
											key={percent}
											type="button"
											name={String(percent)}
											className={`percent-btns__item ${percent === currentPercent ? 'active' : ''}`}
											onClick={handleClickPercentButtons}
										>
											{percent * 100}%
										</button>
									))}
								</div>
							</div>

							<div className="block-footer">
								<button
									type="button"
									disabled={!address.wallet || !value}
									onClick={handleStake}
									className="button button--full-width"
								>
									{address.wallet ? 'Stake it' : 'Please connect wallet'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ConfirmStakePopUp
				openModal={openConfirmModal}
				setOpenModal={setOpenConfirmModal}
				title="Stake"
				text={`${value} ${activeCurrency} per ${monts}`}
				loader={loader}
				itemIn={token}
				fromValue={value}
			/>
		</section>
	);
};
